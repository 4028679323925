<!-- 本体 + 变径 + 轨道 + 回料合 -->
<template>
    <div>
        <div id="prviewArea">

            <div id="canvas3d" height="320px"></div>
            <canvas id="canvas" height="320px" v-show="false"></canvas>
        </div>
        <div class="selectBox">
            <div :key="index" v-for="(item, index) in sufaceGroups">
              <input type="checkbox" :checked="item.show" >{{ item.name }} 
             </div>
      </div>
        <div id="controlerArea">
            <div style="border-bottom: 1px solid #25ccdb;padding-bottom: 5px;">设置参数: ({{active + 1 }} / {{ steps.length }})</div>
            <!-- 步骤1-->
            <div v-show="steps[0]">

                <van-row>
                    <van-col span="6"><span>本体直径</span></van-col>
                    <van-col span="10">
                        <van-slider v-model="diameter" active-color="#3ea5b9" :min="minDiameter" :max="maxDiameter"
                            :step="10">
                            <template #button>
                                <div class="custom-button">{{ diameter }}</div>
                            </template>
                        </van-slider>
                    </van-col>
                    <van-col span="8"><van-stepper v-model="diameter" :min="minDiameter" :max="maxDiameter"
                            :step="10" /></van-col>
                </van-row>
                <van-row>
                    <van-col span="6"><span>道宽</span></van-col>
                    <van-col span="10">
                        <van-slider v-model="width0" active-color="#3ea5b9" :min="minWidth0" :max="maxWidth0" :step="5">
                            <template #button>
                                <div class="custom-button">{{ width0 }}</div>
                            </template>
                        </van-slider>
                    </van-col>
                    <van-col span="8"><van-stepper v-model="width0" :min="minWidth0" :max="maxWidth0" :step="5" /></van-col>
                </van-row>
                <van-row>
                    <van-col span="6"><span>螺距</span></van-col>
                    <van-col span="10">
                        <van-slider v-model="luoju" active-color="#3ea5b9" :min="minLuoju" :max="maxLuoju" :step="5">
                            <template #button>
                                <div class="custom-button">{{ luoju }}</div>
                            </template>
                        </van-slider>
                    </van-col>
                    <van-col span="8"><van-stepper v-model="luoju" :min="minLuoju" :max="maxLuoju" :step="5" /></van-col>
                </van-row>
                <van-row>
                    <van-col span="6"><span>圈数</span></van-col>
                    <van-col span="10">
                        <van-slider v-model="quanshu" active-color="#3ea5b9" :min="minQuanshu" :max="maxQuanshu"
                            :step="0.5">
                            <template #button>
                                <div class="custom-button">{{ quanshu }}</div>
                            </template>
                        </van-slider>
                    </van-col>
                    <van-col span="8"><van-stepper v-model="quanshu" :min="minQuanshu" :max="maxQuanshu"
                            :step="0.5" /></van-col>
                </van-row>
                <van-row type="flex" justify="end">
                    <van-col span="6"><van-button type="info" size="small" @click="next">下一步</van-button></van-col>
                </van-row>
            </div>

            <!-- 步骤2-->
            <div v-show="steps[1]">
                <van-row>
                    <van-col span="6"><span>变径对应角度</span></van-col>
                    <van-col span="10">
                        <van-slider v-model="theta" active-color="#3ea5b9" :min="minTheta" :max="maxTheta" :step="5">
                            <template #button>
                                <div class="custom-button">{{ theta }}</div>
                            </template>
                        </van-slider>
                    </van-col>
                    <van-col span="8"><van-stepper v-model="theta" :min="minTheta" :max="maxTheta" :step="5" /></van-col>
                </van-row>
                <van-row>
                    <van-col span="6"><span>变径尺寸</span></van-col>
                    <van-col span="10">
                        <van-slider v-model="width" active-color="#3ea5b9" :min="minWidth" :max="maxWidth" :step="5">
                            <template #button>
                                <div class="custom-button">{{ width }}</div>
                            </template>
                        </van-slider>
                    </van-col>
                    <van-col span="8"><van-stepper v-model="width" :min="minWidth" :max="maxWidth" :step="5" /></van-col>
                </van-row>
                <van-row>
                    <van-col span="6"><span>径向落差</span></van-col>
                    <van-col span="10">
                        <van-slider v-model="decline_jingxiang" active-color="#3ea5b9" :min="min_decline_jingxiang"
                            :max="max_decline_jingxiang" :step="2">
                            <template #button>
                                <div class="custom-button">{{ decline_jingxiang }}</div>
                            </template>
                        </van-slider>
                    </van-col>
                    <van-col span="8"><van-stepper v-model="decline_jingxiang" :min="min_decline_jingxiang"
                            :max="max_decline_jingxiang" :step="2" /></van-col>
                </van-row>
                <van-row>
                    <van-radio-group v-model="bianjing" direction="horizontal">
                        <van-col span="6">模式</van-col>

                        <van-col span="9">
                            <van-radio name="yuanhu">圆弧变径</van-radio>
                        </van-col>
                        <van-col span="9">
                            <van-radio name="pinghua">平滑变径</van-radio>
                        </van-col>
                    </van-radio-group>
                </van-row>
                <van-row type="flex" justify="end">
                    <van-col span="4"> <van-button type="info" size="small" @click="prev">上一步</van-button></van-col>
                    <van-col span="6"><van-button type="info" size="small" @click="next">下一步</van-button></van-col>
                </van-row>
            </div>
            <!-- 步骤3-->
            <div v-show="steps[2]">
                <van-row>
                    <van-col span="6"><span>轨迹半径</span></van-col>
                    <van-col span="10">
                        <van-slider v-model="qidian" active-color="#3ea5b9" :min="minQidian" :max="maxQidian" :step="10">
                            <template #button>
                                <div class="custom-button">{{ qidian }}</div>
                            </template>
                        </van-slider>
                    </van-col>
                    <van-col span="8"><van-stepper v-model="qidian" :min="minQidian" :max="maxQidian"
                            :step="10" /></van-col>
                </van-row>
                <van-row>
                    <van-col span="6"><span>轨迹圆心角</span></van-col>
                    <van-col span="10">
                        <van-slider v-model="yuanxinjiao" active-color="#3ea5b9" :min="minYuanxinjiao" :max="maxYuanxinJiao"
                            :step="5">
                            <template #button>
                                <div class="custom-button">{{ yuanxinjiao }}</div>
                            </template>
                        </van-slider>
                    </van-col>
                    <van-col span="8"><van-stepper v-model="yuanxinjiao" :min="minYuanxinjiao" :max="maxYuanxinJiao"
                            :step="5" /></van-col>
                </van-row>
                <van-row>
                    <van-col span="6"><span>轨迹落差</span></van-col>
                    <van-col span="10">
                        <van-slider v-model="luocha" active-color="#3ea5b9" :min="minLuocha" :max="maxLuocha" :step="5">
                            <template #button>
                                <div class="custom-button">{{ luocha }}</div>
                            </template>
                        </van-slider>
                    </van-col>
                    <van-col span="8"><van-stepper v-model="luocha" :min="minLuocha" :max="maxLuocha" :step="5" /></van-col>
                </van-row>
                <van-row>
                    <van-col span="6"><span>扭转角度</span></van-col>
                    <van-col span="10">
                        <van-slider v-model="niuzhuan" active-color="#3ea5b9" :min="minNiuzhuan" :max="maxNiuzhuan"
                            :step="5">
                            <template #button>
                                <div class="custom-button">{{ niuzhuan }}</div>
                            </template>
                        </van-slider>
                    </van-col>
                    <van-col span="8"><van-stepper v-model="niuzhuan" :min="minNiuzhuan" :max="maxNiuzhuan"
                            :step="5" /></van-col>
                </van-row>
                <van-row>
                    <van-col span="6"><span>出口直段</span></van-col>
                    <van-col span="10">
                        <van-slider v-model="zhiduan" active-color="#3ea5b9" :min="minZhiduan" :max="maxZhiduan" :step="5">
                            <template #button>
                                <div class="custom-button">{{ zhiduan }}</div>
                            </template>
                        </van-slider>
                    </van-col>
                    <van-col span="8"><van-stepper v-model="zhiduan" :min="minZhiduan" :max="maxZhiduan"
                            :step="5" /></van-col>
                </van-row>
                <van-row type="flex" justify="end">
                    <van-col span="4"> <van-button type="info" size="small" @click="prev">上一步</van-button></van-col>
                    <van-col span="6"><van-button type="info" size="small" @click="next"
                            v-show="false">下一步</van-button></van-col>
                </van-row>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from "vue";
import {
    Col,
    Row,
    Slider,
    Stepper,
    Image as VanImage,
    Button,
    Toast,
    Swipe,
    SwipeItem,
    Checkbox,
    CheckboxGroup,
    Popup,
    Field,
    RadioGroup,
    Radio,
} from "vant";
import * as THREE from "three";
import { ParametricGeometry } from "three/examples/jsm/geometries/ParametricGeometry";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader";
import { DragControls } from "three/examples/jsm/controls/DragControls";
import { TransformControls } from "three/examples/jsm/controls/TransformControls";
import { RoomEnvironment } from "three/examples/jsm/environments/RoomEnvironment";
import VueCookies from "vue-cookies";
import { Base64 } from "js-base64";
import { Loading } from "vant";
import LoginBox from "@/libs/LoginBox";
import BrowserInfo from "@/libs/Browserinfo";
Vue.use(Loading);
Vue.use(VueCookies);
Vue.use(Col);
Vue.use(Slider);
Vue.use(Row);
Vue.use(Stepper);
Vue.use(Button);
Vue.use(VanImage);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Popup);
Vue.use(Field);
Vue.use(Radio);
Vue.use(RadioGroup);
export default {
    name: "bezier",

    data() {

        return {
            /* 控制显示的步骤 */
            steps: [true, false, false, false],
            /**
             * 画布
              */
            canvas: null,
            /**
             * 场景 
             */
            scene: null,

            renderer: null,

            /**
             * 相机
             */
            camera: null,
            /**
               * 背景颜色
               */
            backgroundColor: 0xbbbbbb,
            /**
             * 控制器
             */
            controls: {},
            /**
             * 辅助坐标
             */
            axesHelper: null,
            transformControls: {},
            /**
              * 当前激活的步骤
              */
            active: 0,

            /**
             *本体直径
             **/
            diameter: 400,
            /**
             * 最小直径
             **/
            minDiameter: 50,
            /**
             * 最大直径 
             **/
            maxDiameter: 600,
            /**
             * 道宽
             **/
            width0: 20,
            minWidth0: 10,
            maxWidth0: 60,

            /**
             * 螺距
             * */
            luoju: 50,
            minLuoju: 20,
            maxLuoju: 200,

            /**
             * 圈数
             * */
            quanshu: 2,
            minQuanshu: 1,
            maxQuanshu: 5,

            /**
             * 第一段道宽
             * */
            width0_1st: 15,
            minWidth0_1st: 0,
            maxWidth0_1st: 40,

            /**
             * 第一段圈数
             **/
            quanshu_1st: 0.5,
            minQuanshu_1st: 0,
            maxQuanshu_1st: 1,

            /**
             * 变径对应角度
             **/
            theta: 90,
            minTheta: 45,
            maxTheta: 135,

            /**
             * 变径尺寸
             **/
            width: 50,
            minWidth: 20,
            maxWidth: 200,

            /**
             * 径向落差
             * */
            decline_jingxiang: 10,
            min_decline_jingxiang: 0,
            max_decline_jingxiang: 30,

            /**
             * 变径
             * */
            bianjing: 'yuanshu',

            //第三步骤
            /**
             * 轨迹半径
             * */
            qidian: 250,
            minQidian: 50,
            maxQidian: 500,

            /**
             * 轨迹圆心角
             * */
            yuanxinjiao: 180,
            minYuanxinjiao: 30,
            maxYuanxinJiao: 330,

            /**
             * 轨迹落差
             * */
            luocha: 10,
            minLuocha: 0,
            maxLuocha: 80,

            /**
             * 扭转角度
             * */
            niuzhuan: 180,
            minNiuzhuan: -180,
            maxNiuzhuan: 180,

            /**
             * 出口直段
             * */
            zhiduan: 100,
            minZhiduan: 0,
            maxZhiduan: 300,

            // 第四步骤
            /**
             * 回料点
             * */
            huiliaodian: 270,
            minHuiliaodian: 0,
            maxHuiliaodian: 360,

            /**
             * 回料落差
             * */
            huiliaoluocha: 16,
            minHuiliaoluocha: 0,
            maxHuiliaoluocha: 50,

            /**
             * 回料盒起点
             * */
            huiliaoheyuanxinjiao: 30,
            minHuiliaoheyuanxinjiao: 0,
            MaxHuiliaoheyuanxinjiao: 360,

            /**
             * 回料口大小
             * */
            huiliaokoudaxiao: 60,
            minHuiliaokoudaxiao: 15,
            maxHuiliaokoudaxiao: 150,

            /**
             * 回料盒切角
             * */
            huiliaoheqiejiao: 0.05,
            minHuiliaoheqiejiao: 0,
            maxHuiliaoheqiejiao: 0.2,

            /**
             * 三角
             * */
            sanjiao: 270,
            minSanjiao: 0,
            maxSanjiao: 360,

            sufaceGroups: [{ name: "本体侧面", show: true }, { name: "本体底部", show: true }, { name: "跑道", show: true }, { name: "变径", show: true },
            { name: "轨道", show: true }, { name: "回料口", show: true }, { name: "回料盒", show: true }, { name: "三角板", show: true }]
        }
    },
    mounted() {
        this.canvas = document.getElementById("canvas")
        this.initThree()
        this.animate()
    },
    methods: {
        /**
         * 初始化threejs
         */
        initThree() {
            console.log("init three scene")
            this.scene = new THREE.Scene()
            // 点光源
            const ambientLight = new THREE.AmbientLight(0x444444)
            this.scene.add(ambientLight)

            const DirLight = new THREE.DirectionalLight(0xffffff, 0.8)
            this.scene.add(DirLight)
            this.width = window.innerWidth // 窗口宽度
            this.height = 320

            let spotLight = new THREE.SpotLight(0xffffff, 1.6)
            spotLight.angle = Math.PI / 2
            spotLight.penumbra = 0
            spotLight.position.set(600, 0, 0)
            this.scene.add(spotLight)

            let spotLight2 = new THREE.SpotLight(0xffffff, 1.6)
            spotLight2.angle = Math.PI / 2
            spotLight2.penumbra = 0
            spotLight2.position.set(-600, 0, 0)
            this.scene.add(spotLight2)
            // 创建相机对象
            //this.camera = new THREE.PerspectiveCamera(this.cameraWatchAngle, width / height, 0.1, 10000)
            this.camera = new THREE.OrthographicCamera(this.width / - 2, this.width / 2, this.height / 2, this.height / - 2, 1, 10000)
            //camera.add(pointLight)
            //this.scene.fog = new THREE.Fog(0xd3e6f0, 1,200)
            this.scene.add(this.camera)



            /**
                    * 创建渲染器对象
                    */
            this.renderer = new THREE.WebGLRenderer({ antialias: true })
            this.renderer.setSize(this.width, this.height)// 设置渲染区域尺寸
            this.renderer.setClearAlpha(0.0)
            this.renderer.outputEncoding = THREE.sRGBEncoding;
            document.getElementById('canvas3d').appendChild(this.renderer.domElement) // body元素中插入canvas对象
            const pmremGenerator = new THREE.PMREMGenerator(this.renderer);
            this.scene.background = new THREE.Color(this.backgroundColor);
            this.scene.environment = pmremGenerator.fromScene(new RoomEnvironment(), 0.04).texture;
            this.controls = new OrbitControls(this.camera, this.renderer.domElement)
            this.controls.target.set(0, 0.5, 0)
            this.controls.update()
            this.controls.enablePan = true
            this.controls.dampingFactor = 0.15
            this.controls.enableDamping = true
            //this.controls.maxPolarAngle = Math.PI / 2
            /* eslint new-cap: ["error", { "newIsCap": false }] */
            this.renderer.render(this.scene, this.camera)
            console.log("finish three scene")

        },
        animate() {
            requestAnimationFrame(this.animate)
            // graphMesh && (graphMesh.rotation.z += 0.01);
            this.controls.update()
            // console.log(this.controls.target)
            this.renderer.render(this.scene, this.camera)
        },
        /**
    * 下一步
    */
        next() {
            if (this.active < this.steps.length - 1) {
                this.active++;
                this.showStep(this.active)
            }
        },
        /**
         * 上一步
         */
        prev() {
            if (this.active > 0) {
                this.active--;
                this.showStep(this.active)
            }

        },
        /**
         * 展示步骤
         */
        showStep(no) {
            for (var i = 0; i < this.steps.length; i++) {
                if (i !== no) {
                    this.$set(this.steps, i, false)
                } else {
                    this.$set(this.steps, i, true)
                }
            }

        }
    }
}
</script>
<style scoped>
#prviewArea {
    height: 300px;
    width: 100%;
}
#controlerArea {
    padding-top: 30px;
}
.selectBox {
    position: absolute;
    top: 70px;
    left: 5px;
    color: #1e0ea9;
    font-size: 13px;
    background: #aecbe9;
    border-radius: 5px;
    box-shadow: 5px 5px 2px #888888;
    width: 80px;
    text-align: left;
}
.custom-button {
    width: 26px;
    color: #fff;
    font-size: 10px;
    line-height: 18px;
    text-align: center;
    background-color: #3ea5b9;
    border-radius: 100px;
}

#footer {
    padding-top: 20px;
    min-height: 320px;
}

.van-row {
    padding-top: 20px;
}

.van-image {
    display: flex;
    align-items: center;
}

.van-slider {
    margin-top: 13px;
}
</style>