<template>
  <div id="app">
  <van-nav-bar 
    title="添加" 
    left-text="" 
    left-arrow 
    @click-left="onClickLeft" />

    <van-form validate-first @submit="onSubmit">
         <van-field
            v-model="name"
            name="name"
            label="应用名称"
            placeholder="请输入应用名称"
            :rules="[{ validator:nameValidator, required: true, message: '应用名称字符长度介于2-64之间' }]"
          />
          <van-field
            v-model="misc"
            name="misc"
            label="应用描述"
            placeholder="请输入应用描述"
            type="text"
            :rules="[{ validator:miscValidator, required: true, message: '应用描述字符长度介于8-512之间' }]"
          />
          <div style="margin: 16px;">
            <van-button round block type="info" native-type="submit">提交</van-button>
          </div>
        </van-form>

<van-tabbar v-model="active">
  <van-tabbar-item replace to="/home" icon="home-o">应用</van-tabbar-item>
  <van-tabbar-item replace to="/add" icon="plus">添加</van-tabbar-item>
  <van-tabbar-item replace to="/person" icon="setting-o">设置</van-tabbar-item>
</van-tabbar>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  data() {
    return {
      active: 1,
      name: '',
      misc: '',
    };
  },
  props: {
    msg: String
  },
  methods: {
    onSubmit() {
      var vue = this
      var name = this.name
      var misc = this.misc
      this.$axios
      .post(vue.$url + '/v1/surface', {
        'name': name,
        'misc': misc,
      })
      .then(response => {
        if(response.data.code == 'ok') {
          vue.$dialog.alert({
            message: "添加成功",

          }).then(() => {
            vue.$router.replace('/app/' + response.data.data.id)
          })
          
        } else {
          vue.$dialog.error({
            message: "添加失败"
          })
        }
        
      })
      .catch(function (error) { // 请求失败处理
        vue.$dialog.alert({
          message: error.response.data.message,
        })
        if (error.response.status == 401) {
          vue.$router.push('/login')
        }
      })

    },
    onClickLeft() {
      this.$router.push('/home')
    },
    // 校验函数返回 true 表示校验通过，false 表示不通过
    nameValidator(val) {
      if (val.length < 2 || val.length > 64) {
          return false
      }
      return true
    },
    miscValidator(val) {
      if (val.length < 8 || val.length > 512) {
          return false
      }
      return true
    },
  }
}
</script>
