import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/views/Login'
import Home from '@/views/Home'
import Add from '@/views/Add'
import Person from '@/views/Person'
import App from '@/views/App'
import Register from '@/views/Register'
import Pendding from '@/views/Pendding'
import AppBezier from '@/views/AppBezier'
import AppVariety from '@/views/AppVariety'
import Portal from '@/views/Portal'
import Maintain from '@/views/Maintain'
import PortalHome from '@/views/PortalHome'
import AppVibrating from '@/views/AppVibrating'
import AppExit from '@/views/AppExit'
import AppBenti from '@/views/AppBenti'

Vue.use(Router);
export default new Router({
    mode: 'history',
    routes: [
      {
        path: '/',
        name: 'portal',
        component: PortalHome
      },
      {
        path: '/portal',
        name: 'Pendding',
        component: Portal
      },
      {
        path: '/bezier/:id',
        name: 'bezier',
        component: AppBezier,
        meta: {
          title: '绘制曲线',
          requireAuth: false
        }
      },
      {
        path:  '/variety/:id',
        name: 'variety',
        component: AppVariety,
        meta: {
          title: '绘制变径曲线',
          requireAuth: false
        }
      },
      {
        path: '/exit/:id',
        name: 'exit',
        component: AppExit,
        meta: {
          title: '出口',
          requireAuth: false
        }
      },
      {
        path: '/benti/:id',
        name: 'benti',
        component: AppBenti,
        meta: {
          title: '本体',
          requireAuth: false
        }
      },
      {
        path: '/vibrating/:id',
        name: 'vibrating',
        component: AppVibrating,
        meta: {
          title: '完整功能',
          requireAuth: false
        }
      },
      {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
          title: 'Login',
          icon: 'el-icon-eleme',
          requireAuth: false
        }
      },
      {
        path: '/register',
        name: 'register',
        component: Register,
        meta: {
          title: '注册',
          icon: 'el-icon-eleme',
          requireAuth: false
        }
      },
      {
        path: '/list',
        name: 'List',
        component: Home,
        meta: {
          title: 'Home',
          icon: 'el-icon-eleme',
          requireAuth: true
        }
      },
      {
        path: '/add',
        name: 'Add',
        component: Add,
        meta: {
          title: 'Add',
          icon: 'el-icon-eleme',
          requireAuth: true
        }
      },
      {
        path: '/person',
        name: 'Person',
        component: Person,
        meta: {
          title: 'Person',
          icon: 'el-icon-eleme',
          requireAuth: true
        }
      },
      {
        path: '/app/:id',
        name: 'App',
        component: App,
        meta: {
          title: 'App',
          icon: 'el-icon-eleme',
          requireAuth: false
        }
      },{
        path: '*',
        name: '404',
        component: Portal
      }
    ]
  });