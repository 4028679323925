<template>
  <div id="app">
  <van-nav-bar 
    title="列表" 
    left-text="" 
    left-arrow 
    @click-left="onClickLeft" />

  <van-list
  v-model="loading"
  :finished="finished"
  finished-text="没有更多了"
  @load="onLoad"
>
  <van-swipe-cell v-for="item in list" :key="item.id">
  <van-cell 
    size="large"
    :id="item.id"
    :title="item.name"
    :value="item.create_time"
    :label="item.misc"
    :type="item.type"
    @click="toApp">
    <template #title>
      <van-tag v-if="item.type=='variety'" type="primary">变</van-tag>
      <van-tag v-else-if="item.type=='benti'" type="success">套</van-tag>
      <van-tag v-else type="danger">圆</van-tag>
      <span class="custom-title">{{item.name}}</span>
    </template>
  </van-cell>
    
    <template #right>
      <van-button square :value="item.id" @click="showPopup" type="info" text="编辑" />
      <van-button square :value="item.id" :name="item.name" @click="onDelete" type="danger" text="删除" />
    </template>
  </van-swipe-cell>

</van-list>

<van-popup v-model="show" round position="bottom">
      <van-form validate-first @submit="onSubmit">
         <van-field
            v-model="name"
            name="name"
            label="应用名称"
            placeholder="请输入应用名称"
            :rules="[{ validator:nameValidator, required: true, message: '应用名称字符长度介于2-64之间' }]"
          />
          <van-field
            v-model="misc"
            name="misc"
            label="应用描述"
            placeholder="请输入应用描述"
            type="text"
            :rules="[{ validator:miscValidator, required: true, message: '应用描述字符长度介于4-200之间' }]"
          />
          <div style="margin: 16px;">
            <van-button round block type="info" native-type="submit">提交</van-button>
          </div>
        </van-form>
</van-popup>

<CNaviBar active="list"></CNaviBar>
  </div>
</template>

<script>
import Vue from 'vue';
import CNaviBar from '@/components/NaviBar'
import Commons from '@/libs/Commons'
import { Tag } from 'vant';

Vue.use(Tag)
export default {
  name: 'HelloWorld',
  components: {
    CNaviBar
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      active: 0,
      show: false,
      name: '',
      misc: '',
      id: 0,
      index: 0,
      offset: 0,
      limit: 10
    };
  },
  props: {
    msg: String
  },
  methods: {
    showPopup(value) {
      var id = value.target.value
      var index = this.list.findIndex(item => {
        if (item.id == id) {
          return true;
        }
      })
      this.id = id
      this.index = index
      this.name = this.list[index].name
      this.misc = this.list[index].misc
      this.type = this.list[index].type
      this.show = true;
    },
    onSubmit() {
      var id = this.id
      // 异步更新数据
      var vue = this
      var index = this.index
      this.$axios
      .put(vue.$url + '/v1/surface/' + id, {
        'name': vue.name,
        'misc': vue.misc
      })
      .then(response => {
        console.log(response)
        vue.$dialog.alert({
          message: "更新成功"
        })
        vue.show = false
        this.list[index].name = vue.name
        this.list[index].misc = vue.misc
      })
      .catch(function (error) { // 请求失败处理
        vue.$dialog.alert({
          message: error.response.data.message,
        });
        if (error.response.status == 401) {
          vue.$router.push('/login')
        }
      })
    },
    onDelete(value) {
      
      var id = value.target.value
      var name = value.target.name
      // 异步更新数据
      var vue = this

      vue.$dialog.confirm({title:'该操作无法撤销',message:'确定删除【'+name+'】？'}).then(() => {
          vue.$axios
              .delete(vue.$url + '/v1/surface/' + id)
              .then(response => {
                console.log(response)
                vue.$dialog.alert({
                  message: "删除成功"
                })
                var index = vue.list.findIndex(item => {
                  if (item.id == id) {
                    return true;
                  }
                })
                vue.list.splice(index, 1)
              })
              .catch(function (error) { // 请求失败处理
                vue.$dialog.alert({
                  message: error.response.data.message,
                });
                if (error.response.status == 401) {
                  vue.$router.push('/login')
                }
              })
      })
      
    },
    onClickLeft() {
      this.$router.push('/')
    },
    toApp(value){
      var id = value.currentTarget.id
      var type = value.currentTarget.getAttribute('type')
      Commons.jumpSurfaceDetail(this, id, type)
    },
    onLoad(){
      setTimeout(() => {
        if (this.finished == false) {
          this.getList()
        }
      }, 1000);
    },
    getList() {
      // 异步更新数据
      var vue = this
      this.$axios
      .get(vue.$url + '/v1/surface?offset=' + vue.offset + '&limit=' + vue.limit)
      .then(response => {
        //随机取1个挑战到编辑页面
        vue.list.push(...response.data.data.list)
        // 加载状态结束
        vue.loading = false;
        if (vue.offset + vue.limit >= response.data.data.total) {
          vue.finished = true;
        }
        vue.offset += vue.limit
      
      })
      .catch(function (error) { // 请求失败处理
        vue.$dialog.alert({
          message: error.response.data.message,
        });
        if (error.response.status == 401) {
          Commons.removeLoginUser()
          vue.$router.push('/login')
        }
      })
    },
    // 校验函数返回 true 表示校验通过，false 表示不通过
    nameValidator(val) {
      if (val.length < 2 || val.length > 64) {
          return false
      }
      return true
    },
    miscValidator(val) {
      if (val.length < 4 || val.length > 200) {
          return false
      }
      return true
    },
  },
}
</script>
<style scoped>
  .custom-title {
    margin-left : 5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
</style>
