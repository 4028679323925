<template>
    <div style="padding-top:32px;">
        <div id="head">选择模式</div>
          <van-row  type="flex"  class="choose" >
          <van-col span="24" >

               <van-button type="info"  @click="generateAndEdit(0)"
              >圆周扭转轨道</van-button
            >
          </van-col>

       </van-row>
       <van-row  type="flex" class="choose" >
            <van-col span="24" >

                 <van-button type="info"  @click="generateAndEdit(2)"
              >变径</van-button
            >
            </van-col>
       </van-row>

       <van-row  type="flex" class="choose" v-show="false">
            <van-col span="24" >

                 <van-button type="info"  @click="generateAndEdit(1)"
              >不规则</van-button
            >
            </van-col>
       </van-row>
        <div class="contactUsBnt" id="contactUsBnt" >
      <div style="font-size: 1.2rem;color: #439ae5;"> 更多功能开发中，即将发布</div>
      <van-button  @click="showContactUsBox" style="border: none;">需要帮助？ 请点击这里</van-button>
      </div>
      <van-dialog v-model="showContactUs" class="contactUsBox" title="扫一扫，加我微信" >
          <van-image
            width="240"
            height="240"
            src="/contact2.jpg" ></van-image>
     </van-dialog>

    </div>

    
</template>
<script>

import Vue from 'vue'
import { Col, Row, Slider, Stepper, Image as VanImage, Button,Toast,Swipe, SwipeItem,Checkbox, CheckboxGroup,Popup ,Field,RadioGroup, Radio } from 'vant'
Vue.use(Col)
Vue.use(Slider)
Vue.use(Row)
Vue.use(Stepper)
Vue.use(Button)
Vue.use(VanImage)
Vue.use(Swipe);
Vue.use(SwipeItem)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Popup)
Vue.use(Field)
Vue.use(Radio)
Vue.use(RadioGroup)
export default {
    data() {
        return {
            showContactUs: false
        }
    },
    methods: {
        randomString(len) {
            len = len || 32;
            var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
            var maxPos = $chars.length
            var pwd = ''
            for (var i = 0; i < len; i++) {
                pwd += $chars.charAt(Math.floor(Math.random() * maxPos))
            }
            return pwd;
        },
        generateAndEdit(type) {
            let decodePath = "/6459312"
            var vue = this
            if ( this.$route.params.type) {
                type = this.$route.params.type;
            }
            let appId = localStorage.getItem("editingAppid",-1)
            let bezierId = localStorage.getItem("editingBezierId",-1)
            let varietyId = localStorage.getItem("editingVarietyId",-1)
            
            if (bezierId > 0 && type == 1) {
                this.$router.push(decodePath + '/bezier/' + bezierId)
                return 
            } 
            if (varietyId > 0 && type == 2) {
                this.$router.push(decodePath + '/variety/' + varietyId)
                return 
            } 
            if (appId > 0 && type == 0) {
                this.$router.push(decodePath + '/app/' + appId)
                return 
            } 
            
            var name = this.randomString(8)
            var misc = type
            this.$axios
            .post(vue.$url + '/v1/surface', {
                'name': name,
                'misc': misc,
            })
            .then(response => {
                if(response.data.code == 'ok') {
                  
                  localStorage.setItem("appType", type)
                  if (type == 2) {
                        localStorage.setItem("editingVarietyId", response.data.data.id)
                      vue.$router.push(decodePath + '/variety/' + response.data.data.id)
                  } else if (type == 1) {
                      localStorage.setItem("editingBezierId", response.data.data.id)
                      vue.$router.push(decodePath + '/bezier/' + response.data.data.id)
                  } else {
                      localStorage.setItem("editingVarietyId", response.data.data.id)
                      vue.$router.push(decodePath + '/app/' + response.data.data.id)
                  }
                  
            
                } else {
                vue.$dialog.error({
                    message: "添加失败"
                })
                }
                
            })
            .catch(function (error) { // 请求失败处理
                vue.$dialog.alert({
                message: error.response.data.message,
                })
                if (error.response.status == 401) {
                  vue.$router.push('/login')
                }
            })
                },
                showContactUsBox() {
      
      //let box = new LoginBox()
      this.showContactUs = true
    }
    },
    
    mounted () {

       
    }
}
</script>
<style scoped>
   #head {
     font-size: 1.2rem;
    color: #1ca4f3;
    height: 120px;
    line-height: 120px;
   }
   .choose {
       font-size: 1.5rem;
       margin-top: 20px;
   }
.contactUsBnt {
   text-align: center;
   text-align: center;
   position: fixed;
   width: 100%;
   bottom: 30px;
}
.contactUsBox /deep/.van-dialog__content {
    display: flex;
    justify-content: center;
  }
</style>