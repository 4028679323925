<template>
    <div class="container">
        <van-nav-bar  title="选择模式" left-text=""  />
          <van-row  type="flex"  class="choose" >
          <van-col span="24" >

               <van-button type="info"  url="/app/new">圆周扭转轨道</van-button>
          </van-col>

       </van-row>
       <van-row  type="flex" class="choose" >
            <van-col span="24" ><van-button type="info"  url="/variety/new">变径</van-button>
            </van-col>
       </van-row>
       <van-row  type="flex" class="choose">
        <van-col span="24" ><van-button type="info"  url="/benti/new" >套件</van-button>
        </van-col>
    </van-row>
       <van-row  type="flex" class="choose" v-show="false">
        <van-col span="24" >
            <van-button type="info"  url="/exit/new">出口</van-button>
        </van-col>
   </van-row>
       <van-row  type="flex" class="choose" v-show="false">
            <van-col span="24" >
                <van-button type="info" url="/bezier/new">不规则</van-button>
            </van-col>
       </van-row>
        <div class="contactUsBnt" id="contactUsBnt" >
      <div style="font-size: 1.2rem;color: #439ae5;display:none;"> 更多功能开发中，即将发布</div>
      <van-button  @click="showContactUsBox" style="border: none;">需要帮助？ 请点击这里</van-button>
      </div>
      <van-dialog v-model="showContactUs" class="contactUsBox" title="扫一扫，加我微信" >
          <van-image
            width="240"
            height="240"
            src="/contact.jpg" ></van-image>
     </van-dialog>
     <CNaviBar active="home"></CNaviBar>
    </div>

    
</template>
<script>

import Vue from 'vue'
import CNaviBar from '@/components/NaviBar'
import { Col, Row, Slider, Stepper, Image as VanImage, Button,Toast,Swipe, SwipeItem,Checkbox, CheckboxGroup,Popup ,Field,RadioGroup, Radio } from 'vant'
Vue.use(Col)
Vue.use(Slider)
Vue.use(Row)
Vue.use(Stepper)
Vue.use(Button)
Vue.use(VanImage)
Vue.use(Swipe);
Vue.use(SwipeItem)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Popup)
Vue.use(Field)
Vue.use(Radio)
Vue.use(RadioGroup)
export default {
    components: {
        CNaviBar
    },
    data() {
        return {
            showContactUs: false
        }
    },
    methods: {
        showContactUsBox() {
        //let box = new LoginBox()
            this.showContactUs = true
        }
    },
    
    mounted () {

       
    }
}
</script>
<style scoped>
   #head {
     font-size: 1.2rem;
    color: #1ca4f3;
    height: 120px;
    line-height: 120px;
   }
   .choose {
       font-size: 1.5rem;
       margin-top: 20px;
   }
.contactUsBnt {
   text-align: center;
   text-align: center;
   position: fixed;
   width: 100%;
   bottom: 60px;
}
.contactUsBox /deep/.van-dialog__content {
    display: flex;
    justify-content: center;
  }
  .container {
    text-align: center;
  }
</style>