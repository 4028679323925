<!-- 本体项目 -->
<template>
    <div>
        <van-nav-bar 
        :title="navTitle" 
        left-text="" 
        left-arrow 
        @click-left="onClickLeft" />
      <div id="preview" v-show="preview">
          <canvas id="canvas3d" height="320" ></canvas>
          <div class="selectBox" v-show="steps[1]" > 
            <input  type="radio" name="spriview" :checked="extendIdx == -1" value="-1" @click="clincExtendItem(undefined, -1)" > 全部
            <div :key="index" v-for="(items, index) in extendGroupMeshs">
                <input type="radio" :value="index"   name="spriview" @change="clincExtendItem(items, index)" >{{ index }} 
              </div>
          </div>
          <div v-show="showVBnt">
          <div style="position:absolute; top:300px;right:115px; height:30px; display:flex;">
            <div style="color:#fafafa;display:inline-block;line-height:30px;margin-right:5px;" >变径</div>
            <van-switch v-model="bianjing_show" size="24" @change="bianjingshowChange" :disabled="preview_only"/>
          </div>
          <div style="position:absolute; top:300px;right:8px; height:30px; display:flex;">
            <div style="color:#fafafa;display:inline-block;line-height:30px;margin-right:5px;" >轨道</div>
            <van-switch v-model="guidao_show" size="24" @change="guijishowChange" :disabled="preview_only"/>
          </div>
          <div style="position:absolute; top:330px;right:115px; height:30px; display:flex;">
            <div style="color:#fafafa;display:inline-block;line-height:30px;margin-right:5px;" >回料盒</div>
            <van-switch v-model="huiliao_show" size="24" @change="huiliaoShowChange" :disabled="preview_only"/>
          </div>
          <div style="position:absolute; top:330px;right:8px; height:30px; display:flex;">
            <div style="color:#fafafa;display:inline-block;line-height:30px;margin-right:5px;" >出口</div>
            <van-switch v-model="chukou_show" size="24" @change="chukouShowChange" :disabled="preview_only"/>
          </div>
        </div>
      </div>
      <div id="footer">
        <div v-show="steps[0]">
            <van-tabs v-model="activeName"  >
            <van-tab title="本体尺寸" name="benti">
                <van-row>
                    <van-col span="6">本体直径</van-col>
                    <van-col span="12">
                    <van-slider v-model="diameter" active-color="#3ea5b9" :min=50 :max="600" :step=10  @input="updateGraphics" :disabled="preview_only" >
                    <template #button>
                        <div class="custom-button">{{ diameter }}</div>
                    </template>
                    </van-slider>
                    </van-col>
                    <van-col span="6"><van-stepper v-model="diameter" :min=50 :max=600 :step=10 @input="updateGraphics" :disabled="preview_only"/></van-col>
                </van-row>

                <van-row>
                    <van-col span="6">道宽</van-col>
                    <van-col span="12">
                    <van-slider v-model="width0" active-color="#3ea5b9" :min=10 :max=60 :step=5 @input="updateGraphics" :disabled="preview_only">
                    <template #button>
                        <div class="custom-button">{{ width0 }}</div>
                    </template>
                    </van-slider>
                    </van-col>
                    <van-col span="6"><van-stepper v-model="width0" :min=10 :max=60 :step=5 @input="updateGraphics" :disabled="preview_only" /></van-col>
                </van-row>

                <van-row>
                    <van-col span="6">螺距</van-col>
                    <van-col span="12">
                    <van-slider v-model="luoju" active-color="#3ea5b9" :min=20 :max=200 :step=5 @input="updateGraphics" :disabled="preview_only" >
                    <template #button>
                        <div class="custom-button">{{ luoju }}</div>
                    </template>
                    </van-slider>
                    </van-col>
                    <van-col span="6"><van-stepper v-model="luoju" :min=20 :max=200 :step=5 @input="updateGraphics" :disabled="preview_only" /></van-col>
                </van-row>

                <van-row>
                    <van-col span="6">圈数</van-col>
                    <van-col span="12">
                    <van-slider v-model="quanshu" active-color="#3ea5b9" :min=1.25 :max=5 :step=0.25 @input="updateGraphics" :disabled="preview_only" >
                    <template #button>
                        <div class="custom-button">{{ quanshu }}</div>
                    </template>
                    </van-slider>
                    </van-col>
                    <van-col span="6"><van-stepper v-model="quanshu" :min=1.25 :max=5 :step=0.25 @input="updateGraphics" :disabled="preview_only" /></van-col>
                </van-row>

                <van-row>
                    <van-col span="6">挡边高度</van-col>
                    <van-col span="12">
                    <van-slider v-model="dangbian_xishu" active-color="#3ea5b9" :min=0.2 :max=1 :step=0.1 @input="updateGraphics" :disabled="preview_only" >
    
                    </van-slider>
                    </van-col>
                    <van-col span="6">{{dangbian_xishu * luoju}}</van-col>
                </van-row>

                <van-row>
                    <van-col span="6">桶高</van-col>
                    <van-col span="12"></van-col>
                    <van-col span="6">{{ bucket_height }}</van-col>
                </van-row>

                <van-row>
                    <van-col span="6">道宽加宽</van-col>
                    <van-col span="12">
                    <van-slider v-model="width0_1st" active-color="#3ea5b9" :min=0 :max=40 :step=5 @input="updateGraphics" :disabled="preview_only" >
                    <template #button>
                        <div class="custom-button">{{ width0_1st }}</div>
                    </template>
                    </van-slider>
                    </van-col>
                    <van-col span="6"><van-stepper v-model="width0_1st" :min=0 :max=40 :step=5 @input="updateGraphics" :disabled="preview_only" /></van-col>
                </van-row>

                <van-row>
                    <van-col span="6">加宽圈数</van-col>
                    <van-col span="12">
                    <van-slider v-model="quanshu_1st" active-color="#3ea5b9" :min=0.75 :max=quanshu_1st_max :step=0.25 @input="updateGraphics" :disabled="preview_only" >
                    <template #button>
                        <div class="custom-button">{{ quanshu_1st }}</div>
                    </template>
                    </van-slider>
                    </van-col>
                    <van-col span="6"><van-stepper v-model="quanshu_1st" :min=0.75 :max=quanshu_1st_max :step=0.25 @input="updateGraphics" :disabled="preview_only" /></van-col>
                </van-row>

                <van-row>
                    <van-radio-group v-model="direction"  direction="horizontal" @change="updateGraphics" :disabled="preview_only" >
                <van-col span="6">方向</van-col>
                
                <van-col span="9">
                    <van-radio name="1" value=1 >逆时针</van-radio>
                </van-col>
                <van-col span="9">
                    <van-radio name="-1" value=-1  >顺时针</van-radio>
                </van-col>
                </van-radio-group>
                </van-row>
            </van-tab>
            <van-tab title="变径尺寸" name="bianjing" :disabled=!bianjing_show >
                <van-row>
                    <van-radio-group v-model="bianjing_type"  direction="horizontal" @input="updateGraphics" :disabled="preview_only" >
                <van-col span="6">变径类型</van-col>
                
                <van-col span="9">
                    <van-radio name="0" value=0 >圆弧</van-radio>
                </van-col>
                <van-col span="9">
                    <van-radio name="1" value=1  >平滑</van-radio>
                </van-col>
                </van-radio-group>
                </van-row>
                <van-row>
                    <van-col span="6"><span >变径尺寸</span></van-col>
                    <van-col span="12">
                      <van-slider active-color="#3ea5b9" v-model="bianjing_width" :min="20" :max="200" :step="5" @input="updateGraphics" :disabled="preview_only">
                        <template #button>
                          <div class="custom-button">{{ bianjing_width }}</div>
                        </template>
                      </van-slider>
                    </van-col>
                    <van-col span="6"
                      ><van-stepper v-model="bianjing_width" :min="20" :max="200" :step="5" @input="updateGraphics" :disabled="preview_only"/></van-col>
                  </van-row>
                  <van-row>
                    <van-col span="6"><span >对应角度</span></van-col>
                    <van-col span="12">
                      <van-slider active-color="#3ea5b9" v-model="bianjing_theta" :min="45" :max="135" :step="5" @input="updateGraphics" :disabled="preview_only">
                        <template #button>
                          <div class="custom-button">{{ bianjing_theta }}</div>
                        </template>
                      </van-slider>
                    </van-col>
                    <van-col span="6"
                      ><van-stepper v-model="bianjing_theta" :min="45" :max="135" :step="5" @input="updateGraphics" :disabled="preview_only" /></van-col>
                  </van-row>
                  <van-row>
                    <van-col span="6"><span >变径落差</span></van-col>
                    <van-col span="12">
                      <van-slider active-color="#3ea5b9" v-model="bianjing_decline_jingxiang" :min="0" :max="30" :step="2" @input="updateGraphics" :disabled="preview_only" >
                        <template #button>
                          <div class="custom-button">{{ bianjing_decline_jingxiang }}</div>
                        </template>
                      </van-slider>
                    </van-col>
                    <van-col span="6"
                      ><van-stepper v-model="bianjing_decline_jingxiang" :min="0" :max="30" :step="2" @input="updateGraphics" :disabled="preview_only" /></van-col>
                  </van-row>

            </van-tab>
            <van-tab title="轨道轨迹" name="guidao_guiji" :disabled=!guidao_show>
                <van-row>
                    <van-col span="6"><span >半径调整</span></van-col>
                    <van-col span="12">
                      <van-slider active-color="#3ea5b9" v-model="guidao_offset_radius" :min="-80" :max="80" :step="5" @input="updateGraphics" :disabled="preview_only">
                        <template #button>
                          <div class="custom-button">{{ guidao_offset_radius }}</div>
                        </template>
                      </van-slider>
                    </van-col>
                    <van-col span="6"
                      ><van-stepper v-model="guidao_offset_radius" :min="-80" :max="80" :step="5" @input="updateGraphics" :disabled="preview_only"/></van-col>
                  </van-row>
                  <van-row>
                    <van-col span="6"><span >圆心角</span></van-col>
                    <van-col span="12">
                      <van-slider active-color="#3ea5b9" v-model="guidao_delta" :min="30" :max="360" :step="5" @input="updateGraphics" :disabled="preview_only">
                        <template #button>
                          <div class="custom-button">{{ guidao_delta }}</div>
                        </template>
                      </van-slider>
                    </van-col>
                    <van-col span="6"
                      ><van-stepper v-model="guidao_delta" :min="30" :max="360" :step="5" @input="updateGraphics" :disabled="preview_only"/></van-col>
                  </van-row>
                  <van-row v-show="false">
                    <van-col span="6"><span >起点直段</span></van-col>
                    <van-col span="12">
                      <van-slider active-color="#3ea5b9" v-model="guidao_offset1" :min="0" :max="150" :step="5" @input="updateGraphics" :disabled="preview_only">
                        <template #button>
                          <div class="custom-button">{{ guidao_offset1 }}</div>
                        </template>
                      </van-slider>
                    </van-col>
                    <van-col span="6"
                      ><van-stepper v-model="guidao_offset1" :min="0" :max="150" :step="5" @input="updateGraphics" :disabled="preview_only"/></van-col>
                  </van-row>
                  <van-row v-show="false">
                    <van-col span="6"><span >终点直段</span></van-col>
                    <van-col span="12">
                      <van-slider active-color="#3ea5b9" v-model="guidao_offset2" :min="0" :max="150" :step="5" @input="updateGraphics" :disabled="preview_only">
                        <template #button>
                          <div class="custom-button">{{ guidao_offset2 }}</div>
                        </template>
                      </van-slider>
                    </van-col>
                    <van-col span="6"
                      ><van-stepper v-model="guidao_offset2" :min="0" :max="150" :step="5" @input="updateGraphics" :disabled="preview_only"/></van-col>
                  </van-row>
                  <van-row>
                    <van-col span="6"><span >落差</span></van-col>
                    <van-col span="12">
                      <van-slider active-color="#3ea5b9" v-model="guidao_high" :min="0" :max="150" :step="5" @input="updateGraphics" :disabled="preview_only">
                        <template #button>
                          <div class="custom-button">{{ guidao_high }}</div>
                        </template>
                      </van-slider>
                    </van-col>
                    <van-col span="6"
                      ><van-stepper v-model="guidao_high" :min="0" :max="150" :step="5" @input="updateGraphics" :disabled="preview_only"/></van-col>
                  </van-row>
                  
            </van-tab>
            <van-tab title="轨道截面" name="guidao_jiemian" :disabled=!guidao_show >
                <van-row>
                    <van-col span="6"><span >截面长度</span></van-col>
                    <van-col span="12">
                      <van-slider active-color="#3ea5b9" v-model="guidao_section_length" :min="5" :max="80" :step="1" @input="updateGraphics" :disabled="preview_only">
                        <template #button>
                          <div class="custom-button">{{ guidao_section_length }}</div>
                        </template>
                      </van-slider>
                    </van-col>
                    <van-col span="6"
                      ><van-stepper v-model="guidao_section_length" :min="5" :max="80" :step="1" @input="updateGraphics" :disabled="preview_only"/></van-col>
                  </van-row>
                  <van-row>
                    <van-col span="6"><span >截面宽度</span></van-col>
                    <van-col span="12">
                      <van-slider active-color="#3ea5b9" v-model="guidao_section_width" :min="5" :max="80" :step="1" @input="updateGraphics" :disabled="preview_only">
                        <template #button>
                          <div class="custom-button">{{ guidao_section_width }}</div>
                        </template>
                      </van-slider>
                    </van-col>
                    <van-col span="6"
                      ><van-stepper v-model="guidao_section_width" :min="5" :max="80" :step="1" @input="updateGraphics" :disabled="preview_only"/></van-col>
                  </van-row>
                  <van-row>
                    <van-col span="6"><span >扭转中心 X</span></van-col>
                    <van-col span="12">
                      <van-slider active-color="#3ea5b9" v-model="guidao_Rotation_X" :min="-100" :max="100" :step="1" @input="updateGraphics" :disabled="preview_only">
                        <template #button>
                          <div class="custom-button">{{ guidao_Rotation_X }}</div>
                        </template>
                      </van-slider>
                    </van-col>
                    <van-col span="6"
                      ><van-stepper v-model="guidao_Rotation_X" :min="-100" :max="100" :step="1" @input="updateGraphics" :disabled="preview_only"/></van-col>
                  </van-row>
                  <van-row>
                    <van-col span="6"><span >扭转中心 Y</span></van-col>
                    <van-col span="12">
                      <van-slider active-color="#3ea5b9" v-model="guidao_Rotation_Y" :min="-100" :max="100" :step="1" @input="updateGraphics" :disabled="preview_only">
                        <template #button>
                          <div class="custom-button">{{ guidao_Rotation_Y }}</div>
                        </template>
                      </van-slider>
                    </van-col>
                    <van-col span="6"
                      ><van-stepper v-model="guidao_Rotation_Y" :min="-100" :max="100" :step="1" @input="updateGraphics" :disabled="preview_only"/></van-col>
                  </van-row>
                  <van-row>
                    <van-col span="6"><span >出口角度</span></van-col>
                    <van-col span="12">
                      <van-slider active-color="#3ea5b9" v-model="guidao_rotation" :min="-360" :max="360" :step="5" @input="updateGraphics" :disabled="preview_only">
                        <template #button>
                          <div class="custom-button">{{ guidao_rotation }}</div>
                        </template>
                      </van-slider>
                    </van-col>
                    <van-col span="6"
                      ><van-stepper v-model="guidao_rotation" :min="-360" :max="360" :step="5" @input="updateGraphics" :disabled="preview_only"/></van-col>
                  </van-row>
            </van-tab>
            <van-tab title="多轨道" name="duoguidao" :disabled=!guidao_show>
                <van-row>
                    <van-col span="6"><span >轨迹数量</span></van-col>
                    <van-col span="12">
                      <van-slider active-color="#3ea5b9" v-model="guidao_n_curve" :min="1" :max="10" :step="1" @input="updateGraphics" :disabled="preview_only">
                        <template #button>
                          <div class="custom-button">{{ guidao_n_curve }}</div>
                        </template>
                      </van-slider>
                    </van-col>
                    <van-col span="6"
                      ><van-stepper v-model="guidao_n_curve" :min="1" :max="10" :step="1" @input="updateGraphics" :disabled="preview_only"/></van-col>
                  </van-row>
                  <van-row>
                    <van-col span="6"><span >多道间隔</span></van-col>
                    <van-col span="12">
                      <van-slider active-color="#3ea5b9" v-model="guidao_offset_N_C0" :min="-100" :max="100" :step="5" @input="updateGraphics" :disabled="preview_only">
                        <template #button>
                          <div class="custom-button">{{ guidao_offset_N_C0 }}</div>
                        </template>
                      </van-slider>
                    </van-col>
                    <van-col span="6"
                      ><van-stepper v-model="guidao_offset_N_C0" :min="-100" :max="100" :step="5" @input="updateGraphics" :disabled="preview_only"/></van-col>
                  </van-row>
                  <van-row>
                    <van-col span="6"><span >多道间隔<br>调整</span></van-col>
                    <van-col span="12">
                      <van-slider active-color="#3ea5b9" v-model="guidao_offset_N_C" :min="-100" :max="100" :step="5" @input="updateGraphics" :disabled="preview_only">
                        <template #button>
                          <div class="custom-button">{{ guidao_offset_N_C }}</div>
                        </template>
                      </van-slider>
                    </van-col>
                    <van-col span="6"
                      ><van-stepper v-model="guidao_offset_N_C" :min="-100" :max="100" :step="5" @input="updateGraphics" :disabled="preview_only"/></van-col>
                  </van-row>
            </van-tab>
            <van-tab title="出口" name="chukou" :disabled=!chukou_show>
                <van-row>
                    <van-col span="6"><span >水平延申</span></van-col>
                    <van-col span="12">
                      <van-slider active-color="#3ea5b9" v-model="chukou_distance" :min="0" :max="600" :step="5" @input="updateGraphics" :disabled="preview_only">
                        <template #button>
                          <div class="custom-button">{{ chukou_distance }}</div>
                        </template>
                      </van-slider>
                    </van-col>
                    <van-col span="6"
                      ><van-stepper v-model="chukou_distance" :min="0" :max="600" :step="5" @input="updateGraphics" :disabled="preview_only"/></van-col>
                  </van-row>
                  <van-row>
                    <van-col span="6"><span >垂直落差</span></van-col>
                    <van-col span="12">
                      <van-slider active-color="#3ea5b9" v-model="chukou_height" :min="0" :max="600" :step="5" @input="updateGraphics" :disabled="preview_only">
                        <template #button>
                          <div class="custom-button">{{ chukou_height }}</div>
                        </template>
                      </van-slider>
                    </van-col>
                    <van-col span="6"
                      ><van-stepper v-model="chukou_height" :min="0" :max="600" :step="5" @input="updateGraphics" :disabled="preview_only"/></van-col>
                  </van-row>
                  <van-row>
                    <van-col span="6"><span >角度</span></van-col>
                    <van-col span="12">
                      <van-slider active-color="#3ea5b9" v-model="chukou_theta2" :min="0" :max="90" :step="5" @input="updateGraphics" :disabled="preview_only">
                        <template #button>
                          <div class="custom-button">{{ chukou_theta2 }}</div>
                        </template>
                      </van-slider>
                    </van-col>
                    <van-col span="6"
                      ><van-stepper v-model="chukou_theta2" :min="0" :max="90" :step="5" @input="updateGraphics" :disabled="preview_only"/></van-col>
                  </van-row>
            </van-tab>  
            <van-tab title="回料盒" name="huiliaohe" :disabled=!huiliao_show>
                <van-row>
                    <van-col span="6"><span >回料点</span></van-col>
                    <van-col span="12">
                      <van-slider active-color="#3ea5b9" v-model="huiliao_dian" :min="0" :max="360" :step="5" @input="updateGraphics" :disabled="preview_only">
                        <template #button>
                          <div class="custom-button">{{ huiliao_dian }}</div>
                        </template>
                      </van-slider>
                    </van-col>
                    <van-col span="6"
                      ><van-stepper v-model="huiliao_dian" :min="0" :max="360" :step="5" @input="updateGraphics" :disabled="preview_only"/></van-col>
                  </van-row>
                  <van-row>
                    <van-col span="6"><span >回料落差</span></van-col>
                    <van-col span="12">
                      <van-slider active-color="#3ea5b9" v-model="huiliao_luocha" :min="0" :max="50" :step="2" @input="updateGraphics" :disabled="preview_only">
                        <template #button>
                          <div class="custom-button">{{ huiliao_luocha }}</div>
                        </template>
                      </van-slider>
                    </van-col>
                    <van-col span="6"
                      ><van-stepper v-model="huiliao_luocha" :min="0" :max="50" :step="2" @input="updateGraphics" :disabled="preview_only"/></van-col>
                  </van-row>
                  <van-row>
                    <van-col span="6"><span >圆心角</span></van-col>
                    <van-col span="12">
                      <van-slider active-color="#3ea5b9" v-model="huiliao_heyuanxinjiao" :min="0" :max="360" :step="5" @input="updateGraphics" :disabled="preview_only">
                        <template #button>
                          <div class="custom-button">{{ huiliao_heyuanxinjiao }}</div>
                        </template>
                      </van-slider>
                    </van-col>
                    <van-col span="6"
                      ><van-stepper v-model="huiliao_heyuanxinjiao" :min="0" :max="360" :step="5" @input="updateGraphics" :disabled="preview_only"/></van-col>
                  </van-row>
                  <van-row>
                    <van-col span="6"><span >回料口大小</span></van-col>
                    <van-col span="12">
                      <van-slider active-color="#3ea5b9" v-model="huiliao_koudaxiao" :min="15" :max="150" :step="5" @input="updateGraphics" :disabled="preview_only">
                        <template #button>
                          <div class="custom-button">{{ huiliao_koudaxiao }}</div>
                        </template>
                      </van-slider>
                    </van-col>
                    <van-col span="6"
                      ><van-stepper v-model="huiliao_koudaxiao" :min="15" :max="150" :step="5" @input="updateGraphics" :disabled="preview_only"/></van-col>
                  </van-row>
                  <van-row>
                    <van-col span="6"><span >回料盒宽度</span></van-col>
                    <van-col span="12">
                      <van-slider active-color="#3ea5b9" v-model="huiliao_hekuandu" :min="50" :max="200" :step="5" @input="updateGraphics" :disabled="preview_only">
                        <template #button>
                          <div class="custom-button">{{ huiliao_hekuandu }}</div>
                        </template>
                      </van-slider>
                    </van-col>
                    <van-col span="6"
                      ><van-stepper v-model="huiliao_hekuandu" :min="50" :max="200" :step="5" @input="updateGraphics" :disabled="preview_only"/></van-col>
                  </van-row>
                  <van-row>
                    <van-col span="6"><span >回料盒高度</span></van-col>
                    <van-col span="12">
                      <van-slider active-color="#3ea5b9" v-model="huiliao_hegaodu" :min="5" :max="20" :step="2.5" @input="updateGraphics" :disabled="preview_only">
                        <template #button>
                          <div class="custom-button">{{ huiliao_hegaodu }}</div>
                        </template>
                      </van-slider>
                    </van-col>
                    <van-col span="6"
                      ><van-stepper v-model="huiliao_hegaodu" :min="5" :max="20" :step="2.5" @input="updateGraphics" :disabled="preview_only"/></van-col>
                  </van-row>
                  <van-row>
                    <van-col span="6"><span >回料盒切角</span></van-col>
                    <van-col span="12">
                      <van-slider active-color="#3ea5b9" v-model="huiliao_heqiejiao" :min="0" :max="60" :step="5" @input="updateGraphics" :disabled="preview_only">
                        <template #button>
                          <div class="custom-button">{{ huiliao_heqiejiao }}</div>
                        </template>
                      </van-slider>
                    </van-col>
                    <van-col span="6"
                      ><van-stepper v-model="huiliao_heqiejiao" :min="0" :max="60" :step="5" @input="updateGraphics" :disabled="preview_only"/></van-col>
                  </van-row>
            </van-tab>
            </van-tabs>
            <van-row type="flex" justify="end">
                <van-col span="24"> </van-col>
            </van-row>  
            <van-row type="flex" justify="end" class="get-me">

              <van-col span="4">
                <van-button type="warning" size="small" @click="saveSurface" >保存</van-button>
              </van-col>
                <van-col span="4" v-show="show_extend">
                    <van-button type="info" size="small" @click="extendClick" >展开</van-button>
                  </van-col>
                <van-col span="6" v-show="false" >
                    <van-button type="warning" size="small"   @click="showSubmit" >点击获取</van-button>
                </van-col>
            </van-row>  
        </div>
        <div v-show="steps[1]">
            <van-row v-show="false" >
                <van-col span="24">
                     
                     <div v-show="extendIdx == -1">本次展开 {{extendMeshs.length}} 个部件<br>合计切割长度 {{cutLength.toFixed(2)}}米，重量{{cutWeight.toFixed(2)}}公斤</div>
                     <div v-show="extendIdx > -1">
      
                       切割长度{{cutLength.toFixed(2)}}米，重量{{cutWeight.toFixed(2)}}公斤
                     </div>
                </van-col>
      
            </van-row>
            <div id="expandArea">
                <div > 
                  <van-image :src="previewImgData" alt="加载中.." height="180px" fit="contain" >
                  <template v-slot:loading>图像加载中...</template>
                  <template v-slot:error>图像加载中...</template>
                </van-image>
                </div>
              </div>
            <van-row type="flex" justify="end">
                  <van-col span="8"  >
                    <van-button type="primary"  size="small" @click="openAndGet"  >点击获取图纸</van-button>
                  </van-col>
                  <van-col span="4">
                    <van-button type="info" size="small" @click="prev" >返回</van-button>
                  </van-col>
            </van-row>  
        </div>
      </div>
      <van-action-sheet v-model="show_order_confirm" title="意向提交">
        <van-form ref="orderSubmitForm" @submit="onSubmit" >
            <van-field name="stepper" label="本体数量">
                <template #input>
                  <van-stepper v-model="order_benti_cnt" />
                </template>
            </van-field>

            <van-field name="stepper" label="变径数量" v-show="bianjing_show">
                <template #input>
                  <van-stepper v-model="order_bianjing_cnt" :min=0 />
                </template>
            </van-field>
            <van-field name="stepper" label="轨道数量" v-show="guidao_show">
                <template #input>
                  <van-stepper v-model="order_guidao_cnt" :min=0 />
                </template>
            </van-field>

            <van-field name="stepper" label="回料盒数量" v-show="huiliao_show">
                <template #input>
                  <van-stepper v-model="order_huiliao_cnt" :min=0 />
                </template>
            </van-field>
            <van-field name="stepper" label="出口数量" v-show="chukou_show">
                <template #input>
                  <van-stepper v-model="order_chukou_cnt" :min=0 />
                </template>
            </van-field>
            <van-field
                v-model="order_name"
                label="称呼"
                placeholder="请填写您的姓名"
            />
            <van-field
                v-model="order_phone"
                label="手机号码"
                placeholder="请填写手机号"
                required
                :rules="[{ validator: phoneValidator, required: true, message: '请填正确的手机号码' }]"
            >
            </van-field>
            <van-field name="captcha"  v-model="order_captcha" label="图形验证码" placeholder="输入验证码"  required 
            :rules="[{  required: true}]"
            >
            <template #extra>
                <van-image :src="order_captcha_url" width="100" @click="refreshCaptpha" > 
                    <template v-slot:loading>验证码加载中...</template>
                </van-image >
            </template>
            
             </van-field>
            <van-field name="captcha"  v-model="order_sms_captcha" label="短信验证码" placeholder="请填写短信验证码"  required 
            :rules="[{  required: true}]">
                <template #button>  
                    <van-button size="small" type="primary" @click="getSmsCaptcha" :disabled="sms_disabled" >{{sms_title}}</van-button>
                </template>
            </van-field>
            <div style="height:110px;"></div>
            <van-submit-bar  button-text="提交" 
               tip="提示：信息提交之后，我们将尽快与您联系"
               tip-icon="info-o"
            />

        </van-form>
      </van-action-sheet>

      <van-popup v-model="showSendBox" closeable position="bottom" :style="{ height: '30%' }" >
        <div style="padding-top:15%">

          <div>
            <van-field
              v-model="app_name"
              center
              label="请输入项目名称"
              maxlength=80
              label-width="8em"
              required
            >
            </van-field>
            <van-field
            v-model="email"
            center
            label="输入邮箱地址"
            maxlength=80
            label-width="8em"
            :error-message="emailErrmsg"
          >
            <template #button>
              <van-button size="small" :disabled="mailSending" :loading="mailSending" type="primary" @click="sendDxfByMail()" loading-text="发送中...">邮箱发送</van-button>
            </template>
          </van-field>
          </div>

        </div>
     </van-popup>

      <SurfaceForm app_type="benti" :app_name="app_name" :show="surface_new_show" v-on:done="add_surface_done" v-on:close="close_surface_form" ></SurfaceForm>
    </div>
</template>
<script>
import Vue from 'vue'
import { Col, Row,Switch, Slider,SubmitBar, Sticky  , Stepper, ActionSheet, Image as VanImage, Button,Toast,Swipe, SwipeItem,Checkbox, CheckboxGroup,Popup ,Field,RadioGroup, Radio } from 'vant'
import * as THREE from 'three'
import { ParametricGeometry } from 'three/examples/jsm/geometries/ParametricGeometry'
//import { OrbitControls } from '../libs/js/OrbitControls'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader'
import { DragControls } from 'three/examples/jsm/controls/DragControls'
import { TransformControls } from 'three/examples/jsm/controls/TransformControls'
import { RoomEnvironment } from "three/examples/jsm/environments/RoomEnvironment";
import VueCookies from "vue-cookies";
import { Base64 } from "js-base64";
import { Loading } from "vant";
import LoginBox from "@/libs/LoginBox";
import BrowserInfo from "@/libs/Browserinfo";
import Clipboard from "clipboard";
import Commons from '@/libs/Commons'
import SurfaceForm from '@/components/SurfaceForm'

Vue.use(Sticky)
Vue.use(SubmitBar)
Vue.use(Switch)
Vue.use(Loading)
Vue.use(VueCookies)
Vue.use(Col)
Vue.use(Slider)
Vue.use(Row)
Vue.use(Stepper)
Vue.use(Button)
Vue.use(VanImage)
Vue.use(Swipe);
Vue.use(SwipeItem)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Popup)
Vue.use(Field)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(ActionSheet);
export default {
  name: 'drawing',
  components: {
    SurfaceForm
  },
  data () {
    return {
        id: 0,
        preview : true,
        show_extend: true,
        camera : null,
        ambientLight : null,
        DirLight : null,
        spotLight : null,
        spotLight2 : null,
        renderer : null,
        controls : null,
        axesHelper : null,
        scene: null,
        navTitle: '套件-新增',

        //所有动态绘制的图形
        meshs : [],

        //需要生成预览的图像
        extendMeshs: [] ,
        //分组的展开
        extendGroupMeshs: {},
        extendPreviewCnt : 0,
        extendShowd : false,

        n: 400,

        // 本体直径
        diameter: 400,
        //道宽
        width0: 20,
        //螺距
        luoju: 50,
        //圈数
        quanshu: 2,
        //道宽加宽
        width0_1st : 0,
        //加宽圈数
        quanshu_1st : 1,
        //螺线
        curve_luoxian : null,

        steps: [true,false],
        active :0,
        //-1顺时针，1逆时针
        direction: '1',
        //展开的下标
        extendIdx : -1,
        extendIdxs : [],
        previewImgData : '',

        /**
       * 材料密度
       */
       density: 7.93,
      /**
       * 材料厚度
       */
       thickness: 1,

       /*变径参数*/
       activeName: 'benti',
       /** 径向落差 **/
       bianjing_decline_jingxiang : 10,
       /**变径对应角度**/
       bianjing_theta : 90,
       /**变径类型，0 圆弧，1平滑**/
       bianjing_type : '1',
       /**变径尺寸**/
       bianjing_width: 50,

       /**跑道的点集合 */
       points1Z_paodao : [],
       points2Z_paodao : [],
       /** 是否展示变径 */
       bianjing_show : true,

       /**
        *  挡边系数
        **/
       dangbian_xishu: 0.6,

       /** 轨迹相关参数 **/
       
       /*半径调整*/
       /** 是否展示轨迹 */
       guidao_show : true,
       guidao_offset_radius : 0,
       /*轨道圆心角*/
       guidao_delta: 180,
       /*起点直段*/
       guidao_offset1: 0,
       /*终点直段*/
       guidao_offset2: 0,
       /*落差*/
       guidao_high: 0,
       /*轨道数量*/
       guidao_n_curve: 1,
       /*多道间隔*/
       guidao_offset_N_C0: 20,
       /*多道间隔调整*/
       guidao_offset_N_C: 0,

       /*截面长度*/
       guidao_section_length: 20,
       /*截面宽度*/
       guidao_section_width: 10,
       /*扭转中心X*/
       guidao_Rotation_X: 0,
       /*扭转中心Y*/
       guidao_Rotation_Y: 0,
       /*出口角度*/
       guidao_rotation: 0,

       /**
        * 回料盒参数
        ***/
        huiliao_show: true,
       /* 回料点 */
        huiliao_dian : 270,
        /* 回料落差 */
        huiliao_luocha: 16,
        /* 回料圆心角 */
        huiliao_heyuanxinjiao: 270,
        /* 回料口大小 */
        huiliao_koudaxiao: 60,
        /* 回料盒宽度 */
        huiliao_hekuandu: 100,
        /* 回料盒斜度 */
        huiliao_hexiedu:5,
        /* 回料盒高度 */
        huiliao_hegaodu: 5,
        /* 回料盒切角 */
        huiliao_heqiejiao: 30,

        /**
         *  出口相关
         **/ 
        //是否展示出口
        chukou_show: true,
        chukou_theta1: [],
        chukou_p_x:[],
        chukou_p_y:[],
        chukou_p_z:[],
        /* 出口水平延申 */
        chukou_distance: 150,
        /* 出口垂直落差 */
        chukou_height: 100,
        /* 出口角度 */
        chukou_theta2: 10,

       /** 下单相关的参数 */
       // 是否展示下单确认框
       show_order_confirm: false,
       //本地数量
       order_benti_cnt : 1,
       //变径
       order_bianjing_cnt: 0,
       //轨道数量
       order_guidao_cnt:0,
       //回料盒数量
       order_huiliao_cnt: 0,
       // 出口数量
       order_chukou_cnt : 0,
       //联系方式
       order_phone : '',
       //验证码
       order_captcha : '',
       //验证码地址
       order_captcha_url : '',
       //称呼
       order_name:'',
       //短信动态验证码
       order_sms_captcha:'',

       /**
        * 是否只预览和下载
        ***/
       preview_only: false,
       sms_captcha_show: false,
       sms_title: "获取验证码",
       sms_disabled: false,
       //倒计时的id
       sms_timer_id : 0,
       //倒计时的秒数
       sms_countdown: 0,
       // 倒计时的总秒数
       sms_countdown_seconds : 120,

       surface_new_show: false,
       

       // 获取图纸相关
       showSendBox : false,
       mailSending:false,
       emailErrmsg : '',
       email:'',
       downloadErrMsg:'',
       app_name: '',
      
       
    }
  },
  methods: {
     initThree() {
        console.log("init three")
        this.scene = new THREE.Scene();
        let width = window.innerWidth // 窗口宽度
        let height = 320
        this.camera = new THREE.OrthographicCamera( width/-1, width/1, height/0.8, height/-1, 1, 10000 );
		this.camera.position.z = 1600;
        this.camera.position.x = 1600;
        this.camera.lookAt(0,0,0)
        this.camera.up.x = 0
        this.camera.up.y = 0
        this.camera.up.z = 1
        this.ambientLight = new THREE.AmbientLight(0x666666);
		this.scene.add(this.ambientLight);
        let DirLight = new THREE.DirectionalLight(0xffffff,0.5);
        DirLight.position.set(1, 1, 1)
        this.scene.add(DirLight);

        let spotLight = new THREE.SpotLight( 0xffffff, 0.5);
        spotLight.position.set( 200, 200, 200 );
        spotLight.angle = Math.PI/2
        this.scene.add( spotLight );

        let spotLight2 = new THREE.SpotLight( 0xffffff, 0.5);
        spotLight2.position.set( 200, 200, -200 );
        spotLight2.angle = Math.PI/2
        this.scene.add( spotLight2 );

        this.renderer = new THREE.WebGLRenderer({canvas: document.getElementById('canvas3d')});
        this.renderer.setSize( width, height );
        this.renderer.setClearColor(0x000000);

        this.controls = new OrbitControls( this.camera, this.renderer.domElement );
        this.controls.enableRotate = true;
        this.controls.enableZoom= true;
        this.controls.mouseButtons = {
            LEFT: THREE.MOUSE.ROTATE,
            MIDDLE: THREE.MOUSE.DOLLY,
            RIGHT: THREE.MOUSE.PAN
        }
        
        this.renderer.render( this.scene, this.camera )
        this.animate()
     },
    animate() {
        requestAnimationFrame( this.animate )
        this.renderer.render( this.scene, this.camera )
        this.controls.update()
        //console.log(this.camera)
	},
    // 根据函数返回的图像添加
    addMeshtoScene(func) {
        var meshs = func()
        var list = []
        if (meshs instanceof Array) {
            list = meshs 
        } else {
            list.push(meshs)
        }
        for (var i in list) {
            var mesh = list[i]
            this.meshs.push(mesh)
            this.scene.add(mesh)
            //需要展开的图形添加
            // let funcList = [this.getCurveBottom, this.getCurvePart1, this.getCurvePaodao, this.getCruveSanjiao]
            if (mesh.lines) {
                console.log("add mesh to extend")
                this.extendMeshs.push(mesh)
                //合并的预览
                if (mesh.showName in this.extendGroupMeshs) {
                  this.extendGroupMeshs[mesh.showName].push(mesh)
                } else {
                  this.extendGroupMeshs[mesh.showName] = [mesh]
                }
                
            }
        }
        
        
    },
    updateGraphics() {
        if (!this.scene) {
            console.log("scene not init")
            return 
        }
        while(this.meshs.length > 0) {
            var c = this.meshs.pop()
            this.scene.remove(c)
        }
        this.extendMeshs = []
        this.extendGroupMeshs = {}

        this.addMeshtoScene(this.getCurveBottomCircle)
        this.addMeshtoScene(this.getCurveBottom)
        this.addMeshtoScene(this.getCurveTop)
        this.addMeshtoScene(this.getCurvePart1)
        this.addMeshtoScene(this.getCurveLuoxian)
        this.addMeshtoScene(this.getCurvePaodao)
        this.addMeshtoScene(this.getCruveSanjiao)
        //变径
        if(this.bianjing_show) {
            this.addMeshtoScene(this.getCurveBianjing)
        }
        //轨迹
        if (this.guidao_show) {
            console.log("draw guiji")
            this.addMeshtoScene(this.getGuijiCurveMeshs)
        }
        //回料盒
        if (this.huiliao_show) {
            this.addMeshtoScene(this.getHuiliaoCurveMeshs)
        }
        //出口
        if (this.chukou_show) {
            this.addMeshtoScene(this.getChukouCurveMeshs)
        }
        
    },
    //变径展示开关事件
    bianjingshowChange() {
        if(!this.bianjing_show) {
            this.activeName = "benti"
        }
        this.updateGraphics() 
    },
    //轨迹展示开关事件
    guijishowChange() {
        if(!this.guidao_show) {
            this.activeName = "benti"
        }
        this.updateGraphics() 
    },
    //回料盒展示开关事件
    huiliaoShowChange() {
        if (!this.huiliao_show) {
            this.activeName = "benti"
        }
        this.updateGraphics() 
    },
    chukouShowChange() {
        if (!this.chukoushow) {
            this.activeName = "benti"
        }
        this.updateGraphics() 
    },
    //绘制本体顶部螺线
    getCurveTop() {

        let qs = this.quanshu
        let R = this.diameter / 2
        let lj = this.luoju
        let DIR = parseInt(this.direction)
        class CustomSinCurve2 extends THREE.Curve {

        constructor( scale = 1 ) {
        super();
        this.scale = scale;
        }

        getPoint( t, optionalTarget = new THREE.Vector3() ) {
        let db = this.dangbian_xishu
        const tx = R*Math.cos(Math.PI*2*t*DIR+Math.PI*2*qs*DIR);
        const ty = R*Math.sin(Math.PI*2*t*DIR+Math.PI*2*qs*DIR);
        const tz = lj*qs+db*lj*t;

        return optionalTarget.set( tx, ty, tz ).multiplyScalar( this.scale );
        }}

        let curve_top = new CustomSinCurve2( 1 );
        let tube_geometry2 = new THREE.TubeGeometry( curve_top, 100, 1, 8, false );
        let tube_material2 = new THREE.MeshBasicMaterial( { color: 0xff0000, side:THREE.DoubleSide } );
        let tube_mesh2 = new THREE.Mesh( tube_geometry2, tube_material2 );
        tube_mesh2.isEdage = true
        return tube_mesh2
    },
     // 获取本地底部圆形
     getCurveBottomCircle() {
        let qs = this.quanshu
        let R = this.diameter / 2
        class CustomSinCurve1 extends THREE.Curve {

            constructor( scale = 1 ) {
            super();
            this.scale = scale
            }

            getPoint( t, optionalTarget = new THREE.Vector3() ) {

            const tx = R*Math.cos(Math.PI*2*t+qs*Math.PI*2)
            const ty = R*Math.sin(Math.PI*2*t+qs*Math.PI*2)
            const tz = 0

            return optionalTarget.set( tx, ty, tz ).multiplyScalar( this.scale )
            }
        }
        let curve_bottom = new CustomSinCurve1( 1 );
        let tube_geometry1 = new THREE.TubeGeometry( curve_bottom, 100, 1, 8, false );
        let tube_material1 = new THREE.MeshBasicMaterial( { color: 0xff0000, side:THREE.DoubleSide } );
        let tube_mesh1 = new THREE.Mesh( tube_geometry1, tube_material1 );
        tube_mesh1.isEdage = true
        return tube_mesh1
    },
    //获取本地底部
    getCurveBottom() { 
        let R = this.diameter / 2
        let n = this.n
        let points1X_bottom = new Array();
        let points1Y_bottom = new Array();
        let points1Z_bottom = new Array();

        let points2X_bottom = new Array();
        let points2Y_bottom = new Array();
        let points2Z_bottom = new Array();
        //展开的两条边的坐标，用于展开和预览图生成
        let lineOne = []
        let lineTwo = []
        var i
        for(i=0;i<n+1;i++){
            points1X_bottom[i]=R*Math.cos(Math.PI*2*(i/n))
            points1Y_bottom[i]=R*Math.sin(Math.PI*2*(i/n))
            points1Z_bottom[i]=0

            lineOne.push([points1X_bottom[i], points1Y_bottom[i], points1Z_bottom[i]])

            points2X_bottom[i]=7*Math.cos(Math.PI*2*(i/n))
            points2Y_bottom[i]=7*Math.sin(Math.PI*2*(i/n))
            points2Z_bottom[i]=R*Math.tan(5/180*Math.PI)

            lineTwo.push([points2X_bottom[i], points2Y_bottom[i], points2Z_bottom[i]])
        }
        let surface_geometry_bottom = new THREE.BufferGeometry();
        let vertices_bottom = new Float32Array(18*n);
        for (i=0; i<n; i++){
            vertices_bottom[i*18]=points1X_bottom[i];
            vertices_bottom[i*18+1]=points1Y_bottom[i];
            vertices_bottom[i*18+2]=points1Z_bottom[i];

            vertices_bottom[i*18+3]=points1X_bottom[i+1];
            vertices_bottom[i*18+4]=points1Y_bottom[i+1];
            vertices_bottom[i*18+5]=points1Z_bottom[i+1];

            vertices_bottom[i*18+6]=points2X_bottom[i+1];
            vertices_bottom[i*18+7]=points2Y_bottom[i+1];
            vertices_bottom[i*18+8]=points2Z_bottom[i+1];

            vertices_bottom[i*18+9]=points2X_bottom[i+1];
            vertices_bottom[i*18+10]=points2Y_bottom[i+1];
            vertices_bottom[i*18+11]=points2Z_bottom[i+1];

            vertices_bottom[i*18+12]=points2X_bottom[i];
            vertices_bottom[i*18+13]=points2Y_bottom[i];
            vertices_bottom[i*18+14]=points2Z_bottom[i];

            vertices_bottom[i*18+15]=points1X_bottom[i];
            vertices_bottom[i*18+16]=points1Y_bottom[i];
            vertices_bottom[i*18+17]=points1Z_bottom[i];
        }
        surface_geometry_bottom.setAttribute( 'position', new THREE.BufferAttribute( vertices_bottom, 3 ) );
        surface_geometry_bottom.computeVertexNormals();
        
        let surface_material_bottom = new THREE.MeshPhongMaterial({color: 0xaaaaaa, side:THREE.DoubleSide, /*ambient:0xff00ff,*/emissive:0x000000,specular:0xffffff, shininess: 3,wireframe: false,transparent: true, opacity: 1});

        let surface_mesh_bottom = new THREE.Mesh( surface_geometry_bottom, surface_material_bottom );
        //surface_mesh_bottom.showName = "底部"
        surface_mesh_bottom.showName = "本体"
        surface_mesh_bottom.lines = [lineOne, lineTwo]
        return surface_mesh_bottom
    },
    //绘制跑道螺线
    getCurveLuoxian() {

        let qs = this.quanshu
        let R = this.diameter / 2
        let lj = this.luoju
        let DIR = parseInt(this.direction)

        class CustomSinCurve3 extends THREE.Curve {

            constructor( scale = 1 ) {
            super();
            this.scale = scale;
            }

            getPoint( t, optionalTarget = new THREE.Vector3() ) {

            const tx = R*Math.cos(Math.PI*2*qs*t*DIR);
            const ty = R*Math.sin(Math.PI*2*qs*t*DIR);
            const tz = lj*qs*t;

            return optionalTarget.set( tx, ty, tz ).multiplyScalar( this.scale );
            }
    }

        let curve_luoxian = new CustomSinCurve3( 1 );
        let tube_geometry3 = new THREE.TubeGeometry( curve_luoxian, 800, 1, 8, false );
        let tube_material3 = new THREE.MeshBasicMaterial( { color: 0x0000ff, side:THREE.DoubleSide } );
        let tube_mesh3 = new THREE.Mesh( tube_geometry3, tube_material3 );
        this.curve_luoxian = curve_luoxian
        tube_mesh3.isEdage = true
        return tube_mesh3
    },
//绘制跑道
getCurvePaodao() {
        let qs = this.quanshu
        let R = this.diameter / 2
        let lj = this.luoju
        let n = this.n
        let w0 = this.width0
        let w0_1st = this.width0_1st + w0
        let qs_1st = this.quanshu_1st
        let vector_paodao = new Array();
        let L_vector_paodao = new Array();
        let points_line_paodao = new Array();
        let points0_paodao = new Array();
        let DIR = parseInt(this.direction)
        var i
        for(i=0;i<n+1;i++){
            points0_paodao[i]=this.curve_luoxian.getPointAt(i/n);
            points_line_paodao[i]=this.curve_luoxian.getPointAt(i/n);
        }

        let points1X_paodao = new Array();
        let points1Y_paodao = new Array();
        let points1Z_paodao = new Array();

        let points2X_paodao = new Array();
        let points2Y_paodao = new Array();
        let points2Z_paodao = new Array();

        let n_0 = parseInt(n*(qs_1st/qs))
        let lineOne = []
        let lineTwo = []
        
        for(i=0;i<n_0;i++){
            points1X_paodao[i]=points0_paodao[i].x
            points1Y_paodao[i]=points0_paodao[i].y
            points1Z_paodao[i]=points0_paodao[i].z

            points2X_paodao[i]=w0_1st*Math.cos(175/180*Math.PI)*Math.cos((i/n)*DIR*qs*Math.PI*2)+w0_1st*Math.sin(175/180*Math.PI)*Math.sin(Math.atan(lj/(R*Math.PI*2)))*Math.sin((i/n)*DIR*qs*Math.PI*2)+points0_paodao[i].x
            points2Y_paodao[i]=w0_1st*Math.cos(175/180*Math.PI)*Math.sin((i/n)*DIR*qs*Math.PI*2)-w0_1st*Math.sin(175/180*Math.PI)*Math.sin(Math.atan(lj/(R*Math.PI*2)))*Math.cos((i/n)*DIR*qs*Math.PI*2)+points0_paodao[i].y
            points2Z_paodao[i]=w0_1st*Math.sin(175/180*Math.PI)*Math.cos(Math.atan(lj/(R*Math.PI*2)))+points0_paodao[i].z
        }

        for(i=n_0;i<n+1;i++){
            points1X_paodao[i]=points0_paodao[i].x
            points1Y_paodao[i]=points0_paodao[i].y
            points1Z_paodao[i]=points0_paodao[i].z

            points2X_paodao[i]=w0*Math.cos(175/180*Math.PI)*Math.cos((i/n)*DIR*qs*Math.PI*2)+w0*Math.sin(175/180*Math.PI)*Math.sin(Math.atan(lj/(R*Math.PI*2)))*Math.sin((i/n)*DIR*qs*Math.PI*2)+points0_paodao[i].x
            points2Y_paodao[i]=w0*Math.cos(175/180*Math.PI)*Math.sin((i/n)*DIR*qs*Math.PI*2)-w0*Math.sin(175/180*Math.PI)*Math.sin(Math.atan(lj/(R*Math.PI*2)))*Math.cos((i/n)*DIR*qs*Math.PI*2)+points0_paodao[i].y
            points2Z_paodao[i]=w0*Math.sin(175/180*Math.PI)*Math.cos(Math.atan(lj/(R*Math.PI*2)))+points0_paodao[i].z
        }
        for (i in points1X_paodao) {
            lineOne.push([points1X_paodao[i], points1Y_paodao[i], points1Z_paodao[i]])
            lineTwo.push([points2X_paodao[i], points2Y_paodao[i], points2Z_paodao[i]])
        }
        for(i=0;i<n+1;i++){
            points_line_paodao[i].x = points2X_paodao[i];
            points_line_paodao[i].y = points2Y_paodao[i];
            points_line_paodao[i].z = points2Z_paodao[i];
        }
        let geometry_line_paodao = new THREE.BufferGeometry().setFromPoints( points_line_paodao );
        let material_line_paodao = new THREE.LineBasicMaterial( { color: 0x0000FF } );
        let line_paodao = new THREE.Line( geometry_line_paodao, material_line_paodao );
        line_paodao.isEdage = true
        let surface_geometry_paodao = new THREE.BufferGeometry();
        let vertices_paodao = new Float32Array(18*n);
        for (i=0; i<n; i++){
            vertices_paodao[i*18]=points1X_paodao[i];
            vertices_paodao[i*18+1]=points1Y_paodao[i];
            vertices_paodao[i*18+2]=points1Z_paodao[i];

            vertices_paodao[i*18+3]=points1X_paodao[i+1];
            vertices_paodao[i*18+4]=points1Y_paodao[i+1];
            vertices_paodao[i*18+5]=points1Z_paodao[i+1];

            vertices_paodao[i*18+6]=points2X_paodao[i+1];
            vertices_paodao[i*18+7]=points2Y_paodao[i+1];
            vertices_paodao[i*18+8]=points2Z_paodao[i+1];

            vertices_paodao[i*18+9]=points2X_paodao[i+1];
            vertices_paodao[i*18+10]=points2Y_paodao[i+1];
            vertices_paodao[i*18+11]=points2Z_paodao[i+1];

            vertices_paodao[i*18+12]=points2X_paodao[i];
            vertices_paodao[i*18+13]=points2Y_paodao[i];
            vertices_paodao[i*18+14]=points2Z_paodao[i];

            vertices_paodao[i*18+15]=points1X_paodao[i];
            vertices_paodao[i*18+16]=points1Y_paodao[i];
            vertices_paodao[i*18+17]=points1Z_paodao[i];
        }
        surface_geometry_paodao.setAttribute( 'position', new THREE.BufferAttribute( vertices_paodao, 3 ) );
        surface_geometry_paodao.computeVertexNormals();
        
        let surface_material_paodao = new THREE.MeshPhongMaterial({color: 0xdddddd, side:THREE.DoubleSide, /* ambient:0xff4444 ,*/ emissive:0x000000,specular:0xffffff, shininess: 3,wireframe: false,transparent: true, opacity: 1});
        let surface_mesh_paodao = new THREE.Mesh( surface_geometry_paodao, surface_material_paodao );
        // surface_mesh_paodao.showName = "跑道"
        surface_mesh_paodao.showName = "本体"
        surface_mesh_paodao.lines = [lineOne, lineTwo]
        this.points1Z_paodao = points1Z_paodao
        this.points2Z_paodao = points2Z_paodao
        return [surface_mesh_paodao, line_paodao]
    },
    // 绘制本体侧面
    getCurvePart1() {
        let qs = this.quanshu
        let R = this.diameter / 2
        let lj = this.luoju
        let n = this.n
        let points1X_benti = new Array();
        let points1Y_benti = new Array();
        let points1Z_benti = new Array();

        let points2X_benti = new Array();
        let points2Y_benti = new Array();
        let points2Z_benti = new Array();
        let lineOne = []
        let lineTwo = []
        let DIR = parseInt(this.direction)
        let db = this.dangbian_xishu
        for(var i=0;i<n+1;i++){
            points1X_benti[i]=R*Math.cos(Math.PI*2*(i/n)*DIR+Math.PI*2*qs*DIR)
            points1Y_benti[i]=R*Math.sin(Math.PI*2*(i/n)*DIR+Math.PI*2*qs*DIR)
            points1Z_benti[i]=0
            
            lineOne.push([points1X_benti[i], points1Y_benti[i], points1Z_benti[i]])

            points2X_benti[i]=R*Math.cos(Math.PI*2*(i/n)*DIR+Math.PI*2*qs*DIR)
            points2Y_benti[i]=R*Math.sin(Math.PI*2*(i/n)*DIR+Math.PI*2*qs*DIR)
            points2Z_benti[i]=lj*qs*(1-((i)/(n)))+(lj*qs+db*lj)*((i)/(n))

            lineTwo.push([points2X_benti[i], points2Y_benti[i], points2Z_benti[i]])
        }

        let surface_geometry_benti_part1 = new THREE.BufferGeometry();
        let vertices_benti_part1 = new Float32Array(18*n);
        for (i=0; i<n; i++){
            vertices_benti_part1[i*18]=points1X_benti[i];
            vertices_benti_part1[i*18+1]=points1Y_benti[i];
            vertices_benti_part1[i*18+2]=points1Z_benti[i];

            vertices_benti_part1[i*18+3]=points1X_benti[i+1];
            vertices_benti_part1[i*18+4]=points1Y_benti[i+1];
            vertices_benti_part1[i*18+5]=points1Z_benti[i+1];

            vertices_benti_part1[i*18+6]=points2X_benti[i+1];
            vertices_benti_part1[i*18+7]=points2Y_benti[i+1];
            vertices_benti_part1[i*18+8]=points2Z_benti[i+1];

            vertices_benti_part1[i*18+9]=points2X_benti[i+1];
            vertices_benti_part1[i*18+10]=points2Y_benti[i+1];
            vertices_benti_part1[i*18+11]=points2Z_benti[i+1];

            vertices_benti_part1[i*18+12]=points2X_benti[i];
            vertices_benti_part1[i*18+13]=points2Y_benti[i];
            vertices_benti_part1[i*18+14]=points2Z_benti[i];

            vertices_benti_part1[i*18+15]=points1X_benti[i];
            vertices_benti_part1[i*18+16]=points1Y_benti[i];
            vertices_benti_part1[i*18+17]=points1Z_benti[i];
        }
        surface_geometry_benti_part1.setAttribute( 'position', new THREE.BufferAttribute( vertices_benti_part1, 3 ) );
        surface_geometry_benti_part1.computeVertexNormals();
        
        let surface_material_benti_part1 = new THREE.MeshPhongMaterial({color: 0xaaaaaa, side:THREE.DoubleSide, /*ambient:0xff00ff,*/emissive:0x000000,specular:0xffffff, shininess: 3,wireframe: false,transparent: true, opacity: 1});

        let surface_mesh_benti_part1 = new THREE.Mesh( surface_geometry_benti_part1, surface_material_benti_part1 );
        //surface_mesh_benti_part1.showName = "侧面"
        surface_mesh_benti_part1.showName = "本体"
        surface_mesh_benti_part1.lines = [lineOne, lineTwo]
        return surface_mesh_benti_part1
    },
    
    //获取三角板
    getCruveSanjiao() {
        let points1X_sanjiao = new Array();
        let points1Y_sanjiao = new Array();
        let points1Z_sanjiao = new Array();

        let points2X_sanjiao = new Array();
        let points2Y_sanjiao = new Array();
        let points2Z_sanjiao = new Array();
        let i
        let qs = this.quanshu
        let R = this.diameter / 2
        let lj = this.luoju
        let n = this.n
        let w0 = this.width0
        let w0_1st = this.width0_1st + w0
        let qs_1st = this.quanshu_1st
        let DIR = parseInt(this.direction)
        let sjiao = 270
        let lineOne = []
        let lineTwo = []
        for(i=0;i<n+1;i++){
            points1X_sanjiao[i]=(R-w0_1st*Math.cos(5/180*Math.PI))*Math.cos(sjiao/180*Math.PI*(i/n)*DIR);
            points1Y_sanjiao[i]=(R-w0_1st*Math.cos(5/180*Math.PI))*Math.sin(sjiao/180*Math.PI*(i/n)*DIR);
            points1Z_sanjiao[i]=w0_1st*Math.sin(5/180*Math.PI);

            lineOne.push([points1X_sanjiao[i], points1Y_sanjiao[i], points1Z_sanjiao[i]])
            
            points2X_sanjiao[i]=(R-w0_1st*Math.cos(5/180*Math.PI))*Math.cos(sjiao/180*Math.PI*(i/n)*DIR);
            points2Y_sanjiao[i]=(R-w0_1st*Math.cos(5/180*Math.PI))*Math.sin(sjiao/180*Math.PI*(i/n)*DIR);
            points2Z_sanjiao[i]=w0_1st*Math.sin(5/180*Math.PI)+lj*(sjiao/360)*(i/n);

            lineTwo.push([points2X_sanjiao[i], points2Y_sanjiao[i], points2Z_sanjiao[i]])
        }
        lineOne.shift()
        lineTwo.shift()
        let surface_geometry_sanjiao = new THREE.BufferGeometry();
        let vertices_sanjiao = new Float32Array(18*n);
        for (i=0; i<n; i++){
            vertices_sanjiao[i*18]=points1X_sanjiao[i];
            vertices_sanjiao[i*18+1]=points1Y_sanjiao[i];
            vertices_sanjiao[i*18+2]=points1Z_sanjiao[i];

            vertices_sanjiao[i*18+3]=points1X_sanjiao[i+1];
            vertices_sanjiao[i*18+4]=points1Y_sanjiao[i+1];
            vertices_sanjiao[i*18+5]=points1Z_sanjiao[i+1];

            vertices_sanjiao[i*18+6]=points2X_sanjiao[i+1];
            vertices_sanjiao[i*18+7]=points2Y_sanjiao[i+1];
            vertices_sanjiao[i*18+8]=points2Z_sanjiao[i+1];

            vertices_sanjiao[i*18+9]=points2X_sanjiao[i+1];
            vertices_sanjiao[i*18+10]=points2Y_sanjiao[i+1];
            vertices_sanjiao[i*18+11]=points2Z_sanjiao[i+1];

            vertices_sanjiao[i*18+12]=points2X_sanjiao[i];
            vertices_sanjiao[i*18+13]=points2Y_sanjiao[i];
            vertices_sanjiao[i*18+14]=points2Z_sanjiao[i];

            vertices_sanjiao[i*18+15]=points1X_sanjiao[i];
            vertices_sanjiao[i*18+16]=points1Y_sanjiao[i];
            vertices_sanjiao[i*18+17]=points1Z_sanjiao[i];
        }
        surface_geometry_sanjiao.setAttribute( 'position', new THREE.BufferAttribute( vertices_sanjiao, 3 ) );
        surface_geometry_sanjiao.computeVertexNormals();
        
        let surface_material_sanjiao = new THREE.MeshPhongMaterial({color: 0x333333, side:THREE.DoubleSide, /*ambient:0xff00ff,*/emissive:0x000000,specular:0xffffff, shininess: 3,wireframe: false,transparent: true, opacity: 1});

        let surface_mesh_sanjiao = new THREE.Mesh( surface_geometry_sanjiao, surface_material_sanjiao );
        // surface_mesh_sanjiao.showName = "三角"
        surface_mesh_sanjiao.showName = "本体"
        surface_mesh_sanjiao.lines = [lineOne, lineTwo]
        return surface_mesh_sanjiao;
    },
    //获取变径
    getCurveBianjing() {
        let i
        let qs = this.quanshu
        let R = this.diameter / 2
        let lj = this.luoju
        let n = this.n
        let w0 = this.width0
        let w0_1st = this.width0_1st + w0
        let qs_1st = this.quanshu_1st
        let DIR = parseInt(this.direction)
        let DIRB=false
        let TH = this.bianjing_theta /180*Math.PI
        let w =  this.bianjing_width
        let dec_jx = this.bianjing_decline_jingxiang
        let bianjing_flag = parseInt(this.bianjing_type)
        let X1 = R;
        let Y1 = 0;
        let Xa = (R+w);
        let Ya = 0;
        let X2 = (R-w0)*Math.cos(TH);
        let Y2 = (R-w0)*Math.sin(TH);
        let Xb = R*Math.cos(TH);
        let Yb = R*Math.sin(TH);
        let C1x = (X2**2+Y2**2-X1**2-Y1**2)/(2*(X2-X1));
        let C1y = 0;
        let Cax = (Xb**2+Yb**2-Xa**2-Ya**2)/(2*(Xb-Xa));
        let Cay = 0;
        let C1xx = C1x*Math.cos((2*Math.PI*qs+TH)*DIR)-C1y*Math.sin((2*Math.PI*qs+TH)*DIR);
        let C1yy = C1x*Math.sin((2*Math.PI*qs+TH)*DIR)+C1y*Math.cos((2*Math.PI*qs+TH)*DIR);

        let Caxx = Cax*Math.cos((2*Math.PI*qs+TH)*DIR)-Cay*Math.sin((2*Math.PI*qs+TH)*DIR);
        let Cayy = Cax*Math.sin((2*Math.PI*qs+TH)*DIR)+Cay*Math.cos((2*Math.PI*qs+TH)*DIR);

        let D_X1 = X2-C1x;
        let D_Y1 = Y2-C1y;
        let D_Xa = Xb-Cax;
        let D_Ya = Yb-Cay;
        var t1,ta
        if (D_X1==0)
            {t1=Math.PI/2;}
        else if (D_X1>0)
            {t1=Math.atan(D_Y1/D_X1);}
        else 
            {t1=Math.atan(D_Y1/D_X1)-Math.PI;}
        if (D_Xa==0)
            {ta=Math.PI/2;}
        else if (D_Xa>0)
            {ta=Math.atan(D_Ya/D_Xa);}
        else
            {ta=Math.atan(D_Ya/D_Xa)-Math.PI;}
        ///////////////////////////////////////////////////////////
        let La = parseFloat(R+w)*(4/3)*((1-Math.cos(TH/2))/(Math.sin(TH/2)));
        let Lb = parseFloat(R)*(4/3)*((1-Math.cos(TH/2))/(Math.sin(TH/2)));
        let L1 = parseFloat(R)*(4/3)*((1-Math.cos(TH/2))/(Math.sin(TH/2)));
        let L2 = parseFloat(R-w0)*(4/3)*((1-Math.cos(TH/2))/(Math.sin(TH/2)));
        let Bxa = R+w;
        let Bya = La;
        let Bxb = Lb * Math.cos(TH-Math.PI/2)+Xb;
        let Byb = Lb * Math.sin(TH-Math.PI/2)+Yb;
        let Bx1 = R;
        let By1 = L1;
        let Bx2 = L2 * Math.cos(TH-Math.PI/2)+X2;
        let By2 = L2 * Math.sin(TH-Math.PI/2)+Y2;

        let X11 = X1*Math.cos(-(2*Math.PI*qs+TH)*DIR)-Y1*DIR*Math.sin(-(2*Math.PI*qs+TH)*DIR);
        let Y11 = X1*Math.sin(-(2*Math.PI*qs+TH)*DIR)+Y1*DIR*Math.cos(-(2*Math.PI*qs+TH)*DIR);

        let X22 = X2*Math.cos(-(2*Math.PI*qs+TH)*DIR)-Y2*DIR*Math.sin(-(2*Math.PI*qs+TH)*DIR);
        let Y22 = X2*Math.sin(-(2*Math.PI*qs+TH)*DIR)+Y2*DIR*Math.cos(-(2*Math.PI*qs+TH)*DIR);

        let Xaa = Xa*Math.cos(-(2*Math.PI*qs+TH)*DIR)-Ya*DIR*Math.sin(-(2*Math.PI*qs+TH)*DIR);
        let Yaa = Xa*Math.sin(-(2*Math.PI*qs+TH)*DIR)+Ya*DIR*Math.cos(-(2*Math.PI*qs+TH)*DIR);

        let Xbb = Xb*Math.cos(-(2*Math.PI*qs+TH)*DIR)-Yb*DIR*Math.sin(-(2*Math.PI*qs+TH)*DIR);
        let Ybb = Xb*Math.sin(-(2*Math.PI*qs+TH)*DIR)+Yb*DIR*Math.cos(-(2*Math.PI*qs+TH)*DIR);

        let Bxaa = Bxa*Math.cos(-(2*Math.PI*qs+TH)*DIR)-Bya*DIR*Math.sin(-(2*Math.PI*qs+TH)*DIR);
        let Byaa = Bxa*Math.sin(-(2*Math.PI*qs+TH)*DIR)+Bya*DIR*Math.cos(-(2*Math.PI*qs+TH)*DIR);

        let Bxbb = Bxb*Math.cos(-(2*Math.PI*qs+TH)*DIR)-Byb*DIR*Math.sin(-(2*Math.PI*qs+TH)*DIR);
        let Bybb = Bxb*Math.sin(-(2*Math.PI*qs+TH)*DIR)+Byb*DIR*Math.cos(-(2*Math.PI*qs+TH)*DIR);

        let Bx11 = Bx1*Math.cos(-(2*Math.PI*qs+TH)*DIR)-By1*DIR*Math.sin(-(2*Math.PI*qs+TH)*DIR);
        let By11 = Bx1*Math.sin(-(2*Math.PI*qs+TH)*DIR)+By1*DIR*Math.cos(-(2*Math.PI*qs+TH)*DIR);

        let Bx22 = Bx2*Math.cos(-(2*Math.PI*qs+TH)*DIR)-By2*DIR*Math.sin(-(2*Math.PI*qs+TH)*DIR);
        let By22 = Bx2*Math.sin(-(2*Math.PI*qs+TH)*DIR)+By2*DIR*Math.cos(-(2*Math.PI*qs+TH)*DIR);
        if(this.direction == '1') {
            DIRB=true
        }
        let lineOne = []
        let lineTwo = []
        
        let curve_arc_bianjing1 = new THREE.EllipseCurve(
            C1xx,  C1yy,            
            R-C1x, R-C1x,   
            (0+2*Math.PI*qs+TH)*DIR,  (-t1+2*Math.PI*qs+TH)*DIR,  
            DIRB,            
            0)
        let curve_arc_bianjing2 = new THREE.EllipseCurve(
            Caxx,  Cayy,            
            R+w-Cax, R+w-Cax,          
            (0+2*Math.PI*qs+TH)*DIR,  (-ta+2*Math.PI*qs+TH)*DIR,  
            DIRB,            
            0)
        let curve_bezier_bianjing1 = new THREE.CubicBezierCurve3(
            new THREE.Vector3( X11, -Y11, 0 ),
            new THREE.Vector3( Bx11, -By11, 0 ),
            new THREE.Vector3( Bx22, -By22, 0 ),
            new THREE.Vector3( X22, -Y22, 0 ))
        let curve_bezier_bianjing2 = new THREE.CubicBezierCurve3(
            new THREE.Vector3( Xaa, -Yaa, 0 ),
            new THREE.Vector3( Bxaa, -Byaa, 0 ),
            new THREE.Vector3( Bxbb, -Bybb, 0 ),
            new THREE.Vector3( Xbb, -Ybb, 0 ))

        let n_bianjing = parseInt(n*(TH/(Math.PI*2)))
        let points_bezier_bianjing1 = curve_bezier_bianjing1.getSpacedPoints( n_bianjing );
        let points_bezier_bianjing2 = curve_bezier_bianjing2.getSpacedPoints( n_bianjing );
        let points_arc_bianjing1 = curve_arc_bianjing1.getSpacedPoints( n_bianjing );
        let points_arc_bianjing2 = curve_arc_bianjing2.getSpacedPoints( n_bianjing );
        let points1Z_paodao = this.points1Z_paodao
        let points2Z_paodao = this.points2Z_paodao
        let meshs = []
        if (bianjing_flag==1){
            
            for(i=0;i<n_bianjing+1;i++){
            points_bezier_bianjing1[i].z=points2Z_paodao[n]*(i/n_bianjing)+(points1Z_paodao[n])*(1-(i/n_bianjing))}
            let geometry_bezier_bianjing1 = new THREE.BufferGeometry().setFromPoints( points_bezier_bianjing1 );
            let material_bezier_bianjing1 = new THREE.LineBasicMaterial( { color: 0x00ffff } );
            let bezier_bianjing1 = new THREE.Line( geometry_bezier_bianjing1, material_bezier_bianjing1 );
            bezier_bianjing1.isEdage = true
            //scene.add(bezier_bianjing1);
            meshs.push(bezier_bianjing1)

            
            for(i=0;i<n_bianjing+1;i++){
            points_bezier_bianjing2[i].z=points1Z_paodao[n]*(i/n_bianjing)+(points1Z_paodao[n]-dec_jx)*(1-(i/n_bianjing))}
            let geometry_bezier_bianjing2 = new THREE.BufferGeometry().setFromPoints( points_bezier_bianjing2 );
            let material_bezier_bianjing2 = new THREE.LineBasicMaterial( { color: 0x00ffff } );
            let bezier_bianjing2 = new THREE.Line( geometry_bezier_bianjing2, material_bezier_bianjing2 );
            bezier_bianjing2.isEdage = true
            //scene.add(bezier_bianjing2);
            meshs.push(bezier_bianjing2)

        } else {
            
            for(i=0;i<n_bianjing+1;i++) {
                points_arc_bianjing1[i].z=points2Z_paodao[n]*(i/n_bianjing)+(points1Z_paodao[n])*(1-(i/n_bianjing))
            }
            let geometry_arc_bianjing1 = new THREE.BufferGeometry().setFromPoints( points_arc_bianjing1 );
            let material_arc_bianjing1 = new THREE.LineBasicMaterial( { color: 0x00ff00 } );
            let arc_bianjing1 = new THREE.Line( geometry_arc_bianjing1, material_arc_bianjing1 );
            //scene.add(arc_bianjing1);
            arc_bianjing1.isEdage = true
            meshs.push(arc_bianjing1)

            
            for(i=0;i<n_bianjing+1;i++){
               points_arc_bianjing2[i].z=points1Z_paodao[n]*(i/n_bianjing)+(points1Z_paodao[n]-dec_jx)*(1-(i/n_bianjing))
            }
            let geometry_arc_bianjing2 = new THREE.BufferGeometry().setFromPoints( points_arc_bianjing2 );
            let material_arc_bianjing2 = new THREE.LineBasicMaterial( { color: 0x00ff00 } );
            let arc_bianjing2 = new THREE.Line( geometry_arc_bianjing2, material_arc_bianjing2 );
            arc_bianjing2.isEdage = true
            // scene.add(arc_bianjing2);
            meshs.push(arc_bianjing2)
        }

            let surface_geometry_bianjing = new THREE.BufferGeometry();
            let vertices_bianjing = new Float32Array(18*n_bianjing);

            let points1X_bianjing = new Array()
            let points1Y_bianjing = new Array()
            let points1Z_bianjing = new Array()
            let points2X_bianjing = new Array()
            let points2Y_bianjing = new Array()
            let points2Z_bianjing = new Array()

            if (bianjing_flag==1){
                for (i=0; i<n_bianjing+1; i++){
                    points1X_bianjing[i]=points_bezier_bianjing1[i].x
                    points1Y_bianjing[i]=points_bezier_bianjing1[i].y
                    points1Z_bianjing[i]=points_bezier_bianjing1[i].z

                    lineOne.push([points1X_bianjing[i], points1Y_bianjing[i],points1Z_bianjing[i]])

                    points2X_bianjing[i]=points_bezier_bianjing2[i].x
                    points2Y_bianjing[i]=points_bezier_bianjing2[i].y
                    points2Z_bianjing[i]=points_bezier_bianjing2[i].z
                    lineTwo.push([points2X_bianjing[i], points2Y_bianjing[i],points2Z_bianjing[i]])
                }
            }
            else {
                for (i=0; i<n_bianjing+1; i++){
                    points1X_bianjing[i]=points_arc_bianjing1[i].x
                    points1Y_bianjing[i]=points_arc_bianjing1[i].y
                    points1Z_bianjing[i]=points_arc_bianjing1[i].z
                    lineOne.push([points1X_bianjing[i], points1Y_bianjing[i],points1Z_bianjing[i]])

                    points2X_bianjing[i]=points_arc_bianjing2[i].x
                    points2Y_bianjing[i]=points_arc_bianjing2[i].y
                    points2Z_bianjing[i]=points_arc_bianjing2[i].z
                    lineTwo.push([points2X_bianjing[i], points2Y_bianjing[i],points2Z_bianjing[i]])
                }
            }
            //调整方向
            lineOne = lineOne.reverse()
            lineTwo = lineTwo.reverse()
            for (i=0; i<n; i++){
                vertices_bianjing[i*18]=points1X_bianjing[i];
                vertices_bianjing[i*18+1]=points1Y_bianjing[i];
                vertices_bianjing[i*18+2]=points1Z_bianjing[i];

                vertices_bianjing[i*18+3]=points1X_bianjing[i+1];
                vertices_bianjing[i*18+4]=points1Y_bianjing[i+1];
                vertices_bianjing[i*18+5]=points1Z_bianjing[i+1];

                vertices_bianjing[i*18+6]=points2X_bianjing[i+1];
                vertices_bianjing[i*18+7]=points2Y_bianjing[i+1];
                vertices_bianjing[i*18+8]=points2Z_bianjing[i+1];

                vertices_bianjing[i*18+9]=points2X_bianjing[i+1];
                vertices_bianjing[i*18+10]=points2Y_bianjing[i+1];
                vertices_bianjing[i*18+11]=points2Z_bianjing[i+1];

                vertices_bianjing[i*18+12]=points2X_bianjing[i];
                vertices_bianjing[i*18+13]=points2Y_bianjing[i];
                vertices_bianjing[i*18+14]=points2Z_bianjing[i];

                vertices_bianjing[i*18+15]=points1X_bianjing[i];
                vertices_bianjing[i*18+16]=points1Y_bianjing[i];
                vertices_bianjing[i*18+17]=points1Z_bianjing[i];
                }
        surface_geometry_bianjing.setAttribute( 'position', new THREE.BufferAttribute( vertices_bianjing, 3 ) );
        surface_geometry_bianjing.computeVertexNormals();
        
        let surface_material_bianjing = new THREE.MeshPhongMaterial({color: 0x555555, side:THREE.DoubleSide, /* ambient:0x224466, */ emissive:0x000000,specular:0xffffff, shininess: 3, wireframe: false ,transparent: true, opacity: 1});

        let surface_mesh_bianjing = new THREE.Mesh( surface_geometry_bianjing, surface_material_bianjing )
        surface_mesh_bianjing.showName = "变径"
        surface_mesh_bianjing.lines = [lineOne, lineTwo]
        surface_mesh_bianjing.group = 1
        meshs.push(surface_mesh_bianjing)
        return meshs
    },
    //获取轨道的曲面图形
    getGuijiCurveMeshs() {

        let qs = this.quanshu
        let R = this.diameter / 2
        let lj = this.luoju
        let DIR = parseInt(this.direction)
        let TH = this.bianjing_theta /180*Math.PI
        let w =  this.bianjing_width
        let n = this.n
        var R0 = new Array();
        var RR = new Array();
        var R1 = new Array();
        var R2 = new Array();
        var R3 = new Array();
        var R4 = new Array();
        var tt1 = new Array();
        var t2 = new Array();
        var t3 = new Array();
        var t4 = new Array();
        var H0 = new Array();
        var H = new Array();
        var B1 = new Array();
        var B2 = new Array();
        var L_S = new Array();
        var A_S = new Array();
        var N_C = this.guidao_n_curve
        var O_R0 = this.guidao_offset_N_C0
        var O_R = O_R0+ this.guidao_offset_N_C
        var dec_jx = this.bianjing_decline_jingxiang;
        var O_H0 = O_R0/w*dec_jx
        var O_H = 0
        var O1 = this.guidao_offset1;
        var O2 = this.guidao_offset2;
        var sc1 = 0.5
        var sc2 = 0.5
        var t = DIR* this.guidao_delta;
        var B0 = 0
        var B = 0
        
        for(var i=0;i<N_C;i++){
            R0[i] = R+w-i*O_R0;
            RR[i] = R+w+this.guidao_offset_radius-i*O_R;
            R1[i] = Math.sqrt(R0[i]**2+O1**2);
            R4[i] = Math.sqrt(RR[i]**2+O2**2);
            tt1[i] = DIR*Math.atan(O1/R0[i])/Math.PI*180;
            t4[i] = t-(DIR*Math.atan(O2/RR[i])/Math.PI*180);
            B1[i] = -DIR*Math.atan((O1/R0[i])*(R1[i]/(180/Math.PI)));
            B2[i] = DIR*Math.atan((O2/RR[i])*(RR[i]/(180/Math.PI)));	
            t2[i] = tt1[i]+(t4[i]-tt1[i])*sc1*Math.cos(B1[i]+B0);
            t3[i] = t4[i]-(t4[i]-tt1[i])*sc2*Math.cos(B2[i]+B);
            R2[i] = R1[i]-(t4[i]-tt1[i])*sc1*Math.sin(B1[i]+B0);
            R3[i] = R4[i]+(t4[i]-tt1[i])*sc2*Math.sin(B2[i]+B);

            H0[i] = 0+i*O_H0;
            H[i] = this.guidao_high-i*O_H;
        }
        var theta_bianjing = Math.atan(dec_jx/w)
        var Rx0 = - this.guidao_Rotation_X;
        var Ry0 =  this.guidao_Rotation_Y
        var Rx = Rx0*Math.cos(theta_bianjing)+Ry0*Math.sin(theta_bianjing)
        var Ry = -Rx0*Math.sin(theta_bianjing)+Ry0*Math.cos(theta_bianjing)
        var RTT = this.guidao_rotation/180*Math.PI

        

        var section_L = this.guidao_section_length;
        var section_W = this.guidao_section_width;

        var point_A_X_sectionA = 0
        var point_A_Y_sectionA = 0
        var point_B_X_sectionA = -section_L*Math.cos(theta_bianjing)
        var point_B_Y_sectionA = section_L*Math.sin(theta_bianjing)
        var point_C_X_sectionA = -section_L*Math.cos(theta_bianjing)+section_W*Math.cos(Math.PI/2-theta_bianjing)
        var point_C_Y_sectionA = section_L*Math.sin(theta_bianjing)+section_W*Math.sin(Math.PI/2-theta_bianjing)
        var point_D_X_sectionA = section_W*Math.cos(Math.PI/2-theta_bianjing)
        var point_D_Y_sectionA = section_W*Math.sin(Math.PI/2-theta_bianjing)

        L_S[0] = Math.sqrt((point_A_X_sectionA-Rx)**2+(point_A_Y_sectionA-Ry)**2)
        if ((point_A_Y_sectionA-Ry)>0)	
            A_S[0]=Math.acos((point_A_X_sectionA-Rx)/L_S[0]);
        else if ((point_A_Y_sectionA-Ry)<0)	
            A_S[0]=-Math.acos((point_A_X_sectionA-Rx)/L_S[0]);
        else if ((point_A_X_sectionA-Rx)>0)		
            A_S[0]=0;
        else 
            A_S[0]=Math.PI

        L_S[1] = Math.sqrt((point_B_X_sectionA-Rx)**2+(point_B_Y_sectionA-Ry)**2)
        if ((point_B_Y_sectionA-Ry)>0)	
            A_S[1]=Math.acos((point_B_X_sectionA-Rx)/L_S[1]);
        else if ((point_B_Y_sectionA-Ry)<0)	
            A_S[1]=-Math.acos((point_B_X_sectionA-Rx)/L_S[1]);
        else if ((point_B_X_sectionA-Rx)>0)		
            A_S[1]=0;
        else 
            A_S[1]=Math.PI

        L_S[2] = Math.sqrt((point_C_X_sectionA-Rx)**2+(point_C_Y_sectionA-Ry)**2)
        if ((point_C_Y_sectionA-Ry)>0)	
            A_S[2]=Math.acos((point_C_X_sectionA-Rx)/L_S[2]);
        else if ((point_C_Y_sectionA-Ry)<0)	
            A_S[2]=-Math.acos((point_C_X_sectionA-Rx)/L_S[2]);
        else if ((point_C_X_sectionA-Rx)>0)		
            A_S[2]=0;
        else 
            A_S[2]=Math.PI

        L_S[3] = Math.sqrt((point_D_X_sectionA-Rx)**2+(point_D_Y_sectionA-Ry)**2)
        if ((point_D_Y_sectionA-Ry)>0)	
            A_S[3]=Math.acos((point_D_X_sectionA-Rx)/L_S[3]);
        else if ((point_D_Y_sectionA-Ry)<0)	
            A_S[3]=-Math.acos((point_D_X_sectionA-Rx)/L_S[3]);
        else if ((point_D_X_sectionA-Rx)>0)		
            A_S[3]=0;
        else 
            A_S[3]=Math.PI

        L_S[4] = Math.sqrt((point_A_X_sectionA-Rx)**2+(point_A_Y_sectionA-Ry)**2)
        if ((point_A_Y_sectionA-Ry)>0)	
            A_S[4]=Math.acos((point_A_X_sectionA-Rx)/L_S[4]);
        else if ((point_A_Y_sectionA-Ry)<0)	
            A_S[4]=-Math.acos((point_A_X_sectionA-Rx)/L_S[4]);
        else if ((point_A_X_sectionA-Rx)>0)		
            A_S[4]=0;
        else 
            A_S[4]=Math.PI
            
        var line_section
        var curve = new Array();
        var points0 = new Array();
        var section = new Array();
        var curveLength = new Array();
        var geometry_line = new Array();
        var curveObject = new Array();
        var vector = new Array();
        var alpha_vector = new Array();
        var pointsAxis = new Array();
        var axisObject = new Array();
        var points_line_paodao = new Array();
        var points_section = new Array();
        var surfaceObjectA = new Array();

        var points1X = new Array();
        var points1Y = new Array();
        var points1Z = new Array();
        var points2X = new Array();
        var points2Y = new Array();
        var points2Z = new Array();

        var points1X_LINK = new Array();
        var points1Y_LINK = new Array();
        var points1Z_LINK = new Array();
        var points2X_LINK = new Array();
        var points2Y_LINK = new Array();
        var points2Z_LINK = new Array();
        var meshs = [];
        class CustomSinCurve extends THREE.Curve {
            constructor( R1, R2, R3, R4, tt1, t2, t3, t4, H0 ) {
                super();
                this.R1 = R1;
                this.R2 = R2;
                this.R3 = R3;
                this.R4 = R4;
                this.tt1 = tt1;
                this.t2 = t2;
                this.t3 = t3;
                this.t4 = t4;
                this.H0 = H0;
            }
            getPoint( t, optionalTarget = new THREE.Vector3() ) {
                const tx = (this.R1*(1-t)**3+3*this.R2*t*(1-t)**2+3*this.R3*t**2*(1-t)+this.R4*t**3)*Math.cos((this.tt1*(1-t)**3+3*this.t2*t*(1-t)**2+3*this.t3*t**2*(1-t)+this.t4*t**3)/180*Math.PI);
                const ty = (this.R1*(1-t)**3+3*this.R2*t*(1-t)**2+3*this.R3*t**2*(1-t)+this.R4*t**3)*Math.sin((this.tt1*(1-t)**3+3*this.t2*t*(1-t)**2+3*this.t3*t**2*(1-t)+this.t4*t**3)/180*Math.PI);
                const tz = this.H0;
                return optionalTarget.set( tx, ty, tz )
            }
        }


        for(i=0;i<5;i++){
            points_section[i]=this.curve_luoxian.getPointAt(i/n);
        }
        points_section[0].x = (point_A_X_sectionA +R+w)*Math.cos((2*Math.PI*qs+TH)*DIR)
        points_section[0].y = (point_A_X_sectionA +R+w)*Math.sin((2*Math.PI*qs+TH)*DIR)
        points_section[0].z = point_A_Y_sectionA + lj*qs-dec_jx

        points_section[1].x = (point_B_X_sectionA +R+w)*Math.cos((2*Math.PI*qs+TH)*DIR)
        points_section[1].y = (point_B_X_sectionA +R+w)*Math.sin((2*Math.PI*qs+TH)*DIR)
        points_section[1].z = point_B_Y_sectionA + lj*qs-dec_jx

        points_section[2].x = (point_C_X_sectionA +R+w)*Math.cos((2*Math.PI*qs+TH)*DIR)
        points_section[2].y = (point_C_X_sectionA +R+w)*Math.sin((2*Math.PI*qs+TH)*DIR)
        points_section[2].z = point_C_Y_sectionA + lj*qs-dec_jx

        points_section[3].x = (point_D_X_sectionA +R+w)*Math.cos((2*Math.PI*qs+TH)*DIR)
        points_section[3].y = (point_D_X_sectionA +R+w)*Math.sin((2*Math.PI*qs+TH)*DIR)
        points_section[3].z = point_D_Y_sectionA + lj*qs-dec_jx

        points_section[4].x = (point_A_X_sectionA +R+w)*Math.cos((2*Math.PI*qs+TH)*DIR)
        points_section[4].y = (point_A_X_sectionA +R+w)*Math.sin((2*Math.PI*qs+TH)*DIR)
        points_section[4].z = point_A_Y_sectionA + lj*qs-dec_jx

        

        let geometry_line_section = new THREE.BufferGeometry().setFromPoints( points_section );
        let material_line_section = new THREE.LineBasicMaterial( { color: 0x0000FF } );
        line_section = new THREE.Line( geometry_line_section, material_line_section );
        //scene.add(line_section);
        line_section.isEdage = true
        meshs.push(line_section)

        for(var k=0;k<N_C;k++) {
            if(DIR==1){
                curve[k] = new CustomSinCurve( R1[k], R2[k], R3[k], R4[k], (tt1[k]+360*qs+TH*180/Math.PI), (t2[k]+360*qs+TH*180/Math.PI),(t3[k]+360*qs+TH*180/Math.PI),(t4[k]+360*qs+TH*180/Math.PI),H0[k]);
            }
            else {
                curve[k] = new CustomSinCurve( R4[k], R3[k], R2[k], R1[k], (t4[k]-360*qs-TH*180/Math.PI),(t3[k]-360*qs-TH*180/Math.PI), (t2[k]-360*qs-TH*180/Math.PI),(tt1[k]-360*qs-TH*180/Math.PI),H0[k]);
            }
            
            points0[k] = curve[k].getSpacedPoints( n );
            curveLength[k] = curve[k].getLength();
            if(DIR==1){
                for (i=0;i<n+1;i++){
                    points0[k][i].z = H0[k]-(H0[k]+H[k])*(i/n)+lj*qs-dec_jx;
                }
            }else {
                for (i=0;i<n+1;i++){
                    points0[k][i].z = H0[k]-(H0[k]+H[k])*((n-i)/n)+lj*qs-dec_jx;
                }
            }
            geometry_line[k] = new THREE.BufferGeometry().setFromPoints( points0[k] );
            const material_line = new THREE.LineBasicMaterial( { color: 0x000000 } );
            curveObject[k] = new THREE.Line( geometry_line[k], material_line );
            // scene.add(curveObject[k]);
            curveObject[k].isEdage = true
            meshs.push(curveObject[k])


            vector[k] = new Array();
            alpha_vector[k] = new Array();
            for(i=0;i<n+1;i++) {
                vector[k][i] = curve[k].getTangentAt(i/n);
                if (vector[k][i].y>0)	
                    alpha_vector[k][i]=Math.acos(vector[k][i].x)-Math.PI/2;
                else if (vector[k][i].y<0)	
                    alpha_vector[k][i]=-Math.acos(vector[k][i].x)-Math.PI/2;
                else if (vector[k][i].x<0)		
                    alpha_vector[k][i]=Math.PI/2;
                else 
                    alpha_vector[k][i]=-Math.PI/2;
            }
            pointsAxis[k] = curve[k].getSpacedPoints( n );
            for (i=0;i<n+1;i++){
                pointsAxis[k][i].x = (Rx*Math.cos(alpha_vector[k][i])+Ry*Math.sin(Math.atan((-H0[k]-H[k])/curveLength[k]))*Math.sin(alpha_vector[k][i])+points0[k][i].x);
                pointsAxis[k][i].y = (Rx*Math.sin(alpha_vector[k][i])-Ry*Math.sin(Math.atan((-H0[k]-H[k])/curveLength[k]))*Math.cos(alpha_vector[k][i])+points0[k][i].y);
                pointsAxis[k][i].z = (Ry*Math.cos(Math.atan((-H0[k]-H[k])/curveLength[k]))+points0[k][i].z);
            }
            // 出口相关的参数
            this.chukou_theta1[k] = (Math.atan((-H0[k]-H[k])/curveLength[k])*180/Math.PI)

            var geometry_axis = new Array();
            geometry_axis[k] = new THREE.BufferGeometry().setFromPoints( pointsAxis[k] );
            const material_axis = new THREE.LineBasicMaterial( { color: 0xFFFFFF } );
            axisObject[k] = new THREE.Line( geometry_axis[k], material_axis );
            //scene.add(axisObject[k]);
            axisObject[k].isEdage = true
            meshs.push(axisObject[k])
            surfaceObjectA[k] = new Array();

            for(var j=0;j<4;j++) {
                var lineOne = []
                var lineTwo = []
                if (DIR==1){
                    for(i=0;i<n+1;i++){
                        points1X[i]=((L_S[j]*Math.cos(A_S[j]+(i/n)*(-RTT+theta_bianjing))+Rx)*Math.cos(alpha_vector[k][i])+(L_S[j]*Math.sin(A_S[j]+(i/n)*(-RTT+theta_bianjing))+Ry)*DIR*Math.sin(Math.atan((-H0[k]-H[k])/curveLength[k]))*Math.sin(alpha_vector[k][i])+points0[k][i].x);
                        points1Y[i]=((L_S[j]*Math.cos(A_S[j]+(i/n)*(-RTT+theta_bianjing))+Rx)*Math.sin(alpha_vector[k][i])-(L_S[j]*Math.sin(A_S[j]+(i/n)*(-RTT+theta_bianjing))+Ry)*DIR*Math.sin(Math.atan((-H0[k]-H[k])/curveLength[k]))*Math.cos(alpha_vector[k][i])+points0[k][i].y);
                        points1Z[i]=((L_S[j]*Math.sin(A_S[j]+(i/n)*(-RTT+theta_bianjing))+Ry)*Math.cos(Math.atan((-H0[k]-H[k])/curveLength[k]))+points0[k][i].z)
                        
                        lineOne.push([points1X[i], points1Y[i], points1Z[i]])

                        points2X[i]=((L_S[j+1]*Math.cos(A_S[j+1]+(i/n)*(-RTT+theta_bianjing))+Rx)*Math.cos(alpha_vector[k][i])+(L_S[j+1]*Math.sin(A_S[j+1]+(i/n)*(-RTT+theta_bianjing))+Ry)*DIR*Math.sin(Math.atan((-H0[k]-H[k])/curveLength[k]))*Math.sin(alpha_vector[k][i])+points0[k][i].x);
                        points2Y[i]=((L_S[j+1]*Math.cos(A_S[j+1]+(i/n)*(-RTT+theta_bianjing))+Rx)*Math.sin(alpha_vector[k][i])-(L_S[j+1]*Math.sin(A_S[j+1]+(i/n)*(-RTT+theta_bianjing))+Ry)*DIR*Math.sin(Math.atan((-H0[k]-H[k])/curveLength[k]))*Math.cos(alpha_vector[k][i])+points0[k][i].y);
                        points2Z[i]=((L_S[j+1]*Math.sin(A_S[j+1]+(i/n)*(-RTT+theta_bianjing))+Ry)*Math.cos(Math.atan((-H0[k]-H[k])/curveLength[k]))+points0[k][i].z)
                                
                        lineTwo.push([points2X[i], points2Y[i], points2Z[i]])

                    }
                } else {
                    for(i=0;i<n+1;i++){
                        points1X[i]=((L_S[j]*Math.cos(A_S[j]+((n-i)/n)*(-RTT+theta_bianjing))+Rx)*Math.cos(alpha_vector[k][i])+(L_S[j]*Math.sin(A_S[j]+((n-i)/n)*(-RTT+theta_bianjing))+Ry)*DIR*Math.sin(Math.atan((-H0[k]-H[k])/curveLength[k]))*Math.sin(alpha_vector[k][i])+points0[k][i].x);
                        points1Y[i]=((L_S[j]*Math.cos(A_S[j]+((n-i)/n)*(-RTT+theta_bianjing))+Rx)*Math.sin(alpha_vector[k][i])-(L_S[j]*Math.sin(A_S[j]+((n-i)/n)*(-RTT+theta_bianjing))+Ry)*DIR*Math.sin(Math.atan((-H0[k]-H[k])/curveLength[k]))*Math.cos(alpha_vector[k][i])+points0[k][i].y);
                        points1Z[i]=((L_S[j]*Math.sin(A_S[j]+((n-i)/n)*(-RTT+theta_bianjing))+Ry)*Math.cos(Math.atan((-H0[k]-H[k])/curveLength[k]))+points0[k][i].z)
                        lineOne.push([points1X[i], points1Y[i], points1Z[i]])
                        
                        points2X[i]=((L_S[j+1]*Math.cos(A_S[j+1]+((n-i)/n)*(-RTT+theta_bianjing))+Rx)*Math.cos(alpha_vector[k][i])+(L_S[j+1]*Math.sin(A_S[j+1]+((n-i)/n)*(-RTT+theta_bianjing))+Ry)*DIR*Math.sin(Math.atan((-H0[k]-H[k])/curveLength[k]))*Math.sin(alpha_vector[k][i])+points0[k][i].x);
                        points2Y[i]=((L_S[j+1]*Math.cos(A_S[j+1]+((n-i)/n)*(-RTT+theta_bianjing))+Rx)*Math.sin(alpha_vector[k][i])-(L_S[j+1]*Math.sin(A_S[j+1]+((n-i)/n)*(-RTT+theta_bianjing))+Ry)*DIR*Math.sin(Math.atan((-H0[k]-H[k])/curveLength[k]))*Math.cos(alpha_vector[k][i])+points0[k][i].y);
                        points2Z[i]=((L_S[j+1]*Math.sin(A_S[j+1]+((n-i)/n)*(-RTT+theta_bianjing))+Ry)*Math.cos(Math.atan((-H0[k]-H[k])/curveLength[k]))+points0[k][i].z)
                        lineTwo.push([points2X[i], points2Y[i], points2Z[i]])
 
                    }
                }
                if (DIR==1){
                    this.chukou_p_x[k] = points2X[n];
                    this.chukou_p_y[k] = points2Y[n];
                    this.chukou_p_z[k] = points2Z[n];
                }
                else{
                    this.chukou_p_x[k] = points2X[0];
                    this.chukou_p_y[k] = points2Y[0];
                    this.chukou_p_z[k] = points2Z[0]
                }

                var surface_geometry = new THREE.BufferGeometry();
                var vertices = new Float32Array(18*n);
                for (i=0; i<n; i++) {
                    vertices[i*18]=points1X[i];
                    vertices[i*18+1]=points1Y[i];
                    vertices[i*18+2]=points1Z[i];

                    vertices[i*18+3]=points1X[i+1];
                    vertices[i*18+4]=points1Y[i+1];
                    vertices[i*18+5]=points1Z[i+1];

                    vertices[i*18+6]=points2X[i+1];
                    vertices[i*18+7]=points2Y[i+1];
                    vertices[i*18+8]=points2Z[i+1];

                    vertices[i*18+9]=points2X[i+1];
                    vertices[i*18+10]=points2Y[i+1];
                    vertices[i*18+11]=points2Z[i+1];

                    vertices[i*18+12]=points2X[i];
                    vertices[i*18+13]=points2Y[i];
                    vertices[i*18+14]=points2Z[i];

                    vertices[i*18+15]=points1X[i];
                    vertices[i*18+16]=points1Y[i];
                    vertices[i*18+17]=points1Z[i];
                }
                    
                surface_geometry.setAttribute( 'position', new THREE.BufferAttribute( vertices, 3 ) );
                surface_geometry.computeVertexNormals();
                let surface_material = new THREE.MeshPhongMaterial({color: 0x1b8985, side:THREE.DoubleSide, /* ambient:0x224466, */ emissive:0x000000,specular:0xffffff, shininess: 3,wireframe: false,transparent: true, opacity: 1});
                surfaceObjectA[k][j] = new THREE.Mesh( surface_geometry, surface_material );
                //scene.add(surfaceObjectA[k][j]);
                surfaceObjectA[k][j].lines = [lineOne, lineTwo]
                surfaceObjectA[k][j].showName = "面"+(k+1)+"-"+(j+1)
                surfaceObjectA[k][j].group = 2+k
                meshs.push(surfaceObjectA[k][j])
            }
        }
       // console.log(meshs)
        return meshs
    },
    //获取回料盒曲面
    getHuiliaoCurveMeshs() {

        let qs = this.quanshu
        let R = this.diameter / 2
        let lj = this.luoju
        let DIR = parseInt(this.direction)
        let TH = this.bianjing_theta /180*Math.PI
        let w =  this.bianjing_width
        let n = this.n
        let i = 0
        let hld  = this.huiliao_dian/180*Math.PI;
        let hllc =	this.huiliao_luocha
        let hlyxj = this.huiliao_heyuanxinjiao/180*Math.PI;
        let hldx = 	this.huiliao_koudaxiao/180*Math.PI;
        let hlkd = 	this.huiliao_hekuandu
        let hlxd = 	this.huiliao_hexiedu/180*Math.PI;
        let hlqj = 	this.huiliao_heqiejiao/180*Math.PI;
        let hlgd = 	this.huiliao_hegaodu
        var meshs = []
        var lineOne = []
        var lineTwo = []
        class CustomSinCurveHL1 extends THREE.Curve {
            constructor( scale = 1 ) {
                super();
                this.scale = scale;
             }

            getPoint( t, optionalTarget = new THREE.Vector3() ) {

            const tx = R*Math.cos(((hld-hlyxj)*(1-t)+hld*t)*DIR);
            const ty = R*Math.sin(((hld-hlyxj)*(1-t)+hld*t)*DIR);
            const tz = hlgd+hllc*(1-t);

            return optionalTarget.set( tx, ty, tz ).multiplyScalar( this.scale );
            }
        }

        let curve_hl_1 = new CustomSinCurveHL1( 1 );
        let hl_geometry1 = new THREE.TubeGeometry( curve_hl_1, 100, 1, 8, false );
        let hl_material1 = new THREE.MeshBasicMaterial( { color: 0xff0000, side:THREE.DoubleSide } );
        let hl_mesh1 = new THREE.Mesh( hl_geometry1, hl_material1 );
        hl_mesh1.isEdage = true
        meshs.push(hl_mesh1)
        // scene.add(hl_mesh1); 



        class CustomSinCurveHL2 extends THREE.Curve {

            constructor( scale = 1 ) {
                super();
                this.scale = scale;
            }

            getPoint( t, optionalTarget = new THREE.Vector3() ) {

            const tx = (R+hlkd)*Math.cos(((hld-hlyxj)*(1-t)+(hld-hlqj)*t)*DIR);
            const ty = (R+hlkd)*Math.sin(((hld-hlyxj)*(1-t)+(hld-hlqj)*t)*DIR);
            const tz = hlgd+hllc*(1-t)+hlkd*Math.tan(hlxd);

            return optionalTarget.set( tx, ty, tz ).multiplyScalar( this.scale );
            }
        }

        let curve_hl_2 = new CustomSinCurveHL2( 1 );
        let hl_geometry2 = new THREE.TubeGeometry( curve_hl_2, 100, 1, 8, false );
        let hl_material2 = new THREE.MeshBasicMaterial( { color: 0xff0000, side:THREE.DoubleSide } );
        let hl_mesh2 = new THREE.Mesh( hl_geometry2, hl_material2 );
        hl_mesh2.isEdage = true
        meshs.push(hl_mesh2)
        // scene.add(hl_mesh2); 

        class CustomSinCurveHLK1 extends THREE.Curve {

            constructor( scale = 1 ) {
                super();
                this.scale = scale;
            }

            getPoint( t, optionalTarget = new THREE.Vector3() ) {
                const tx = R*Math.cos(((hld-hldx)*(1-t)+hld*t)*DIR);
                const ty = R*Math.sin(((hld-hldx)*(1-t)+hld*t)*DIR);
                const tz = hlgd+hllc*hldx/hlyxj*(1-t);

                return optionalTarget.set( tx, ty, tz ).multiplyScalar( this.scale );
            }
        }

        let curve_hlk_1 = new CustomSinCurveHLK1( 1 );
        let hlk_geometry1 = new THREE.TubeGeometry( curve_hlk_1, 100, 1, 8, false );
        let hlk_material1 = new THREE.MeshBasicMaterial( { color: 0x00ff00, side:THREE.DoubleSide } );
        let hlk_mesh1 = new THREE.Mesh( hlk_geometry1, hlk_material1 );
        hlk_mesh1.isEdage = true
        meshs.push(hlk_mesh1)
        // scene.add(hlk_mesh1); 

        class CustomSinCurveHLK2 extends THREE.Curve {

            constructor( scale = 1 ) {
                super();
                this.scale = scale;
            }

            getPoint( t, optionalTarget = new THREE.Vector3() ) {

            const tx = R*Math.cos(((hld-hldx)*(1-t)+hld*t)*DIR);
            const ty = R*Math.sin(((hld-hldx)*(1-t)+hld*t)*DIR);
            const tz = hld/2/Math.PI*lj*t+(hld-hldx)/2/Math.PI*lj*(1-t)-1;

            return optionalTarget.set( tx, ty, tz ).multiplyScalar( this.scale );
            }
        }

        let curve_hlk_2 = new CustomSinCurveHLK2( 1 );
        let hlk_geometry2 = new THREE.TubeGeometry( curve_hlk_2, 100, 1, 8, false );
        let hlk_material2 = new THREE.MeshBasicMaterial( { color: 0x00ff00, side:THREE.DoubleSide } );
        let hlk_mesh2 = new THREE.Mesh( hlk_geometry2, hlk_material2 );
        hlk_mesh2.isEdage = true
        meshs.push(hlk_mesh2)
        // scene.add(hlk_mesh2); 

        class CustomSinCurveHLK3 extends THREE.Curve {

            constructor( scale = 1 ) {
                super();
                this.scale = scale;
            }

            getPoint( t, optionalTarget = new THREE.Vector3() ) {

            const tx = R*Math.cos((hld-hldx)*DIR);
            const ty = R*Math.sin((hld-hldx)*DIR);
            const tz = (hllc*(hldx/hlyxj)+hlgd)*t+(hld-hldx)/2/Math.PI*lj*(1-t)-1;

            return optionalTarget.set( tx, ty, tz ).multiplyScalar( this.scale );
            }
        }

        let curve_hlk_3 = new CustomSinCurveHLK3( 1 );
        let hlk_geometry3 = new THREE.TubeGeometry( curve_hlk_3, 100, 1, 8, false );
        let hlk_material3 = new THREE.MeshBasicMaterial( { color: 0x00ff00, side:THREE.DoubleSide } );
        let hlk_mesh3 = new THREE.Mesh( hlk_geometry3, hlk_material3 );
        hlk_mesh3.isEdage = true
        meshs.push(hlk_mesh3)
        // scene.add(hlk_mesh3); 

        class CustomSinCurveHLK4 extends THREE.Curve {

            constructor( scale = 1 ) {
                super();
                this.scale = scale;
            }

            getPoint( t, optionalTarget = new THREE.Vector3() ) {

            const tx = R*Math.cos(hld*DIR);
            const ty = R*Math.sin(hld*DIR);
            const tz = hld/2/Math.PI*lj*t+hlgd*(1-t)-1;

            return optionalTarget.set( tx, ty, tz ).multiplyScalar( this.scale );
            }
        }

        let curve_hlk_4 = new CustomSinCurveHLK4( 1 );
        let hlk_geometry4 = new THREE.TubeGeometry( curve_hlk_4, 100, 1, 8, false );
        let hlk_material4 = new THREE.MeshBasicMaterial( { color: 0x00ff00, side:THREE.DoubleSide } );
        let hlk_mesh4 = new THREE.Mesh( hlk_geometry4, hlk_material4 );
        hlk_mesh4.isEdage = true
        meshs.push(hlk_mesh4)
        // scene.add(hlk_mesh4); 

        let points_hl1 = curve_hl_1.getSpacedPoints( n );
        let points_hl2 = curve_hl_2.getSpacedPoints( n );

        let surface_geometry_hl = new THREE.BufferGeometry();
        let vertices_hl = new Float32Array(18*n);

        let points1X_hl = new Array()
        let points1Y_hl = new Array()
        let points1Z_hl = new Array()
        let points2X_hl = new Array()
        let points2Y_hl = new Array()
        let points2Z_hl = new Array()
        for (let i=0; i<n+1; i++){
            points1X_hl[i]=points_hl1[i].x
            points1Y_hl[i]=points_hl1[i].y
            points1Z_hl[i]=points_hl1[i].z
            lineOne.push([points1X_hl[i], points1Y_hl[i], points1Z_hl[i]])

            points2X_hl[i]=points_hl2[i].x
            points2Y_hl[i]=points_hl2[i].y
            points2Z_hl[i]=points_hl2[i].z
            lineTwo.push([points2X_hl[i], points2Y_hl[i], points2Z_hl[i]])
        }
        
        for (i=0; i<n; i++){
            vertices_hl[i*18]=points1X_hl[i];
            vertices_hl[i*18+1]=points1Y_hl[i];
            vertices_hl[i*18+2]=points1Z_hl[i];

            vertices_hl[i*18+3]=points1X_hl[i+1];
            vertices_hl[i*18+4]=points1Y_hl[i+1];
            vertices_hl[i*18+5]=points1Z_hl[i+1];

            vertices_hl[i*18+6]=points2X_hl[i+1];
            vertices_hl[i*18+7]=points2Y_hl[i+1];
            vertices_hl[i*18+8]=points2Z_hl[i+1];

            vertices_hl[i*18+9]=points2X_hl[i+1];
            vertices_hl[i*18+10]=points2Y_hl[i+1];
            vertices_hl[i*18+11]=points2Z_hl[i+1];

            vertices_hl[i*18+12]=points2X_hl[i];
            vertices_hl[i*18+13]=points2Y_hl[i];
            vertices_hl[i*18+14]=points2Z_hl[i];

            vertices_hl[i*18+15]=points1X_hl[i];
            vertices_hl[i*18+16]=points1Y_hl[i];
            vertices_hl[i*18+17]=points1Z_hl[i];
        }
        surface_geometry_hl.setAttribute( 'position', new THREE.BufferAttribute( vertices_hl, 3 ) );
        surface_geometry_hl.computeVertexNormals();
        
        let surface_material_hl = new THREE.MeshPhongMaterial({color: 0x555555, side:THREE.DoubleSide, /* ambient:0x224466, */ emissive:0x000000,specular:0xffffff, shininess: 3,wireframe: false,transparent: true, opacity: 1});

        let surface_mesh_hl = new THREE.Mesh( surface_geometry_hl, surface_material_hl );
        surface_mesh_hl.showName = "回料盒"
        surface_mesh_hl.lines = [lineOne, lineTwo]
        surface_mesh_hl.group = 1
        meshs.push(surface_mesh_hl)
            //scene.add(surface_mesh_hl);
        return meshs
    },
    // 获取出口图形
    getChukouCurveMeshs() {

        let qs = this.quanshu
        let R = this.diameter / 2
        let lj = this.luoju
        let DIR = parseInt(this.direction)
        let TH = this.bianjing_theta /180*Math.PI
        let w =  this.bianjing_width
        let n = this.n
        let i = 0
        var j = 0
		let t = DIR* this.guidao_delta;
        var theta1 = this.chukou_theta1;
        var T1_chukou = new Array();
        var X2_chukou = new Array();
        var Y2_chukou = new Array();
        var p_x = this.chukou_p_x;
        var p_y = this.chukou_p_y;
        var p_z = this.chukou_p_z;
        var P_X_chukou = new Array();
        var P_Y_chukou = new Array();
        var section_L = this.guidao_section_length;
        var section_W = this.guidao_section_width;
        var RTT = this.guidao_rotation/180*Math.PI
        var point_A_X_chukou = 0
        var point_A_Y_chukou = 0
        var point_B_X_chukou = -section_L*Math.cos(RTT)
        var point_B_Y_chukou = section_L*Math.sin(RTT)
        var point_C_X_chukou = -section_L*Math.cos(RTT)+section_W*Math.cos(Math.PI/2-RTT)
        var point_C_Y_chukou = section_L*Math.sin(RTT)+section_W*Math.sin(Math.PI/2-RTT)
        var point_D_X_chukou = section_W*Math.cos(Math.PI/2-RTT)
        var point_D_Y_chukou = section_W*Math.sin(Math.PI/2-RTT)
        var N_C = this.guidao_n_curve;
        var geometry_line = new Array();
        P_X_chukou[0] = point_A_X_chukou
        P_Y_chukou[0] = point_A_Y_chukou*DIR
        P_X_chukou[1] = point_B_X_chukou
        P_Y_chukou[1] = point_B_Y_chukou*DIR
        P_X_chukou[2] = point_C_X_chukou
        P_Y_chukou[2] = point_C_Y_chukou*DIR
        P_X_chukou[3] = point_D_X_chukou
        P_Y_chukou[3] = point_D_Y_chukou*DIR
        P_X_chukou[4] = point_A_X_chukou
        P_Y_chukou[4] = point_A_Y_chukou*DIR

        var line_chukou
        var points0_chukou0 = new Array();
        var points0_chukou = new Array();
        var vector_chukou = new Array()
        var points_chukou = new Array();
        var curveObject_chukou = new Array();
        var curve_chukou = new Array();
        var surfaceObjectA_chukou = new Array();
        var points1X_chukou = new Array();
        var points1Y_chukou = new Array();
        var points1Z_chukou = new Array();
        var points2X_chukou = new Array();
        var points2Y_chukou = new Array();
        var points2Z_chukou = new Array();

        var points1X_chukou0 = new Array();
        var points1Y_chukou0 = new Array();
        var points1Z_chukou0 = new Array();
        var points2X_chukou0 = new Array();
        var points2Y_chukou0 = new Array();
        var points2Z_chukou0 = new Array();

        var X1_chukou = 0;
        var Y1_chukou = 0;
        var L_chukou = 10;
        for (i=0;i<N_C;i++) {
            T1_chukou[i] = -theta1[i]/180*Math.PI;
        }
        var T2_chukou = -this.chukou_theta2/180*Math.PI;
        var X4_chukou = this.chukou_distance-L_chukou*Math.cos(T2_chukou);
        var Y4_chukou = -(this.chukou_height+L_chukou*Math.sin(T2_chukou));
        var X_chukou = this.chukou_distance;
        var Y_chukou = - this.chukou_height;

        for (i=0;i<N_C;i++) {
            X2_chukou[i] = X4_chukou*Math.cos(T1_chukou[i])*0.5;
            Y2_chukou[i] = -X4_chukou*Math.sin(T1_chukou[i])*0.5;
        }

        var X3_chukou = X4_chukou-X4_chukou*Math.cos(T2_chukou)*0.5;
        var Y3_chukou = Y4_chukou-X4_chukou*Math.sin(T2_chukou)*0.5;

        var meshs = []
        //var k = N_C - 1
        class CustomSinCurve_chukou extends THREE.Curve {
                constructor( X1_chukou, X2_chukou, X3_chukou, X4_chukou, Y1_chukou, Y2_chukou, Y3_chukou, Y4_chukou) {
                super();
                this.X1_chukou = X1_chukou;
                this.X2_chukou = X2_chukou;
                this.X3_chukou = X3_chukou;
                this.X4_chukou = X4_chukou;
                this.Y1_chukou = Y1_chukou;
                this.Y2_chukou = Y2_chukou;
                this.Y3_chukou = Y3_chukou;
                this.Y4_chukou = Y4_chukou;
                this.p_x = p_x;
                this.p_y = p_y;
                this.p_z = p_z;
                }
                getPoint( t, optionalTarget = new THREE.Vector3() ) {
                    const tx = X1_chukou*(1-t)**3+3*X2_chukou[k]*t*(1-t)**2+3*X3_chukou*t**2*(1-t)+X4_chukou*t**3;
                    const tz = Y1_chukou*(1-t)**3+3*Y2_chukou[k]*t*(1-t)**2+3*Y3_chukou*t**2*(1-t)+Y4_chukou*t**3;
                    const ty= 0;
                    return optionalTarget.set( tx, ty, tz )
                }
            }


        for(var k=0;k<N_C;k++) {
            curve_chukou[k] = new CustomSinCurve_chukou( X1_chukou, X2_chukou[i], X3_chukou, X4_chukou, Y1_chukou, Y2_chukou[i], Y3_chukou, Y4_chukou);        
            points0_chukou0[k] = curve_chukou[k].getSpacedPoints( n );
            points0_chukou0[k].push (new THREE.Vector3(X_chukou, 0, Y_chukou));
            points0_chukou[k] = curve_chukou[k].getSpacedPoints( n );
            points0_chukou[k].push (new THREE.Vector3(X_chukou, 0, Y_chukou));
            
            for(i=0;i<n+2;i++){
                points0_chukou[k][i].x = points0_chukou0[k][i].x*Math.cos(((2*Math.PI*qs+TH)+(t*DIR)/180*Math.PI+0.5*Math.PI)*DIR)-points0_chukou0[k][i].y*Math.sin(((2*Math.PI*qs+TH)+(t*DIR)/180*Math.PI+0.5*Math.PI)*DIR)+p_x[k]
                points0_chukou[k][i].y = points0_chukou0[k][i].x*Math.sin(((2*Math.PI*qs+TH)+(t*DIR)/180*Math.PI+0.5*Math.PI)*DIR)+points0_chukou0[k][i].y*Math.cos(((2*Math.PI*qs+TH)+(t*DIR)/180*Math.PI+0.5*Math.PI)*DIR)+p_y[k]
                points0_chukou[k][i].z = points0_chukou0[k][i].z+p_z[k]
            }
            
            geometry_line[k] = new THREE.BufferGeometry().setFromPoints( points0_chukou[k] );
            const material_line = new THREE.LineBasicMaterial( { color: 0x000000 } );
            curveObject_chukou[k] = new THREE.Line( geometry_line[k], material_line );
            curveObject_chukou[k].isEdage = true
            meshs.push(curveObject_chukou[k])
        // scene.add(curveObject_chukou[k]);
                

            surfaceObjectA_chukou[k] = new Array();
            vector_chukou[k] = new Array();
            for(i=0;i<n+1;i++){
                vector_chukou[k][i] = curve_chukou[k].getTangentAt(i/n);
            }
            vector_chukou[k].push (new THREE.Vector3(vector_chukou[k][n].x, vector_chukou[k][n].y, vector_chukou[k][n].z));
            for(j=0;j<4;j++) {
                var lineOne = []
                var lineTwo = []
                for(i=0;i<n+2;i++){
                    points1X_chukou0[i]=DIR*P_Y_chukou[j]*Math.sin(Math.acos(vector_chukou[k][i].x))+points0_chukou0[k][i].x;
                    points1Y_chukou0[i]=-DIR*P_X_chukou[j]+points0_chukou0[k][i].y;
                    points1Z_chukou0[i]=DIR*P_Y_chukou[j]*Math.cos(Math.acos(vector_chukou[k][i].x))+points0_chukou0[k][i].z;

                    points2X_chukou0[i]=DIR*P_Y_chukou[j+1]*Math.sin(Math.acos(vector_chukou[k][i].x))+points0_chukou0[k][i].x;
                    points2Y_chukou0[i]=-DIR*P_X_chukou[j+1]+points0_chukou0[k][i].y;
                    points2Z_chukou0[i]=DIR*P_Y_chukou[j+1]*Math.cos(Math.acos(vector_chukou[k][i].x))+points0_chukou0[k][i].z;
                    
                }


                for(i=0;i<n+2;i++){
                    points1X_chukou[i] = points1X_chukou0[i]*Math.cos(((2*Math.PI*qs+TH)+(t*DIR)/180*Math.PI+0.5*Math.PI)*DIR)-points1Y_chukou0[i]*Math.sin(((2*Math.PI*qs+TH)+(t*DIR)/180*Math.PI+0.5*Math.PI)*DIR)+points0_chukou[k][0].x
                    points1Y_chukou[i] = points1X_chukou0[i]*Math.sin(((2*Math.PI*qs+TH)+(t*DIR)/180*Math.PI+0.5*Math.PI)*DIR)+points1Y_chukou0[i]*Math.cos(((2*Math.PI*qs+TH)+(t*DIR)/180*Math.PI+0.5*Math.PI)*DIR)+points0_chukou[k][0].y
                    points1Z_chukou[i] = points1Z_chukou0[i]+points0_chukou[k][0].z

                    lineOne.push([points1X_chukou[i], points1Y_chukou[i], points1Z_chukou[i]])

                    points2X_chukou[i] = points2X_chukou0[i]*Math.cos(((2*Math.PI*qs+TH)+(t*DIR)/180*Math.PI+0.5*Math.PI)*DIR)-points2Y_chukou0[i]*Math.sin(((2*Math.PI*qs+TH)+(t*DIR)/180*Math.PI+0.5*Math.PI)*DIR)+points0_chukou[k][0].x
                    points2Y_chukou[i] = points2X_chukou0[i]*Math.sin(((2*Math.PI*qs+TH)+(t*DIR)/180*Math.PI+0.5*Math.PI)*DIR)+points2Y_chukou0[i]*Math.cos(((2*Math.PI*qs+TH)+(t*DIR)/180*Math.PI+0.5*Math.PI)*DIR)+points0_chukou[k][0].y
                    points2Z_chukou[i] = points2Z_chukou0[i]+points0_chukou[k][0].z

                    lineTwo.push([points2X_chukou[i], points2Y_chukou[i], points2Z_chukou[i]])
                }
                let surface_geometry_chukou = new THREE.BufferGeometry();
                let vertices_chukou = new Float32Array(18*(n+1));
                for (i=0; i<n+1; i++) {
                    vertices_chukou[i*18]=points1X_chukou[i];
                    vertices_chukou[i*18+1]=points1Y_chukou[i];
                    vertices_chukou[i*18+2]=points1Z_chukou[i];

                    vertices_chukou[i*18+3]=points1X_chukou[i+1];
                    vertices_chukou[i*18+4]=points1Y_chukou[i+1];
                    vertices_chukou[i*18+5]=points1Z_chukou[i+1];

                    vertices_chukou[i*18+6]=points2X_chukou[i+1];
                    vertices_chukou[i*18+7]=points2Y_chukou[i+1];
                    vertices_chukou[i*18+8]=points2Z_chukou[i+1];

                    vertices_chukou[i*18+9]=points2X_chukou[i+1];
                    vertices_chukou[i*18+10]=points2Y_chukou[i+1];
                    vertices_chukou[i*18+11]=points2Z_chukou[i+1];

                    vertices_chukou[i*18+12]=points2X_chukou[i];
                    vertices_chukou[i*18+13]=points2Y_chukou[i];
                    vertices_chukou[i*18+14]=points2Z_chukou[i];

                    vertices_chukou[i*18+15]=points1X_chukou[i];
                    vertices_chukou[i*18+16]=points1Y_chukou[i];
                    vertices_chukou[i*18+17]=points1Z_chukou[i];
                }
                    
                surface_geometry_chukou.setAttribute( 'position', new THREE.BufferAttribute( vertices_chukou, 3 ) );
                surface_geometry_chukou.computeVertexNormals();
                let surface_material_chukou = new THREE.MeshPhongMaterial({color: 0x555555, side:THREE.DoubleSide, /* ambient:0x224466, */ emissive:0x000000,specular:0xffffff, shininess: 3,wireframe: false,transparent: true, opacity: 1});
                surfaceObjectA_chukou[k][j] = new THREE.Mesh( surface_geometry_chukou, surface_material_chukou );
                surfaceObjectA_chukou[k][j].showName = "出"+(k+1)+"-"+(j+1)
                surfaceObjectA_chukou[k][j].lines = [lineOne, lineTwo]
                surfaceObjectA_chukou[k][j].group = (3+this.guidao_n_curve * 4) + k
                meshs.push(surfaceObjectA_chukou[k][j])
                //scene.add(surfaceObjectA_chukou[k][j]);
            }
         }
      return meshs
    },
    //下一步
    next() {
        this.$set(this.steps, this.active, false)
        this.active = (this.active + 1 ) % this.steps.length;
        this.$set(this.steps, this.active, true)
        console.log("next", this.active)

    },
    //上一步
    prev() {
        this.$set(this.steps, this.active, false)
        this.active = (this.active - 1 ) % this.steps.length;
        this.$set(this.steps, this.active, true)
        this.resetVisile()
        this.updateGraphics()
    },
    resetVisile() {
        for (let i in this.meshs) {
            this.meshs[i].material.opacity  = 1
            this.meshs[i].material.depthWrite = true
        }
        this.extendShowd = false
        this.extendIdx = -1
    },
    
    //加载所有的预览图
    showPreimgs() {

        for (var i in this.extendMeshs) {
            var item = this.extendMeshs[i]
            this.extendCurve(item)
        }

    },
    //传入图像进行展开：曲面的两条边 预览图、下载链接
    extendCurve(item) {
        //懒性加载预览图
        let url = this.$url + '/v2/surface/preview'
        var vue = this
        if (!item.url && item.lines) {
            console.log("load preview img")
            var param = {
            "line1": item.lines[0],
            "line2": item.lines[1],
          };
          let data = {
             "params":param
          }
          let c = function(item, url, data) {
                vue.$axios.post(url,data).then(res=>{
                if (res && res.data.code == 'ok') {
                    let baseImgData = "data:image/png;base64,"+res.data.data.image;
                    //周长
                    let length = res.data.data.arc_length ? res.data.data.arc_length /1000 : 1
                    //面积
                    let area  = res.data.data.contour_area ? res.data.data.contour_area : 1
                    //重量
                    let weight = (((area *  vue.thickness ) / 1000) * vue.density) / 1000
                    vue.$set(item, url, baseImgData)
                    item.url = baseImgData
                    item.meterialMeta = {length:length, area:area, weight:weight}
                    //vue.priviewImgUrls.push({url:baseImgData, idx: i, sfno: sfno,length:length,area:area,weight:weight})
                }
                
            });

          }
          c(item,url, data)
          
        }

    },
    //展开按钮
    extendClick() {
      this.extendShowd = true
      this.next()
      this.removeEdage()
      this.extendMultiCurve()
      
    },
    //展开时候移除边框，因为太混乱了
    removeEdage() {
        for (let i in this.meshs) {
            let mesh = this.meshs[i]
            if ( mesh.isEdage ) {
                console.log("remove ", mesh)
                this.scene.remove(mesh)
                //this.meshs.splice(i,1)
            }
        }
    },
    getPaibanParams() {
      let params = []
      for (let i in  this.extendMeshs) {
          let itemMesh = this.extendMeshs[i]
          if (!itemMesh.group) {
            continue
          }
          params.push({
            'line1': itemMesh.lines[0],
            'line2': itemMesh.lines[1],
            'name': itemMesh.showName,
            'group': itemMesh.group
          });
      }
      return {params: params}
    },
    //排版，同时展开多个图形
    extendMultiCurve() {
        let vue = this
        vue.extendPreviewCnt ++
        let c = vue.extendPreviewCnt
        let params = vue.getPaibanParams()
        vue.previewImgData = ''
        vue.$axios.post(vue.$url + "/v2/surface/preview/all", params)
            .then((res) => {
              //console.log(res.data)
              console.log("callback", c, vue.extendPreviewCnt)
              if (c == vue.extendPreviewCnt) {
                vue.previewImgData = "data:image/png;base64," + res.data.data.image
              }
              
            }).catch(function (error) {
                console.log("catch error", error)
                if (vue.extendPreviewCnt < 5) {
                    vue.extendMultiCurve()
                } else {
                  Toast.fail("获取展开图形异常")
                  vue.extendPreviewCnt = 0
                }
            })
    },
    //展开选择
    clincExtendItem(items, idx) {
        //this.extendIdx = idx
        //console.log(this.extendIdxs, idx)
        if (items == undefined) {
            // 全部
            this.resetVisile()
        } else {
            //某一个
            for (let i in this.meshs ) {
              this.meshs[i].material.transparent = true
              this.meshs[i].material.depthWrite = false
              this.meshs[i].material.opacity  = 0.15
               
            }
            for (let j in items) {
              let item = items[j]
              item.material.opacity  = 1
              item.material.depthWrite = true
            }
            
                  // this.extendCurve(item)
        }
    },
    // 展示获取框
    openAndGet() {
       let vue = this
       if (!Commons.isLogin()) {
          //没有登录
          vue.$dialog.confirm({title:'该操作需要先登陆', message: "是否前往登录?"})
              .then(()=> {
                Commons.goLogin(vue)
              })

          return ;
      }
       vue.showSendBox = true
    },
    //通过邮件发送
    async sendDxfByMail() {
      let vue = this
      if (vue.app_name == "") {
        console.log("project name is empty ,not allowed download")
        vue.downloadErrMsg = "请输入项目名称"
        return ;
      }
      const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
      if (vue.email == "" || !regEmail.test(vue.email)) {
        vue.emailErrmsg = "请输入正确的邮箱地址"
        return ;
      }
      localStorage.setItem("email", vue.email);
      vue.emailErrmsg = ""

      /**
      // 先保存再发送
      if (this.id == 'new') {
          let p = {
              name: vue.app_name,
              misc: '',
              type: 'benti'
          }
          let resp = await Commons.addSurface(this, p)
          if (resp.code == 'ok') {
              this.id = resp.data.id
          }
          //保存
        let params = vue.getInputParams()
        await Commons.updateSurface(vue, this.id, {"params":[params]})
      }
       **/ 
      let params = vue.getPaibanParams()
      params['name'] = vue.app_name + ".dxf"
      params['email'] = vue.email
      params['id'] = vue.id
      vue.mailSending = true
      vue.$axios.post(vue.$url + '/v2/surface/dxf/all', params).then((rep) => {
          if (rep.data.code == 'ok') {
             Toast.success("发送成功")
             vue.showSendBox = false
             vue.mailSending = false
          }
      }).catch((error) => {
           Toast.success("发送异常")
           vue.mailSending = false
      })

    },
    // 下载dxf文件
    downloadSelectdxf() {
        
        var vue = this
        var extendMesh = vue.extendMeshs[vue.extendIdx]
        var sufaceLines = extendMesh.lines
        var url = vue.$url + '/v2/surface/dxf'
        var param = {
            "line1": sufaceLines[0],
            "line2": sufaceLines[1],

        };
        let data = {
            "name": extendMesh.showName + ".dxf",
            "params":param
        }
        Commons.downloadFile(vue, url, data)
    },
    /**
     * 下单的操作
     **/
    //展示下单窗口
    showSubmit() {
        //TODO 读取缓存的手机号码，联系方式
        this.show_order_confirm = true
        this.refreshCaptpha()
        this.$refs.orderSubmitForm.resetValidation()
        //展示 
    },
    //提交订单
    onSubmit() {
        let vue = this
        let url = vue.$url + '/v2/order'
        let params = {
            benti: [
                {
                    key: 'number', value: vue.order_benti_cnt, desc: "本体数量"
                },
                {
                    key: 'diameter', value: vue.diameter, desc: "本体直径"
                } ,
                {
                    key: 'width0', value: vue.width0, desc: "本体道宽"
                } ,
                {
                    key: 'luoju', value: vue.luoju, desc: "螺距"
                } ,
                {
                    key: 'quanshu', value: vue.quanshu, desc: "圈数"
                } ,
                {
                    key: 'width0_1st', value: vue.width0_1st, desc: "道宽加宽"
                } ,
                {
                    key: 'quanshu_1st', value: vue.quanshu_1st, desc: "加宽圈数"
                } ,
                {
                    key: 'direction', value: vue.direction, desc: "方向 (-1顺时针，1逆时针)"
                } ,
                {
                    key: 'dangbian_xishu', value: vue.dangbian_xishu, desc: "挡边系数"
                } 
                
            ]
        }
        // 有变径
        if (vue.bianjing_show && vue.order_bianjing_cnt > 0) {
            params['bianjing'] = [
                { key: 'bianjing_decline_jingxiang', value: vue.bianjing_decline_jingxiang, desc:'径向落差'},
                { key: 'bianjing_theta', value: vue.bianjing_theta, desc:'对应角度'},
                { key: 'bianjing_type', value: vue.bianjing_type, desc:'变径类型，0 圆弧，1平滑'},
                { key: 'bianjing_width', value: vue.bianjing_width, desc:'变径尺寸'}
            ]
        }
        //轨道
        if (vue.guidao_show && vue.order_guidao_cnt > 0) {
            params['guidao'] = [
                { key: 'guidao_offset_radius', value: vue.guidao_offset_radius, desc:'半径'},
                { key: 'guidao_delta', value: vue.guidao_delta, desc:'轨道圆心角'},
                { key: 'guidao_offset1', value: vue.guidao_offset1, desc:'起点直段'},
                { key: 'guidao_offset2', value: vue.guidao_offset2, desc:'终点直段'},
                { key: 'guidao_delta', value: vue.guidao_delta, desc:'轨道圆心角'},
                { key: 'guidao_high', value: vue.guidao_high, desc:'落差'},
                { key: 'guidao_n_curve', value: vue.guidao_n_curve, desc:'轨道数量'},
                { key: 'guidao_offset_N_C0', value: vue.guidao_offset_N_C0, desc:'多道间隔'},
                { key: 'guidao_offset_N_C', value: vue.guidao_offset_N_C, desc:'多道间隔调整'},
                { key: 'guidao_section_length', value: vue.guidao_section_length, desc:'截面长度'},
                { key: 'guidao_section_width', value: vue.guidao_section_width, desc:'截面宽度'},
                { key: 'guidao_Rotation_X', value: vue.guidao_Rotation_X, desc:'扭转中心X'},
                { key: 'guidao_Rotation_Y', value: vue.guidao_Rotation_Y, desc:'扭转中心Y'},
                { key: 'guidao_rotation', value: vue.guidao_rotation, desc:'出口角度'}
            ]
        }
        //回料盒
        if (vue.huiliao_show && vue.order_huiliao_cnt > 0) {
            params['huiliao'] = [
                { key: 'huiliao_dian', value: vue.huiliao_dian, desc:'回料点'},
                { key: 'huiliao_luocha', value: vue.huiliao_luocha, desc:'回料落差'},
                { key: 'huiliao_heyuanxinjiao', value: vue.huiliao_heyuanxinjiao, desc:'回料盒圆心角'},
                { key: 'huiliao_koudaxiao', value: vue.huiliao_koudaxiao, desc:'回料口大小'},
                { key: 'huiliao_hekuandu', value: vue.huiliao_hekuandu, desc:'回料盒宽度'},
                { key: 'huiliao_hexiedu', value: vue.huiliao_hexiedu, desc:'回料盒斜度'},
                { key: 'huiliao_hegaodu', value: vue.huiliao_hegaodu, desc:'回料盒高度'},
                { key: 'huiliao_heqiejiao', value: vue.huiliao_heqiejiao, desc:'回料盒切角'}
            ]
        }
        //回料盒
        if (vue.chukou_show && vue.order_chukou_cnt > 0) {
            params['chukou'] = [
                { key: 'chukou_distance', value: vue.chukou_distance, desc:'出口水平延'},
                { key: 'chukou_height', value: vue.chukou_height, desc:'出口垂直落差'},
                { key: 'chukou_theta2', value: vue.chukou_theta2, desc:'出口角度'}
            ]
        }
        let param_data = {
            "verify_code" : vue.order_sms_captcha,
            "name":vue.order_name,
            "address":"",
            "mobile": vue.order_phone,
            "params":params
        }
        vue.$refs.orderSubmitForm.loading = true
        vue.$axios.post(url, param_data).then(response => {
            if(response.data.code == 'ok') {
                Toast.success('信息提交成功');
                vue.show_order_confirm = false
            } else {
                Toast.fail(response.data.message);
            }
            vue.$refs.orderSubmitForm.loading = false
            
        })
        .catch(function (error) { // 请求失败处理
            vue.$dialog.alert({
            message: error.response.data.message,
            })
            vue.$refs.orderSubmitForm.loading = false
           // vue.show_order_confirm = false
        })
    },
    refreshCaptpha() {
        this.order_captcha_url = this.get_new_order_captcha_url()
    },
    get_new_order_captcha_url() {
        return '/openapi/v1/captcha?v=' +  Math.random()
    },
    phoneValidator(val) {
        return /^1(3[0-9]|4|5[0-35-9]|6|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/.test(val)
    },
    // 获取参数信息，判断是否仅预览
    async getParams(callback) {
        let vue = this
        let id = vue.$route.params.id
        this.id = id
        let force_extend = vue.$route.query.force_extend
        let paramStr = vue.$route.query.params
        let params = paramStr ? Base64.decode(paramStr) : '{}'
        params = JSON.parse(params)
        if ('true' ==  force_extend ) {
            vue.show_extend = true
        }
       /** **/
        if (id == "preview" && params) {
            vue.preview_only = true
            vue.show_extend = true
            vue.navTitle = '套件-预览'
        } else if('new' != id){
            // 从服务端拉取配置
            let resp = await Commons.getSurface(vue, id)
            if (resp.params) {
              params = resp.params[0]
              vue.navTitle = '套件-【'+resp.name + "】"
              vue.app_name = resp.name
            }
        }
        if ('benti' in params) {
          vue.bianjing_show = "bianjing" in params
          vue.guidao_show = "guidao" in params
          vue.huiliao_show = "huiliao" in params
          vue.chukou_show = "chukou" in params
          for (var k in  params) {
              for (var i in params[k]) {
                  vue.$set(this, params[k][i]['key'], params[k][i]['value'])
              }
          }
        }
        callback()
        
    },
    //打开发送短信验证码的图像验证码
    getSmsCaptcha() {
        if (this.order_phone && this.order_captcha) {
            this.getSmsVeriyCode(this)
        }
        return false
    },
    //调用接口获取短信验证码
    getSmsVeriyCode(vue) {
        let url = vue.$url + "/v1/sms"
        let param_data = {
            "verify_code" : vue.order_captcha,
            "mobile": vue.order_phone
        }
        vue.$axios.post(url, param_data).then(response => {
            if(response.data.code == 'ok') {
                Toast.success('发送成功，请留意短信');
                vue.startCountdown(vue, vue.sms_countdown_seconds)
            } else {
                Toast.fail(response.data.message);
            } 
        })
        
    },
    // times 是剩余的描述
    startCountdown(vue, times) {
        console.log("sms countdown" + times)
        vue.sms_timer_id = setInterval(() => {
            console.log("sms counting" + times)
            if (times > 0) {
                times --
                vue.sms_disabled = true
                vue.sms_title = times + "秒重新获取"
                console.log(times)
            } else {
                clearInterval(vue.sms_timer_id)
                vue.sms_disabled = false
                vue.sms_title = "获取验证码"
                console.log("sms count done")
            }
        }, 1000);
        
    },
    saveSurface() {
        var vue = this
        if (!Commons.isLogin()) {
          //没有登录
          this.$dialog.confirm({title:'该操作需要先登陆', message: "是否前往登录?"})
              .then(()=> {
                Commons.goLogin(vue)
              })

          return ;
        }

        if (this.id == 'new') {
            this.surface_new_show = true
        } else {
          //保存
          this.saveSurfaceParam(this.id)
        }
    },
    async add_surface_done(n_id, name) {
      this.id = n_id      
      this.app_name = name
      this.saveSurfaceParam(n_id)
      this.surface_new_show = false

    },
    close_surface_form() {
      this.surface_new_show = false
    },
    async getSurfaceParam(id) {
        if (id == 'new') {
          return 
        }
        let vue = this
        let resp = await Commons.getSurface(this, id)
        let params = Commons.getAppParams(this, 'benti', id, resp)
        if (params ) {
            vue.navTitle = "套件-【"+resp.name + "】"
            vue.bianjing_show = "bianjing" in params
            vue.guidao_show = "guidao" in params
            vue.huiliao_show = "huiliao" in params
            vue.chukou_show = "chukou" in params
            for (var k in  params) {
                for (var i in params[k]) {
                    vue.$set(this, params[k][i]['key'], params[k][i]['value'])
                }
            }
        }
        
    },
    getInputParams() {
      let vue = this
      //调用保存
      let params = {
          benti: [
              {
                  key: 'number', value: vue.order_benti_cnt, desc: "本体数量"
              },
              {
                  key: 'diameter', value: vue.diameter, desc: "本体直径"
              } ,
              {
                  key: 'width0', value: vue.width0, desc: "本体道宽"
              } ,
              {
                  key: 'luoju', value: vue.luoju, desc: "螺距"
              } ,
              {
                  key: 'quanshu', value: vue.quanshu, desc: "圈数"
              } ,
              {
                  key: 'width0_1st', value: vue.width0_1st, desc: "道宽加宽"
              } ,
              {
                  key: 'quanshu_1st', value: vue.quanshu_1st, desc: "加宽圈数"
              } ,
              {
                  key: 'direction', value: vue.direction, desc: "方向 (-1顺时针，1逆时针)"
              } ,
              {
                  key: 'dangbian_xishu', value: vue.dangbian_xishu, desc: "挡边系数"
              } 
          ]
        }
        // 有变径
        if (vue.bianjing_show ) {
            params['bianjing'] = [
                { key: 'bianjing_decline_jingxiang', value: vue.bianjing_decline_jingxiang, desc:'径向落差'},
                { key: 'bianjing_theta', value: vue.bianjing_theta, desc:'对应角度'},
                { key: 'bianjing_type', value: vue.bianjing_type, desc:'变径类型，0 圆弧，1平滑'},
                { key: 'bianjing_width', value: vue.bianjing_width, desc:'变径尺寸'}
            ]
        }
        //轨道
        if (vue.guidao_show ) {
            params['guidao'] = [
                { key: 'guidao_offset_radius', value: vue.guidao_offset_radius, desc:'半径'},
                { key: 'guidao_delta', value: vue.guidao_delta, desc:'轨道圆心角'},
                { key: 'guidao_offset1', value: vue.guidao_offset1, desc:'起点直段'},
                { key: 'guidao_offset2', value: vue.guidao_offset2, desc:'终点直段'},
                { key: 'guidao_delta', value: vue.guidao_delta, desc:'轨道圆心角'},
                { key: 'guidao_high', value: vue.guidao_high, desc:'落差'},
                { key: 'guidao_n_curve', value: vue.guidao_n_curve, desc:'轨道数量'},
                { key: 'guidao_offset_N_C0', value: vue.guidao_offset_N_C0, desc:'多道间隔'},
                { key: 'guidao_offset_N_C', value: vue.guidao_offset_N_C, desc:'多道间隔调整'},
                { key: 'guidao_section_length', value: vue.guidao_section_length, desc:'截面长度'},
                { key: 'guidao_section_width', value: vue.guidao_section_width, desc:'截面宽度'},
                { key: 'guidao_Rotation_X', value: vue.guidao_Rotation_X, desc:'扭转中心X'},
                { key: 'guidao_Rotation_Y', value: vue.guidao_Rotation_Y, desc:'扭转中心Y'},
                { key: 'guidao_rotation', value: vue.guidao_rotation, desc:'出口角度'}
            ]
        }
        //回料盒
        if (vue.huiliao_show ) {
            params['huiliao'] = [
                { key: 'huiliao_dian', value: vue.huiliao_dian, desc:'回料点'},
                { key: 'huiliao_luocha', value: vue.huiliao_luocha, desc:'回料落差'},
                { key: 'huiliao_heyuanxinjiao', value: vue.huiliao_heyuanxinjiao, desc:'回料盒圆心角'},
                { key: 'huiliao_koudaxiao', value: vue.huiliao_koudaxiao, desc:'回料口大小'},
                { key: 'huiliao_hekuandu', value: vue.huiliao_hekuandu, desc:'回料盒宽度'},
                { key: 'huiliao_hexiedu', value: vue.huiliao_hexiedu, desc:'回料盒斜度'},
                { key: 'huiliao_hegaodu', value: vue.huiliao_hegaodu, desc:'回料盒高度'},
                { key: 'huiliao_heqiejiao', value: vue.huiliao_heqiejiao, desc:'回料盒切角'}
            ]
        }
        //回料盒
        if (vue.chukou_show ) {
            params['chukou'] = [
                { key: 'chukou_distance', value: vue.chukou_distance, desc:'出口水平延'},
                { key: 'chukou_height', value: vue.chukou_height, desc:'出口垂直落差'},
                { key: 'chukou_theta2', value: vue.chukou_theta2, desc:'出口角度'}
            ]
        }
        return params
    },
    async saveSurfaceParam(id) {
        let vue = this
        let params = vue.getInputParams()
        let resp = await Commons.updateSurface(this, id, {"params":[params]})
        if (resp.code =='ok') {
            // vue.navTitle = '套件-'+vue.surface_name
            Toast.success("保存成功")
        }
    },
    onClickLeft() {
      this.$router.back()
    }
  },
  mounted() {
    this.initThree()
    this.getParams(this.updateGraphics)
    this.email = localStorage.getItem('email')
    
  },
  computed: {
     //加宽圈数上限
     quanshu_1st_max() {
        return this.quanshu - 0.25
     },
      /**
       * 是否展示重量,预览图全部加载完成再展示
       */
       canShowWeight() {
          return this.extendMeshs.length > 0
      },
      cutLength() {
          let total = 0
          if (this.extendIdx > -1 && this.extendMeshs[this.extendIdx].meterialMeta ) {
             return this.extendMeshs[this.extendIdx].meterialMeta.length
          } else {
            for (let i in this.extendMeshs) {
                if (this.extendMeshs[i].meterialMeta) {
                    total += this.extendMeshs[i].meterialMeta.length
                }
            }
          }

          return total
      },
      cutWeight() {
          let total = 0
          if (this.extendIdx > -1 && this.extendMeshs[this.extendIdx].meterialMeta) {
            return this.extendMeshs[this.extendIdx].meterialMeta.weight
          } else {
            for (let i in this.extendMeshs) {
                if (this.extendMeshs[i].meterialMeta) {
                    total += this.extendMeshs[i].meterialMeta.weight
                }
                
            }
          }
          return total
      },
      dangbian_gaodu() {
        return this.dangbian_xishu * 12
      },
      //桶高
      bucket_height() {
        return parseInt(this.luoju * (this.quanshu + this.dangbian_xishu))
      },
      
      showVBnt() {
        return !this.steps[1]
      },
      
      
  }
}
</script>
<style scoped>

        #preview,#sufaceview {
          overflow: hidden;
          width: 100%;
        }
        .custom-button {
          width: 26px;
          color: #fff;
          font-size: 10px;
          line-height: 18px;
          text-align: center;
          background-color: #3ea5b9;
          border-radius: 100px;
        }
        #footer {
          min-height: 320px;
          text-align:center;
        }
        .van-row {
          padding-top: 20px;
        }
       .van-image {
         display: flex;
         align-items: center;
       }
       .van-slider {
         margin-top: 13px;
       }
       .contactUsBnt {
         text-align: center;
       }
       
        .contactUsBox /deep/.van-dialog__content {
          display: flex;
          justify-content: center;
       }
       #sectionPopBox /deep/.van-overlay {
          background: none
       }
       #sectionPopBox /deep/.van-dialog {
         top: 420px;
         background: #5bcdd3;
       }
       #sectionPopBox /deep/.van-button {
         background: #5bcdd3;
       }
       .selectBox {
            position: absolute;
            top: 70px;
            right: 5px;
            color: #1e0ea9;
            background: #aecbe9;
            border-radius: 5px;
            box-shadow: 10px 10px 5px #888888;
            width: 75px;
            text-align: left;
            max-height: 260px;
            overflow: scroll;
       }
       .resetCameraBnt {
            position: absolute;
            top: 240px;
            right: 20px;
       }
       .showtrack-label {
         height: 30px;
       }
       .showtrack-label /deep/ .van-checkbox__label {
         color: rgb(22, 143, 199);
         height: 20px;
       }
       .van-image {
        display: flex;
        align-items: center;
      }
      #expandArea {
        height: 180px;
        overflow: hidden;
      }
      .captcha_input {
        border: none;
      }
    
    </style>

    <style>
    .van-tabs__content {
        height: 50vh;
        overflow: scroll;
        padding-bottom: 35px;
      }
    .get-me {
        position: fixed;
        bottom: 10px;
        width: 100%;
    }
    </style>