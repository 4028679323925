import Vue from 'vue'
import App from './App.vue'
import router from './routers';
import md5 from 'js-md5';
import axios from 'axios';
import Commons from '@/libs/Commons'


// import * as THREE from './libs/js'

import { 
  Form,
  Field,
  Button,
  Tab, 
  Tabs,
  Dialog,
  List,
  Tabbar,
  TabbarItem,
  NavBar,
  Cell,
  SwipeCell,
  Card,
  Popover,
  Popup,
  Overlay,
  Icon, 
} from 'vant';
import Vuex from 'vuex'
Vue.use(Icon)
Vue.use(Vuex)
Vue.use(Form);
Vue.use(Field);
Vue.use(Button);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Dialog);
Vue.use(List);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(NavBar);
Vue.use(Cell);
Vue.use(SwipeCell);
Vue.use(Card);
Vue.use(Popover);
Vue.use(Popup);
Vue.use(Overlay);
Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    count: 0,
    myappId: -1
  },
  mutations: {
    increment (state) {
      state.count++
    },
    sDeviceAppId(state, id) {
      state.myappId = id
    }
  },
  getters: {
    // ...
    appId: state => {
      return state.myappId
    }
  }
})
// 全局设置 axios 发送请求带上cookie
axios.defaults.withCredentials = true

Vue.config.productionTip = false
Vue.prototype.$md5 = md5;
Vue.prototype.$crypto = require('crypto');
Vue.prototype.$axios = axios;
// Vue.prototype.$THREE = require(THREE);
/**
 * 
 * @param {*} category  要监控的目标的类型名称，通常是同一组目标的名字，比如"视频"、"音乐"、"软件"、"游戏"等等。该项必填，不填、填"-"的事件会被抛弃。
 * @param {*} action  用户跟目标交互的行为，如"播放"、"暂停"、"下载"等等。该项必填，不填、填"-"的事件会被抛弃。
 * @param {*} opt_label 事件的一些额外信息，通常可以是歌曲的名称、软件的名称、链接的名称等等。该项选填，不填、填"-"代表此项为空。
 * @param {*} opt_value 事件的一些数值信息，比如权重、时长、价格等等，在报表中可以看到其平均值等数据。该项可选。
 */
 Vue.prototype.$trackEvent = function(category, action, opt_label, opt_value) {
  var _hmt = window._hmt || [];
  console.log("track event", category, action, opt_label, opt_value)
  _hmt.push(['_trackEvent', category, action, opt_label, opt_value]);
}

Vue.prototype.$url = process.env.VUE_APP_API_URL;
var _hmt = _hmt || []
window._hmt = _hmt;
(function() {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?c5b40ef10da49412602915d8fa10b562";
  var s = document.getElementsByTagName("script")[0]; 
  s.parentNode.insertBefore(hm, s);
})();

Vue.config.productionTip = false;
router.beforeEach((to, from, next) => {
  
  // 访问login页面时，若已登陆，则直接跳转首页
  if(to.name == "login"){
    if (Commons.isLogin()){
        router.push("/"); 
    }
    return 
  }
// 判断页面是否需要检测登录状态
  if(to.meta.requireAuth){
      if(!Commons.isLogin()){
          next({
              path: "/login",
          })
          return 
      }else{
          next();
      }
  } else {
      next();
  }
  
})

new Vue({
  el: '#app',
  router,
  store: store,
  render: h => h(App)
}).$mount('#app')




