import Vue from 'vue'
import LoginBox from '@/components/LoginBox'
let LoginBoxConstructor = Vue.extend(LoginBox)
let LoginModal = () => {
    let loginBoxDom = new LoginBoxConstructor({
        el: document.createElement('div')
    })
    document.body.appendChild(loginBoxDom.$el)

    return {
        close: function() {
            loginBoxDom.close()
        }

    }
}

export default LoginModal;