<template>
  <div id="app" style="text-align:center;">
    <van-nav-bar 
      title="登录/注册" 
      left-text="" 
      left-arrow 
      @click-left="onClickLeft" />
      <img alt="Vue logo" src="../assets/logo.png"  style="width:46%">
      <LoginBox></LoginBox>
  </div>
</template>

<script>
import Vue from 'vue'
import LoginBox from '@/components/LoginBox'
import {Toast} from 'vant'
Vue.use(Toast)

export default {
  name: 'Login',
  components: {
    LoginBox
  },
  data() {
    return {
      mobile: '',
      verifyCode: '',
      captcha: '',
      password: '',
      active: 0,
      // 是否已经发送了验证码
      isSmsSend: false,
      // 文本
      sendBtnText: '点击发送验证码',
      // 计时器对象
      timer: null,
      // 倒数120秒
      counter: 120,
      order_captcha_url: '',
    };
  },
  methods: {
    onSubmit(values) {
      var vue = this
      var params = {
        'mobile': this.mobile,
        'verify_code' : this.verifyCode
      }
      var url = vue.$url + "/v2/user/login"
      vue.$axios.post(url, params).then(response => {
          if (response.data.code =='ok') {
              //登录成功
              localStorage.setItem("user", JSON.stringify(response.data.data));
              Toast.success("登录成功")
              vue.$router.back()
          } else {
            Toast.fail(response.data.message)
          }
      }).catch(function (error) { // 请求失败处理
        vue.$dialog.alert({
          message: error.response.data.message,
        })
      })

    },
    onClickLeft() {
      this.$router.push("/")
    },
    onFailed(errorInfo) {
      console.log('failed', errorInfo);
    },
    // 校验函数返回 true 表示校验通过，false 表示不通过
    mobileValidator(val) {
      return /^1[3456789]\d{9}$/.test(val);
    },
    passwordValidator(val) {
      return /^(?=.*\d)(?=.*[a-zA-Z])[\da-zA-Z~!@#$%^&*]{6,18}$/.test(val);
    },
    sendSmsCode() {
      // 判断手机号是否已经输入
      console.log(this.form)
      if (!this.mobileValidator(this.mobile)) {
        return false
      }
      let vue = this
      let url = vue.$url + "/v1/captcha/sms"
        let param_data = {
            "verify_code" : vue.captcha,
            "mobile": vue.mobile
        }
        vue.$axios.post(url, param_data).then(response => {
            if(response.data.code == 'ok') {
                Toast.success('发送成功，请留意短信');
                //vue.startCountdown(vue, vue.sms_countdown_seconds)
                vue.countDown()  // 这里实现倒计时的功能，文章下面开始介绍
            } else {
                Toast.fail(response.data.message);
            } 
        })
        
      // 调用接口，发送短信验证码
      // 这部分放调用发送短信的接口，这里先不做任何功能，主要先把按钮倒计时的功能实现
      // 将按钮禁用，防止再次点击
      this.isSmsSend = true
      // 开始倒计时，60s之后才能再次点击
      
    },
    countDown () {
      // 将setInterval()方法赋值给前面定义的timer计时器对象，等用clearInterval()方法时方便清空这个计时器对象
      this.timer = setInterval(() => {
        // 替换文本，用es6里面的``这个来创建字符串模板，让秒实时改变
        this.sendBtnText = `(${this.counter}秒)后重新发送`
        this.counter--
        if (this.counter < 0) {
          // 当计时小于零时，取消该计时器
          clearInterval(this.timer)
        }
      }, 1000)
    },
    refreshCaptpha() {
        this.order_captcha_url = this.get_new_order_captcha_url()
    },
    get_new_order_captcha_url() {
        return this.$url + '/v1/captcha?v=' +  Math.random()
    },
    
  },
  mounted(){
      this.order_captcha_url = this.get_new_order_captcha_url()
  }
}
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;

}
</style>
