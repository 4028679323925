export default {
    downloadFile: function(vue, url, data) {
        vue.$axios.post(url,data).then((res) => {
            console.log(res)
            if (window.navigator.msSaveBlob) {
            //IE以及IE内核的浏览器
            try {
                window.navigator.msSaveBlob(res.data, data.name); //response为接口返回数据，这里请求的时候已经处理了，如果没处理需要在此之前自行处理var data = new Blob([response.data]) 注意这里需要是数组形式的,fileNm就是下载之后的文件名
                // window.navigator.msSaveOrOpenBlob(response, fileNm);	//此方法类似上面的方法，区别可自行百度
            } catch (e) {console.log("down error", e)}
            } else {
            //在headers中截取filename
            var filename = data.name;
            var blob = new Blob([res.data], {type: "application/octet-stream"})
            var downloadElement = document.createElement('a');
            var href = window.URL.createObjectURL(blob); //创建下载的链接
            downloadElement.href = href;
            downloadElement.download = filename 
            //此处也可以将filename写死 "filename.xlsx"
            document.body.appendChild(downloadElement);
            downloadElement.click(); //点击下载
            document.body.removeChild(downloadElement); //下载完成移除元素
            window.URL.revokeObjectURL(href); //释放掉blob对象 
            }
        });
    },
    //处理异常
    handlerError(vue, error) {
        console.log(error)
        if (error.response.status == 401) {
            vue.removeLoginUser()
            vue.$router.push('/login')
        } else if (error.response.status == 403) {
            vue.$dialog.alert({'message': error.response.data.message}).then(() => {
                vue.$router.back()
            })
            
        }
    },
    /**
     * 判断参数类型，并调整道对应的路由
     * @param {*} vue 
     * @param {*} need 
     * @param {*} params 
     */
    getAppParams(vue, need, id, params) {
        console.log(

            "getAppParams", params
        )
        //兼容历史参数，如果只普通数据但是不是app类型则跳转
        if (!('type' in  params) || !params['type']) {
            vue.$router.push("/app/"+id)
            return 
        }
        if ('type' in  params && need != params['type']) {
            vue.$router.push("/"+params['type']+"/"+id)
            return 
        }
        return params['params']
        
    },

    jumpSurfaceDetail(vue, id, type) {
        if (!type) {
            vue.$router.push("/app/"+id)
            return 
        } else {
            vue.$router.push("/"+type+"/"+id)
            return 
        }
    },

    /**
     * 根据id获取项目详情
     **/ 
    async getSurface(vue, id) {
        try {
            let response = await vue.$axios.get(vue.$url + '/v1/surface/' + id)
            return response.data.data
            
        } catch(error) {
            console.log(error)
            this.handlerError(vue, error)
        }
    },
    /**
     *  保存新项目
     * @param {*} vue 
     * @param {*} params 
     */
    async addSurface(vue, params) {
        try {
            let response = await vue.$axios.post(vue.$url + '/v1/surface' , params)
            return response.data
        } catch(error) {
            console.log(error)
            this.handlerError(vue, error)
        }

    },
    /**
     * 更新项目
     * @param {*} vue 
     * @param {*} id 
     * @param {*} params 
     */
    async updateSurface(vue, id, params) {
        try {
            let response = await vue.$axios.put(vue.$url + '/v1/surface/'+id , params)
            return response.data
        } catch(error) {
            console.log(error)
            this.handlerError(vue, error)
        }
    },
    /**
     * 是否登录
     */
    isLogin() {
       console.log("check login")
       return this.getLoginUser() != undefined
    },
    getLoginUser() {
        let user = localStorage.getItem('user')
        console.log(user)
        if (!user) {
            return undefined
        }
        return JSON.parse(user)
    },
    removeLoginUser() {
        localStorage.removeItem('user')
    },
    goLogin(vue) {
        vue.$router.push('/login')
    }

    
}