/**
 * 出口
 **/
<template>
    <div>
        <div id="prviewArea">

            <div id="canvas3d" height="320px"></div>

        </div>
        <!-- 第三页的 选择图形-->
        <div class="selectBox" v-show="frame[2]">
            <div :key="index" v-for="(item, index) in surfaceLineMeta">
                <input type="checkbox" :checked="item.selected" @click="onShowCheckBoxClick(item)">{{ item.sfno }}
            </div>
        </div>
        <!-- 展开页，通过radio 选择-->
        <div class="selectBox" v-show="frame[3]">
            <input type="radio" name="spriview" value="-1" v-model="showPreviewNo" @click="onShowRadioBoxClick()">全部
            <div :key="index" v-for="(item, index) in surfaceLineMeta" v-show="item.selected">
                <input type="radio" name="spriview" :value="item.sfno" v-model="showPreviewNo"
                    @click="onShowRadioBoxClick(item)">{{ item.sfno }}
            </div>

        </div>

        <div style="margin-top:30px;" v-show="frame[0]">
            <div>确认轨迹</div>
            <van-row>
                <van-col span="6"><span>水平距离</span></van-col>
                <van-col span="10">
                    <van-slider v-model="distance" active-color="#3ea5b9" :min="0" :max="600" :step="5"
                        @input="drawStepOne">
                        <template #button>
                            <div class="custom-button">{{ distance }}</div>
                        </template>
                    </van-slider>
                </van-col>
                <van-col span="8"><van-stepper v-model="distance" :min="0" :max="600" :step="5"
                        @input="drawStepOne" /></van-col>
            </van-row>
            <van-row>
                <van-col span="6"><span>垂直距离</span></van-col>
                <van-col span="10">
                    <van-slider v-model="height" active-color="#3ea5b9" :min="0" :max="600" :step="5" @input="drawStepOne">
                        <template #button>
                            <div class="custom-button">{{ height }}</div>
                        </template>
                    </van-slider>
                </van-col>
                <van-col span="8"><van-stepper v-model="height" :min="0" :max="600" :step="5"
                        @input="drawStepOne" /></van-col>
            </van-row>
            <van-row>
                <van-col span="6"><span>开始角度</span></van-col>
                <van-col span="10">
                    <van-slider v-model="theta1" active-color="#3ea5b9" :min="0" :max="90" :step="5" @input="drawStepOne">
                        <template #button>
                            <div class="custom-button">{{ theta1 }}</div>
                        </template>
                    </van-slider>
                </van-col>
                <van-col span="8"><van-stepper v-model="theta1" :min="0" :max="90" :step="5"
                        @input="drawStepOne" /></van-col>
            </van-row>
            <van-row>
                <van-col span="6"><span>结束角度</span></van-col>
                <van-col span="10">
                    <van-slider v-model="theta2" active-color="#3ea5b9" :min="0" :max="90" :step="5" @input="drawStepOne">
                        <template #button>
                            <div class="custom-button">{{ theta2 }}</div>
                        </template>
                    </van-slider>
                </van-col>
                <van-col span="8"><van-stepper v-model="theta2" :min="0" :max="90" :step="5"
                        @input="drawStepOne" /></van-col>
            </van-row>
            <van-row>
                <van-col span="6"><span>直段长度</span></van-col>
                <van-col span="10">
                    <van-slider v-model="LEN" active-color="#3ea5b9" :min="0" :max="400" :step="5" @input="drawStepOne">
                        <template #button>
                            <div class="custom-button">{{ LEN }}</div>
                        </template>
                    </van-slider>
                </van-col>
                <van-col span="8"><van-stepper v-model="LEN" :min="0" :max="400" :step="5" @input="drawStepOne" /></van-col>
            </van-row>
            <!-- <van-row>
                <van-col span="6"><span>point1_x</span></van-col>
                <van-col span="10">
                    <van-slider v-model="point1_x" active-color="#3ea5b9" :min="-50" :max="50" :step="1"
                        @input="updateGraphs">
                        <template #button>
                            <div class="custom-button">{{ point1_x }}</div>
                        </template>
                    </van-slider>
                </van-col>
                <van-col span="8"><van-stepper v-model="point1_x" :min="-50" :max="50" :step="1"
                        @input="updateGraphs" /></van-col>
            </van-row>
            <van-row>
                <van-col span="6"><span>point1_y</span></van-col>
                <van-col span="10">
                    <van-slider v-model="point1_y" active-color="#3ea5b9" :min="-50" :max="50" :step="1"
                        @input="updateGraphs">
                        <template #button>
                            <div class="custom-button">{{ point1_y }}</div>
                        </template>
                    </van-slider>
                </van-col>
                <van-col span="8"><van-stepper v-model="point1_y" :min="-50" :max="50" :step="1"
                        @input="updateGraphs" /></van-col>
            </van-row>
            <van-row>
                <van-col span="6"><span>point2_x</span></van-col>
                <van-col span="10">
                    <van-slider v-model="point2_x" active-color="#3ea5b9" :min="-50" :max="50" :step="1"
                        @input="updateGraphs">
                        <template #button>
                            <div class="custom-button">{{ point2_x }}</div>
                        </template>
                    </van-slider>
                </van-col>
                <van-col span="8"><van-stepper v-model="point2_x" :min="-50" :max="50" :step="1"
                        @input="updateGraphs" /></van-col>
            </van-row>
            <van-row>
                <van-col span="6"><span>point2_y</span></van-col>
                <van-col span="10">
                    <van-slider v-model="point2_y" active-color="#3ea5b9" :min="-50" :max="50" :step="1"
                        @input="updateGraphs">
                        <template #button>
                            <div class="custom-button">{{ point2_y }}</div>
                        </template>
                    </van-slider>
                </van-col>
                <van-col span="8"><van-stepper v-model="point2_y" :min="-50" :max="50" :step="1"
                        @input="updateGraphs" /></van-col>
            </van-row>
            <van-row>
                <van-col span="6"><span>SCALE1</span></van-col>
                <van-col span="10">
                    <van-slider v-model="scale1" active-color="#3ea5b9" :min="0" :max="2" :step="0.05"
                        @input="updateGraphs">
                        <template #button>
                            <div class="custom-button">{{ scale1 }}</div>
                        </template>
                    </van-slider>
                </van-col>
                <van-col span="8"><van-stepper v-model="scale1" :min="0" :max="2" :step="0.05"
                        @input="updateGraphs" /></van-col>
            </van-row>
            <van-row>
                <van-col span="6"><span>SCALE2</span></van-col>
                <van-col span="10">
                    <van-slider v-model="scale2" active-color="#3ea5b9" :min="0" :max="2" :step="0.05"
                        @input="updateGraphs">
                        <template #button>
                            <div class="custom-button">{{ scale2 }}</div>
                        </template>
                    </van-slider>
                </van-col>
                <van-col span="8"><van-stepper v-model="scale2" :min="0" :max="2" :step="0.05"
                        @input="updateGraphs" /></van-col>
            </van-row>
            <van-row>
                <van-col span="6"><span>SCALE_ALL</span></van-col>
                <van-col span="10">
                    <van-slider v-model="scale_all" active-color="#3ea5b9" :min="0" :max="2" :step="0.05"
                        @input="scalle_all_change">
                        <template #button>
                            <div class="custom-button">{{ scale_all }}</div>
                        </template>
                    </van-slider>
                </van-col>
                <van-col span="8"><van-stepper v-model="scale_all" :min="0" :max="2" :step="0.05"
                        @input="scalle_all_change" /></van-col>
            </van-row>
             -->
            <van-row>
                <van-radio-group v-model="thDirect" direction="horizontal" @change="drawStepOne">
                    <van-col span="6">方向</van-col>

                    <van-col span="9">
                        <van-radio name="-1">逆时针</van-radio>
                    </van-col>
                    <van-col span="9">
                        <van-radio name="1">顺时针</van-radio>
                    </van-col>
                </van-radio-group>
            </van-row>
            <van-row type="flex" justify="end">
                <van-col span="4">

                </van-col>
                <van-col span="4">
                    <van-button type="info" size="small" @click="next">下一步</van-button>
                </van-col>
            </van-row>
        </div>
        <div id="step2" v-show="frame[1]" style="margin-top:30px;">
            <div>确认截面</div>
            <van-row align="center">
                <van-col span="2"></van-col>
                <van-col span="18" style="text-align: left;">
                    <van-button plain type="info"
                        :style="{ 'margin': '0 2px', 'border': 'none', 'background': str_pad(item.color.toString(16)) }"
                        :key="index" v-for="(item, index) in sufaceMetas" @click="showSectionInfoByIdx(index, 1)">
                    </van-button>
                    <van-icon name="delete-o" v-show="sufaceMetas.length > 0" size="24" @click="removeLastSection(1)" />
                </van-col>
                <van-col span="4"> <van-icon name="add-o" size="34" @click="showAddSectionBox(1)" /></van-col>
            </van-row>
            <van-row>
                <van-col span="2"></van-col>
                <van-col span="18" style="text-align: left;">
                    <van-button plain type="info"
                        :style="{ 'margin': '0 2px', 'border': 'none', 'background': str_pad(item.color.toString(16)) }"
                        :key="index" v-for="(item, index) in sufaceMetasB"
                        @click="showSectionInfoByIdx(index, 2)"></van-button>
                    <van-icon name="delete-o" v-show="sufaceMetasB.length > 0" size="24" @click="removeLastSection(2)" />
                </van-col>
                <van-col span="4"> <van-icon name="add-o" size="34" @click="showAddSectionBox(2)" /></van-col>
            </van-row>
            <van-row v-show="false">
                <van-col span="6">起始角度</van-col>
                <van-col span="10">
                    <van-slider v-model="startAngle" active-color="#3ea5b9" :min=-180 :max=180 :step=5>
                        <template #button>
                            <div class="custom-button">{{ startAngle }}</div>
                        </template>
                    </van-slider>
                </van-col>
                <van-col span="8"><van-stepper v-model="startAngle" :min=-180 :max=180 :step=5 /></van-col>
            </van-row>
            <van-row type="flex" justify="end">
                <van-col span="4">
                    <van-button type="info" size="small" @click="prev">上一步</van-button>
                </van-col>
                <van-col span="4">
                    <van-button type="info" size="small" @click="next">下一步</van-button>
                </van-col>
            </van-row>
        </div>
        <div id="step3" v-show="frame[2]" style="margin-top:30px;">
            <div>预览</div>
            <van-row>
                <van-col span="6"><span>SCALE1</span></van-col>
                <van-col span="10">
                    <van-slider v-model="scale1" active-color="#3ea5b9" :min="0" :max="2" :step="0.05"
                        @input="updateStepTheeGraphs">
                        <template #button>
                            <div class="custom-button">{{ scale1 }}</div>
                        </template>
                    </van-slider>
                </van-col>
                <van-col span="8"><van-stepper v-model="scale1" :min="0" :max="2" :step="0.05"
                        @input="updateStepTheeGraphs" /></van-col>
            </van-row>
            <van-row>
                <van-col span="6"><span>SCALE2</span></van-col>
                <van-col span="10">
                    <van-slider v-model="scale2" active-color="#3ea5b9" :min="0" :max="2" :step="0.05"
                        @input="updateStepTheeGraphs">
                        <template #button>
                            <div class="custom-button">{{ scale2 }}</div>
                        </template>
                    </van-slider>
                </van-col>
                <van-col span="8"><van-stepper v-model="scale2" :min="0" :max="2" :step="0.05"
                        @input="updateStepTheeGraphs" /></van-col>
            </van-row>
            <van-row>
                <van-col span="6"><span>SCALE_ALL</span></van-col>
                <van-col span="10">
                    <van-slider v-model="scale_all" active-color="#3ea5b9" :min="0" :max="2" :step="0.05"
                        @input="scalle_all_change">
                        <template #button>
                            <div class="custom-button">{{ scale_all }}</div>
                        </template>
                    </van-slider>
                </van-col>
                <van-col span="8"><van-stepper v-model="scale_all" :min="0" :max="2" :step="0.05"
                        @input="scalle_all_change" /></van-col>
            </van-row>
            <van-row type="flex" justify="end">
                <van-col span="4">
                    <van-button type="info" size="small" @click="prev">上一步</van-button>
                </van-col>
                <van-col span="4">
                    <van-button type="info" size="small" @click="next">展开</van-button>
                </van-col>
            </van-row>
        </div>
        <div id="step4" v-show="frame[3]">
            <div id="expandArea">
                <div :key="index" v-for="(item, index) in this.priviewImgUrls">
                    <van-image :src="item.url" alt="加载中.." height="180px" fit="contain" v-show="item.sfno == showPreviewNo">
                        <template v-slot:loading>图像加载中...</template>
                        <template v-slot:error>图像加载中...</template>
                    </van-image>
                </div>
            </div>
            <van-row v-show="!canShowWeight">
                <van-col span="24">图形展开中，请稍候</van-col>
            </van-row>
            <van-row v-show="canShowWeight">
                <van-col span="24">

                    <div v-show="showPreviewNo == '-1'">本次展开 {{ priviewImgUrls.length }} 个部件<br>合计切割长度
                        {{ cutLength.toFixed(2) }}米，重量{{ cutWeight.toFixed(2) }}公斤</div>
                    <div v-show="showPreviewNo != '-1'">

                        切割长度{{ cutLength.toFixed(2) }}米，重量{{ cutWeight.toFixed(2) }}公斤
                    </div>
                </van-col>

            </van-row>
            <van-row type="flex" justify="end">
                <van-col span="3">
                    <van-button type="info" size="small" @click="prev">返回</van-button>
                </van-col>
                <van-col span="6" v-show="showPreviewNo != '-1'">
                    <van-button type="primary" size="small" @click="showDownload('dxf')">下载DXF文件</van-button>
                </van-col>
                <van-col span="6" v-show="showPreviewNo != '-1'">
                    <van-button type="primary" size="small" @click="showDownload('cnc')">下载加工代码</van-button>
                </van-col>
            </van-row>

        </div>
        <div id="sectionPopBox">
            <van-dialog v-model="showAddSection" title="截面参数" show-cancel-button :show-confirm-button="true"
                :before-close="addSectionClose">
                <div id="sectionParamArea">
                    <van-row>
                        <van-col span="6">板材宽度</van-col>
                        <van-col span="10">
                            <van-slider v-model="sectionTmpLength" active-color="#3ea5b9" :min=5 :max=200
                                @input="updateSectionDraw()">
                                <template #button>
                                    <div class="custom-button">{{ sectionTmpLength }}</div>
                                </template>
                            </van-slider>
                        </van-col>
                        <van-col span="8"><van-stepper v-model="sectionTmpLength" :min=5 :max=200
                                @input="updateSectionDraw()" /></van-col>
                    </van-row>
                    <van-row>
                        <van-col span="6">角度</van-col>
                        <van-col span="10">
                            <van-slider v-model="sectionTmpAngle" active-color="#3ea5b9" :min=-180 :max=180 :step=5
                                @input="updateSectionDraw()">
                                <template #button>
                                    <div class="custom-button">{{ sectionTmpAngle }}</div>
                                </template>
                            </van-slider>
                        </van-col>
                        <van-col span="8"><van-stepper v-model="sectionTmpAngle" :min=-180 :max=180 :step=5
                                @input="updateSectionDraw()" /></van-col>
                    </van-row>
                </div>
            </van-dialog>
        </div>
        <van-popup v-model="showDownRename" closeable position="bottom" :style="{ height: '30%' }"
            @closed="closeDownRenameBox">
            <div style="padding-top:15%">

                <div>
                    <van-field v-model="userDefineName" center label="请输入项目名称" maxlength=80 label-width="8em" required>
                        <template #button>
                            <van-button size="small" type="primary" @click="download()">直接下载</van-button>
                        </template>
                    </van-field>
                    <van-field v-model="email" center label="输入邮箱地址" maxlength=80 label-width="8em"
                        :error-message="emailErrmsg">
                        <template #button>
                            <van-button size="small" type="primary" @click="sendMail()">邮箱发送</van-button>
                        </template>
                    </van-field>
                </div>

            </div>
        </van-popup>
        <div class="contactUsBnt" id="contactUsBnt" v-show="false">
            <div style="font-size: 1.2rem;color: #439ae5;"> 更多功能开发中，即将发布</div>
            <van-button @click="showContactUsBox" style="border: none;">需要帮助？ 请点击这里</van-button>
        </div>
        <van-dialog v-model="showContactUs" class="contactUsBox" title="扫一扫，加我微信">
            <van-image width="240" height="240" src="/contact2.jpg"></van-image>
        </van-dialog>
    </div>
</template>
<script>
import Vue from "vue";
import {
    Col,
    Row,
    Slider,
    Stepper,
    Image as VanImage,
    Button,
    Toast,
    Swipe,
    SwipeItem,
    Checkbox,
    CheckboxGroup,
    Popup,
    Field,
    RadioGroup,
    Radio,
} from "vant";
import * as THREE from "three";
import { ParametricGeometry } from "three/examples/jsm/geometries/ParametricGeometry";
//import { OrbitControls } from '../libs/js/OrbitControls'
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader";
import { DragControls } from "three/examples/jsm/controls/DragControls";
import { TransformControls } from "three/examples/jsm/controls/TransformControls";
import { RoomEnvironment } from "three/examples/jsm/environments/RoomEnvironment";
import VueCookies from "vue-cookies";
import { Base64 } from "js-base64";
import { Loading } from "vant";
import LoginBox from "@/libs/LoginBox";
import BrowserInfo from "@/libs/Browserinfo";
import Clipboard from "clipboard";
import M from "minimatch";


Vue.use(Loading);
Vue.use(VueCookies);
Vue.use(Col);
Vue.use(Slider);
Vue.use(Row);
Vue.use(Stepper);
Vue.use(Button);
Vue.use(VanImage);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Popup);
Vue.use(Field);
Vue.use(Radio);
Vue.use(RadioGroup);
export default {
    data() {
        return {
            scene: null,
            camera: null,
            renderer: null,
            controls: null,
            graphMeshs: [],
            sufaceLines: [],
            showDownRename: false,
            email: "",
            userDefineName: '默认',
            downType: '',
            //方向
            thDirect: '-1',
            showContactUs: false,
            //邮箱提示
            emailErrmsg: '',

            /**
             * 水平距离
             **/
            distance: 100,
            /**
             * 垂直距离
             * */
            height: 300,
            /**
             * 开始角度
             * */
            theta1: 0,
            /**
             * 结束角度
             * */
            theta2: 90,
            /**
             * 直段长度
             **/
            LEN: 200,
            scale1: 0.5,
            scale2: 0.5,
            scale_all: 0.5,
            curveObject: null,
            surfaceObject: null,
            point1_x: 0,
            point1_y: 0,
            point2_x: 10,
            point2_y: 10,
            active: 0,
            /* 展示那个步骤 */
            frame: [true, false, false],
            sufaceMetas: [],
            sufaceMetasB: [],
            startAngle: 0,
            showAddSection: false,
            isNewSection: false,
            //正在编辑的截面
            sectionModifyIdx: -1,
            // 新增截面临时参数：长度
            sectionTmpLength: 0,
            sectionTmpLengthForRestore: 0,
            // 新增截面临时参数：角度
            sectionTmpAngle: 0,
            sectionTmpAngleForRestore: 0,
            // 正在添加的截面方向
            addingSection: 0,
            /**
             * 截面线
             */
            sectionGraphMeshs: [],
            surfaceObjects: [],
            surfaceLineMeta: [],
            sufaceColors: [0xff00ff, 0xffff00, 0x00ffff, 0x600060, 0x606000, 0x006060, 0xff0000, 0x00ff00, 0x0000ff, 0x600000, 0x006000, 0x000060],
            showPreviewNo: "-1",
            priviewImgUrls: [],
            curnentImgMeta: null,
            /**
             * 材料密度
             */
            density: 7.93,

            /**
             * 材料厚度
             */
            thickness: 1,
        }
    },
    methods: {
        initTree() {
            this.scene = new THREE.Scene();
            let width = window.innerWidth // 窗口宽度
            let height = 320
            //this.camera = new THREE.OrthographicCamera( window.innerWidth/-2, window.innerWidth/2, window.innerHeight/2, window.innerHeight/-2, 1, 10000 );
            this.camera = new THREE.OrthographicCamera(width / - 2.8, width / 2.8, height / 2.8, height / - 2.8, 1, 10000)
            this.camera.position.y = 8000;
            this.camera.up = new THREE.Vector3(0, 0, 1);
            this.camera.lookAt({ x: 0, y: 1, z: 0 });
            const ambientLight = new THREE.AmbientLight(0x666666);
            this.scene.add(ambientLight);

            const DirLight = new THREE.DirectionalLight(0xffffff, 1);
            this.scene.add(DirLight);


            this.renderer = new THREE.WebGLRenderer();
            this.renderer.setSize(width, height);
            this.renderer.setClearColor(0xaaaaaa);

            document.getElementById('canvas3d').appendChild(this.renderer.domElement) // body元素中插入canvas对象

            this.controls = new OrbitControls(this.camera, this.renderer.domElement);
            //const controls = new THREE.TrackballControls( camera, renderer.domElement );
            //this.controls.enableRotate  = false
            const axesHelper = new THREE.AxesHelper(10);
            this.scene.add(axesHelper);
        },
        animate() {
            requestAnimationFrame(this.animate);
            this.renderer.render(this.scene, this.camera);
            this.controls.update();
        },
        showContactUsBox() {

            //let box = new LoginBox()
            this.showContactUs = true
        },
        download() {
            console.log("download ", this.downType)
            if ('cnc' == this.downType) {
                this.downLoadCnc('direct')
            } else {
                this.downLoadDxf('direct')
            }
        },
        //邮箱发送
        sendMail() {
            let vue = this

            if (vue.userDefineName == "") {
                console.log("project name is empty ,not allowed download")
                vue.downloadErrMsg = "请输入项目名称"
                return;
            }
            const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
            if (vue.email == "" || !regEmail.test(vue.email)) {
                vue.emailErrmsg = "请输入正确的邮箱地址"
                return;
            }
            localStorage.setItem("email", vue.email);
            vue.emailErrmsg = ""
            vue.downloadErrMsg = ""
            if ('cnc' == this.downType) {
                this.downLoadCnc('email')
            } else {
                this.downLoadDxf('email')
            }
            vue.$trackEvent("email", this.downType, vue.email, vue.userDefineName)
        },
        sendMailFile(url, data) {
            let vue = this
            data.email = vue.email;
            vue.$axios.post(url, data).then((res) => {
                Toast.success({ message: res.data.message, onClose: () => { vue.showDownRename = false } })

            }, (e) => {
                console.log(e)
            });
        },
        downloadFile(url, data) {
            var vue = this
            vue.$axios.post(url, data).then((res) => {
                console.log(res)
                if (window.navigator.msSaveBlob) {
                    //IE以及IE内核的浏览器
                    try {
                        window.navigator.msSaveBlob(res.data, data.name); //response为接口返回数据，这里请求的时候已经处理了，如果没处理需要在此之前自行处理var data = new Blob([response.data]) 注意这里需要是数组形式的,fileNm就是下载之后的文件名
                        // window.navigator.msSaveOrOpenBlob(response, fileNm);	//此方法类似上面的方法，区别可自行百度
                    } catch (e) { console.log("down error", e) }
                } else {
                    //在headers中截取filename
                    var filename = data.name;
                    var blob = new Blob([res.data], { type: "application/octet-stream" })
                    var downloadElement = document.createElement('a');
                    var href = window.URL.createObjectURL(blob); //创建下载的链接
                    downloadElement.href = href;
                    downloadElement.download = filename
                    //此处也可以将filename写死 "filename.xlsx"
                    document.body.appendChild(downloadElement);
                    downloadElement.click(); //点击下载
                    document.body.removeChild(downloadElement); //下载完成移除元素
                    window.URL.revokeObjectURL(href); //释放掉blob对象 
                }
            });
        },
        /**
         * 下载相关
         */
        showDownload(type) {
            this.downType = type
            this.showDownRename = true

        },
        closeDownRenameBox() {
            this.showDownRename = false
        },
        downLoadDxf(type) {
            var url = '';
            url = this.$url + '/v2/surface/dxf'
            var vue = this
            var param = {
                "line1": vue.curnentImgMeta['lines'][0],
                "line2": vue.curnentImgMeta['lines'][1]

            };
            let data = {
                "name": this.currentDate + "_" + this.userDefineName + '_' + vue.curnentImgMeta.sfno + ".dxf",
                "params": param
            }
            if ('email' == type) {
                this.sendMailFile(url, data);
            } else {
                this.downloadFile(url, data);
            }
        },
        downLoadCnc(type) {
            var url = '';
            url = this.$url + '/v2/surface/cnc'
            var vue = this
            var param = {
                "line1": vue.curnentImgMeta['lines'][0],
                "line2": vue.curnentImgMeta['lines'][1]

            };
            let data = {
                "name": this.currentDate + "_" + this.userDefineName + '_' + vue.curnentImgMeta.sfno + ".cnc",
                "params": param
            }
            if ('email' == type) {
                this.sendMailFile(url, data);
            } else {
                this.downloadFile(url, data);
            }
        },
        scalle_all_change() {
            this.scale1 = this.scale2 = this.scale_all
            this.updateStepTheeGraphs()
        },

        /**
         * 【第二步】根据两个坐标返回曲面去添加 
         **/
        getStepTwoOfCave() {

        },
        /**
         *  绘制第一步【绘制轨道】
         **/
        drawStepOne() {

            var X1, X2, X3, X4, Y1, Y2, Y3, Y4;
            let DIR = this.thDirect == '1' ? 1 : -1
            var n = 400;
            var points0 = new Array();
            let sc1 = this.scale1;
            let sc2 = this.scale2;
            X1 = 0;
            Y1 = 0;
            let L = this.LEN;
            let T1 = -DIR * this.theta1 / 180 * Math.PI;
            let T2 = -DIR * this.theta2 / 180 * Math.PI;
            X4 = DIR * this.distance - DIR * L * Math.cos(T2);
            Y4 = -(this.height + DIR * L * Math.sin(T2));
            let X = DIR * this.distance;
            let Y = -this.height;

            X2 = X4 * Math.cos(T1) * sc1;
            Y2 = X4 * Math.sin(T1) * sc1;
            X3 = X4 - X4 * Math.cos(T2) * sc2;
            Y3 = Y4 - X4 * Math.sin(T2) * sc2;
            if (this.curveObject) {
                this.scene.remove(this.curveObject);
            }
            if (this.surfaceObjects.length > 0) {
                var surfaceObject = this.surfaceObjects.pop()
                this.scene.remove(surfaceObject);
            }
            class CustomSinCurve extends THREE.Curve {
                constructor(scale = 1) {
                    super();
                    this.scale = scale;
                }
                getPoint(t, optionalTarget = new THREE.Vector3()) {
                    const tx = X1 * (1 - t) ** 3 + 3 * X2 * t * (1 - t) ** 2 + 3 * X3 * t ** 2 * (1 - t) + X4 * t ** 3;
                    const tz = Y1 * (1 - t) ** 3 + 3 * Y2 * t * (1 - t) ** 2 + 3 * Y3 * t ** 2 * (1 - t) + Y4 * t ** 3;
                    const ty = 0;
                    return optionalTarget.set(tx, ty, tz).multiplyScalar(this.scale)
                }
            }

            let curve = new CustomSinCurve(1);
            points0 = curve.getSpacedPoints(n);
            points0.push(new THREE.Vector3(X, 0, Y));


            const geometry_line = new THREE.BufferGeometry().setFromPoints(points0);
            const material_line = new THREE.LineBasicMaterial({ color: 0xff0000 });
            // Create the final object to add to the scene
            this.curveObject = new THREE.Line(geometry_line, material_line);
            this.scene.add(this.curveObject)

        },
        /**
         *  绘制第二步 【绘制截面】
         **/
        drawStepTwo() {
            if (this.curveObject) {
                this.scene.remove(this.curveObject);
            }
            if (this.surfaceObject) {
                this.scene.remove(this.surfaceObject);
            }
            console.log(this.surfaceObjects)
            //删除第三步的曲面
            while (this.surfaceObjects.length > 0) {
                var one = this.surfaceObjects.pop()
                console.log(one)
                this.scene.remove(one)
            }
            this.draw3DsufaceSection()

        },


        clearSectionLine() {
            while (this.sectionGraphMeshs.length > 0) {
                let graphMesh = this.sectionGraphMeshs.pop()
                this.scene.remove(graphMesh)
                // renderer.deallocateObject( graphMesh );
            }
        },
        getsufaceMetasBySite(pos) {
            return pos == 1 ? this.sufaceMetas : this.sufaceMetasB;
        },
        removeLastSection(pos) {
            this.getsufaceMetasBySite(pos).pop();
            this.draw3DsufaceSection()
        },
        showAddSectionBox(pos) {
            this.addingSection = pos;
            this.showAddSection = true;
            //下标等于则添加，因为新增可能还有动态调整
            this.sectionModifyIdx = this.getsufaceMetasBySite(this.addingSection).length
            this.isNewSection = true
        },
        /**
        * 点击某个截面的按钮，弹出来展示
        */
        showSectionInfoByIdx(index, pos) {
            let sufaceMetas = this.getsufaceMetasBySite(pos);
            if (sufaceMetas.length > index) {
                this.sectionTmpAngleForRestore = this.sectionTmpAngle = sufaceMetas[index].angle
                this.sectionTmpLengthForRestore = this.sectionTmpLength = sufaceMetas[index].mL
                this.sectionModifyIdx = index
                this.showAddSection = true
                this.addingSection = pos
                this.isNewSection = false
            }
        },
        updateSectionDraw() {
            let length = this.sectionTmpLength
            let angleDiff = this.sectionTmpAngle
            let sufaceMetas = this.getsufaceMetasBySite(this.addingSection)
            this.addAndUpdateSufafMetaIdx(sufaceMetas, angleDiff, length, this.sectionModifyIdx);
            this.clearSectionLine()
            this.draw3DsufaceSection()
        },
        /**
         * 确认添加截面
         */
        addSectionClose(action, done) {

            if ('confirm' == action) {

                //计算新曲面的坐标，并加入到曲面数组
                this.updateSectionDraw()

            } else {
                console.log("anthor action", action)
                if (this.sectionModifyIdx > 0 && this.isNewSection) {
                    //TODO 还原，不保存最后新增的曲面，修改的没有取消
                    this.getsufaceMetasBySite(this.addingSection).pop()

                } else {
                    //还原
                    this.getsufaceMetasBySite(this.addingSection)[this.sectionModifyIdx].mL = this.sectionTmpLengthForRestore
                    this.getsufaceMetasBySite(this.addingSection)[this.sectionModifyIdx].angle = this.sectionTmpAngleForRestore

                    //addAndUpdateSufafMetaIdx
                }
                this.clearSectionLine()
                this.draw3DsufaceSection();
            }
            this.sectionModifyIdx = -1;
            return done()
        },

        str_pad(hex) {
            var zero = '000000';
            var tmp = 6 - hex.length;
            var c = "#" + zero.substr(0, tmp) + hex;
            return c;
        },
        /**
     * 更新第index个曲面的角度和长度
     * 如果index < -1 ，则插入到最后，
     * 如果index > 0 and < sufaceMetas , 更新index 之后的值
     * no 编号
     */
        addAndUpdateSufafMetaIdx(sufaceMetas, angle, length, index) {
            let lastPos = { x: 0, y: 0 };
            let lastTotalAngle = 0;
            let alpha1 = 0;
            let L1 = 0;
            let vue = this
            if (index > -1 && index < sufaceMetas.length) {
                //更新中间
                sufaceMetas[index].angle = angle
                sufaceMetas[index].mL = length
                for (var k = index; k < sufaceMetas.length; k++) {
                    if (k > 0) {
                        lastPos = sufaceMetas[k - 1].endPos
                        lastTotalAngle = sufaceMetas[k - 1].totalAngle
                        alpha1 = sufaceMetas[k - 1].alpha2
                        L1 = sufaceMetas[k - 1].l2
                    }
                    let totalAngle = lastTotalAngle + sufaceMetas[k].angle;
                    let x = sufaceMetas[k].mL * Math.cos(totalAngle / 180 * Math.PI) + lastPos.x
                    let y = sufaceMetas[k].mL * Math.sin(totalAngle / 180 * Math.PI) + lastPos.y
                    let endPos = { x: x, y: y };
                    let L2 = Math.sqrt(x ** 2 + y ** 2)
                    let alpha2 = 0;
                    if (y > 0) alpha2 = -Math.acos(x / L2);
                    else if (y < 0) alpha2 = Math.acos(x / L2);
                    else alpha2 = 0;
                    sufaceMetas[k].endPos = endPos
                    sufaceMetas[k].l1 = L1
                    sufaceMetas[k].l2 = L2
                    sufaceMetas[k].alpha1 = alpha1
                    sufaceMetas[k].alpha2 = alpha2
                    sufaceMetas[k].totalAngle = totalAngle
                    sufaceMetas[k].selected = true
                    sufaceMetas[k].color = this.sufaceColors[k % this.sufaceColors.length];
                }
            } else {
                //追加到最后
                if (sufaceMetas.length > 0) {
                    lastPos = sufaceMetas[sufaceMetas.length - 1].endPos
                    lastTotalAngle = sufaceMetas[sufaceMetas.length - 1].totalAngle
                    alpha1 = sufaceMetas[sufaceMetas.length - 1].alpha2
                    L1 = sufaceMetas[sufaceMetas.length - 1].l2
                }
                let totalAngle = lastTotalAngle + angle;
                let x = length * Math.cos(totalAngle / 180 * Math.PI) + lastPos.x
                let y = length * Math.sin(totalAngle / 180 * Math.PI) + lastPos.y
                let endPos = { x: x, y: y };
                let L2 = Math.sqrt(x ** 2 + y ** 2)
                let alpha2 = 0;
                if (y > 0) alpha2 = -Math.acos(x / L2);
                else if (y < 0) alpha2 = Math.acos(x / L2);
                else alpha2 = 0;
                let oneSufaceMeta = {
                    totalAngle: totalAngle,
                    alpha1: alpha1,
                    l1: L1,
                    l2: L2,
                    alpha2: alpha2,
                    endPos: endPos,
                    mL: length,
                    angle: angle,
                    selected: true,
                    sfno: vue.genSurfaceNo(this.addingSection, index),
                    color: this.sufaceColors[sufaceMetas.length % this.sufaceColors.length]
                }
                sufaceMetas.push(oneSufaceMeta);
            }

        },
        /**
         * 绘制3d的截面
         */
        draw3DsufaceSection() {
            // this.updateSufaceMetaAngleAndRadio(this.sufaceMetas, this.radio, this.leftheight, this.startAngle, this.twistAngle, this.centralAngle)
            // this.updateSufaceMetaAngleAndRadio(this.sufaceMetasB, this.radio, this.leftheight, this.startAngle, this.twistAngle, this.centralAngle)

            var lastPos = { x: 0, y: 0 }
            this.surfaceLineMeta = []
            const centerGeometry = new THREE.BufferGeometry().setFromPoints([new THREE.Vector3(0, 0, 0)]);
            var meterial = new THREE.PointsMaterial({
                color: new THREE.Color(0xff000f),
                size: 2.0
            })
            var points = new THREE.Points(centerGeometry, meterial)
            this.scene.add(points)
            this.sectionGraphMeshs.push(points)
            for (let i = 0; i < this.sufaceMetas.length; i++) {
                let csuface = this.sufaceMetas[i];
                const points = [];
                points.push(new THREE.Vector3(lastPos.x, lastPos.y, 0));
                points.push(new THREE.Vector3(csuface.endPos.x, csuface.endPos.y, 0));
                lastPos = csuface.endPos
                const geometry = new THREE.BufferGeometry().setFromPoints(points);

                let color = this.sufaceColors[i % this.sufaceColors.length];
                const material = new THREE.LineBasicMaterial({
                    color: color
                });
                //生成编号
                csuface['idx'] = i
                csuface['color'] = color
                const line = new THREE.Line(geometry, material);
                this.sectionGraphMeshs.push(line)
                this.scene.add(line);
                this.surfaceLineMeta.push({ 'color': color, 'point': points, 'sfno': csuface.sfno, 'selected': true })
            }

            //this.getTextGemotery(this.sufaceMetas[0])

            lastPos = { x: 0, y: 0 }
            for (let i = 0; i < this.sufaceMetasB.length; i++) {
                let csuface = this.sufaceMetasB[i];
                const points = [];
                points.push(new THREE.Vector3(lastPos.x, lastPos.y, 0));
                points.push(new THREE.Vector3(csuface.endPos.x, csuface.endPos.y, 0));
                lastPos = csuface.endPos
                const geometry = new THREE.BufferGeometry().setFromPoints(points);
                let color = this.sufaceColors[this.sufaceColors.length - i % this.sufaceColors.length - 1];
                //生成编号
                csuface['idx'] = parseInt(i) + this.sufaceMetas.length
                csuface['color'] = color
                const material = new THREE.LineBasicMaterial({
                    color: color
                });
                const line = new THREE.Line(geometry, material);
                this.sectionGraphMeshs.push(line)
                this.scene.add(line);
                this.surfaceLineMeta.push({ 'color': color, 'point': points, 'sfno': csuface.sfno, 'selected': true })
            }



        },
        /**
         * 生成曲面/截面的编号 0-1,0-2 0-A 0-B
         */
        genSurfaceNo(pos, index) {
            if (pos == 1) {
                return (index + 1)
            } else {
                return String.fromCharCode(index + 65)
            }
        },
        /**
         *  绘制第三步 【绘制曲面】
         **/
        drawStepThree() {

            this.updateStepTheeGraphs()
        },
        //步骤三的绘图
        updateStepTheeGraphs() {
            var X1, X2, X3, X4, Y1, Y2, Y3, Y4;
            var P1X, P1Y, P2X, P2Y;
            var points0 = new Array();
            var vector = new Array();
            let DIR = this.thDirect == '1' ? 1 : -1
            var n = 400;
            X1 = 0;
            Y1 = 0;
            let L = this.LEN;
            let T1 = -DIR * this.theta1 / 180 * Math.PI;
            let T2 = -DIR * this.theta2 / 180 * Math.PI;
            X4 = DIR * this.distance - DIR * L * Math.cos(T2);
            Y4 = -(this.height + DIR * L * Math.sin(T2));
            let X = DIR * this.distance;
            let Y = -this.height;

            let sc1 = this.scale1;
            let sc2 = this.scale2;
            X2 = X4 * Math.cos(T1) * sc1;
            Y2 = X4 * Math.sin(T1) * sc1;
            X3 = X4 - X4 * Math.cos(T2) * sc2;
            Y3 = Y4 - X4 * Math.sin(T2) * sc2;
            while (this.surfaceObjects.length > 0) {
                var sufOne = this.surfaceObjects.pop()
                console.log("【第三步】删除图像", sufOne)
                this.scene.remove(sufOne);
            }
            if (this.curveObject) {
                this.scene.remove(this.curveObject)
            }
            class CustomSinCurve extends THREE.Curve {
                constructor(scale = 1) {
                    super();
                    this.scale = scale;
                }
                getPoint(t, optionalTarget = new THREE.Vector3()) {
                    const tx = X1 * (1 - t) ** 3 + 3 * X2 * t * (1 - t) ** 2 + 3 * X3 * t ** 2 * (1 - t) + X4 * t ** 3;
                    const tz = Y1 * (1 - t) ** 3 + 3 * Y2 * t * (1 - t) ** 2 + 3 * Y3 * t ** 2 * (1 - t) + Y4 * t ** 3;
                    const ty = 0;
                    return optionalTarget.set(tx, ty, tz).multiplyScalar(this.scale)
                }
            }

            let curve = new CustomSinCurve(1);
            points0 = curve.getSpacedPoints(n);
            points0.push(new THREE.Vector3(X, 0, Y));


            const geometry_line = new THREE.BufferGeometry().setFromPoints(points0);
            const material_line = new THREE.LineBasicMaterial({ color: 0xff0000 });
            // Create the final object to add to the scene
            this.curveObject = new THREE.Line(geometry_line, material_line);
            this.scene.add(this.curveObject)
            var i;
            this.sufaceLines = []
            for (let k = 0; k < this.surfaceLineMeta.length; k++) {

                for (i = 0; i < n + 1; i++) {
                    vector[i] = curve.getTangentAt(i / n);
                }
                vector.push(new THREE.Vector3(vector[n].x, vector[n].y, vector[n].z));
                var oneSide = this.surfaceLineMeta[k]

                if (!oneSide.selected) {
                    console.log("图形不展示", k)
                    continue;
                }
                console.log("draw 图形", k)
                var points1X = new Array();
                var points1Y = new Array();
                var points1Z = new Array();
                var points2X = new Array();
                var points2Y = new Array();
                var points2Z = new Array();

                P1X = oneSide.point[0].x;
                P1Y = oneSide.point[0].y;
                P2X = oneSide.point[1].x;
                P2Y = oneSide.point[1].y;
                for (i = 0; i < n + 1; i++) {
                    vector[i] = curve.getTangentAt(i / n);
                }
                vector.push(new THREE.Vector3(vector[n].x, vector[n].y, vector[n].z));
                var linePs = [], lineTwoPs = []
                for (i = 0; i < n + 2; i++) {
                    points1X[i] = DIR * P1Y * Math.sin(Math.acos(vector[i].x)) + points0[i].x;
                    points1Y[i] = -DIR * P1X + points0[i].y;
                    points1Z[i] = DIR * P1Y * Math.cos(DIR * Math.acos(vector[i].x)) + points0[i].z;
                    linePs.push([points1X[i], points1Y[i], points1Z[i]])
                    points2X[i] = DIR * P2Y * Math.sin(Math.acos(vector[i].x)) + points0[i].x;
                    points2Y[i] = -DIR * P2X + points0[i].y;
                    points2Z[i] = DIR * P2Y * Math.cos(DIR * Math.acos(vector[i].x)) + points0[i].z;
                    lineTwoPs.push([points2X[i], points2Y[i], points2Z[i]])
                }
                this.sufaceLines.push(linePs)
                this.sufaceLines.push(lineTwoPs)
                //两条边保存在元数据中
                oneSide['lines'] = [linePs, lineTwoPs]
                vector.pop();

                let surface_geometry = new THREE.BufferGeometry();
                var vertices = new Float32Array(18 * (n + 1));
                for (i = 0; i < n + 2; i++) {
                    vertices[i * 18] = points1X[i];
                    vertices[i * 18 + 1] = points1Y[i];
                    vertices[i * 18 + 2] = points1Z[i];

                    vertices[i * 18 + 3] = points1X[i + 1];
                    vertices[i * 18 + 4] = points1Y[i + 1];
                    vertices[i * 18 + 5] = points1Z[i + 1];

                    vertices[i * 18 + 6] = points2X[i + 1];
                    vertices[i * 18 + 7] = points2Y[i + 1];
                    vertices[i * 18 + 8] = points2Z[i + 1];

                    vertices[i * 18 + 9] = points2X[i + 1];
                    vertices[i * 18 + 10] = points2Y[i + 1];
                    vertices[i * 18 + 11] = points2Z[i + 1];

                    vertices[i * 18 + 12] = points2X[i];
                    vertices[i * 18 + 13] = points2Y[i];
                    vertices[i * 18 + 14] = points2Z[i];

                    vertices[i * 18 + 15] = points1X[i];
                    vertices[i * 18 + 16] = points1Y[i];
                    vertices[i * 18 + 17] = points1Z[i];
                }

                surface_geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
                surface_geometry.computeVertexNormals();
                let surface_material = new THREE.MeshPhongMaterial({ color: oneSide.color, side: THREE.DoubleSide, emissive: 0x000000, specular: 0xffffff, shininess: 10, wireframe: false, transparent: true, opacity: 1 });
                let surfaceObject = new THREE.Mesh(surface_geometry, surface_material);
                console.log("添加图像", k)
                surfaceObject.metaInfo = oneSide
                this.surfaceObjects.push(surfaceObject)
                this.scene.add(surfaceObject);

            }

        },

        //第四个步骤展开
        extend() {
            var vue = this
            vue.getPrivewImg()
        },
        /**
        * 获取预览图
        */
        getPrivewImg() {
            var url = '';
            url = this.$url + '/v2/surface/preview'
            var vue = this

            vue.priviewImgUrls = []
            for (var i = 0; i < vue.surfaceLineMeta.length; i++) {
                var one = vue.surfaceLineMeta[i];
                if (!one.selected) {
                    console.log("跳过预览图", i)
                    continue;
                }
                var param = {
                    "line1": one['lines'][0],
                    "line2": one['lines'][1]

                };
                let data = {
                    "params": param
                }
                //{{ index == 0 ? '0' : String.fromCharCode(64 + index) }} - {{ String.fromCharCode(index + 65)}} 
                var fc = function (i, sfno, data) {
                    vue.$axios.post(url, data).then(res => {
                        if (res && res.data.code == 'ok') {
                            let baseImgData = "data:image/png;base64," + res.data.data.image;
                            //周长
                            let length = res.data.data.arc_length ? res.data.data.arc_length / 1000 : 1
                            //面积
                            let area = res.data.data.contour_area ? res.data.data.contour_area : 1
                            //重量
                            let weight = (((area * vue.thickness) / 1000) * vue.density) / 1000
                            vue.priviewImgUrls.push({ url: baseImgData, idx: i, sfno: sfno, length: length, area: area, weight: weight })
                        }

                    });
                };
                fc(i, one.sfno, data);

            }
        },

        /**
         * 选择展示
         */
        onShowCheckBoxClick(item) {
            item.selected = !item.selected
            this.updateStepTheeGraphs()
        },
        onShowRadioBoxClick(item) {
            if (item == null || undefined == item) {
                // 展示全部
                for (let i in this.surfaceObjects) {
                    this.surfaceObjects[i].material.opacity = 1
                    this.surfaceObjects[i].material.depthWrite = true
                }
                return;
            }
            //当前选择（预览）的图形
            this.curnentImgMeta = item;
            for (let i in this.surfaceObjects) {
                this.surfaceObjects[i].material.transparent = true
                this.surfaceObjects[i].material.depthWrite = false
                this.surfaceObjects[i].material.opacity = 0.15
                //this.graphMeshs[i].material.color = new THREE.Color(0x808080)
                if (this.surfaceObjects[i].metaInfo.sfno == item.sfno) {
                    this.surfaceObjects[i].material.opacity = 1
                    this.surfaceObjects[i].material.depthWrite = true
                    // this.graphMeshs[i].material.color = new THREE.Color(this.graphMeshs[i].metaInfo.color)
                }
            }
        },
        change() {

        },
        prev() {
            if (this.active > 0) this.active--
            this.showFrame(this.active)
        },

        next() {
            var show = 0
            if (this.active < 4) {
                this.active++
                show = this.active
            } else {
                show = 3
            }
            this.showFrame(show)
        },
        // 展示具体的帧
        showFrame(no) {
            for (var i = 0; i < 4; i++) {
                if (i !== no) {
                    this.$set(this.frame, i, false)
                } else {
                    this.$set(this.frame, i, true)
                }
            }
            //每个步骤特殊处理步骤
            this.controls.enableRotate = true
            this.clearSectionLine()
            if (no == 0) {
                //步骤1
                this.camera.position.y = 8000;
                this.camera.up = new THREE.Vector3(0, 0, 1);
                this.camera.lookAt({ x: 0, y: 1, z: 0 });
                this.camera.updateProjectionMatrix()
                this.drawStepOne()
            }
            if (no == 1) {
                //步骤2
                this.controls.enableRotate = false
                this.camera.position.x = 0;
                this.camera.position.z = 3000;
                this.camera.position.y = 0;
                this.controls.target = new THREE.Vector3(0, 0, 0)
                this.camera.zoom = 1
                this.camera.updateProjectionMatrix()
                this.drawStepTwo()
            }
            if (no == 2) {
                this.drawStepThree()
            }
            //展开
            if (no == 3) {
                this.extend()
            }
        }


    },
    mounted() {
        this.initTree()
        this.drawStepOne()
        this.animate()
        this.email = localStorage.getItem("email")
    },
    computed: {
        currentDate: function () {
            var date = new Date();
            return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
        },
        /**
      * 是否展示重量,预览图全部加载完成再展示
      */
        canShowWeight() {
            return this.priviewImgUrls.length == this.surfaceObjects.length
        },
        cutLength() {
            let total = 0
            for (let i in this.priviewImgUrls) {
                total += this.priviewImgUrls[i].length
                if (this.priviewImgUrls[i].sfno == this.showPreviewNo) {
                    return this.priviewImgUrls[i].length
                }
            }
            return total
        },
        cutWeight() {
            let total = 0
            for (let i in this.priviewImgUrls) {
                total += this.priviewImgUrls[i].weight
                if (this.priviewImgUrls[i].sfno == this.showPreviewNo) {
                    return this.priviewImgUrls[i].weight
                }
            }
            return total
        },
    }
}
</script>
<style scoped>
#preview,
#sufaceview {
    overflow: hidden;
    width: 100%;
    margin: 3px;
}

.custom-button {
    width: 26px;
    color: #fff;
    font-size: 10px;
    line-height: 18px;
    text-align: center;
    background-color: #3ea5b9;
    border-radius: 100px;
}

#footer {
    padding-top: 20px;
    min-height: 320px;
}

.van-row {
    padding-top: 20px;
}

.van-image {
    display: flex;
    align-items: center;
}

.van-slider {
    margin-top: 13px;
}

.contactUsBnt {
    text-align: center;
}

.contactUsBox /deep/.van-dialog__content {
    display: flex;
    justify-content: center;
}

#sectionPopBox /deep/.van-overlay {
    background: none
}

#sectionPopBox /deep/.van-dialog {
    top: 420px;
    background: #5bcdd3;
}

#sectionPopBox /deep/.van-button {
    background: #5bcdd3;
}

.selectBox {
    position: absolute;
    top: 70px;
    right: 20px;
    color: #1e0ea9;
    background: #aecbe9;
    border-radius: 5px;
    box-shadow: 10px 10px 5px #888888;
    width: 68px;
    text-align: left;
}

.resetCameraBnt {
    position: absolute;
    top: 240px;
    right: 20px;
}

.showtrack-label {
    height: 30px;
}

.showtrack-label /deep/ .van-checkbox__label {
    color: rgb(22, 143, 199);
    height: 20px;
}</style>
