<template>
    <div style="padding-top:32px;">
        打开中，请耐心等待...
    </div>
</template>
<script>
export default {

    methods: {
        randomString(len) {
            len = len || 32;
            var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
            var maxPos = $chars.length
            var pwd = ''
            for (var i = 0; i < len; i++) {
                pwd += $chars.charAt(Math.floor(Math.random() * maxPos))
            }
            return pwd;
        },
        generateAndEdit() {

            var vue = this
            let type = localStorage.getItem("appType", 0);
            if ( this.$route.params.type) {
                type = this.$route.params.type;
            }
            let appId = localStorage.getItem("editingAppid",-1)
            let bezierId = localStorage.getItem("editingBezierId",-1)
            
            if (bezierId > 0 && type > 0) {
                this.$router.push('/bezier/' + appId)
                return 
            } 
            if (appId > 0 && type == 0) {
                this.$router.push('/app/' + appId)
                return 
            } 
            
            var name = this.randomString(8)
            var misc = type
            this.$axios
            .post(vue.$url + '/v1/surface', {
                'name': name,
                'misc': misc,
            })
            .then(response => {
                if(response.data.code == 'ok') {
                  
                  localStorage.setItem("appType", type)
                  if (type == 1) {
                      localStorage.setItem("editingBezierId", response.data.data.id)
                      vue.$router.replace('/bezier/' + response.data.data.id)
                  } else {
                      localStorage.setItem("editingAppid", response.data.data.id)
                      vue.$router.replace('/app/' + response.data.data.id)
                  }
                  
            
                } else {
                vue.$dialog.error({
                    message: "添加失败"
                })
                }
                
            })
            .catch(function (error) { // 请求失败处理
                vue.$dialog.alert({
                message: error.response.data.message,
                })
                if (error.response.status == 401) {
                  vue.$router.push('/login')
                }
            })
                }
    },
    mounted () {
       this.generateAndEdit()
       
    }
}
</script>