<template>
    <div>
      <van-nav-bar 
      :title="navTitle" 
      left-text="" 
      left-arrow 
      @click-left="onClickLeft" />
        <div id="prviewArea">
      
           <div id="canvas3d"  height="320px" ></div>

        </div> 

        <div style="margin-top:30px;text-align:center;">
             <van-row>
          <van-col span="6"><span >本体直径</span></van-col>
          <van-col span="10">
            <van-slider
              v-model="diameter"
              active-color="#3ea5b9"
              :min="50"
              :max="800"
              :step="5"
              @input="updateGraphs"
            >
              <template #button>
                <div class="custom-button">{{ diameter }}</div>
              </template>
            </van-slider>
          </van-col>
          <van-col span="8"
            ><van-stepper v-model="diameter" :min="50" :max="800" :step="5" @input="updateGraphs"
          /></van-col>
        </van-row>
        <van-row>
          <van-col span="6"><span >对应角度</span></van-col>
          <van-col span="10">
            <van-slider
              v-model="theta"
              active-color="#3ea5b9"
              :min="45"
              :max="135"
              :step="5"
              @input="updateGraphs"
            >
              <template #button>
                <div class="custom-button">{{ theta }}</div>
              </template>
            </van-slider>
          </van-col>
          <van-col span="8"
            ><van-stepper v-model="theta" :min="45" :max="135" :step="5"
            @input="updateGraphs"
          /></van-col>
        </van-row>
        <van-row>
          <van-col span="6"><span >跑道宽度</span></van-col>
          <van-col span="10">
            <van-slider
              v-model="width0"
              active-color="#3ea5b9"
              :min="10"
              :max="50"
              :step="5"
              @input="updateGraphs"
            >
              <template #button>
                <div class="custom-button">{{ width0 }}</div>
              </template>
            </van-slider>
          </van-col>
          <van-col span="8"
            ><van-stepper v-model="width0" :min="10" :max="50" :step="5"
            @input="updateGraphs"
          /></van-col>
        </van-row>

        <van-row>
          <van-col span="6"><span >变径尺寸</span></van-col>
          <van-col span="10">
            <van-slider
              v-model="width"
              active-color="#3ea5b9"
              :min="10"
              :max="150"
              :step="5"
              @input="updateGraphs"
            >
              <template #button>
                <div class="custom-button">{{ width }}</div>
              </template>
            </van-slider>
          </van-col>
          <van-col span="8"
            ><van-stepper v-model="width" :min="10" :max="150" :step="5"
            @input="updateGraphs"
          /></van-col>
        </van-row>
        <van-row>
          <van-col span="6"><span >跑道倾角</span></van-col>
          <van-col span="10">
            <van-slider
              v-model="w_theta"
              active-color="#3ea5b9"
              :min="0"
              :max="30"
              :step="5"
              @input="updateGraphs"
            >
              <template #button>
                <div class="custom-button">{{ w_theta }}</div>
              </template>
            </van-slider>
          </van-col>
          <van-col span="8"
            ><van-stepper v-model="w_theta" :min="0" :max="30" :step="5"
            @input="updateGraphs"
          /></van-col>
        </van-row>
        <van-row >
          <van-col span="6"><span >下降角度</span></van-col>
          <van-col span="10">
            <van-slider
              v-model="decrease"
              active-color="#3ea5b9"
              :min="0"
              :max="50"
              :step="5"
              @input="updateGraphs"
            >
              <template #button>
                <div class="custom-button">{{ decrease }}</div>
              </template>
            </van-slider>
          </van-col>
          <van-col span="8"
            ><van-stepper v-model="decrease" :min="0" :max="50" :step="5"
            @input="updateGraphs"
          /></van-col>
        </van-row>
        <van-row>
         <van-radio-group v-model="thDirect"  direction="horizontal" @change="updateGraphs">
        <van-col span="6">方向</van-col>
       
        <van-col span="9">
            <van-radio name="-1">逆时针</van-radio>
        </van-col>
        <van-col span="9">
            <van-radio name="1">顺时针</van-radio>
        </van-col>
        </van-radio-group>
      </van-row>

        <van-row type="flex" justify="end">
          <van-col span="6">
            <van-button type="warning" size="small" @click="saveSurface"
            >保存</van-button
          >
          </van-col>
          <van-col span="6">
            <van-button type="info" size="small" @click="showDownload('dxf')"
              >下载2D图纸</van-button
            >
          </van-col>
          <van-col span="6">
            <van-button type="info" size="small" @click="showDownload('cnc')"
              >下载数控代码</van-button
            >
          </van-col>
        </van-row>
        </div>

        <van-popup v-model="showDownRename" closeable position="bottom" :style="{ height: '30%' }" @closed="closeDownRenameBox">
        <div style="padding-top:15%">

          <div>
            <van-field
              v-model="userDefineName"
              center
              label="请输入项目名称"
              maxlength=80
              label-width="8em"
              required
            >
              <template #button>
                <van-button size="small" type="primary" @click="download()"  >直接下载</van-button>
              </template>
            </van-field>
            <van-field
            v-model="email"
            center
            label="输入邮箱地址"
            maxlength=80
            label-width="8em"
            :error-message="emailErrmsg"
          >
            <template #button>
              <van-button size="small" type="primary" @click="sendMail()">邮箱发送</van-button>
            </template>
          </van-field>
          </div>

        </div>
     </van-popup>
     <SurfaceForm app_type="variety" :show="surface_new_show" v-on:done="add_surface_done" v-on:close="close_surface_form" ></SurfaceForm>
    </div>
</template>
<script>
import Vue from "vue";
import {
  Col,
  Row,
  Slider,
  Stepper,
  Image as VanImage,
  Button,
  Toast,
  Swipe,
  SwipeItem,
  Checkbox,
  CheckboxGroup,
  Popup,
  Field,
  RadioGroup,
  Radio,
  NavBar
} from "vant";
import * as THREE from "three";
import { ParametricGeometry } from "three/examples/jsm/geometries/ParametricGeometry";
//import { OrbitControls } from '../libs/js/OrbitControls'
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader";
import { DragControls } from "three/examples/jsm/controls/DragControls";
import { TransformControls } from "three/examples/jsm/controls/TransformControls";
import { RoomEnvironment } from "three/examples/jsm/environments/RoomEnvironment";
import VueCookies from "vue-cookies";
import { Base64 } from "js-base64";
import { Loading } from "vant";
import LoginBox from "@/libs/LoginBox";
import BrowserInfo from "@/libs/Browserinfo";
import Clipboard from "clipboard";
import Commons from '@/libs/Commons'
import SurfaceForm from '@/components/SurfaceForm'


Vue.use(NavBar);

Vue.use(Loading);
Vue.use(VueCookies);
Vue.use(Col);
Vue.use(Slider);
Vue.use(Row);
Vue.use(Stepper);
Vue.use(Button);
Vue.use(VanImage);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Popup);
Vue.use(Field);
Vue.use(Radio);
Vue.use(RadioGroup);

export default {
    components: {
        SurfaceForm,
    },
    data() {
        return {
          //对应角度
            theta: 90,
            //本体直径
            diameter: 300,
            //道宽
            width0: 15,
            //变径尺寸
            width: 50,
            scene: null,
            camera: null,
            renderer: null,
            controls: null,
            graphMeshs: [],
            sufaceLines: [],
            showDownRename: false,
            email:"",
            userDefineName:'默认',
            downType: '',
            //方向
            thDirect: '-1' ,
            showContactUs: false,
            //邮箱提示
            emailErrmsg: '',
            //跑道倾角
            w_theta:0,
            //下降距离
            decrease: 0,

            surface_new_show: false,

            navTitle: "变径-新增",


        }
    },
    methods: {
        resetCameraView() {
            this.camera.position.x = 0;
            this.camera.position.z = 7000;
            this.camera.position.y = 0;
            this.controls.target = new THREE.Vector3(0,0,0)
            this.camera.zoom = 1
            this.camera.updateProjectionMatrix()
        },
        updateGraphs() {
            if (this.scene != null) {
                this.drawGraphs()
            }
        },
        drawGraphs() {
            while(this.graphMeshs.length > 0) {
                var mesh = this.graphMeshs.pop()
                this.scene.remove(mesh)
            }
            let DIR = this.thDirect == '1' ? 1 : -1 
            let R = this.diameter / 2
            let w = this.width
            let w_th = this.w_theta/180*Math.PI;
            let w0 = this.width0*Math.cos(w_th)
            let dec = this.decrease / 180 * Math.PI
            let t = DIR * this.theta/180*Math.PI
            let X1 = R;
            let Y1 = 0;
            let Xa = (R+w);
            let Ya = 0;
            let X2 = (R-w0)*Math.cos(t);
            let Y2 = (R-w0)*Math.sin(t);
            let Xb = R*Math.cos(t);
            let Yb = R*Math.sin(t);
            let C1x = (X2**2+Y2**2-X1**2-Y1**2)/(2*(X2-X1));
            let C1y = 0;
            let Cax = (Xb**2+Yb**2-Xa**2-Ya**2)/(2*(Xb-Xa));
            let Cay = 0;
            let D_X1 = X2-C1x;
            let D_Y1 = Y2-C1y;
            let D_Xa = Xb-Cax;
            let D_Ya = Yb-Cay;
            let t1,ta;
            let La = (R+w)*(4/3)*((1-Math.cos(t/2))/(Math.sin(t/2)));
            let Lb = (R)*(4/3)*((1-Math.cos(t/2))/(Math.sin(t/2)));
            let L1 = (R)*(4/3)*((1-Math.cos(t/2))/(Math.sin(t/2)));
            let L2 = (R-w0)*(4/3)*((1-Math.cos(t/2))/(Math.sin(t/2)));
            let Bxa = R+w;
            let Bya = La;
            let Bxb = Lb * Math.cos(t-Math.PI/2)+Xb;
            let Byb = Lb * Math.sin(t-Math.PI/2)+Yb;
            let Bx1 = R;
            let By1 = L1;
            let Bx2 = L2 * Math.cos(t-Math.PI/2)+X2;
            let By2 = L2 * Math.sin(t-Math.PI/2)+Y2;
            if (D_X1==0)
                {t1=Math.PI/2;}
            else if (D_X1>0)
                {t1=Math.atan(D_Y1/D_X1);}
            else 
                {t1=Math.atan(D_Y1/D_X1)-Math.PI;}
            if (D_Xa==0)
                {ta=Math.PI/2;}
            else if (D_Xa>0)
                {ta=Math.atan(D_Ya/D_Xa);}
            else
                {ta=Math.atan(D_Ya/D_Xa)-Math.PI;}
            let n = 400
            
            var t_solve = new Array();
            var t1_solve = new Array();
            var t2_solve = new Array();
            var pointsX_circle = new Array();
            var pointsY_circle = new Array();
            var L_1C = new Array();
            var L_2C = new Array();
            var theta_1 = new Array();
            let points1
            let points2
            //本体圆弧
            const curve = new THREE.EllipseCurve(
            0,  0,           
            R, R,           
            0,  2 * Math.PI,  
            false,            
            0                 
            );
            const points = curve.getPoints( 50 );
            const geometry = new THREE.BufferGeometry().setFromPoints( points );
            const material = new THREE.LineBasicMaterial( { color: 0x555555 } );
            const ellipse = new THREE.Line( geometry, material );
            this.scene.add(ellipse);
            this.graphMeshs.push(ellipse)
            //跑道圆弧
            
            const curve_paodao = new THREE.EllipseCurve(
                0,  0,           
                R-w0, R-w0,         
                (0-DIR) * Math.PI/2, t,  
                DIR == 1,           
                0                 
            );
            const points_paodao = curve_paodao.getPoints( n );
            const geometry_paodao = new THREE.BufferGeometry().setFromPoints( points_paodao );
            const material_paodao = new THREE.LineBasicMaterial( { color: 0x555555 } );
            const ellipse_paodao = new THREE.Line( geometry_paodao, material_paodao );
            this.scene.add(ellipse_paodao);
            this.graphMeshs.push(ellipse_paodao)
            let linePs = []
            let lineTwoPs = []
            this.sufaceLines = []
              /**
               * 贝塞尔曲线
               */
              //第一条变径圆弧
              const bezierCurve = new THREE.CubicBezierCurve3(
                new THREE.Vector3( Xa, Ya, 0 ),
                new THREE.Vector3( Bxa, Bya, 0  ),
                new THREE.Vector3( Bxb, Byb, 0 ),
                new THREE.Vector3( Xb, Yb , 0 )
              )
              const bezierPoints = bezierCurve.getSpacedPoints( n );
              const geometry3 = new THREE.BufferGeometry().setFromPoints( bezierPoints );

              const material3 = new THREE.LineBasicMaterial( { color: 0x0000ff } );
              const curveObject = new THREE.Line( geometry3, material3 )
              this.scene.add(curveObject);
              this.graphMeshs.push(curveObject)

              //第二条变径圆弧
              const bezierCurve2 = new THREE.CubicBezierCurve3(
                new THREE.Vector3( X1, Y1 , 0 ),
                new THREE.Vector3( Bx1, By1 , 0 ),
                new THREE.Vector3( Bx2, By2, 0 ),
                new THREE.Vector3( X2, Y2 , 0 )
              )
              const bezierPoints2 = bezierCurve2.getSpacedPoints( n );
              const geometry4 = new THREE.BufferGeometry().setFromPoints( bezierPoints2 );

              const material4 = new THREE.LineBasicMaterial( { color: 0x0000ff } );
              const curveObject2 = new THREE.Line( geometry4, material4 )
              this.scene.add(curveObject2);
              this.graphMeshs.push(curveObject2)


              points1 = bezierPoints2
              points2 = bezierPoints

            //z坐标
           

            for (i=0;i<(n+1);i++){
            t1_solve[i] = ((-(2*points1[i].x*points2[i].x-2*points2[i].x**2+2*points1[i].y*points2[i].y-2*points2[i].y**2)+Math.sqrt((2*points1[i].x*points2[i].x-2*points2[i].x**2+2*points1[i].y*points2[i].y-2*points2[i].y**2)**2-4*(points1[i].x**2-2*points1[i].x*points2[i].x+points2[i].x**2+points1[i].y**2-2*points1[i].y*points2[i].y+points2[i].y**2)*(points2[i].x**2+points2[i].y**2-R**2)))/(2*(points1[i].x**2-2*points1[i].x*points2[i].x+points2[i].x**2+points1[i].y**2-2*points1[i].y*points2[i].y+points2[i].y**2)));
            t2_solve[i] = ((-(2*points1[i].x*points2[i].x-2*points2[i].x**2+2*points1[i].y*points2[i].y-2*points2[i].y**2)-Math.sqrt((2*points1[i].x*points2[i].x-2*points2[i].x**2+2*points1[i].y*points2[i].y-2*points2[i].y**2)**2-4*(points1[i].x**2-2*points1[i].x*points2[i].x+points2[i].x**2+points1[i].y**2-2*points1[i].y*points2[i].y+points2[i].y**2)*(points2[i].x**2+points2[i].y**2-R**2)))/(2*(points1[i].x**2-2*points1[i].x*points2[i].x+points2[i].x**2+points1[i].y**2-2*points1[i].y*points2[i].y+points2[i].y**2)));
            
            if (t2_solve[i]>=-0.1 && t2_solve[i]<=1.1)
              t_solve[i] = t2_solve[i];
            else 
              t_solve[i] = t1_solve[i];
            pointsX_circle[i] =points1[i].x*t_solve[i]+points2[i].x*(1-t_solve[i]);
            pointsY_circle[i] =points1[i].y*t_solve[i]+points2[i].y*(1-t_solve[i]);

            L_1C[i] = Math.sqrt((points1[i].x-pointsX_circle[i])**2+(points1[i].y-pointsY_circle[i])**2);
            L_2C[i] = Math.sqrt((points2[i].x-pointsX_circle[i])**2+(points2[i].y-pointsY_circle[i])**2);
            //theta_1[i] = Math.atan(dec/w)+((w_th-Math.atan(dec/w))*(i/n));
            //theta_1[i] = w_th;
            theta_1[i] = dec + ((w_th-dec)*(i/n));
            points1[i].z = L_1C[i]*Math.tan(theta_1[i]);
            points2[i].z = -L_2C[i]*Math.tan(theta_1[i]);
          }
          for (let i=points1.length - 1; i > 0; i--) {
              linePs.push([points1[i].x, points1[i].y, points1[i].z])
          }
          for (let i=points2.length - 1; i > 0; i--) {
              lineTwoPs.push([points2[i].x, points2[i].y, points2[i].z])
          }
           this.sufaceLines.push(linePs)
           this.sufaceLines.push(lineTwoPs)
            let surface_geometry = new THREE.BufferGeometry();
            var vertices = new Float32Array(18*n);
            for (var i=0; i<n; i++){
              vertices[i*18]=points1[i].x;
              vertices[i*18+1]=points1[i].y;
              vertices[i*18+2]=points1[i].z;

              vertices[i*18+3]=points1[i+1].x;
              vertices[i*18+4]=points1[i+1].y;
              vertices[i*18+5]=points1[i+1].z;

              vertices[i*18+6]=points2[i+1].x;
              vertices[i*18+7]=points2[i+1].y;
              vertices[i*18+8]=points2[i+1].z;

              vertices[i*18+9]=points2[i+1].x;
              vertices[i*18+10]=points2[i+1].y;
              vertices[i*18+11]=points2[i+1].z;

              vertices[i*18+12]=points2[i].x;
              vertices[i*18+13]=points2[i].y;
              vertices[i*18+14]=points2[i].z;

              vertices[i*18+15]=points1[i].x;
              vertices[i*18+16]=points1[i].y;
              vertices[i*18+17]=points1[i].z;}
              
            surface_geometry.setAttribute( 'position', new THREE.BufferAttribute( vertices, 3 ) );
            surface_geometry.computeVertexNormals();
            let surface_material = new THREE.MeshPhongMaterial({color: 0xff00ff, side:THREE.DoubleSide,emissive:0x000000,specular:0xffffff, shininess: 10,wireframe: false,transparent: true,opacity: 1});
            let surfaceObject = new THREE.Mesh( surface_geometry, surface_material );
            this.scene.add(surfaceObject)
            this.graphMeshs.push(surfaceObject)
            


            let color =  0x0000ff;
            //对应角度
            const points_line = [];
            points_line.push( new THREE.Vector3( R/2, 0, 0 ) );
            points_line.push( new THREE.Vector3( 0, 0, 0 ) );
            points_line.push( new THREE.Vector3( R/2*Math.cos(t), R/2*Math.sin(t), 0 ) );
            const material_line = new THREE.LineBasicMaterial( { color: 0x555555 } );
            const geometry_line = new THREE.BufferGeometry().setFromPoints( points_line );
            const line = new THREE.Line( geometry_line, material_line );
            this.scene.add(line);
            this.graphMeshs.push(line)
            //变径端点线段
            const points_line1 = [];
            points_line1.push( new THREE.Vector3( X1, Y1, 0 ) );
            points_line1.push( new THREE.Vector3( Xa, Ya, 0 ) );
            const material_line1 = new THREE.LineBasicMaterial( { color: color } );
            const geometry_line1 = new THREE.BufferGeometry().setFromPoints( points_line1 );
            const line1 = new THREE.Line( geometry_line1, material_line1 );
            this.scene.add(line1);
            this.graphMeshs.push(line1)
            //变径端点线段
            const points_line2 = [];
            points_line2.push( new THREE.Vector3( X2, Y2, 0 ) );
            points_line2.push( new THREE.Vector3( Xb, Yb, 0 ) );
            const material_line2 = new THREE.LineBasicMaterial( { color: color } );
            const geometry_line2 = new THREE.BufferGeometry().setFromPoints( points_line2 );
            const line2 = new THREE.Line( geometry_line2, material_line2 );
            this.scene.add(line2);
            this.graphMeshs.push(line2)


            
            
        },
        initTree() {
            this.scene = new THREE.Scene();
            let width = window.innerWidth // 窗口宽度
            let height = 320
            //this.camera = new THREE.OrthographicCamera( window.innerWidth/-2, window.innerWidth/2, window.innerHeight/2, window.innerHeight/-2, 1, 10000 );
			this.camera = new THREE.OrthographicCamera( width / - 2, width / 2, height / 2, height / - 2,  1, 10000 )
            this.camera.position.z = 7000;
            this.camera.up = new THREE.Vector3(0,0,1);
            this.camera.lookAt({x:0,y:1,z:0});

			const ambientLight = new THREE.AmbientLight(0x666666);
			this.scene.add(ambientLight);

			const DirLight = new THREE.DirectionalLight(0xffffff,1);
			this.scene.add(DirLight);
	

			this.renderer = new THREE.WebGLRenderer();
			this.renderer.setSize( width, height);
			this.renderer.setClearColor(0xaaaaaa);

			document.getElementById('canvas3d').appendChild(this.renderer.domElement) // body元素中插入canvas对象

			this.controls = new OrbitControls( this.camera, this.renderer.domElement );
			//const controls = new THREE.TrackballControls( camera, renderer.domElement );
        //    this.controls.enableRotate  = false
      this.controls.mouseButtons = {
        LEFT: THREE.MOUSE.ROTATE,
        MIDDLE: THREE.MOUSE.DOLLY,
        RIGHT: THREE.MOUSE.PAN
        }

        const axesHelper = new THREE.AxesHelper( 30 );
        this.scene.add( axesHelper );
        },
        animate() {
			requestAnimationFrame( this.animate );
			this.renderer.render( this.scene, this.camera );
			this.controls.update();
		},
    showContactUsBox() {
      
      //let box = new LoginBox()
      this.showContactUs = true
    },
     download() {
          console.log("download ", this.downType)
          if ('cnc' == this.downType) {
              this.downLoadCnc('direct')
          } else {
              this.downLoadDxf('direct')
          }
      },
            //邮箱发送
    sendMail() {
      let vue = this

      if (vue.userDefineName == "") {
        console.log("project name is empty ,not allowed download")
        vue.downloadErrMsg = "请输入项目名称"
        return ;
      }
      const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
      if (vue.email == "" || !regEmail.test(vue.email)) {
        vue.emailErrmsg = "请输入正确的邮箱地址"
        return ;
      }
      localStorage.setItem("email", vue.email);
      vue.emailErrmsg = ""
      vue.downloadErrMsg = ""
      if ('cnc' == this.downType) {
          this.downLoadCnc('email')
      } else {
          this.downLoadDxf('email')
      }
      vue.$trackEvent("email", this.downType, vue.email, vue.userDefineName)
    },
    sendMailFile(url, data) {
      let vue = this
      data.email = vue.email;
      vue.$axios.post(url,data).then((res) => {
          Toast.success({message: res.data.message, onClose: ()=>{vue.showDownRename = false}})
        
      }, (e) => {
         console.log(e)
      });
    },
     downloadFile(url,  data) {
        var vue = this
        vue.$axios.post(url,data).then((res) => {
            console.log(res)
            if (window.navigator.msSaveBlob) {
            //IE以及IE内核的浏览器
            try {
                window.navigator.msSaveBlob(res.data, data.name); //response为接口返回数据，这里请求的时候已经处理了，如果没处理需要在此之前自行处理var data = new Blob([response.data]) 注意这里需要是数组形式的,fileNm就是下载之后的文件名
                // window.navigator.msSaveOrOpenBlob(response, fileNm);	//此方法类似上面的方法，区别可自行百度
            } catch (e) {console.log("down error", e)}
            } else {
            //在headers中截取filename
            var filename = data.name;
            var blob = new Blob([res.data], {type: "application/octet-stream"})
            var downloadElement = document.createElement('a');
            var href = window.URL.createObjectURL(blob); //创建下载的链接
            downloadElement.href = href;
            downloadElement.download = filename 
            //此处也可以将filename写死 "filename.xlsx"
            document.body.appendChild(downloadElement);
            downloadElement.click(); //点击下载
            document.body.removeChild(downloadElement); //下载完成移除元素
            window.URL.revokeObjectURL(href); //释放掉blob对象 
            }
        });
        }, 
        /**
         * 下载相关
         */
        showDownload(type) {
            var vue = this
            if (!Commons.isLogin()) {
              //没有登录
              this.$dialog.confirm({title:'该操作需要先登陆', message: "是否前往登录?"})
                  .then(()=> {
                    Commons.goLogin(vue)
                    console.log('go login')
                  })

              return ;
            }
            this.downType = type
            this.showDownRename = true
            
        },
        closeDownRenameBox() {
        this.showDownRename = false
        },
        downLoadDxf(type) {
        var url = '';
        url = this.$url + '/v2/surface/dxf'
        var vue = this
        var param = {
            "line1": vue.sufaceLines[0],
            "line2": vue.sufaceLines[1],

        };
        let data = {
            "name": this.currentDate + "_"+ this.userDefineName + ".dxf",
            "params":param
        }
        if ('email' == type) {
           this.sendMailFile(url, data);
        } else {
          this.downloadFile(url, data);
        }
        },
        downLoadCnc(type) {
        var url = '';
        url = this.$url + '/v2/surface/cnc'
        var vue = this
        var param = {
            "line1": vue.sufaceLines[0],
            "line2": vue.sufaceLines[1],

        };
        let data = {
            "name": this.currentDate + "_"+ this.userDefineName + ".cnc",
            "params":param
        }
        if ('email' == type) {
           this.sendMailFile(url, data);
        } else {
          this.downloadFile(url, data);
        }
        
        },
        saveSurface() {
          var vue = this
          if (!Commons.isLogin()) {
            //没有登录
            this.$dialog.confirm({title:'该操作需要先登陆', message: "是否前往登录?"})
                .then(()=> {
                  Commons.goLogin(vue)
                  console.log('go login')
                })

            return ;
          }
            if (this.id == 'new') {
               this.surface_new_show = true
            } else {
              //保存
              this.saveSurfaceParam(this.id)
            }
        },
        async add_surface_done(n_id) {
          this.id = n_id      
          this.saveSurfaceParam(n_id)
          this.surface_new_show = false
       },
        close_surface_form() {
          this.surface_new_show = false
        },
        async getSurfaceParam(id) {
            if (id == 'new') {
              return 
            }
            let resp = await Commons.getSurface(this, id)
            let params = Commons.getAppParams(this, 'variety', id, resp)
            if (params ) {
                this.navTitle = '变径-【' + resp.name+"】"
                for (var i in params[0]) {
                  console.log('setting', i, params[0][i])
                  this.$set(this, i, params[0][i])
                }
            }
            
        },
      async saveSurfaceParam(id) {
            //调用保存
            let params = {
              "params": [{
                //对应角度
                theta: this.theta ,
                //本体直径
                diameter: this.diameter,
                //道宽
                width0: this. width0,
                //变径尺寸
                width: this.width,
                thDirect: this.thDirect,
                //跑道倾角
                w_theta: this.w_theta,
                //下降距离
                decrease: this.decrease
              }]
          }

            let resp = await Commons.updateSurface(this, id, params)
            if (resp.code =='ok') {
               Toast.success("保存成功")
            }
        },
        onClickLeft() {
          this.$router.back()
        }
    },
    mounted() {
        this.id = this.$route.params.id
        this.getSurfaceParam(this.id)
        this.initTree()
        this.updateGraphs()
        this.animate()
        this.email = localStorage.getItem("email")
        
    },
    computed:  {
        currentDate: function() {
        var date = new Date();
        return date.getFullYear() + "-" + (date.getMonth()  + 1 )+ "-" + date.getDate()
      }
    }
}
</script>

<style scoped>
#prviewArea {
  height: 300px;
  width: 100%;
}
.custom-button {
  width: 26px;
  color: #fff;
  font-size: 10px;
  line-height: 18px;
  text-align: center;
  background-color: #3ea5b9;
  border-radius: 100px;
}
#footer {
  padding-top: 20px;
  min-height: 320px;
}
.van-row {
  padding-top: 20px;
}
.van-image {
  display: flex;
  align-items: center;
}
.van-slider {
  margin-top: 13px;
}
.resetCameraBnt {
    position: absolute;
    top: 260px;
    right: 20px;
}
.showtrack-label {
    height: 30px;
}
.showtrack-label /deep/ .van-checkbox__label {
    color: rgb(22, 143, 199);
    height: 20px;
}
.contactUsBnt {
  text-align: center;
}
.contactUsBox /deep/.van-dialog__content {
    display: flex;
    justify-content: center;
  }
</style>
