import Vue from 'vue'
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
export default {
    isLogin: function() {
       console.log("check is login")
       var ct0 = VueCookies.get("id") //sessionID
       var st =  VueCookies.get("_st")
       return ct0 != undefined && st != undefined;
    }
}