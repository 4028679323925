<template>
    <div id="app">
        <router-view v-if="isPhone"/>
        <div v-if="!isPhone" style="margin-top:80px;text-align:center"> 
          
          <div>请使用手机浏览器访问</div>
          <div><img src="/qrcode.png" style="width:240px;"></div>
        </div>
    </div>
</template>

<script>
import BrowserInfo from '@/libs/Browserinfo';
export default {
    name: 'App',
    data() {
      return {
         isPhone : BrowserInfo.isPhone
      }
    },
    computed: {
      url() {
        return 'https://www.baidu.com'
      }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
#footer {
  text-align: center;
}

</style>