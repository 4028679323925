/* eslint-disable vue/valid-v-model */
/* eslint-disable vue/valid-template-root */
/* eslint-disable no-redeclare */
/* eslint-disable camelcase */
/* eslint-disable eqeqeq */
/* eslint-disable computed-property-spacing */
/* eslint-disable spaced-comment */
/* eslint-disable wrap-iife */
/* eslint-disable padded-blocks */
/* eslint-disable space-before-function-paren */
/* eslint-disable operator-linebreak */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable space-unary-ops */
/* eslint-disable space-in-parens */
/* eslint-disable semi */
/* eslint-disable no-tabs */
/* eslint-disable indent */
/* eslint-disable no-unused-vars */
/* eslint-disable space-infix-ops */
/* eslint-disable vue/valid-template-root */
<template id="appSurface">
  <div>
    <van-nav-bar 
      :title="navTitle" 
      left-text="" 
      left-arrow 
      @click-left="onClickLeft" />
    <div id="preview" v-show="preview">
      
    </div>
    <!-- 第三页的 选择图形-->
    <div class="selectBox" v-show="frame[2]">
          <div :key="index" v-for="(item, index) in sufaceMetas">
            <input type="checkbox" :checked="item.selected" @click="onShowCheckBoxClick(item)">{{ item.sfno }} 
           </div>
           <div :key="index + sufaceMetas.length" v-for="(item, index) in sufaceMetasB">
            <input type="checkbox" :checked="item.selected" @click="onShowCheckBoxClick(item)">{{ item.sfno }} 
           </div>
    </div>
    <!-- 展开页，通过radio 选择-->
    <div class="selectBox" v-show="frame[3]"> 
        <input type="radio" name="spriview" value="-1" v-model="showPreviewNo"  @click="onShowRadioBoxClick()">全部
        <div :key="index" v-for="(item, index) in selectedSufafesA">
            <input type="radio" name="spriview" :value="item.sfno" v-model="showPreviewNo" @click="onShowRadioBoxClick(item)">{{ item.sfno }} 
           </div>
        <div :key="index + sufaceMetas.length" v-for="(item, index) in selectedSufafesB">
          <input type="radio" name="spriview" :value="item.sfno" v-model="showPreviewNo" @click="onShowRadioBoxClick(item)">{{ item.sfno }} 
        </div>

    </div>
    <div v-show="frame[1] || frame[2] || frame[3]" class="resetCameraBnt">
      <div class="showtrack-label"><van-checkbox v-model="showTrack"  v-show="frame[1]" @click="updateGraphicMesh()">显示轨迹</van-checkbox> </div>
      <van-button round hairline type="info" @click="resetCameraView">重设视角</van-button>
      </div>
    <div id="sufaceview" v-show="!preview">
      <canvas id="canvas" width="100%" height="300"></canvas>
    </div>
    <div id="footer">
       <div id="step1" v-show="frame[0]">
      <van-row>
        <van-col span="6">半径</van-col>
        <van-col span="10">
          <van-slider v-model="radio" active-color="#3ea5b9" :min=50 :max="maxRadio" :step=5 @input="updateGraphicMesh">
          <template #button>
            <div class="custom-button">{{ radio }}</div>
          </template>
        </van-slider>
        </van-col>
        <van-col span="8"><van-stepper v-model="radio" :min=50 :max=600 :step=5 @input="updateGraphicMesh"/></van-col>
      </van-row>
      <van-row>
         <van-radio-group v-model="thDirect"  direction="horizontal" @change="onDirectChange">
        <van-col span="6">方向</van-col>
       
        <van-col span="9">
            <van-radio name="0">逆时针</van-radio>
        </van-col>
        <van-col span="9">
            <van-radio name="1">顺时针</van-radio>
        </van-col>
        </van-radio-group>
      </van-row>
      <van-row>
        <van-col span="6">圆心角</van-col>
        <van-col span="10">
          <van-slider v-model="thetaShow" active-color="#3ea5b9" :min=30 :max=360 :step=5 @input="updateGraphicMesh">
          <template #button>
            <div class="custom-button">{{ thetaShow }}</div>
          </template>
        </van-slider>
        </van-col>
        <van-col span="8"><van-stepper v-model="thetaShow" :min=30 :max=360 :step=5 @input="updateGraphicMesh"/></van-col>
      </van-row>
      <van-row>
        <van-col span="6">轨迹长度</van-col>
        <van-col span="10">
          
        </van-col>
        <van-col span="8">
          {{ trackLength }}
        </van-col>
      </van-row>
      <van-row>
        <van-col span="6">落差</van-col>
        <van-col span="10">
          <van-slider v-model="leftheight" active-color="#3ea5b9" :min=0 :max=80 :step=5 @input="updateGraphicMesh">
          <template #button>
            <div class="custom-button">{{ leftheight }}</div>
          </template>
        </van-slider>
        </van-col>
        <van-col span="8"><van-stepper v-model="leftheight" :min=0 :max=80 :step=5 @input="updateGraphicMesh"/></van-col>
      </van-row>
      
      <van-row type="flex" justify="end">
        
        <van-col span="6"><van-button type="info" size="small" @click="next">下一步</van-button></van-col>
     </van-row>  
    </div>
    <div id="step2" v-show="frame[1]">
    <div>确认截面:</div>
    <van-row align="center">
      <van-col span="2"></van-col>
      <van-col span="18" style="text-align: left;">
        <van-button plain type="info"   :style="{'margin': '0 2px', 'border':'none','background':  str_pad(item.color.toString(16))}" :key="index" v-for="(item, index) in sufaceMetas" @click="showSectionInfoByIdx(index, 1)"> </van-button> 
        <van-icon name="delete-o" v-show="sufaceMetas.length > 0" size="24" @click="removeLastSection(1)" />
      </van-col>
      <van-col span="4"> <van-icon name="add-o" size="34" @click="showAddSectionBox(1)"/></van-col>
    </van-row>
    <van-row>
      <van-col span="2"></van-col>
      <van-col span="18" style="text-align: left;">
        <van-button plain type="info" :style="{'margin': '0 2px', 'border':'none',  'background':  str_pad(item.color.toString(16))}" :key="index" v-for="(item, index) in sufaceMetasB" @click="showSectionInfoByIdx(index, 2)"></van-button> 
        <van-icon name="delete-o" v-show="sufaceMetasB.length > 0" size="24" @click="removeLastSection(2)" />
      </van-col>
      <van-col span="4"> <van-icon name="add-o" size="34" @click="showAddSectionBox(2)"/></van-col>
    </van-row>
    <van-row v-show="false">
      <van-col span="6">起始角度</van-col>
      <van-col span="10">
        <van-slider v-model="startAngle" active-color="#3ea5b9" :min=-180 :max=180 :step=5 @input="updateGraphicMesh">
        <template #button>
          <div class="custom-button">{{ startAngle }}</div>
        </template>
      </van-slider>
      </van-col>
      <van-col span="8"><van-stepper v-model="startAngle" :min=-180 :max=180 :step=5 @input="updateGraphicMesh"/></van-col>
    </van-row>
    <van-row type="flex" justify="end">
      <van-col span="4">
          <van-button type="info" size="small" @click="prev">上一步</van-button>
        </van-col>
        <van-col span="4">
          <van-button type="info" size="small" @click="next">下一步</van-button>
        </van-col>
     </van-row>  
    </div>
    <div id="step3" v-show="frame[2]">

  <van-row>
      <van-col span="6">材料厚度</van-col>
      <van-col span="10">
        <van-slider v-model="thickness" active-color="#3ea5b9" :min=1 :max=3.5 :step=0.1 @input="updateGraphicMesh">
        <template #button>
          <div class="custom-button">{{ thickness }}</div>
        </template>
      </van-slider>
      </van-col>
      <van-col span="8"><van-stepper v-model="thickness" :min=1 :max=3.5  :step=0.1 @input="updateGraphicMesh"/></van-col>
    </van-row>
    
    <van-row>
      <van-col span="6">扭转角度</van-col>
      <van-col span="10">
        <van-slider v-model="twistAngle" active-color="#3ea5b9"  :min=-180 :max=180 :step=5 @input="updateGraphicMesh">
        <template #button>
          <div class="custom-button">{{ twistAngle }}</div>
        </template>
      </van-slider>
      </van-col>
      <van-col span="8"><van-stepper v-model="twistAngle" :min=-180 :max=180 :step=5 @input="updateGraphicMesh"/></van-col>
    </van-row>
    <van-row v-show="false">
      <van-col span="6">终止角度</van-col>
      <van-col span="10">
      </van-col>
      <van-col span="8"><div >{{ endAngle}}</div></van-col>
    </van-row>
    <van-row type="flex" justify="end">
      <van-col span="4">
          <van-button type="info" size="small" @click="prev">上一步</van-button>
        </van-col>
        <van-col span="4"><van-button type="warning" size="small" @click="saveSurface">保存</van-button></van-col>
        <van-col span="4">
          <van-button type="info" size="small" @click="next">展开</van-button>
        </van-col>
     </van-row>  
    </div>
    <div id="step4" v-show="frame[3]">
      <div id="expandArea">
        <div :key="index" v-for="(item, index) in this.priviewImgUrls" >
          <van-image :src="item.url" alt="加载中.." height="180px" fit="contain" v-show="item.sfno == showPreviewNo">
          <template v-slot:loading>图像加载中...</template>
          <template v-slot:error>图像加载中...</template>
        </van-image>
        </div>
      </div>
      <van-row v-show="!canShowWeight">
        <van-col span="24">图形展开中，请稍候</van-col>
      </van-row>
      <van-row v-show="canShowWeight">
          <van-col span="24">
               
               <div v-show="showPreviewNo == '-1'">本次展开 {{priviewImgUrls.length}} 个部件<br>合计切割长度 {{cutLength.toFixed(2)}}米，重量{{cutWeight.toFixed(2)}}公斤</div>
               <div v-show="showPreviewNo != '-1'">

                 切割长度{{cutLength.toFixed(2)}}米，重量{{cutWeight.toFixed(2)}}公斤
               </div>
          </van-col>

      </van-row>
      <van-row type="flex" justify="end">
      <van-col span="3">
          <van-button type="info" size="small" @click="prev">返回</van-button>
        </van-col>
        <van-col span="6" v-show="showPreviewNo != '-1'"> 
          
          <van-button type="primary" v-if="needCopyAndDown" class="downloadBnt" size="small"  v-bind:data-clipboard-text="exportDxf" tag="a" >下载DXF文件</van-button>
          <van-button type="primary" v-else  size="small" @click="showDownload('dxf')"  >下载DXF文件</van-button>
        </van-col>
        <van-col span="6" v-show="showPreviewNo != '-1'">
          <van-button type="primary" v-if="needCopyAndDown" class="downloadBnt" size="small"  v-bind:data-clipboard-text="exportCnc" tag="a" >下载加工代码</van-button>
          <van-button type="primary" v-else size="small" @click="showDownload('cnc')" >下载加工代码</van-button>
        </van-col>
     </van-row>  

    </div>

    </div>
      <div class="contactUsBnt" id="contactUsBnt" v-show="false" >
      <div style="font-size: 1.2rem;color: #439ae5;"> 更多功能开发中，即将发布</div>
      <van-button  @click="showContactUsBox" style="border: none;">需要帮助？ 请点击这里</van-button>
      </div>
      <van-dialog v-model="showContactUs" class="contactUsBox" title="扫一扫，加我微信" >
          <van-image
            width="240"
            height="240"
            src="/contact2.jpg" ></van-image>
     </van-dialog>
     <div  id="sectionPopBox">
     <van-dialog v-model="showAddSection" title="截面参数" show-cancel-button :show-confirm-button="true"  :before-close="addSectionClose">
      <div id="sectionParamArea">
      <van-row>
      <van-col span="6">板材宽度</van-col>
      <van-col span="10">
        <van-slider v-model="sectionTmpLength" active-color="#3ea5b9"  :min=5 :max=200 @input="updateSectionDraw()">
        <template #button>
          <div class="custom-button">{{ sectionTmpLength }}</div>
        </template>
      </van-slider>
      </van-col>
      <van-col span="8"><van-stepper v-model="sectionTmpLength" :min=5 :max=200  @input="updateSectionDraw()" /></van-col>
    </van-row>
       <van-row>
      <van-col span="6">角度</van-col>
      <van-col span="10">
        <van-slider v-model="sectionTmpAngle" active-color="#3ea5b9"  :min=-180 :max=180 :step=5 @input="updateSectionDraw()">
        <template #button>
          <div class="custom-button">{{ sectionTmpAngle }}</div>
        </template>
      </van-slider>
      </van-col>
      <van-col span="8"><van-stepper v-model="sectionTmpAngle" :min=-180 :max=180 :step=5 @input="updateSectionDraw()" /></van-col>
    </van-row>
        </div>
    </van-dialog>
     </div>
     <van-popup v-model="showDownRename" closeable position="bottom" :style="{ height: '30%' }" @closed="closeDownRenameBox">
        <div style="padding-top:15%">

          <div>
            <van-field
              v-model="userDefineName"
              center
              label="请输入项目名称"
              maxlength=80
              label-width="8em"
              required
              :error-message="downloadErrMsg"
            >
              <template #button>
                <van-button size="small" type="primary" @click="openAndDown()" >直接下载</van-button>
              </template>
            </van-field>
            <van-field
              v-model="email"
              center
              label="输入邮箱地址"
              maxlength=80
              label-width="8em"
              :error-message="emailErrmsg"
            >
              <template #button>
                <van-button size="small" type="primary" @click="sendMail()">邮箱发送</van-button>
              </template>
            </van-field>
          </div>

        </div>
     </van-popup>
     <SurfaceForm app_type="app" :show="surface_new_show" v-on:done="add_surface_done" v-on:close="close_surface_form" ></SurfaceForm>
    </div>
</template>
<script>
import Vue from 'vue'
import { Col, Row, Slider, Overlay,Stepper, Image as VanImage, Button,Toast,Swipe, SwipeItem,Checkbox, CheckboxGroup,Popup ,Field,RadioGroup, Radio } from 'vant'
import * as THREE from 'three'
import { ParametricGeometry } from 'three/examples/jsm/geometries/ParametricGeometry'
//import { OrbitControls } from '../libs/js/OrbitControls'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader'
import { DragControls } from 'three/examples/jsm/controls/DragControls'
import { TransformControls } from 'three/examples/jsm/controls/TransformControls'
import { RoomEnvironment } from 'three/examples/jsm/environments/RoomEnvironment';
import VueCookies from 'vue-cookies'
import { Base64 } from 'js-base64';
import { Loading } from 'vant';
import LoginBox from '@/libs/LoginBox';
import BrowserInfo from '@/libs/Browserinfo';
import Clipboard from 'clipboard'
import { verify } from 'crypto'
import Commons from '@/libs/Commons'
import SurfaceForm from '@/components/SurfaceForm'


Vue.use(Overlay);
Vue.use(Loading);
Vue.use(VueCookies)
Vue.use(Col)
Vue.use(Slider)
Vue.use(Row)
Vue.use(Stepper)
Vue.use(Button)
Vue.use(VanImage)
Vue.use(Swipe);
Vue.use(SwipeItem)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Popup)
Vue.use(Field)
Vue.use(Radio)
Vue.use(RadioGroup)
var graphMesh
var graphGeometry
var segments = 50
var uMin = 0; var uMax = 1; var uRange = uMax - uMin
var vMin = 0; var vMax = 1; var vRange = vMax - vMin
var zMin = -10, zMax = 10, zRange = zMax - zMin
var xMin = 0, xMax = 0, yMin = 0, yMax = 0;
var renderer
var graphicAreaInited = false
var controls, material

export default {
  name: 'drawing',
  components: {
    'SurfaceForm':SurfaceForm
  },
  data () {
    return {
    /* 半径 */
      radio: 200,
      /* 圆心角 */
      centralAngle: 90,
      /* 落差 */
      leftheight: 5,
      load: true,
      active: 0,
      /* 起始角度 */
      startAngle: 0,
      /* 扭转角度 */
      twistAngle: 30,
      /* 展示那个步骤 */
      frame: [true, false, false],
      /* 第1个方向几个截面 */
      sectionOneEnd: 0,
      sectionOneList: [],
      /* 第2个方向几个截面 */
      sectionTwoEnd: 0,
      sectionTwoList: [],
      dialogVisible: false,
      //正在编辑的截面
      sectionModifyIdx: -1,
      // 新增截面临时参数：长度
      sectionTmpLength: 0,
      sectionTmpLengthForRestore: 0,
      // 新增截面临时参数：角度
      sectionTmpAngle: 0,
      sectionTmpAngleForRestore: 0,
      // 正在添加的截面方向
      addingSection: 0,
      /**
       * true 新增 false修改 
       */
      isNewSection : false,
      showAddSection: false,
      // 材料宽度
      materialLength: 20,
      previewImg: '',
      previewloading: false,
      cameraWatchAngle: 60,
      showContactUs: false,
      camera: {},
      message:"",
      /**
       * 画布的大小
       */
      width: 0,
      height:0,
      scene : null,
      /**
       * 曲面的图形
       */
      graphMeshs: [],

      /**
       * 截面线
       */
      sectionGraphMeshs: [] ,

      /**
       * 截面文字编号的图形
       */
      sectionNoMeshs: [] ,

      axesHelper:{},

      arrowHelper: {},
      /**
       * 轨迹面的图形
       */
      trackMesh:[],
      showTrack: true,
      showSurface: false,
      showHelper: true,
      showSection: false,
      showLoginBox: false,
      /**
       * 所有曲面的数组,与服务端的参数保持一致
       * {
       *    alphaDiff, 与上一个图形的旋转角度差 lastAlpha + alphaDiff = alpha, 第一个图形为0
       *    alpha1,  旋转角度 
       *    beta,   扭转角度，所有图形一致
       *    theta,  圆心角，所有图形一致
       *    l1, 材料宽度
       *    r,  半径 ，所有图形一致
       *    h, 落差，所有图形一致
       *    startPos: {
       *       x, y
       *    }   起始坐标
       *    endPos: {
       *       x,y 
       *    }  终点坐标（根据材料宽度和alpha和起点可以推算）,绘制截面时候需要
       *      终点坐标 x = 起始坐标x + length * cos (alphaDiff)
       *      终点坐标 y = 起始坐标y + length * sin (alphaDiff)
       * }
       * 
       */
      sufaceMetas: [],
      sufaceMetasB:[],
      addSufafeSite : 1, // 1 加到sufaceMetas ， 其他加到 sufaceMetasB
      //展示绘图3D，否则展示截面的二维图
      preview: true,
      priviewImgUrls: [],
      // colors:         [0xff00ff,0xffff00,0x00ffff,0x800080,0x808000,0x008080,0xff0000,0x00ff00,0x0000ff,0x800000,0x008000,0x000080],
      sufaceColors:   [0xff00ff,0xffff00,0x00ffff,0x600060,0x606000,0x006060,0xff0000,0x00ff00,0x0000ff,0x600000,0x006000,0x000060],
      backgroundColor:0xbbbbbb,
      controls: {},
      transformControls: {},
      downloadFileIdx: 0,
      downLoadFileSfNo: '',
      showPreviewNo: "-1",
      // 对应app的id
      id: 0,
      //图形组的标题
      title:'',
      //用户自定义的文件名
      userDefineName: '',
      //是否显示重命名窗口
      showDownRename: false,
      suffix: '.dxf',
      /**
       * 材料密度
       */
      density: 7.93,

      /**
       * 材料厚度
       */
      thickness: 1,

      /**
       * 圆心角方向
       * 0 逆时针，1 顺时针
       * 对应 centerAngle 就是负值和正值
       */
      thDirect: '0',
      /**
       * 页面上的圆心角
       */
      thetaShow: 0,

     //下载提示
      downloadErrMsg:"",

      //邮箱提示
      emailErrmsg: '',

      //当前日期，用于文件命名
      //最后展开的日志
      lastExtandDate: '',
      //展开的次数
      extandTimes: 0,

      //邮箱地址
      email: null,

      /**保存的名称和描述 */
      surface_name : '',
      surface_misc : '',
      surface_new_id : 0,
      surface_new_show: false,

      navTitle: '圆周扭转轨道-新增'
      
    }
  },
  methods: {
    init_three () {
      console.log("init three scene")
      this.scene = new THREE.Scene()
      // 点光源
      const ambientLight = new THREE.AmbientLight(0x444444)
      this.scene.add(ambientLight)

      const DirLight = new THREE.DirectionalLight(0xffffff, 0.8)
      this.scene.add(DirLight)
      this.width = window.innerWidth // 窗口宽度
      this.height = 320
      
      let spotLight = new THREE.SpotLight(0xffffff,1.6)
      spotLight.angle = Math.PI / 2
      spotLight.penumbra = 0
      spotLight.position.set(600,0,0)
      this.scene.add(spotLight)

      let spotLight2 = new THREE.SpotLight(0xffffff,1.6)
      spotLight2.angle = Math.PI / 2
      spotLight2.penumbra = 0
      spotLight2.position.set (-600,0,0)
      this.scene.add(spotLight2)
      // 创建相机对象
      //this.camera = new THREE.PerspectiveCamera(this.cameraWatchAngle, width / height, 0.1, 10000)
      this.camera = new THREE.OrthographicCamera( this.width / - 2, this.width / 2, this.height / 2, this.height / - 2, 1, 10000 )
      //camera.add(pointLight)
      //this.scene.fog = new THREE.Fog(0xd3e6f0, 1,200)
      this.scene.add(this.camera)
      this.camera.position.y = 600
      this.camera.position.z = 3200
      this.camera.position.x = 2400

  
      /**
              * 创建渲染器对象
              */
      renderer = new THREE.WebGLRenderer({ antialias: true })
      renderer.setSize(this.width, this.height)// 设置渲染区域尺寸
      renderer.setClearAlpha(0.0)
			renderer.outputEncoding = THREE.sRGBEncoding;
      document.getElementById('preview').appendChild(renderer.domElement) // body元素中插入canvas对象
      const pmremGenerator = new THREE.PMREMGenerator( renderer );
      this.scene.background = new THREE.Color( this.backgroundColor );
			this.scene.environment = pmremGenerator.fromScene( new RoomEnvironment(), 0.04 ).texture;
      this.controls = new OrbitControls(this.camera, renderer.domElement)
      this.controls.target.set(0, 0.5, 0)
      this.controls.update()
      this.controls.enablePan = false
      console.log("zuli", this.controls.dampingFactor)
      this.controls.dampingFactor = 0.15
      this.controls.enableDamping = true
      //this.controls.maxPolarAngle = Math.PI / 2

      

      this.axesHelper = new THREE.AxesHelper( 50 );
      this.scene.add( this.axesHelper );


      /* eslint new-cap: ["error", { "newIsCap": false }] */


      renderer.render(this.scene, this.camera)
      console.log("finish three scene")


    },
    animate () {
      requestAnimationFrame(this.animate)
      // graphMesh && (graphMesh.rotation.z += 0.01);

      this.controls.update()
     // console.log(this.controls.target)
      this.axesHelper.position = this.controls.target

      renderer.render(this.scene, this.camera)
    },
    // 展示具体的帧
    showFrame (no) {
      for (var i = 0; i < 4; i++) {
        if (i !== no) {
          this.$set(this.frame, i, false)
        } else {
          this.$set(this.frame, i, true)
        }
      }
      
      this.controls.enablePan = false
      this.preview = true;
      this.scene.add(this.axesHelper)
      this.showHelper = true
      this.showTrack = true
      this.showSurface = false
      this.showSection = false
      if (this.frame[3]) {
        //展开
        this.showSurface = true
        this.showPreviewNo = "-1"
        this.controls.enablePan = true
        this.extand()
        this.resetCameraView() 
      }
      if (this.frame[2]) {
        //曲面
          this.showSurface = true
          this.controls.enablePan = true
          this.resetCameraView()
      } 
      if (this.frame[1]) {
        //或者截面
        this.showSection = true
        //this.preview = false
        this.showHelper = false
        //this.scene.remove(this.axesHelper)
        this.resetCameraView()
        //this.draw2DsufaceSection()
      }
      if (this.frame[0]) {
        //或者轨迹
        this.showHelper = true
        this.showTrack = true
      }
      this.updateGraphicMesh()
      
    },
    getsufaceMetasBySite(pos) {
        return pos == 1 ? this.sufaceMetas : this.sufaceMetasB;
    }, 
    removeLastSection(pos) {
      this.getsufaceMetasBySite(pos).pop();
      this.updateGraphicMesh()
    },
    showAddSectionBox(pos) {
      this.addingSection = pos;
      this.showAddSection = true;
      //下标等于则添加，因为新增可能还有动态调整
      this.sectionModifyIdx =  this.getsufaceMetasBySite(this.addingSection).length
      this.isNewSection = true
    },
    /**
     * 根据 半径，圆心角，落差 返回轨迹绘制
     */
    getTrackMesh(R, TH, H, xOffset) {
        let vue = this
        TH = TH/180*Math.PI;
        var xfUv = function(t) {
          return R* Math.cos(t*TH)
        }
        var yfUv = function(t) {
          return R * Math.sin(t*TH)
        }
        var zfUv = function(t) {
          return -H * t
        }
        class CustomSinCurve extends THREE.Curve {

        constructor( scale = 2 ) {

          super();

          this.scale = scale;

        }

        getPoint( t, optionalTarget = new THREE.Vector3() ) {

          let tx = xfUv(t);
          const ty = yfUv(t);
          const tz = zfUv(t);

          tx = tx + xOffset
          return optionalTarget.set( tx , tz, ty ).multiplyScalar( this.scale );

        }

      }	
        const path = new CustomSinCurve( 1);
        const geometry = new THREE.TubeGeometry( path, 45, 0.5, 6, false );
        const material = new THREE.MeshBasicMaterial( { color: 0xff0000, side: THREE.DoubleSide} );
        return new THREE.Mesh( geometry, material );
    },
    getTextGemotery(surfaceMeta) {

      if (!this.scene) {
        return ;
      }
      const loader = new FontLoader();

      loader.load( '/helvetiker_bold.typeface.json', function ( font ) {
        console.log(surfaceMeta)
        const geometry = new THREE.TextGeometry( 'AB', {
          font: font,
          size: 80,
        } );

        var txtMater = new THREE.MeshBasicMaterial({color: 0x0000ff});
        var txtMesh = new THREE.Mesh(geometry, txtMater);
        txtMesh.position.set(surfaceMeta.endPos.x, surfaceMeta.endPos.y,surfaceMeta.endPos.z);
        this.scene.add(txtMesh);
        this.sectionNoMeshs.push(txtMesh)
      } );
    },
 
    /**
     * 绘制3d的截面
     */
    draw3DsufaceSection() {
     this.updateSufaceMetaAngleAndRadio(this.sufaceMetas, this.radio, this.leftheight, this.startAngle, this.twistAngle, this.centralAngle)
     this.updateSufaceMetaAngleAndRadio(this.sufaceMetasB, this.radio, this.leftheight, this.startAngle, this.twistAngle, this.centralAngle)
      
      var lastPos = {x:0, y:0}
  
      const centerGeometry = new THREE.BufferGeometry().setFromPoints([new THREE.Vector3( 0 , 0, 0 ) ]);
      var meterial = new THREE.PointsMaterial({
        color: new THREE.Color(0xff000f),
        size: 2.0
      })
      var points = new THREE.Points(centerGeometry, meterial)
      this.scene.add(points)
      this.sectionGraphMeshs.push(points)
      for (let i=0 ;  i < this.sufaceMetas.length; i ++) { 
          let csuface = this.sufaceMetas[i];
          const points = [];
          points.push( new THREE.Vector3( lastPos.x , lastPos.y, 0 ) );
          points.push( new THREE.Vector3( csuface.endPos.x , csuface.endPos.y, 0 ) );
          lastPos = csuface.endPos
          const geometry = new THREE.BufferGeometry().setFromPoints( points );

          let color = this.sufaceColors[i % this.sufaceColors.length];
          const material = new THREE.LineBasicMaterial({
              color: color
            });
          //生成编号
          csuface['idx'] = i
          csuface['color'] = color
          const line = new THREE.Line( geometry, material );
          this.sectionGraphMeshs.push(line)
          this.scene.add( line );
      }

      //this.getTextGemotery(this.sufaceMetas[0])

      lastPos = {x:0, y:0}
      for (let i=0 ;  i <  this.sufaceMetasB.length; i++) { 
          let csuface = this.sufaceMetasB[i];
          const points = [];
          points.push( new THREE.Vector3( lastPos.x , lastPos.y, 0 ) );
          points.push( new THREE.Vector3( csuface.endPos.x , csuface.endPos.y, 0 ) );
          lastPos = csuface.endPos
          const geometry = new THREE.BufferGeometry().setFromPoints( points );
          let color = this.sufaceColors[this.sufaceColors.length - i % this.sufaceColors.length - 1];
          //生成编号
          csuface['idx'] = parseInt(i) + this.sufaceMetas.length
          csuface['color'] = color
          const material = new THREE.LineBasicMaterial({
              color: color
            });
          const line = new THREE.Line( geometry, material );
          this.sectionGraphMeshs.push(line)
          this.scene.add( line );
      }

      

    },
    /**
     * 绘制截面的2D图
     */
    draw2DsufaceSection() {

      var canvas=document.getElementById("canvas");
      canvas.width = document.documentElement.clientWidth
      var context=canvas.getContext("2d");

      var center  = {x: canvas.width / 2, y : canvas.height / 2};
      context.clearRect(0,0,canvas.width,canvas.height);
      context.fillStyle =  "#" + this.backgroundColor .toString(16);
      context.fillRect(0,0,canvas.width,canvas.height);
      context.lineWidth = 2
      var lastPos = {x:center.x, y:center.y};
      var csuface, color;
      var scale = 3.2
      for (let i in this.sufaceMetas) {
          csuface = this.sufaceMetas[i];
          let x = Math.round(csuface.endPos.x * scale)
          let y = Math.round(csuface.endPos.y * scale)
          color = this.sufaceColors[2].toString(16) 
          context.beginPath();
          context.strokeStyle = "#" + color
          context.moveTo(lastPos.x, lastPos.y );
         
          context.lineTo((x + center.x)  , ( y + center.y) )
          context.stroke();
          lastPos = {x:x + center.x, y:y + center.y};
      }
      lastPos = {x:center.x, y:center.y};
      for (let i in this.sufaceMetasB) {
          csuface = this.sufaceMetasB[i];
          let x = Math.round(csuface.endPos.x * scale)
          let y = Math.round(csuface.endPos.y * scale)
          color = this.sufaceColors[3].toString(16) 
          context.beginPath();
          context.strokeStyle = "#" + color
          context.moveTo(lastPos.x, lastPos.y );
          context.lineTo((x + center.x)  , ( y + center.y) )
          context.stroke();
          lastPos = {x:x + center.x, y:y + center.y};
      }
      
      context.beginPath();
      context.strokeStyle = "green";
      context.fillStyle = "red";
      context.arc(center.x, center.y,3,0,Math.PI*2,true);
      context.fill();
      context.stroke();
      context.closePath();
    },
    /**
     * 绘制轨迹
     */
    drawTrack(xOffset) {
      var vue = this
      vue.trackMesh.push(vue.getTrackMesh(vue.radio, vue.centralAngle, vue.leftheight, xOffset));
      for (var i in vue.trackMesh) {
        this.scene.add(vue.trackMesh[i])
      }

      //删除辅助箭头
      if (this.arrowHelper) {
        this.scene.remove(this.arrowHelper)
      }
      let z = this.thDirect == '0' ? -1 : 1
      const dir = new THREE.Vector3( 0,  0, z );
      //dir.normalize();
      const origin = new THREE.Vector3( vue.radio+30 , 30, 0 );
      const hex = 0x990000;
      //var length = Math.min(100, this.trackLength / 5)
      var length = 50
      this.arrowHelper = new THREE.ArrowHelper( dir, origin, length , hex,  0.3 * length, 0.15 * length);
      this.scene.add( this.arrowHelper );

    },

    updateGraphicMesh() {

      if (!this.scene) {
        console.log("scene has not been inited")
        return 
      }
      //根据方向调整实际圆心角的正负值
      this.centralAngle = this.thDirect == '0' ?  -this.thetaShow : this.thetaShow
      this.clearAllMeths('all');
        
        
        if (this.showTrack) {
          var xOffset = this.showSection ? - this.radio : 0
          this.drawTrack(xOffset)
        }
        if (this.showSection) {
          this.draw3DsufaceSection()
        }
        if (this.showSurface) {
          this.drawGraphic()
        }
        if (this.showHelper) {
           this.scene.add(this.axesHelper)
        }
    },
    
    /**
     * 点击某个截面的按钮，弹出来展示
     */
    showSectionInfoByIdx(index, pos) {
        let sufaceMetas = this.getsufaceMetasBySite(pos);
        if (sufaceMetas.length > index) {
            this.sectionTmpAngleForRestore  =  this.sectionTmpAngle = sufaceMetas[index].angle
            this.sectionTmpLengthForRestore = this.sectionTmpLength = sufaceMetas[index].mL
            this.sectionModifyIdx = index
            this.showAddSection = true
            this.addingSection = pos
            this.isNewSection = false
        }
    },
    /**
     * 更新曲面的内容
     * "beta" : β,
          "theta" : θ,
          "h" : H,
          "r" : R
     */
    updateSufaceMetaAngleAndRadio(sufaceMetas, radio, H, startAngle, twistAngle, theta) {
        /**
         * 更新圆心角和半径信息
         * 根据起始角度，重新计算alph1,alpth2 L1,L2
         */
        let lastPos = {x : 0, y: 0};
        let lastTotalAngle = startAngle;
        let alpha1 = 0;
        let L1 = 0;
        for (var j =0; j<sufaceMetas.length; j++) {
           sufaceMetas[j].r = radio;
           sufaceMetas[j].beta = twistAngle
           sufaceMetas[j].h = H
           sufaceMetas[j].theta = theta
           sufaceMetas[j].startAngle = startAngle
         }
        //if (startAngle != 0) {
           //根据起始角度重新计算曲面参数
           for (j =0; j<sufaceMetas.length; j++) {
             let totalAngle = lastTotalAngle + sufaceMetas[j].angle;
              let x = sufaceMetas[j].mL * Math.cos(totalAngle/180*Math.PI) + lastPos.x
              let y = sufaceMetas[j].mL * Math.sin(totalAngle/180*Math.PI) + lastPos.y
              let L2 = Math.sqrt(x**2+y**2)
              let alpha2 = 0;
              if (y>0)	alpha2=-Math.acos(x/L2);
              else if (y<0)	alpha2=Math.acos(x/L2);
              else 		alpha2=0;

              sufaceMetas[j].l1 = L1;
              sufaceMetas[j].l2 = L2;
              sufaceMetas[j].alpha1 = alpha1;
              sufaceMetas[j].alpha2 = alpha2;
              // 加上就影响截面绘制，不加就不会
              sufaceMetas[j].endPos = {x:x, y:y}

              lastPos = {x:x, y:y}
              alpha1 = alpha2
              L1 = L2
              lastTotalAngle = totalAngle
           }
        // }
    },
    /**
     * 生成曲面/截面的编号 0-1,0-2 0-A 0-B
     */
    genSurfaceNo(pos, index) {
        if (pos == 1) {
           return  (index + 1)
        } else {
          return  String.fromCharCode(index + 65)
        }
    },

    /**
     * 更新第index个曲面的角度和长度
     * 如果index < -1 ，则插入到最后，
     * 如果index > 0 and < sufaceMetas , 更新index 之后的值
     * no 编号
     */
    addAndUpdateSufafMetaIdx(sufaceMetas, angle, length, index ) {
         let lastPos = {x : 0, y: 0};
         let lastTotalAngle = 0;
         let alpha1 = 0;
         let L1 = 0;
         let vue = this
         if ( index > -1 && index < sufaceMetas.length) {
           //更新中间
           sufaceMetas[index].angle = angle
           sufaceMetas[index].mL = length
            for (var k = index; k < sufaceMetas.length; k++) {
              if (k > 0) {
                lastPos = sufaceMetas[k - 1].endPos
                lastTotalAngle = sufaceMetas[k - 1].totalAngle
                alpha1 = sufaceMetas[k - 1].alpha2
                L1 = sufaceMetas[k - 1].l2
              }
              let totalAngle = lastTotalAngle + sufaceMetas[k].angle;
              let x = sufaceMetas[k].mL * Math.cos(totalAngle/180*Math.PI) + lastPos.x
              let y = sufaceMetas[k].mL * Math.sin(totalAngle/180*Math.PI) + lastPos.y
              let endPos = {x:x, y:y};
              let L2 = Math.sqrt(x**2+y**2)
              let alpha2 = 0;
              if (y>0)	alpha2=-Math.acos(x/L2);
              else if (y<0)	alpha2=Math.acos(x/L2);
              else 		alpha2=0;
              sufaceMetas[k].endPos = endPos
              sufaceMetas[k].l1 = L1
              sufaceMetas[k].l2 = L2
              sufaceMetas[k].alpha1 = alpha1
              sufaceMetas[k].alpha2 = alpha2
              sufaceMetas[k].totalAngle = totalAngle
              sufaceMetas[k].selected = true
              }
         } else {
           //追加到最后
            if (sufaceMetas.length > 0 ) {
              lastPos = sufaceMetas[sufaceMetas.length - 1].endPos
              lastTotalAngle = sufaceMetas[sufaceMetas.length - 1].totalAngle
              alpha1 = sufaceMetas[sufaceMetas.length - 1].alpha2
              L1 = sufaceMetas[sufaceMetas.length - 1].l2
            }
            let totalAngle = lastTotalAngle + angle;
            let x = length * Math.cos(totalAngle/180*Math.PI) + lastPos.x
            let y = length * Math.sin(totalAngle/180*Math.PI) + lastPos.y
            let endPos = {x:x, y:y};
            let L2 = Math.sqrt(x**2+y**2)
            let alpha2 = 0;
            if (y>0)	alpha2=-Math.acos(x/L2);
            else if (y<0)	alpha2=Math.acos(x/L2);
            else 		alpha2=0;
            let oneSufaceMeta = {
                totalAngle: totalAngle,
                alpha1: alpha1,
                l1: L1,
                l2:L2,
                alpha2:alpha2,
                endPos: endPos,
                mL: length,
                angle: angle,
                selected: true,
                sfno: vue.genSurfaceNo(this.addingSection, index)
            }
            sufaceMetas.push(oneSufaceMeta);
         }
        
    },
    updateSectionDraw( ) {
            let length = this.sectionTmpLength
            let angleDiff = this.sectionTmpAngle
            let sufaceMetas = this.getsufaceMetasBySite(this.addingSection)
            this.addAndUpdateSufafMetaIdx(sufaceMetas, angleDiff, length, this.sectionModifyIdx );
            this.updateGraphicMesh();
    },
    /**
     * 确认添加截面
     */
    addSectionClose(action, done) {
      

        if ('confirm' == action) {

            //计算新曲面的坐标，并加入到曲面数组
            this.updateSectionDraw()
            
        } else {
          console.log("anthor action" , action)
          if (this.sectionModifyIdx > 0 && this.isNewSection) {
            //TODO 还原，不保存最后新增的曲面，修改的没有取消
            this.getsufaceMetasBySite(this.addingSection).pop()
            
          } else {
            //还原
            this.getsufaceMetasBySite(this.addingSection)[this.sectionModifyIdx].mL = this.sectionTmpLengthForRestore
            this.getsufaceMetasBySite(this.addingSection)[this.sectionModifyIdx].angle = this.sectionTmpAngleForRestore
            
            //addAndUpdateSufafMetaIdx
          }

          while (this.sectionGraphMeshs.length > 0) {
                graphMesh = this.sectionGraphMeshs.pop()
                this.scene.remove(graphMesh)
              // renderer.deallocateObject( graphMesh );
            }
          this.draw3DsufaceSection();
        }
        
         this.sectionModifyIdx = -1 ;
        return done()
    },
    prev () {
      if (this.active > 0) this.active--
      this.showFrame(this.active)
    },

    next () {
      var show = 0
      if (this.active < 4) {
        this.active++
        show = this.active
      } else {
        show = 3
      }
      this.showFrame(show)
    },
    // 点击展开按钮
    extand () {
      // this.drawTmp();
      this.previewloading = true
      this.userDefineName = ""
      
      
      var vue = this
      vue.previewImg = ""
      if (vue.lastExtandDate != vue.currentDate ) {
        console.log("last extand date is not today ", vue.lastExtandDate, vue.currentDate)
        vue.lastExtandDate = vue.currentDate
        vue.extandTimes = 0
      }
      vue.extandTimes ++
      vue.setParams()
      vue.getPrivewImg()
      vue.$trackEvent("extand", "extand", vue.id, vue.graphMeshs.length)
    },
    finishLoading () {
      this.previewloading = false
    },
    
    /**
         * 根据起始、扭转角度，半径，圆心角，落差,宽度确定xyz轴的uv方程
         * α2 当前曲面的角度， α1 上个曲面的角度
         * L2 当前曲面的离圆心的距离
         * L1 上一个曲面离圆心的距离
         **/
    getXbyUV (α1, α2, β, R, θ, H, L1, L2) {
      return function (u, v) {
        return (L2*Math.cos(α2+u*β)*Math.cos(u*θ)+L2*Math.sin(α2+u*β)*Math.sin(Math.atan(H/(R*θ)))*Math.sin(u*θ)+R*Math.cos(u*θ))*v+(L1*Math.cos(α1+u*β)*Math.cos(u*θ)+L1*Math.sin(α1+u*β)*Math.sin(Math.atan(H/(R*θ)))*Math.sin(u*θ)+R*Math.cos(u*θ))*(1-v)
      }
    },
    getYbyUV (α1, α2, β, R, θ, H, L1, L2) {
      return function (u, v) {
        return (L2*Math.cos(α2+u*β)*Math.sin(u*θ)-L2*Math.sin(α2+u*β)*Math.sin(Math.atan(H/(R*θ)))*Math.cos(u*θ)+R*Math.sin(u*θ))*v+(L1*Math.cos(α1+u*β)*Math.sin(u*θ)-L1*Math.sin(α1+u*β)*Math.sin(Math.atan(H/(R*θ)))*Math.cos(u*θ)+R*Math.sin(u*θ))*(1-v)
      }
    },
    getZbyUV (α1, α2, β, R, θ, H, L1, L2) {
      return function (u, v) {
        return -((L2*Math.sin(α2+u*β)*Math.cos(Math.atan(H/(R*θ)))+u*H)*v+(L1*Math.sin(α1+u*β)*Math.cos(Math.atan(H/(R*θ)))+u*H)*(1-v))
      }
    },
    /**
     * α2 L2 为当前曲面的圆心角和离圆心巨鹿
     * α1,L1 为上一个曲面 
     */
    getSuface(α1, α2, β, R, θ, H, L1, L2, color) {
      var xFunc = this.getXbyUV(α1, α2, β, R, θ, H, L1, L2)
      var yFunc = this.getYbyUV(α1, α2, β, R, θ, H, L1, L2)
      var zFunc = this.getZbyUV(α1, α2, β, R, θ, H, L1, L2)

      let meshFunction = function (u0, v0, target) {
        var u = u0
        var v = v0 
        var x = xFunc(u, v) 
        var z = yFunc(u, v)
        var y = zFunc(u, v) 
        if (isNaN(x) || isNaN(y) || isNaN(z)) {
          target.set(0, 0, 0)
        } else {
          target.set(x , y , z)
        }
      }

      // controls.target.set( 0, 1, 0 );
      // true => sensible image tile repeat...
      graphGeometry = new ParametricGeometry(meshFunction, 60, 60,false)

      var meterial4 = new THREE.MeshPhongMaterial()
      meterial4.color = color
      meterial4.side = THREE.DoubleSide
      meterial4.ambient = color
      meterial4.emissive = new THREE.Color(0x000000)
      meterial4.specular = new THREE.Color(0xffffff)
      meterial4.shininess = 10
      meterial4.transparent = true
      var  graphMeshTmp = new THREE.Mesh( graphGeometry, meterial4 );
      return graphMeshTmp
    },
    clearAllMeths(type) {
        while (this.graphMeshs.length > 0) {
            graphMesh = this.graphMeshs.pop()
            this.scene.remove(graphMesh)
          // renderer.deallocateObject( graphMesh );
        }
        while (this.sectionGraphMeshs.length > 0) {
            graphMesh = this.sectionGraphMeshs.pop()
            this.scene.remove(graphMesh)
          // renderer.deallocateObject( graphMesh );
        }
        while (this.trackMesh.length > 0) {
          let mesh = this.trackMesh.pop()
          this.scene.remove(mesh) 
        }
        while (this.sectionNoMeshs.length > 0) {
          graphMesh = this.sectionNoMeshs.pop()
          this.scene.remove(graphMesh)
        }
        this.scene.remove(this.axesHelper)
    },
    /**
     * 或者所有曲面
     */
    drawGraphic () {
      if (!graphicAreaInited) {
        console.log('drawing area not finished..')
        return
      }
  
    //调整所有的角度
    //this.fixedSectionAngleWidthPreAngle(this.sufaceMetas, 0 , this.startAngle)
    //绘制每个曲面
    //this.updateSufaceMetaAngleAndRadio(this.sufaceMetas, this.radio, this.leftheight, this.startAngle, this.twistAngle, this.centralAngle)
    //this.updateSufaceMetaAngleAndRadio(this.sufaceMetasB, this.radio, this.leftheight, this.startAngle, this.twistAngle, this.centralAngle)
    var sufaceMetas =  [this.sufaceMetas, this.sufaceMetasB];
    for (var i in sufaceMetas) {
      let tmpSufaces = sufaceMetas[i]
      for (var j in tmpSufaces) {
        let tmpSuface = tmpSufaces[j]
         if (!tmpSuface.selected) {
              console.log("不展示")
              continue;
            }
            var L1 = tmpSuface.l1
            var α1 =  tmpSuface.alpha1
            var β = (0 -  this.twistAngle) * Math.PI / 180
            var H = this.leftheight
            var R = this.radio
            var θ = this.centralAngle * Math.PI / 180
            var L2 = tmpSuface.l2
            var α2 =  tmpSuface.alpha2
            let colorIdx = i == 0 ? j % this.sufaceColors.length : this.sufaceColors.length - j % this.sufaceColors.length - 1
            var randomItem = new THREE.Color(this.sufaceColors[colorIdx]);

            let graphMesh = this.getSuface(α1, α2, β, R, θ, H, L1, L2, randomItem);
            //0 0-1 ; 1 0-A
            //图形的编号 0-1, 0-A 这样的格式
           
            //将元数据存起来
            graphMesh.metaInfo = tmpSuface
            //graphMesh.position.x =  tmpSuface.startPos.x   
            //graphMesh.position.y =  tmpSuface.startPos.y 
            
            this.graphMeshs.push(graphMesh)
            //位置
            this.scene.add(graphMesh)
      }
      
     
    }
      
    
      
      // first, assign colors to vertices as desired
      
     
 
      //camera.position.z = this.radio
      //求出x轴最近可以包含圆形的距离
      //let tmpcx = this.radio / Math.tan(this.cameraWatchAngle / 360 * Math.PI)
      //如果小于360度则可以根据角度移动
      
      //camera.position.y = tmpcx + 2
      // camera.position.x = this.radio * 2 - (Math.abs(this.centralAngle) / 180 * this.radio)
      
      //camera.updateProjectionMatrix()
      //renderer.render(this.scene, camera)
    }, // 获取链接（png 或 dxf）
    get_url (action) {
      var url = '';
      var ct1 = (new Date()).toGMTString()
      var ct0 = this.$cookies.get("id") //sessionID
      var st = this.$cookies.get("_st")
     
      var ct = "?_ct=";
      if (ct0 && st) {
        try {
          var hmac = this.$crypto.createHmac('sha256', st)
          var ct2 = hmac.update(ct1).digest('base64')
          ct  += Base64.encode(ct0 + '|' + ct1 + '|' + ct2)
        } catch (error) {
          console.log(error)
          ct = "";
        } 
     }
      let tax = ct.indexOf("_ct")>0 ? "&sfno=" : "?sfno=";
      ct += (tax + this.showPreviewNo)
      let downNo = this.extandTimes > 0 ? "("+this.extandTimes+")" : ""
      ct += ("&name=" + this.currentDate + downNo + "_" + this.title + '_' + this.showPreviewNo)
      console.log("ct = ", ct)
      if (action == 'get_png') {
        url = this.$url + '/v1/surface/' + this.id + '/preview/'+ct
      } else if (action == 'get_dxf') {
        url = this.$url + '/v1/surface/' + this.id + '/dxf'+ct
      } else if (action == 'get_cnc') {
        url = this.$url + '/v1/surface/' + this.id + '/cnc'+ct
      }
      return url

      
    },
    
    getPrivewImg() {
      var url = '';
      url = this.$url + '/v1/surface/preview'
      var vue = this
      //var metas = vue.sufaceMetas.concat(vue.sufaceMetasB).filter((v) => { return v.selected});
      vue.priviewImgUrls = []
      for (var k in this.graphMeshs) {
          var mesh = this.graphMeshs[k];
          let meta = mesh.metaInfo;
          var param = [];
          param.push({
            "l1":meta.l1,
            "l2":meta.l2,
            "alpha1":meta.alpha1,
            "alpha2":meta.alpha2,
            "beta": - vue.twistAngle * Math.PI / 180 ,
            "theta": vue.centralAngle * Math.PI / 180,
            "h":vue.leftheight,
            "r":meta.r,
            "sfno":meta.sfno
          });
          let data = {
             "params":param
          }
          //{{ index == 0 ? '0' : String.fromCharCode(64 + index) }} - {{ String.fromCharCode(index + 65)}} 
       
          var fc = function(i, sfno, data) {
              vue.$axios.post(url,data).then(res=>{
                        if (res && res.data.code == 'ok') {
                            let baseImgData = "data:image/png;base64,"+res.data.data.image;
                            //周长
                            let length = res.data.data.arc_length ? res.data.data.arc_length /1000 : 1
                            //面积
                            let area  = res.data.data.contour_area ? res.data.data.contour_area : 1
                            //重量
                            let weight = (((area *  vue.thickness ) / 1000) * vue.density) / 1000
                            vue.priviewImgUrls.push({url:baseImgData, idx: i, sfno: sfno,length:length,area:area,weight:weight})
                        }
                        
                      });
          } ;
          fc(k, meta.sfno, data);
          
      }
      
    },
    async getParams() {
      var vue = this
      if ('new' == this.id) {
        console.log('新增')
        return 
      }
      let response = await Commons.getSurface(vue, this.id)
      let params = Commons.getAppParams(vue, 'app', this.id, response)
      this.surface_name = response.name
      this.surface_misc = response.misc
      vue.navTitle = '圆周扭转轨道-【' + this.surface_name + "】"
      if (params.length > 0) { 
          var param = params[0]
          vue.title = response.name
          vue.userDefineName = response.name
          vue.leftheight = param.h
          vue.radio = param.r
          vue.startAngle = 0
          vue.extandTimes = param.extandTimes
          vue.lastExtandDate = param.lastExtandDate
          vue.twistAngle = - Math.round(180 * param.beta / Math.PI)
          vue.centralAngle = Math.round(180* param.theta / Math.PI)
          vue.thetaShow = Math.abs(vue.centralAngle);
          vue.thDirect = vue.centralAngle > 0 ? '1' : '0'
          vue.thickness = param.thickness ? param.thickness : 1
          vue.sufaceMetas = []
          vue.sufaceMetasB = []
          
          for (var i in  params) {
             var oneParam = params[i]
             if (oneParam.pos && oneParam.pos == 2){
               if (!oneParam.sfno) {
                 //兼容老数据，补上编号
                  oneParam['sfno'] = vue.genSurfaceNo(2, vue.sufaceMetasB.length)
               }
               oneParam['color'] = this.sufaceColors[(this.sufaceColors.length - vue.sufaceMetasB.length + 1) % this.sufaceColors.length]
                vue.sufaceMetasB.push(oneParam)
             } else {
               if (!oneParam.sfno) {
                 //兼容老数据，补上编号
                  oneParam['sfno'] = vue.genSurfacealertNo(1, vue.sufaceMetas.length)
               }
               oneParam['color'] = this.sufaceColors[vue.sufaceMetas.length % this.sufaceColors.length]
               vue.sufaceMetas.push(oneParam)
             }
          }
          // vue.draw2DsufaceSection();
          vue.updateGraphicMesh()
        }
    },
    async setParams() {
      var vue = this
      let params = [];

      for (var i in vue.sufaceMetas) {
         var meta = vue.sufaceMetas[i]
          params.push({
          'l1' : meta.l1,
          'l2' : meta.l2,
          'alpha1' : meta.alpha1,
          'alpha2' : meta.alpha2,
          'startAngle': vue.startAngle,
          'beta' : (0 - vue.twistAngle) * Math.PI / 180,
          'theta' : vue.centralAngle * Math.PI / 180,
          'h' : vue.leftheight,
          'r' : vue.radio,
          'pos' : 1,
          'angle': meta.angle,
          'mL': meta.mL,
          'endPos': meta.endPos,
          'totalAngle' : meta.totalAngle,
          'selected': meta.selected,
          'sfno' : meta.sfno,
          "thickness":vue.thickness,
          "lastExtandDate":vue.lastExtandDate,
          "extandTimes":vue.extandTimes

        });     
      }
      for (i in vue.sufaceMetasB) {
          meta = vue.sufaceMetasB[i]
          params.push({
          'l1' : meta.l1,
          'l2' : meta.l2,
          'alpha1' : meta.alpha1,
          'alpha2' : meta.alpha2,
          'startAngle': vue.startAngle,
          'beta' : (0 - vue.twistAngle) * Math.PI / 180,
          'theta' : vue.centralAngle * Math.PI / 180,
          'h' : vue.leftheight,
          'r' : vue.radio,
          'pos' : 2,
          'angle': meta.angle,
          'mL': meta.mL,
          'endPos': meta.endPos,
          'totalAngle' : meta.totalAngle,
          'selected': meta.selected,
          'sfno' : meta.sfno,
          "thickness":vue.thickness,
          "lastExtandDate":vue.lastExtandDate,
          "extandTimes":vue.extandTimes
        });     
      }
      let saveParams = {
        'params' : params,
        'type': 'app',
        'name': vue.title,
        'misc': ''
      }
      let response = await Commons.updateSurface(this, this.id, saveParams)
      return response
    },
    showContactUsBox() {
      
      //let box = new LoginBox()
      this.showContactUs = true
    },
    sendConstactUs() {
      //联系我们
      let vue = this
      let msg = vue.message
      this.$axios
            .post(vue.$url + '/v1/suggest', {
                'misc': msg,
            })
            .then(response => {
                if(response.code == 'ok') {
                  Toast.success('感谢您的反馈');
                } else {
                  Toast.fail(response.message);
                }
                
            })
            .catch(function (error) { // 请求失败处理
                vue.$dialog.alert({
                message: error.response.message,
                })
                if (error.response.status == 401) {
                  vue.$router.push('/login')
                }
            })
    },
    /**
     * 预览图轮播图切换事件
     */
    onPreviewChange(index) {
        for (var i in this.graphMeshs ) {

          this.graphMeshs[i].material.transparent = true
          this.graphMeshs[i].material.opacity  = 0.15
        }
        this.graphMeshs[this.priviewImgUrls[index].idx].material.opacity  = 1
        this.downloadFileIdx = index
        
        
    },
    /**
     * 选择展示
     */
    onShowCheckBoxClick(item) {
        item.selected = !item.selected
          this.updateGraphicMesh()
    },
    onShowRadioBoxClick(item) {
       if (item == null || undefined == item) {
         // 展示全部
          for (let i in this.graphMeshs ) {
             this.graphMeshs[i].material.opacity  = 1
             this.graphMeshs[i].material.depthWrite = true
          }
          return ;
       }
       //#808080
        this.showPreviewNo = item.sfno
        this.downloadFileIdx = item.idx
        for (let i in this.graphMeshs ) {
          this.graphMeshs[i].material.transparent = true
          this.graphMeshs[i].material.depthWrite = false
          this.graphMeshs[i].material.opacity  = 0.15
          //this.graphMeshs[i].material.color = new THREE.Color(0x808080)
          if (this.graphMeshs[i].metaInfo.sfno == item.sfno) {
           this.graphMeshs[i].material.opacity  = 1
           this.graphMeshs[i].material.depthWrite = true
          // this.graphMeshs[i].material.color = new THREE.Color(this.graphMeshs[i].metaInfo.color)
          }
        }
    },
    /**
     * 下载相关
     */
    showDownload(type) {
      let vue = this
      if (!Commons.isLogin()) {
            //没有登录
            this.$dialog.confirm({title:'该操作需要先登陆', message: "是否前往登录?"})
                .then(()=> {
                  Commons.goLogin(vue)
                  console.log('go login')
                })

            return ;
        }
        this.suffix = "."+type
        this.showDownRename = true
        
    },
    closeDownRenameBox() {
      this.showDownRename = false
    },
    //开始下载
    openAndDown() {
        let vue = this

        if (vue.userDefineName == "") {
          console.log("project name is empty ,not allowed download")
          vue.downloadErrMsg = "请输入项目名称"
          return ;
        }
        vue.downloadErrMsg = ""
        let oldTitle = vue.title
        vue.title = vue.userDefineName
        var url = (vue.suffix.indexOf("dxf") > 0) ? vue.exportDxf : vue.exportCnc;
        vue.$trackEvent("download", vue.suffix.indexOf("dxf") > 0 ? 'dxf':'cnc', vue.id, vue.showPreviewNo)
        var filename = vue.title  + "." + ( vue.suffix.indexOf("dxf") > 0 ? 'dxf':'cnc');
        if (oldTitle != vue.userDefineName) {
          //调用保存名称
          this.setParams()
          vue.downloadFile(url, filename)
          
        } else {
           // location.href = url;
           vue.downloadFile(url, filename)
        }
      
    },
    downloadFile(url, name) {
      this.$axios.get(url)
               .then((res) => {
                if (window.navigator.msSaveBlob) {
                  //IE以及IE内核的浏览器
                  try {
                      window.navigator.msSaveBlob(res.data, name); //response为接口返回数据，这里请求的时候已经处理了，如果没处理需要在此之前自行处理var data = new Blob([response]) 注意这里需要是数组形式的,fileNm就是下载之后的文件名
                      // window.navigator.msSaveOrOpenBlob(response, fileNm);	//此方法类似上面的方法，区别可自行百度
                  } catch (e) {console.log("down error", e)}
                  } else {
                  //在headers中截取filename
                  var filename = name;
                  var blob = new Blob([res.data], {type: "application/octet-stream"})
                  var downloadElement = document.createElement('a');
                  var href = window.URL.createObjectURL(blob); //创建下载的链接
                  downloadElement.href = href;
                  downloadElement.download = filename 
                  //此处也可以将filename写死 "filename.xlsx"
                  document.body.appendChild(downloadElement);
                  console.log(filename);
                  downloadElement.click(); //点击下载
                  document.body.removeChild(downloadElement); //下载完成移除元素
                  window.URL.revokeObjectURL(href); //释放掉blob对象 
            }
               })
               .catch((e) => {Toast.fail('系统开小差')});
    }, 
    //邮箱发送
    sendMail() {
      let vue = this
      

      if (vue.userDefineName == "") {
        console.log("project name is empty ,not allowed download")
        vue.downloadErrMsg = "请输入项目名称"
        return ;
      }
      const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
      if (vue.email == "" || !regEmail.test(vue.email)) {
        vue.emailErrmsg = "请输入正确的邮箱地址"
        return ;
      }
      localStorage.setItem("email", vue.email);
      vue.emailErrmsg = ""
      vue.downloadErrMsg = ""
      let oldTitle = vue.title
      vue.title = vue.userDefineName
      var url = (vue.suffix.indexOf("dxf") > 0) ? vue.exportDxf : vue.exportCnc;
      url += "&email=" + vue.email;
      vue.$trackEvent("email", vue.suffix.indexOf("dxf") > 0 ? 'dxf':'cnc', vue.email, vue.showPreviewNo)
      vue.$axios.get(url).then((res) => {
          Toast.success({message: res.data.message, onClose: ()=>{vue.showDownRename = false}})
          
      }, (e) => {
         console.log(e)
      })
    },
      onDirectChange() {
        console.log("change", this.thDirect)
        this.updateGraphicMesh()
      },
      resetCameraView() {
        if (this.frame[1]) {
          this.camera.position.x = 0;
          this.camera.position.z = 600;
          this.camera.position.y = 0;
          this.camera.left = this.width / -10
          this.camera.right = this.width / 10
          this.camera.top = this.height / 10
          this.camera.bottom = this.height / -10
        } else {
          this.camera.position.y = 600
          this.camera.position.z = 3200
          this.camera.position.x = 2400
          this.camera.left = this.width / -2
          this.camera.right = this.width / 2
          this.camera.top = this.height / 2
          this.camera.bottom = this.height / -2
        }
        this.controls.target = new THREE.Vector3(0,0,0)
        this.camera.zoom = 1
        this.camera.updateProjectionMatrix()
        
        //this.camera.lookAt

        
      },
      str_pad( hex ){
        var zero = '000000';
        var tmp = 6-hex.length;
        var c =  "#" + zero.substr(0,tmp) + hex;
        return c;
      },
      //保存图形
      async saveSurface() {
        var vue = this
        if (!Commons.isLogin()) {
            //没有登录
            this.$dialog.confirm({title:'该操作需要先登陆', message: "是否前往登录?"})
                .then(()=> {
                  Commons.goLogin(vue)
                  console.log('go login')
                })

            return ;
        }
        if (this.id == 'new') {
            //新增，弹出输入名称和描述
            this.surface_new_show = true
        } else {
          let res = await this.setParams()
          console.log(res)
          if (res.code == 'ok') {
            Toast.success("保存成功")
          } else {
            Toast.success(res.message)
          }
          
        }
      },
      add_surface_done(n_id) {
        this.id = n_id
        //调用保存
        console.log("back id is ", this.id)
        this.setParams()
        
        this.surface_new_show = false
      },
      close_surface_form() {
        this.surface_new_show = false
      },
      onClickLeft(){
        this.$router.back()
      }
  },
  computed:  {
      exportDxf: function () {
        return location.origin + this.get_url('get_dxf')
      },
      exportCnc: function () {
        return location.origin + this.get_url('get_cnc')
      },
      priviewImgUrl: function() {
        return this.get_url('get_png')
      },
      needCopyAndDown: function() {
        // return BrowserInfo.isAndroid && BrowserInfo.isWeChat
        return false;
      },
      selectedSufafesA: function() {
        return this.sufaceMetas.filter(v => v.selected);
      },
      selectedSufafesB: function() {
        return this.sufaceMetasB.filter(v => v.selected);
      },
      currentDate: function() {
        var date = new Date();
        return date.getFullYear() + "-" + (date.getMonth()  + 1 )+ "-" + date.getDate()
      },
      /**
       * 轨迹长度
       */
      trackLength: function() {
        let length = this.radio  * Math.abs(this.centralAngle) * Math.PI/ 180
        return (Math.sqrt(length ** 2 + this.leftheight**2)).toFixed(2);
      },
      /**
       * 是否展示重量,预览图全部加载完成再展示
       */
      canShowWeight() {
          return this.priviewImgUrls.length == this.graphMeshs.length
      },
      cutLength() {
          let total = 0
          for (let i in this.priviewImgUrls) {
            total += this.priviewImgUrls[i].length
            if (this.priviewImgUrls[i].sfno == this.showPreviewNo) {
              return this.priviewImgUrls[i].length
            }
          }
          return total
      },
      cutWeight() {
          let total = 0
          for (let i in this.priviewImgUrls) {
            total += this.priviewImgUrls[i].weight
            if (this.priviewImgUrls[i].sfno == this.showPreviewNo) {
              return this.priviewImgUrls[i].weight
            }
          }
          return total
      },
      endAngle() {
        return this.startAngle + this.twistAngle
      },
      maxRadio() {
        return Math.max(400,this.radio)
      },
      /**
       * 最大材料宽度
       */
      maxMerialLength() {
        return Math.max(40, this.sectionTmpLength);
      }
  },
  mounted () {
    this.id = this.$route.params.id
    this.init_three()
    this.getParams()
    this.animate()
    graphicAreaInited = true
    this.drawTrack(0)
    this.email = localStorage.getItem("email")
    var clipboard = new Clipboard('.downloadBnt');
    clipboard.on('success', function(e) {
        console.log(e)
        Toast.success("下载链接已复制，请在自带浏览器下载")
         //e.clearSelection();

      });
      clipboard.on('error', function(e) {
       Toast.fail("链接复制失败，请联系我们")
      });

    
    
  }
}

</script>

<style scoped>

        #preview,#sufaceview {
          overflow: hidden;
          width: 100%;
        }
        .custom-button {
          width: 26px;
          color: #fff;
          font-size: 10px;
          line-height: 18px;
          text-align: center;
          background-color: #3ea5b9;
          border-radius: 100px;
        }
        #footer {
          padding-top: 20px;
          min-height: 320px;
          text-align: center;
        }
        .van-row {
          padding-top: 20px;
        }
       .van-image {
         display: flex;
         align-items: center;
       }
       .van-slider {
         margin-top: 13px;
       }
       .contactUsBnt {
         text-align: center;
       }
       
        .contactUsBox /deep/.van-dialog__content {
          display: flex;
          justify-content: center;
       }
       #sectionPopBox /deep/.van-overlay {
          background: none
       }
       #sectionPopBox /deep/.van-dialog {
         top: 420px;
         background: #5bcdd3;
       }
       #sectionPopBox /deep/.van-button {
         background: #5bcdd3;
       }
       .selectBox {
            position: absolute;
            top: 70px;
            right: 20px;
            color: #1e0ea9;
            background: #aecbe9;
            border-radius: 5px;
            box-shadow: 10px 10px 5px #888888;
            width: 68px;
            text-align: left;
       }
       .resetCameraBnt {
            position: absolute;
            top: 240px;
            right: 20px;
       }
       .showtrack-label {
         height: 30px;
       }
       .showtrack-label /deep/ .van-checkbox__label {
         color: rgb(22, 143, 199);
         height: 20px;
       }
    </style>