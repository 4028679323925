<template>
   <div class="register-box">
        <van-form validate-first @failed="onFailed" @submit="onSubmit">
          <van-field
            v-model="username"
            name="username"
            label="用户名"
            placeholder="请输入用户名"
            :rules="[{ validator:nameValidator, required: true, message: '用户名不符合要求' }]"
          />
          <van-field
            v-model="password"
            name="password"
            label="密码"
            placeholder="请输入密码"
            type="password"
            :rules="[{ validator:passwordValidator, required: true, message: '长度6-18位字符串类型：数字、英文、特殊符号且必须包含数字及英文' }]"
          />
           <van-field
            v-model="password2"
            name="password2"
            label="密码"
            placeholder="请再次输入密码"
            type="password"
            :rules="[{ validator:passwordValidator, required: true, message: '长度6-18位字符串类型：数字、英文、特殊符号且必须包含数字及英文' }]"
          />
          <div style="margin: 16px;">
            <van-button round block type="info" native-type="submit">注册</van-button>
          </div>
        </van-form>

        <LoinBox show=true ></LoinBox>
   </div>
   
</template>

<script>
import Auth from '@/libs/auth.js'
import LoinBox from '@/components/LoginBox'
export default {
  name: 'App',
  components: {
  },
  data() {
      return {
          username: "",
          password: "",
          password2: ""
      }
  },
  methods: {
      // 校验函数返回 true 表示校验通过，false 表示不通过
    nameValidator(val) {
      return true;
    },
    passwordValidator(val) {
      return /^(?=.*\d)(?=.*[a-zA-Z])[\da-zA-Z~!@#$%^&*]{6,18}$/.test(val);
    },
    onSubmit() {
        console.log(Auth.isLogin())
    },
    onFailed() {
      console.log("failed")
    }
  }
}

</script>

<style scoped>
.register-box {
    padding-top:100px;
}
</style>