<template>
  <div id="app">
  <van-nav-bar 
    title="设置" 
    left-text="" 
    left-arrow 
    @click-left="onClickLeft" />

  <van-cell title="手机号码" :value="username" />
  <van-cell title="会员开始时间" :value="time_from"/>
  <van-cell title="会员结束时间" :value="time_to"/>
  <br/>

  <van-button type="danger" @click="logout" round block>退出登录</van-button>

  <van-popup v-model="show">
    <van-form validate-first @submit="onSubmit">
         <van-field
            v-model="old_password"
            name="old_password"
            label="旧密码"
            placeholder="请输入旧密码"
            type="password"
            :rules="[{ validator:passwordValidator, required: true, message: '长度6-18位字符串类型：数字、英文、特殊符号且必须包含数字及英文' }]"
          />
          <van-field
            v-model="new_password"
            name="new_password"
            label="新密码"
            placeholder="请输入新密码"
            type="password"
            :rules="[{ validator:passwordValidator, required: true, message: '长度6-18位字符串类型：数字、英文、特殊符号且必须包含数字及英文' }]"
          />
          <van-field
            v-model="verify_password"
            name="verify_password"
            label="确认新密码"
            placeholder="请再次输入新密码"
            type="password"
            :rules="[{ validator:passwordValidator, required: true, message: '长度6-18位字符串类型：数字、英文、特殊符号且必须包含数字及英文' }]"
          />
          <div style="margin: 16px;">
            <van-button round block type="info" native-type="submit">提交</van-button>
          </div>
        </van-form>
  </van-popup>

  <CNaviBar active="setting"></CNaviBar>
  </div>
</template>

<script>
import CNaviBar from '@/components/NaviBar'
import Commons from '@/libs/Commons'
export default {
  name: 'HelloWorld',
  components: {
    CNaviBar
  },
  data() {
    return {
      active: 2,
      username: '',
      time_from: '',
      time_to: '',
      show: false,
      old_password: '',
      new_password: '',
      verify_password: '',
    };
  },
  props: {
    msg: String
  },
  methods: {
    onSubmit() {
      var vue = this
      if (this.old_password == this.new_password) {
        this.$dialog.alert({
          message: "新密码不能和旧密码相同"
        })
        return
      }
      if (this.new_password != this.verify_password) {
        this.$dialog.alert({
          message: "两次新密码结果不相同"
        })
        return
      }

      var old_password = this.$md5(this.old_password)
      var new_password = this.$md5(this.new_password)
      this.$axios
      .put(vue.$url + '/v1/user', {
        'old_password': old_password,
        'new_password': new_password,
      })
      .then(response => {
        console.log(response)
        vue.$dialog.alert({
          message: "修改密码成功"
        })
      })
      .catch(function (error) { // 请求失败处理
        vue.$dialog.alert({
          message: error.response.data.message,
        })
        if (error.response.status == 401) {
          vue.$router.push('/login')
        }
      })

    },
    passwordValidator(val) {
      return /^(?=.*\d)(?=.*[a-zA-Z])[\da-zA-Z~!@#$%^&*]{6,18}$/.test(val);
    },
    onClickLeft() {
      this.$router.back()
    },
    showPopup() {
      this.show = true;
    },
    logout() {
      // 异步更新数据
      var vue = this
      this.$axios
      .get(vue.$url + '/v1/user/logout')
      .then(response => {
        console.log(response)
        Commons.removeLoginUser()
        vue.$router.push('/login')
      })
      .catch(function (error) { // 请求失败处理
        vue.$dialog.alert({
          message: error.response.data.message,
        });
        if (error.response.status == 401) {
          vue.$router.push('/login')
        }
      })
    },
    onload () {
      // 异步更新数据
      var vue = this
      this.$axios
      .get(vue.$url + '/v1/user')
      .then(response => {
        console.log(response.data.data)
        vue.username = response.data.data.username
        vue.time_from = response.data.data.time_from
        vue.time_to = response.data.data.time_to
      })
      .catch(function (error) { // 请求失败处理
        vue.$dialog.alert({
          message: error.response.data.message,
        });
        if (error.response.status == 401) {
          Commons.removeLoginUser()
          vue.$router.push('/login')
        }
      })
    }
  },
  mounted:function () {   //自动触发写入的函数
    this.onload();
  }
}
</script>
