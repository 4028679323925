/* eslint-disable no-multiple-empty-lines */
/* eslint-disable space-unary-ops */
/* eslint-disable space-in-parens */
/* eslint-disable semi */
/* eslint-disable no-tabs */
/* eslint-disable indent */
/* eslint-disable no-unused-vars */
/* eslint-disable space-infix-ops */
/* eslint-disable vue/valid-template-root */
<!-- 图形保存的表单-->
<template id="surfaceForm">
    <div>
    <van-action-sheet v-model="show" title="保存参数"  @close="close">
    <van-form validate-first @submit="onSubmit">
        <van-field
           v-model="name"
           name="name"
           label="应用名称"
           placeholder="请输入应用名称"
           :rules="[{ validator:nameValidator, required: true, message: '应用名称字符长度介于2-64之间' }]"
         />
        
         <van-field
           v-model="misc"
           name="misc"
           label="应用描述"
           placeholder="请输入应用描述"
           type="text"
           :rules="[{ validator:miscValidator, required: true, message: '应用描述字符长度介于4-200之间' }]"
         />
         <div style="margin: 16px;">
           <van-button round block type="info" native-type="submit">保存</van-button>
         </div>
       </van-form>
    </van-action-sheet>
    </div>
</template>
<script>
import Vue from 'vue';
import Commons from '@/libs/Commons'
import { Form } from 'vant';
import { Field } from 'vant';
import { ActionSheet } from 'vant';
import { Popup } from 'vant';

Vue.use(Popup);
Vue.use(Form);
Vue.use(Field);
Vue.use(ActionSheet);

export default {
    props: {
        // 定义你的props
        app_id: {
            type: Number,
            default: 0
        },
        show: {
            type: Boolean,
            defalut: true
        },
        app_type: {
            type: String,
            default: 'app'
        },
        app_name: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            name: this.app_name,
            misc: '',
            show_form: this.show
        }
    },
    methods: {
        async onSubmit() {
            //创建图形
            var vue = this
            var name = this.name
            var misc = this.misc
            var id = 0
            let param = {
                name: name,
                misc: misc,
                type: this.app_type
            }
            let resp = await Commons.addSurface(vue, param)
            console.log("ccc", resp)
            if (resp.code == 'ok') {
                console.log("trigger done")
                vue.$emit('done', resp.data.id, name)
            }
            
            
        },
        // 校验函数返回 true 表示校验通过，false 表示不通过
        nameValidator(val) {
            if (val.length < 2 || val.length > 64) {
                return false
            }
            return true
        },
        miscValidator(val) {
            if (val.length < 4 || val.length > 200) {
                return false
            }
            return true
        },
        close(e) {
            this.$emit('close', true)
        },
    }
}
</script>