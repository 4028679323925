import { render, staticRenderFns } from "./AppBezier.vue?vue&type=template&id=05f4512b&scoped=true&"
import script from "./AppBezier.vue?vue&type=script&lang=js&"
export * from "./AppBezier.vue?vue&type=script&lang=js&"
import style0 from "./AppBezier.vue?vue&type=style&index=0&id=05f4512b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05f4512b",
  null
  
)

export default component.exports