/* eslint-disable no-irregular-whitespace */
/* eslint-disable no-irregular-whitespace */
<template>
  <div>
    <div id="prviewArea">
      
      <div id="canvas3d"  height="320px"></div>
      <canvas id="canvas"  height="320px" v-show="false"></canvas>
    </div>
    <div id="controlerArea">
        <!-- 步骤一-->
      <div v-show="steps[0]">
        <div>设置辅助线区域</div>
        <van-row>
          <van-col span="6"><span style="color:blue;">本体直径</span></van-col>
          <van-col span="10">
            <van-slider
              v-model="diameter"
              active-color="#3ea5b9"
              :min="minDiameter"
              :max="maxDiameter"
              :step="5"
              @input="showStep1"
            >
              <template #button>
                <div class="custom-button">{{ diameter }}</div>
              </template>
            </van-slider>
          </van-col>
          <van-col span="8"
            ><van-stepper v-model="diameter" :min="minDiameter" :max="maxDiameter" :step="5" @input="showStep1"
          /></van-col>
        </van-row>
        <van-row>
          <van-col span="6"><span style="color:yellow;">回料盒尺寸</span></van-col>
          <van-col span="10">
            <van-slider
              v-model="RBoxSize"
              active-color="#3ea5b9"
              :min="minRBoxSize"
              :max="maxRBoxSize"
              :step="5"
              @input="showStep1"
            >
              <template #button>
                <div class="custom-button">{{ RBoxSize }}</div>
              </template>
            </van-slider>
          </van-col>
          <van-col span="8"
            ><van-stepper v-model="RBoxSize" :min="minRBoxSize" :max="maxRBoxSize" :step="5"
            @input="showStep1"
          /></van-col>
        </van-row>
        <van-row type="flex" justify="end">
          <van-col span="6"
            ><van-button type="info" size="small" @click="next"
              >下一步</van-button
            ></van-col
          >
        </van-row>
      </div>

       <!-- 步骤二 -->
      <div v-show="steps[1]">
         <div>设置基本尺寸</div>
        <van-row>
          <van-col span="6"><span style="color:green;">起始半径</span></van-col>
          <van-col span="10">
            <van-slider
              v-model="radio"
              active-color="#3ea5b9"
              :min="50"
              :max="600"
              :step="5"
              @input="showStep2"
            >
              <template #button>
                <div class="custom-button">{{ radio }}</div>
              </template>
            </van-slider>
          </van-col>
          <van-col span="8"
            ><van-stepper v-model="radio" :min="50" :max="600" :step="5"  @input="showStep2"
          /></van-col>
        </van-row>
        <van-row>
         <van-radio-group v-model="thDirect"  direction="horizontal" @change="showStep2">
        <van-col span="6">方向</van-col>
       
        <van-col span="9">
            <van-radio name="0">逆时针</van-radio>
        </van-col>
        <van-col span="9">
            <van-radio name="1">顺时针</van-radio>
        </van-col>
        </van-radio-group>
      </van-row>
      <van-row>
        <van-col span="6">圆心角</van-col>
        <van-col span="10">
          <van-slider v-model="theta" active-color="#3ea5b9" :min=30 :max=135 :step=5 @input="showStep2">
          <template #button>
            <div class="custom-button">{{ theta }}</div>
          </template>
        </van-slider>
        </van-col>
        <van-col span="8"><van-stepper v-model="theta" :min=30 :max=135 :step=5 @input="showStep2" /></van-col>
      </van-row>
      <van-row>
        <van-col span="6">轨迹长度</van-col>
        <van-col span="10">
          
        </van-col>
        <van-col span="8">
          {{ trackLength }}
        </van-col>
      </van-row>
      <van-row>
        <van-col span="6">落差</van-col>
        <van-col span="10">
          <van-slider v-model="drop" active-color="#3ea5b9" :min=0 :max=80 :step=5 >
          <template #button>
            <div class="custom-button">{{ drop }}</div>
          </template>
        </van-slider>
        </van-col>
        <van-col span="8"><van-stepper v-model="drop" :min=0 :max=80 :step=5 /></van-col>
      </van-row>
        <van-row type="flex" justify="end">
          <van-col span="4">
            <van-button type="info" size="small" @click="prev"
              >上一步</van-button
            >
          </van-col>
          <van-col span="4">
            <van-button type="info" size="small" @click="next"
              >下一步</van-button
            >
          </van-col>
        </van-row>
      </div>

      <div v-show="steps[2]">
        <div>调整轨迹</div>

        <van-row>
        <van-col span="6"><span style="color:yellow;">起始直段</span></van-col>
        <van-col span="10">
          <van-slider v-model="startStraight" active-color="#3ea5b9" :min=0 :max=100 :step=5 @input="showStep3">
          <template #button>
            <div class="custom-button">{{ startStraight }}</div>
          </template>
        </van-slider>
        </van-col>
        <van-col span="8"><van-stepper v-model="startStraight" :min=0 :max=100 :step=5 @input="showStep3" /></van-col>
      </van-row>

      <van-row>
        <van-col span="6"><span style="color:blue;">终止直段</span></van-col>
        <van-col span="10">
          <van-slider v-model="endStraight" active-color="#3ea5b9" :min=0 :max=100 :step=5 @input="showStep3" >
          <template #button>
            <div class="custom-button">{{ endStraight }}</div>
          </template>
        </van-slider>
        </van-col>
        <van-col span="8"><van-stepper v-model="endStraight" :min=0 :max=100 :step=5 @input="showStep3" /></van-col>
      </van-row>

      <van-row>
        <van-col span="6"><span style="color:purple;">起始圆弧</span></van-col>
        <van-col span="10">
          <van-slider v-model="startArc" active-color="#3ea5b9" :min=0 :max=2 :step=0.1 @input="showStep3" >
          <template #button>
            <div class="custom-button">{{ startArc }}</div>
          </template>
        </van-slider>
        </van-col>
        <van-col span="8"><van-stepper v-model="startArc" :min=0 :max=2 :step=0.1 @input="showStep3" /></van-col>
      </van-row>


      <van-row>
        <van-col span="6"><span style="color:#cfcf0b;">终止圆弧</span></van-col>
        <van-col span="10">
          <van-slider v-model="endArc" active-color="#3ea5b9" :min=0 :max=2 :step=0.1 @input="showStep3" >
          <template #button>
            <div class="custom-button">{{ endArc }}</div>
          </template>
        </van-slider>
        </van-col>
        <van-col span="8"><van-stepper v-model="endArc" :min=0 :max=2 :step=0.1 @input="showStep3" /></van-col>
      </van-row>


      <van-row>
        <van-col span="6"><span style="color:green;">终止偏移</span></van-col>
        <van-col span="10">
          <van-slider v-model="endOffset" active-color="#3ea5b9" :min=-80 :max=80 :step=5 @input="showStep3">
          <template #button>
            <div class="custom-button">{{ endOffset }}</div>
          </template>
        </van-slider>
        </van-col>
        <van-col span="8"><van-stepper v-model="endOffset" :min=-80 :max=80  :step=5 @input="showStep3" /></van-col>
      </van-row>


      <van-row>
        <van-col span="6"><span style="color:#cfcf0b;">终止角度</span></van-col>
        <van-col span="10">
          <van-slider v-model="endAngle" active-color="#3ea5b9" :min=-45 :max=45 :step=5 @input="showStep3" >
          <template #button>
            <div class="custom-button">{{ endAngle }}</div>
          </template>
        </van-slider>
        </van-col>
        <van-col span="8"><van-stepper v-model="endAngle" :min=-45 :max=45 :step=5 @input="showStep3" /></van-col>
      </van-row>

        <van-row type="flex" justify="end">
          <van-col span="4">
            <van-button type="info" size="small" @click="prev"
              >上一步</van-button
            >
          </van-col>
          <van-col span="4">
            <van-button type="info" size="small" @click="next"
              >下一步</van-button
            >
          </van-col>
        </van-row>
      </div>

      <div v-show="steps[3]">
        <div>确认截面:</div>
        <van-row align="center">
          <van-col span="2"></van-col>
          <van-col span="18" style="text-align: left;">
            <van-button plain type="info"   :style="{'margin': '0 2px', 'border':'none','background':  str_pad(item.color.toString(16))}" :key="index" v-for="(item, index) in sufaceMetas" @click="showSectionInfoByIdx(index, 1)"> </van-button> 
            <van-icon name="delete-o" v-show="sufaceMetas.length > 0" size="24" @click="removeLastSection(1)" />
          </van-col>
          <van-col span="4"> <van-icon name="add-o" size="34" @click="showAddSectionBox(1)"/></van-col>
        </van-row>
        <van-row>
          <van-col span="2"></van-col>
          <van-col span="18" style="text-align: left;">
            <van-button plain type="info" :style="{'margin': '0 2px', 'border':'none',  'background':  str_pad(item.color.toString(16))}" :key="index" v-for="(item, index) in sufaceMetasB" @click="showSectionInfoByIdx(index, 2)"></van-button> 
            <van-icon name="delete-o" v-show="sufaceMetasB.length > 0" size="24" @click="removeLastSection(2)" />
          </van-col>
          <van-col span="4"> <van-icon name="add-o" size="34" @click="showAddSectionBox(2)"/></van-col>
        </van-row>
        <van-row v-show="false">
          <van-col span="6">起始角度</van-col>
          <van-col span="10">
            <van-slider v-model="startAngle" active-color="#3ea5b9" :min=-180 :max=180 :step=5>
            <template #button>
              <div class="custom-button">{{ startAngle }}</div>
            </template>
          </van-slider>
          </van-col>
          <van-col span="8"><van-stepper v-model="startAngle" :min=-180 :max=180 :step=5 /></van-col>
        </van-row>
        <van-row type="flex" justify="end">
          <van-col span="4">
              <van-button type="info" size="small" @click="prev">上一步</van-button>
            </van-col>
            <van-col span="4">
              <van-button type="info" size="small" @click="next">下一步</van-button>
            </van-col>
        </van-row> 
      </div>

      <div v-show="steps[4]">
        <van-row>
        <van-col span="6">材料厚度</van-col>
        <van-col span="10">
          <van-slider v-model="thickness" active-color="#3ea5b9" :min=1 :max=3.5 :step=0.1  >
          <template #button>
            <div class="custom-button">{{ thickness }}</div>
          </template>
        </van-slider>
        </van-col>
        <van-col span="8"><van-stepper v-model="thickness" :min=1 :max=3.5  :step=0.1  /></van-col>
      </van-row>
      
      <van-row>
        <van-col span="6">扭转角度</van-col>
        <van-col span="10">
          <van-slider v-model="twistAngle" active-color="#3ea5b9"  :min=-180 :max=180 :step=5  >
          <template #button>
            <div class="custom-button">{{ twistAngle }}</div>
          </template>
        </van-slider>
        </van-col>
        <van-col span="8"><van-stepper v-model="twistAngle" :min=-180 :max=180 :step=5  /></van-col>
      </van-row>
        <van-row type="flex" justify="end">
          <van-col span="4">
              <van-button type="info" size="small" @click="prev">上一步</van-button>
            </van-col>
            <van-col span="4">
              <van-button type="info" size="small" @click="next">下一步</van-button>
            </van-col>
        </van-row> 
      </div>

      <div v-show="steps[5]">
        <div id="expandArea">
        <div :key="index" v-for="(item, index) in this.priviewImgUrls" >
          <van-image :src="item.url" alt="加载中.." height="180px" fit="contain" >
          <template v-slot:loading>图像加载中...</template>
          <template v-slot:error>图像加载中...</template>
        </van-image>
        </div>
      </div>
        <van-row type="flex" justify="end">
          <van-col span="4">
            <van-button type="info" size="small" @click="prev"
              >上一步</van-button
            >
          </van-col>
          <van-col span="8">
            <van-button type="info" size="small" @click="downLoadDxf"
              >下载2D图纸</van-button
            >
          </van-col>
          <van-col span="8">
            <van-button type="info" size="small" @click="downLoadCnc"
              >下载数控代码</van-button
            >
          </van-col>
        </van-row>
      </div>

      <div  id="sectionPopBox">
        <van-dialog v-model="showAddSection" title="截面参数" show-cancel-button :show-confirm-button="true"  :before-close="addSectionClose">
          <div id="sectionParamArea">
          <van-row>
          <van-col span="6">板材宽度</van-col>
          <van-col span="10">
            <van-slider v-model="sectionTmpLength" active-color="#3ea5b9"  :min=5 :max=200 @input="updateSectionDraw()">
            <template #button>
              <div class="custom-button">{{ sectionTmpLength }}</div>
            </template>
          </van-slider>
          </van-col>
          <van-col span="8"><van-stepper v-model="sectionTmpLength" :min=5 :max=200  @input="updateSectionDraw()" /></van-col>
        </van-row>
          <van-row>
          <van-col span="6">角度</van-col>
          <van-col span="10">
            <van-slider v-model="sectionTmpAngle" active-color="#3ea5b9"  :min=-180 :max=180 :step=5 @input="updateSectionDraw()">
            <template #button>
              <div class="custom-button">{{ sectionTmpAngle }}</div>
            </template>
          </van-slider>
          </van-col>
          <van-col span="8"><van-stepper v-model="sectionTmpAngle" :min=-180 :max=180 :step=5 @input="updateSectionDraw()" /></van-col>
        </van-row>
            </div>
        </van-dialog>
        </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import {
  Col,
  Row,
  Slider,
  Stepper,
  Image as VanImage,
  Button,
  Toast,
  Swipe,
  SwipeItem,
  Checkbox,
  CheckboxGroup,
  Popup,
  Field,
  RadioGroup,
  Radio,
} from "vant";
import * as THREE from "three";
import { ParametricGeometry } from "three/examples/jsm/geometries/ParametricGeometry";
//import { OrbitControls } from '../libs/js/OrbitControls'
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader";
import { DragControls } from "three/examples/jsm/controls/DragControls";
import { TransformControls } from "three/examples/jsm/controls/TransformControls";
import { RoomEnvironment } from "three/examples/jsm/environments/RoomEnvironment";
import VueCookies from "vue-cookies";
import { Base64 } from "js-base64";
import { Loading } from "vant";
import LoginBox from "@/libs/LoginBox";
import BrowserInfo from "@/libs/Browserinfo";
import Clipboard from "clipboard";


Vue.use(Loading);
Vue.use(VueCookies);
Vue.use(Col);
Vue.use(Slider);
Vue.use(Row);
Vue.use(Stepper);
Vue.use(Button);
Vue.use(VanImage);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Popup);
Vue.use(Field);
Vue.use(Radio);
Vue.use(RadioGroup);
export default {
  name: "bezier",
  data() {
    return {
      /* 控制显示的步骤 */
      steps: [true, false, false, false, false ,false ],
      width: 0,
      height: 0,
      /**
       * 当前激活的步骤
       */
      active: 0,
      /**
       * 直径
       */
      diameter: 300,

      minDiameter: 100,
      maxDiameter: 600,
      /**
       * 回料盒尺寸
       */
      RBoxSize: 100,
      minRBoxSize : 0,
      maxRBoxSize : 300,

      /**
       * 贝塞尔曲线四个点
       */
      bezierPoints: [],

    /**
    * 起始半径
    */
      radio: 200,
      /**
       * 圆心角
       */
      theta: 90,
      /**
       * 落差
       */
      drop: 0 ,

      /**方向  0 逆时针，1顺时针*/
      thDirect: '0',

    /**
     * 开始直段
     */
      startStraight: 0,

     /**
     * 终止直段
     */
      endStraight: 0,
      /**
       *开始圆弧
       */
      startArc: 1,
      /**
       * 终止圆弧
       * */
      endArc: 1,
      /**
       * 终止角度
       * */
      endAngle: 0,
      /**
       * 终止偏移
       * */
      endOffset: 0,

    /**
     * 画布
     */
      canvas: null,

    /**
     * 场景 
     */
      scene: null,

      renderer: null,

    /**
     * 相机
     */
      camera: null,

      /**
       * 轨迹的图形
       */
      trackSuface: null,

      /**
       * 曲面的颜色池
       */
      sufaceColors:   [0xff00ff,0xffff00,0x00ffff,0x600060,0x606000,0x006060,0xff0000,0x00ff00,0x0000ff,0x600000,0x006000,0x000060],
      /**
       * 背景颜色
       */
      backgroundColor:0xbbbbbb,
      /**
       * 控制器
       */
      controls: {},
      /**
       * 辅助坐标
       */
      axesHelper: null,
      transformControls: {},
      /**
       * threejs 所有的对象
       */
      allMeshs: [],
      /**
       * 曲面的图形
       */
      graphMeshs: [],

      /**
       * 截面的图形
       */
      sectionGraphMeshs: [],
      /**
       * 逆时针方向的截面参数
       * sufaceMetas[k].endPos = endPos
       * sufaceMetas[k].l1 = L1
       * sufaceMetas[k].l2 = L2
       * sufaceMetas[k].alpha1 = alpha1
       * sufaceMetas[k].alpha2 = alpha2
       * sufaceMetas[k].totalAngle = totalAngle
       * sufaceMetas[k].selected = true
       */
      sufaceMetas: [],
      /**
       * 顺时针方向的截面参数
       */
      sufaceMetasB:[],
      
      /**
       * 轨迹图形
       */
      curve: null,

      /**
       * 曲面的边的点集合 [{x,y,z}]
       */
      sufaceLines : [],

      /**
       * 预览图
       */
      priviewImgUrls:[],

      /** 
       * 新增截面临时参数：长度
       **/
      sectionTmpLength: 0,

      /**
       * 截面长度,用户撤销恢复
       */
      sectionTmpLengthForRestore: 0,
      
    /**
     * 新增截面临时参数：角度
     * */
      sectionTmpAngle: 0,
      /**
       * 截面宽度，用于恢复
       */
      sectionTmpAngleForRestore: 0,

      /**
       * 起始角度
       * */ 
      startAngle: 0,

      /**
       * 材料密度
       */
      density: 7.93,
      /**
       * 材料厚度
       */
      thickness: 1,
      /**
       * 扭转角度
       * */
      twistAngle: 30,
      
      /**
       * 正在添加的截面方向
       * */
      addingSection: 0,
      /**
       * true 新增 false修改 
       */
      isNewSection : false,
      /**
       * 是否展示截面参数
       **/
      showAddSection: false,
      /**
       * 材料宽度
       **/
      materialLength: 20,

    };
  },
  methods: {
    /**
     * 下一步
     */
    next() {
      if (this.active < this.steps.length - 1) {
        this.active++;
        this.showStep(this.active)
      }
    },
    /**
     * 上一步
     */
    prev() {
      if (this.active > 0) {
        this.active--;
        this.showStep(this.active)
      }
      
    },
    /**
     * 展示步骤
     */
    showStep (no) {
      for (var i = 0; i < this.steps.length; i++) {
        if (i !== no) {
          this.$set(this.steps, i, false)
        } else {
          this.$set(this.steps, i, true)
        }
      }
      let stepFunc = "this.showStep" + (no + 1) + "()";
      try {
        eval(stepFunc);
      } catch(e) {
        console.log("not func found")
      }
      
    },
    clearnAllGraphs() {
       while(this.graphMeshs.length > 0) {
         let mesh = this.graphMeshs.pop()
         this.scene.remove(mesh)
       }
    },

    getCtx() {
        if (this.canvas == null) {
            console.log("canvas not inited")
            return;
        }
        let ctx = this.canvas.getContext("2d");
        ctx.clearRect(0,0, this.canvas.width, this.canvas.height);
        return ctx;
    },
    /**
     * 或者直线
     */
    draw3DLine(color, point1, point2) {
     const material2 = new THREE.LineBasicMaterial({
        color: color
      });
      const points2 = [];
      points2.push( point1);
      points2.push( point2 );
      const geometry2 = new THREE.BufferGeometry().setFromPoints( points2 );
      const line2 = new THREE.Line( geometry2, material2 );
      this.scene.add(line2)
      this.allMeshs.push(line2)
    }, 
    drawStep1(ctx, background) {
       ctx.beginPath();
        ctx.strokeStyle = background? "gray" : "blue" ;
        let mapRadio = this.diameter * this.compressRatio
        ctx.arc(this.centerPos.x, this.centerPos.y, mapRadio / 2, 0, 2*Math.PI);
        ctx.stroke();
        ctx.closePath();
        ctx.beginPath();
        ctx.strokeStyle =  background? "gray" : "yellow";
        let boxRadio = this.RBoxSize * this.compressRatio
        ctx.arc(this.centerPos.x, this.centerPos.y, (boxRadio / 2 + mapRadio / 2), 0, 2*Math.PI);
        ctx.stroke();
        ctx.closePath();
    },
    drawStep13D(gray) {
         if ( !this.scene) {
           return ; 
         }
        const curve = new THREE.EllipseCurve(
            0,  0,           
            this.diameter / 2,  this.diameter / 2,           
            0,  2 * Math.PI,  
            false,            
            0                 
            );
            let icolor = gray ? 0x555555: 0x0000ff;
            const points = curve.getPoints( 50 );
            const geometry = new THREE.BufferGeometry().setFromPoints( points );
            const material = new THREE.LineBasicMaterial( { color: icolor } );
            const ellipse = new THREE.Line( geometry, material );
            this.scene.add(ellipse);
            this.allMeshs.push(ellipse)

            const curve2 = new THREE.EllipseCurve(
            0,  0,           
             this.RBoxSize / 2 + this.diameter / 2,  this.RBoxSize / 2 + this.diameter / 2,           
            0,  2 * Math.PI,  
            false,            
            0                 
            );
            icolor = gray ? 0x555555: 0xedfb1c;
            const points2 = curve2.getPoints( 50 );
            const geometry2 = new THREE.BufferGeometry().setFromPoints( points2 );
            const material2 = new THREE.LineBasicMaterial( { color: icolor } );
            const ellipse2 = new THREE.Line( geometry2, material2 );
            this.scene.add(ellipse2);
            this.allMeshs.push(ellipse2)
    },
    drawStep2(ctx, background) {
       ctx.beginPath();
       let iRadio = this.radio * this.compressRatio / 2
       ctx.strokeStyle = background? "gray" : "green";
       ctx.moveTo(this.centerPos.x, this.centerPos.y);
       ctx.lineTo(this.centerPos.x + iRadio, this.centerPos.y);
       ctx.stroke();
       ctx.closePath();
       ctx.beginPath();
       ctx.strokeStyle =  background? "gray" : "yellow";
       let flag = this.thDirect == '0' ? -1 : 1
       let angle = flag * this.theta / 180 * Math.PI
       ctx.arc(this.centerPos.x, this.centerPos.y, iRadio, 0, angle, this.thDirect == '0');
       ctx.stroke();
       ctx.closePath();
    },
    drawStep23D(gray) {
      let color = gray ? 0x555555: 0x00ff00;
       const material = new THREE.LineBasicMaterial({
        color: color
      });

      const points = [];
      points.push( new THREE.Vector3( 0, 0, 0 ) );
      points.push( new THREE.Vector3( this.radio / 2, 0, 0 ) );

      const geometry = new THREE.BufferGeometry().setFromPoints( points );

      const line = new THREE.Line( geometry, material );
      this.scene.add( line );
      this.allMeshs.push(line)
       let flag = this.thDirect == '0' ? 1 : -1
       let angle = flag * this.theta / 180 * Math.PI

      const curve = new THREE.EllipseCurve(
        0,  0,            // ax, aY
        this.radio / 2, this.radio / 2,           // xRadius, yRadius
        0,  angle,  // aStartAngle, aEndAngle
        this.thDirect == '1',            // aClockwise
        0                 // aRotation
      );

      const points1 = curve.getPoints( 50 );
      const geometry1 = new THREE.BufferGeometry().setFromPoints( points1 );
      color = gray ? 0x555555: 0xff0000;
      const material1 = new THREE.LineBasicMaterial( { color: color } );
      const ellipse = new THREE.Line( geometry1, material1 );
      this.scene.add( ellipse );
      this.allMeshs.push(ellipse)

    },
    drawStep3(ctx, background) {
      ctx.beginPath();
      let iRadio = this.radio * this.compressRatio / 2
      //符号
      let flag = this.thDirect == '0' ? -1 : 1
      let angle = flag * this.theta / 180 * Math.PI
      let startPos = {x: this.centerPos.x +  iRadio, y: this.centerPos.y}
      let endPos = { x:  this.centerPos.x + iRadio * Math.cos(angle), y: this.centerPos.y + iRadio * Math.sin(angle)}
      
      //起始直段
      ctx.moveTo(startPos.x, startPos.y)
      ctx.strokeStyle =  background? "gray" : "yellow";
      let startStraight = this.startStraight * this.compressRatio;
      ctx.lineTo(startPos.x,  (flag * startStraight + startPos.y) )
      ctx.stroke();
      ctx.closePath();
      // 起始圆弧
      ctx.beginPath();
      ctx.moveTo(startPos.x,  (flag * startStraight + startPos.y) )
      ctx.strokeStyle =  background? "gray" : "purple";
      //起始圆弧终点
      let lengthc=parseFloat(iRadio)*(4/3)*((1-Math.cos(angle/2))/(Math.sin(angle/2)))*parseFloat(this.startArc);
      let Xc =lengthc*Math.cos(Math.PI / 2) + startPos.x;
      let Yc =lengthc*Math.sin(Math.PI / 2) + (flag * startStraight + startPos.y);
      ctx.lineTo(Xc, Yc);
      ctx.stroke();
      ctx.closePath();
      
      //终止偏移
      ctx.beginPath();
      ctx.strokeStyle =  background? "gray" : "green";
      let offsetLength = this.endOffset * this.compressRatio;
      ctx.moveTo(endPos.x, endPos.y)
      let offsetEndPos = {x:Math.cos(angle) * (offsetLength + iRadio) + this.centerPos.x , y:Math.sin(angle) * (offsetLength + iRadio) + this.centerPos.y}
      ctx.lineTo( offsetEndPos.x ,  offsetEndPos.y)
      ctx.stroke();
      ctx.closePath();
 
      //终止直段
      ctx.beginPath();
      let endStraight =  this.endStraight * this.compressRatio;
      ctx.strokeStyle =  background? "gray" : "blue";
      ctx.moveTo(offsetEndPos.x ,  offsetEndPos.y)
      /**
       * 终止直段终点坐标
       */
      let offsetStraightEndPos = {x: offsetEndPos.x + endStraight * Math.cos(angle - flag * Math.PI/2), y:offsetEndPos.y + endStraight * Math.sin(angle - flag * Math.PI/2)}
      ctx.lineTo(offsetStraightEndPos.x ,  offsetStraightEndPos.y)
      ctx.stroke();
      ctx.closePath();

      //终止圆弧
      ctx.beginPath()
      ctx.strokeStyle =  background? "gray" : "#cfcf0b";
      ctx.moveTo(offsetStraightEndPos.x, offsetStraightEndPos.y);
      let length3= (iRadio + offsetLength) *(4/3)*((1-Math.cos(angle/2))/(Math.sin(angle/2)))*parseFloat(this.endArc);
      let arcAngle = ( flag * this.theta -  flag * 90 + this.endAngle) / 180* Math.PI;
      let X3=flag * length3 * Math.cos( arcAngle) + offsetStraightEndPos.x;
      let Y3=flag * length3 * Math.sin( arcAngle) + offsetStraightEndPos.y;
      ctx.lineTo(X3, Y3)
      ctx.stroke();
      ctx.closePath();

      /**
       * 贝塞尔曲线
       */

      ctx.beginPath()
      ctx.strokeStyle = "red";
      ctx.moveTo(startPos.x,  (flag * startStraight + startPos.y))
      ctx.bezierCurveTo(Xc, Yc,X3,Y3, offsetStraightEndPos.x ,  offsetStraightEndPos.y);
      ctx.stroke();
      ctx.closePath();

      this.bezierPoints = [{x:startPos.x, y: (flag * startStraight + startPos.y) }, 
                            {x:Xc,y:Yc},
                            {x:X3, y:Y3}, 
                            {x:offsetStraightEndPos.x, y:offsetStraightEndPos.y}];

    },
    drawStep33D(gray) {
       let iRadio = this.radio  / 2
      //符号
      let flag = this.thDirect == '0' ? 1 : -1
      let angle = flag * this.theta / 180 * Math.PI
      let startPos = {x:  iRadio, y: 0}
      let endPos = { x: iRadio * Math.cos(angle), y:  iRadio * Math.sin(angle)}
      
      //起始直段
    
      let color  =  gray ? 0x555555 : 0xffff00;
      let startStraight = this.startStraight ;
      this.draw3DLine(color, new THREE.Vector3( startPos.x, startPos.y, 0 ), new THREE.Vector3( startPos.x , (flag * startStraight + startPos.y) , 0 ))
      
      // 起始圆弧
      //起始圆弧终点
      let lengthc=  parseFloat(iRadio)*(4/3)*((1-Math.cos(angle/2))/(Math.sin(angle/2)))*parseFloat(this.startArc);
      let Xc =lengthc*Math.cos(Math.PI / 2) + startPos.x;
      let Yc =lengthc*Math.sin(Math.PI / 2) + (flag * startStraight + startPos.y);
      color = gray ?  0x555555 : 0x800080;
      this.draw3DLine(color, new THREE.Vector3( startPos.x , (flag * startStraight + startPos.y) , 0 ), new THREE.Vector3( Xc , Yc , 0 ) )


      
      //终止偏移
      let offsetLength = this.endOffset ;
      let offsetEndPos = {x:Math.cos(angle) * (offsetLength + iRadio)  , y:Math.sin(angle) * (offsetLength + iRadio)}
      color = gray ?  0x555555 : 0x00ff00;
      this.draw3DLine(color, new THREE.Vector3( endPos.x, endPos.y , 0 ), new THREE.Vector3(  offsetEndPos.x ,  offsetEndPos.y, 0 ) )
 
      //终止直段

      let endStraight =  this.endStraight ;
      /**
       * 终止直段终点坐标
       */
      let offsetStraightEndPos = {x: offsetEndPos.x + endStraight * Math.cos(angle - flag * Math.PI/2), y:offsetEndPos.y + endStraight * Math.sin(angle - flag * Math.PI/2)}
      color = gray ?  0x555555 : 0x0000ff;
      this.draw3DLine(color, new THREE.Vector3( offsetEndPos.x ,  offsetEndPos.y, 0 ), new THREE.Vector3( offsetStraightEndPos.x ,  offsetStraightEndPos.y, 0 ) )
    

      //终止圆弧
      let length3= (iRadio + offsetLength) *(4/3)*((1-Math.cos(angle/2))/(Math.sin(angle/2)))*parseFloat(this.endArc);
      let arcAngle = ( flag * this.theta -  flag * 90 + this.endAngle) / 180* Math.PI;
      let X3=flag * length3 * Math.cos( arcAngle) + offsetStraightEndPos.x;
      let Y3=flag * length3 * Math.sin( arcAngle) + offsetStraightEndPos.y;
      color = gray ?  0x555555 : 0xcfcf0b
      this.draw3DLine(color, new THREE.Vector3( offsetStraightEndPos.x, offsetStraightEndPos.y, 0 ), new THREE.Vector3( X3, Y3, 0 ) )

       /**
         * 贝塞尔曲线
         */
        const bezierCurve = new THREE.CubicBezierCurve(
          new THREE.Vector2( startPos.x, (flag * startStraight + startPos.y) ),
          new THREE.Vector2( Xc, Yc ),
          new THREE.Vector2( X3,Y3),
          new THREE.Vector2( offsetStraightEndPos.x ,  offsetStraightEndPos.y )
        )
        const bezierPoints = bezierCurve.getPoints( 50 );
        const geometry3 = new THREE.BufferGeometry().setFromPoints( bezierPoints );
        color = gray ?  0x555555 : 0xff0000
        const material3 = new THREE.LineBasicMaterial( { color: color } );
        const curveObject = new THREE.Line( geometry3, material3 )
        this.scene.add(curveObject);
        this.allMeshs.push(curveObject)

        this.bezierPoints = [{x:startPos.x, y: (flag * startStraight + startPos.y) }, 
                            {x:Xc,y:Yc},
                            {x:X3, y:Y3}, 
                            {x:offsetStraightEndPos.x, y:offsetStraightEndPos.y}];
    },

    /**
     * 获取轨迹的图形
     */
    get3DTrack() {

       let X0 = this.bezierPoints[0].x - this.centerPos.x;
       let Y0 = this.bezierPoints[0].y - this.centerPos.y;
       let X1 = this.bezierPoints[1].x - this.centerPos.x;
       let Y1 = this.bezierPoints[1].y - this.centerPos.y;
       let X2 = this.bezierPoints[2].x - this.centerPos.x;
       let Y2 = this.bezierPoints[2].y - this.centerPos.y;
       let X3 = this.bezierPoints[3].x - this.centerPos.x;
       let Y3 = this.bezierPoints[3].y - this.centerPos.y;

       class CustomSinCurve extends THREE.Curve {

          constructor( scale = 1 ) {

          super();

          this.scale = scale;

          }
          
          getPoint( t, optionalTarget = new THREE.Vector3() ) {

          const tx = X0*(1-t)**3+3*X1*t*(1-t)**2+3*X2*t**2*(1-t)+X3*t**3;
          const ty = (Y0)*(1-t)**3+3*(Y1)*t*(1-t)**2+3*(Y2)*t**2*(1-t)+(Y3)*t**3;
          const tz = 0;

          return optionalTarget.set( tx, ty , tz).multiplyScalar( this.scale );

          }

        }

          this.curve = new CustomSinCurve( 1 );
          const tube_geometry = new THREE.TubeGeometry( this.curve, 100, 1, 8, false );
          const tube_material = new THREE.MeshBasicMaterial( { color: 0xff0000, side:THREE.DoubleSide } );
          const tube_mesh = new THREE.Mesh( tube_geometry, tube_material );
          return tube_mesh;
    },
    /**
     * 清除所有的对象
     */
    clearAllMesh() {
      while (this.allMeshs.length > 0) {
        var mesh = this.allMeshs.pop()
        this.scene.remove(mesh)
      }
      this.trackSuface = null;
      this.sectionGraphMeshs = [];
    } , 
   /**
    * 根据参数获取曲面图形
    */
    getCurveSuface(n, α_vector, points0,α1, α2, β,L1, L2) {
      console.log("根据参数获取曲面图形", n, α_vector, points0,α1, α2, β,L1, L2)
       var points1X = new Array();
        var points1Y = new Array();
        var points1Z = new Array();
        var points2X = new Array();
        var points2Y = new Array();
        var points2Z = new Array();
        var line1 = [];
        var line2 = [];
        var i =0;
        for(i=0;i<n+1;i++){
          points1X[i]=(L1*Math.cos(α1+(i/n)*β)*Math.cos(α_vector[i])+points0[i].x);
          points1Y[i]=(L1*Math.cos(α1+(i/n)*β)*Math.sin(α_vector[i])+points0[i].y);
          points1Z[i]=-(L1*Math.sin(α1+(i/n)*β)+points0[i].z)

          line1.push([points1X[i],points1Y[i], points1Z[i]])

          points2X[i]=(L2*Math.cos(α2+(i/n)*β)*Math.cos(α_vector[i])+points0[i].x);
          points2Y[i]=(L2*Math.cos(α2+(i/n)*β)*Math.sin(α_vector[i])+points0[i].y);
          points2Z[i]=-(L2*Math.sin(α2+(i/n)*β)+points0[i].z)

          line2.push([points2X[i],points2Y[i], points2Z[i]])

        }
        var surface_geometry = new THREE.BufferGeometry();
  
        var vertices = [];
        for (i=0; i<n; i++){

          vertices[i*18]=points1X[i]
          vertices[i*18+1]=points1Y[i]
          vertices[i*18+2]=points1Z[i]

          vertices[i*18+3]=points1X[i+1]
          vertices[i*18+4]=points1Y[i+1]
          vertices[i*18+5]=points1Z[i+1]

          vertices[i*18+6]=points2X[i+1]
          vertices[i*18+7]=points2Y[i+1]
          vertices[i*18+8]=points2Z[i+1]

          vertices[i*18+9]=points2X[i+1]
          vertices[i*18+10]=points2Y[i+1]
          vertices[i*18+11]=points2Z[i+1]

          vertices[i*18+12]=points2X[i]
          vertices[i*18+13]=points2Y[i]
          vertices[i*18+14]=points2Z[i]

          vertices[i*18+15]=points1X[i]
          vertices[i*18+16]=points1Y[i]
          vertices[i*18+17]=points1Z[i]
        }

        const ac = new Float32Array(vertices)

        surface_geometry.setAttribute( 'position', new THREE.BufferAttribute( ac, 3 ) );
        //surface_geometry.computeFaceNormals();
        surface_geometry.computeVertexNormals();

        const surface_material = new THREE.MeshPhongMaterial({color: 0xff00ff, side:THREE.DoubleSide, /*ambient:0xff00ff,*/emissive:0x000000,specular:0xffffff, shininess: 10,wireframe: false,transparent: true,opacity: 0.6});
      
        const surface_mesh = new THREE.Mesh( surface_geometry, surface_material );

        surface_mesh.meta = {"line1": line1, "line2": line2};
        return surface_mesh
    },
    /**
     * 第六步骤，绘制曲面
     */
    drawStep6() {
      console.log("第六步骤，绘制曲面")
      this.clearAllMesh()
      this.drawTrack()

      ;
     this.sufaceLines = [];
      /**
       * 
       */
      let n = 50;
      var vector = new Array();
      var L_vector = new Array();
      var α_vector = new Array();
      var points0 = new Array();
      
      for(var i=0; i< n+1;i++){
          vector[i] = this.curve.getTangentAt(i/n);
          L_vector[i]=Math.sqrt(vector[i].x**2+vector[i].y**2);
          if (vector[i].y>0)	α_vector[i]=Math.acos(vector[i].x/L_vector[i])-Math.PI/2;
          else if (vector[i].y<0)	α_vector[i]=-Math.acos(vector[i].x/L_vector[i])-Math.PI/2;
          else 		α_vector[i]=0-Math.PI/2;
          
        }

        for(i=0;i<n+1;i++){
          points0[i] = this.curve.getPointAt(i/n);
        }


      for (let k=0;k < this.sufaceMetas.length; k++) {
            let surfaceMeta = this.sufaceMetas[k]
            let α1 = surfaceMeta.alpha1 ;
            let α2 = surfaceMeta.alpha2 ; 
            let β = surfaceMeta.beta / 180 * Math.PI; 
            let L1 = surfaceMeta.l1; 
            let L2 = surfaceMeta.l2
            let color = surfaceMeta.color
            const surface_mesh =   this.getCurveSuface(n, α_vector, points0,α1, α2, β,L1, L2)
            this.sufaceLines.push(surface_mesh.meta.line1)
            this.sufaceLines.push(surface_mesh.meta.line2)
            this.scene.add(surface_mesh);
            this.allMeshs.push(surface_mesh)
      }

      for (let k=0;k < this.sufaceMetasB.length; k++) {
            let surfaceMeta = this.sufaceMetasB[k]
            let α1 = surfaceMeta.alpha1 ;
            let α2 = surfaceMeta.alpha2 ; 
            let β = surfaceMeta.beta / 180 * Math.PI; 
            let L1 = surfaceMeta.l1; 
            let L2 = surfaceMeta.l2
            let color = surfaceMeta.color
            const surface_mesh =   this.getCurveSuface(n, α_vector, points0,α1, α2, β,L1, L2)
            this.sufaceLines.push(surface_mesh.meta.line1)
            this.sufaceLines.push(surface_mesh.meta.line2)
            this.scene.add(surface_mesh);
            this.allMeshs.push(surface_mesh)
      }
              
      
      
    },
    reset2DView() {
      if (!this.scene) return;
      this.controls.enableRotate  = false
      this.camera.position.y = 0
      this.camera.position.z = 5200
      this.camera.position.x = 0
    },
    /**
     * 展示第一步
     */
    showStep1() {
        this.reset2DView()
        this.clearAllMesh()
        this.drawStep13D()
    },
    /**
     * 展示第二步
     */
    showStep2() {
        this.reset2DView()
        this.clearAllMesh()
        this.drawStep13D(true)
        this.drawStep23D()
    },
    showStep3() {
       this.reset2DView()
        this.clearAllMesh()
        this.drawStep13D(true)
        this.drawStep23D(true)
        this.drawStep33D()
    },

    showStep4() {
      this.controls.enableRotate  = true
       this.clearAllMeths('all');
       this.drawTrack()
       this.draw3DsufaceSection();
      
    },
    showStep5() {

    },
    showStep6() {
      this.drawStep6()
      this. getPrivewImg()
    },

     /**
     * 更新曲面的内容
     * "beta" : β,"theta" : θ, "h" : H, "r" : R
     */
    updateSufaceMetaAngleAndRadio(sufaceMetas, radio, H, startAngle, twistAngle, theta) {
        /**
         * 更新圆心角和半径信息
         * 根据起始角度，重新计算alph1,alpth2 L1,L2
         */
        let lastPos = {x : 0, y: 0};
        let lastTotalAngle = startAngle;
        let alpha1 = 0;
        let L1 = 0;
        for (var j =0; j<sufaceMetas.length; j++) {
           sufaceMetas[j].r = radio;
           sufaceMetas[j].beta = twistAngle
           sufaceMetas[j].h = H
           sufaceMetas[j].theta = theta
           sufaceMetas[j].startAngle = startAngle
         }
        //if (startAngle != 0) {
           //根据起始角度重新计算曲面参数
           for (j =0; j<sufaceMetas.length; j++) {
             let totalAngle = lastTotalAngle + sufaceMetas[j].angle;
              let x = sufaceMetas[j].mL * Math.cos(totalAngle/180*Math.PI) + lastPos.x
              let y = sufaceMetas[j].mL * Math.sin(totalAngle/180*Math.PI) + lastPos.y
              let L2 = Math.sqrt(x**2+y**2)
              let alpha2 = 0;
              if (y>0)	alpha2=-Math.acos(x/L2);
              else if (y<0)	alpha2=Math.acos(x/L2);
              else 		alpha2=0;

              sufaceMetas[j].l1 = L1;
              sufaceMetas[j].l2 = L2;
              sufaceMetas[j].alpha1 = alpha1;
              sufaceMetas[j].alpha2 = alpha2;
              // 加上就影响截面绘制，不加就不会
              sufaceMetas[j].endPos = {x:x, y:y}

              lastPos = {x:x, y:y}
              alpha1 = alpha2
              L1 = L2
              lastTotalAngle = totalAngle
           }
        // }
    }, 
    /**
     * 生成曲面/截面的编号 0-1,0-2 0-A 0-B
     */
    genSurfaceNo(pos, index) {
        if (pos == 1) {
           return  (index + 1)
        } else {
          return  String.fromCharCode(index + 65)
        }
    },
    /**
     * 根据位置获取曲面(截面)参数
     */
    getsufaceMetasBySite(pos) {
        return pos == 1 ? this.sufaceMetas : this.sufaceMetasB;
    }, 
  
    /**
     * 删除指定方向最新的截面
     */
    removeLastSection(pos) {
      this.getsufaceMetasBySite(pos).pop();
      this.draw3DsufaceSection()
    },
    
    /**
     * 展示添加指定(pos)方向的截面
     */
    showAddSectionBox(pos) {
      this.addingSection = pos;
      this.showAddSection = true;
      //下标等于则添加，因为新增可能还有动态调整
      this.sectionModifyIdx =  this.getsufaceMetasBySite(this.addingSection).length
      this.isNewSection = true
    },
    /**
     * 点击某个截面的按钮，弹出来展示
     */
    showSectionInfoByIdx(index, pos) {
        let sufaceMetas = this.getsufaceMetasBySite(pos);
        if (sufaceMetas.length > index) {
            this.sectionTmpAngleForRestore  =  this.sectionTmpAngle = sufaceMetas[index].angle
            this.sectionTmpLengthForRestore = this.sectionTmpLength = sufaceMetas[index].mL
            this.sectionModifyIdx = index
            this.showAddSection = true
            this.addingSection = pos
            this.isNewSection = false
        }
    },
     /**
     * 更新第index个曲面的角度和长度
     * 如果index < -1 ，则插入到最后，
     * 如果index > 0 and < sufaceMetas , 更新index 之后的值
     * no 编号
     */
    addAndUpdateSufafMetaIdx(sufaceMetas, angle, length, index ) {
         let lastPos = {x : 0, y: 0};
         let lastTotalAngle = 0;
         let alpha1 = 0;
         let L1 = 0;
         let vue = this
         if ( index > -1 && index < sufaceMetas.length) {
           //更新中间
           sufaceMetas[index].angle = angle
           sufaceMetas[index].mL = length
            for (var k = index; k < sufaceMetas.length; k++) {
              if (k > 0) {
                lastPos = sufaceMetas[k - 1].endPos
                lastTotalAngle = sufaceMetas[k - 1].totalAngle
                alpha1 = sufaceMetas[k - 1].alpha2
                L1 = sufaceMetas[k - 1].l2
              }
              let totalAngle = lastTotalAngle + sufaceMetas[k].angle;
              let x = sufaceMetas[k].mL * Math.cos(totalAngle/180*Math.PI) + lastPos.x
              let y = sufaceMetas[k].mL * Math.sin(totalAngle/180*Math.PI) + lastPos.y
              let endPos = {x:x, y:y};
              let L2 = Math.sqrt(x**2+y**2)
              let alpha2 = 0;
              if (y>0)	alpha2=-Math.acos(x/L2);
              else if (y<0)	alpha2=Math.acos(x/L2);
              else 		alpha2=0;
              sufaceMetas[k].endPos = endPos
              sufaceMetas[k].l1 = L1
              sufaceMetas[k].l2 = L2
              sufaceMetas[k].alpha1 = alpha1
              sufaceMetas[k].alpha2 = alpha2
              sufaceMetas[k].totalAngle = totalAngle
              sufaceMetas[k].selected = true
              }
         } else {
           //追加到最后
            if (sufaceMetas.length > 0 ) {
              lastPos = sufaceMetas[sufaceMetas.length - 1].endPos
              lastTotalAngle = sufaceMetas[sufaceMetas.length - 1].totalAngle
              alpha1 = sufaceMetas[sufaceMetas.length - 1].alpha2
              L1 = sufaceMetas[sufaceMetas.length - 1].l2
            }
            let totalAngle = lastTotalAngle + angle;
            let x = length * Math.cos(totalAngle/180*Math.PI) + lastPos.x
            let y = length * Math.sin(totalAngle/180*Math.PI) + lastPos.y
            let endPos = {x:x, y:y};
            let L2 = Math.sqrt(x**2+y**2)
            let alpha2 = 0;
            if (y>0)	alpha2=-Math.acos(x/L2);
            else if (y<0)	alpha2=Math.acos(x/L2);
            else 		alpha2=0;
            let oneSufaceMeta = {
                totalAngle: totalAngle,
                alpha1: alpha1,
                l1: L1,
                l2:L2,
                alpha2:alpha2,
                endPos: endPos,
                mL: length,
                angle: angle,
                selected: true,
                sfno: vue.genSurfaceNo(this.addingSection, index)
            }
            sufaceMetas.push(oneSufaceMeta);
         }
        
    },
   /**
    * 清空threejs的图形
    */
    clearAllMeths(type) {
        while (this.allMeshs.length > 0) {
            let graphMesh = this.allMeshs.pop()
            this.scene.remove(graphMesh)
        }
        this.scene.remove(this.axesHelper)
    },
    /**
     * 更新截面
     */
    updateSectionDraw( ) {

            this.clearAllMeths('all');
            let length = this.sectionTmpLength
            let angleDiff = this.sectionTmpAngle
            let sufaceMetas = this.getsufaceMetasBySite(this.addingSection)
            this.addAndUpdateSufafMetaIdx(sufaceMetas, angleDiff, length, this.sectionModifyIdx );
            this.drawTrack()
            this.draw3DsufaceSection();
    },

    /**
     * 绘制轨迹
     */
    drawTrack() {
      if (!this.trackMesh) {
        this.scene.add(this.trackMesh);
      }
      this.trackMesh = this.get3DTrack();
      this.scene.add(this.trackMesh);
      this.allMeshs.push(this.trackMesh)
    },

     /**
     * 绘制3d的截面
     */
    draw3DsufaceSection() {
     this.updateSufaceMetaAngleAndRadio(this.sufaceMetas, this.radio, this.drop, this.startAngle, this.twistAngle, this.centralAngle)
     this.updateSufaceMetaAngleAndRadio(this.sufaceMetasB, this.radio, this.drop, this.startAngle, this.twistAngle, this.centralAngle)
      
      var lastPos = {x:0, y:0}
  
      const centerGeometry = new THREE.BufferGeometry().setFromPoints([new THREE.Vector3( 0 , 0, 0 ) ]);
      var meterial = new THREE.PointsMaterial({
        color: new THREE.Color(0xff000f),
        size: 2.0
      })
      var points = new THREE.Points(centerGeometry, meterial)
      this.scene.add(points)
      this.sectionGraphMeshs.push(points)
      this.allMeshs.push(points)
      for (let i=0 ;  i < this.sufaceMetas.length; i ++) { 
          let csuface = this.sufaceMetas[i];
          const points = [];
          points.push( new THREE.Vector3( lastPos.x , lastPos.y, 0 ) );
          points.push( new THREE.Vector3( csuface.endPos.x , csuface.endPos.y, 0 ) );
          lastPos = csuface.endPos
          const geometry = new THREE.BufferGeometry().setFromPoints( points );

          let color = this.sufaceColors[i % this.sufaceColors.length];
          const material = new THREE.LineBasicMaterial({
              color: color
            });
          //生成编号
          csuface['idx'] = i
          csuface['color'] = color
          const line = new THREE.Line( geometry, material );
          this.sectionGraphMeshs.push(line)
          this.allMeshs.push(line)
          this.scene.add( line );
      }

      //this.getTextGemotery(this.sufaceMetas[0])

      lastPos = {x:0, y:0}
      for (let i=0 ;  i <  this.sufaceMetasB.length; i++) { 
          let csuface = this.sufaceMetasB[i];
          const points = [];
          points.push( new THREE.Vector3( lastPos.x , lastPos.y, 0 ) );
          points.push( new THREE.Vector3( csuface.endPos.x , csuface.endPos.y, 0 ) );
          lastPos = csuface.endPos
          const geometry = new THREE.BufferGeometry().setFromPoints( points );
          let color = this.sufaceColors[this.sufaceColors.length - i % this.sufaceColors.length - 1];
          //生成编号
          csuface['idx'] = parseInt(i) + this.sufaceMetas.length
          csuface['color'] = color
          const material = new THREE.LineBasicMaterial({
              color: color
            });
          const line = new THREE.Line( geometry, material );
          this.sectionGraphMeshs.push(line)
          this.allMeshs.push(line)
          this.scene.add( line );
      }

      

    },
    /**
     * 确认添加截面
     */
    addSectionClose(action, done) {
      

        if ('confirm' == action) {

            //计算新曲面的坐标，并加入到曲面数组
            this.updateSectionDraw()
            
        } else {
          console.log("anthor action" , action)
          if (this.sectionModifyIdx > 0 && this.isNewSection) {
            //TODO 还原，不保存最后新增的曲面，修改的没有取消
            this.getsufaceMetasBySite(this.addingSection).pop()
            
          } else {
            //还原
            this.getsufaceMetasBySite(this.addingSection)[this.sectionModifyIdx].mL = this.sectionTmpLengthForRestore
            this.getsufaceMetasBySite(this.addingSection)[this.sectionModifyIdx].angle = this.sectionTmpAngleForRestore
            
            //addAndUpdateSufafMetaIdx
          }

          while (this.sectionGraphMeshs.length > 0) {
                let graphMesh = this.sectionGraphMeshs.pop()
                this.scene.remove(graphMesh)
              // renderer.deallocateObject( graphMesh );
            }
          this.draw3DsufaceSection();
        }
        
         this.sectionModifyIdx = -1 ;
        return done()
    },
    
    /**
     * 获取预览图
     */
    getPrivewImg() {
      var url = '';
      url = this.$url + '/v2/surface/preview'
      var vue = this

      vue.priviewImgUrls = []
      for (var i = 1; i < vue.sufaceLines.length; i++) {
        
          var param = {
            "line1": vue.sufaceLines[i - 1],
            "line2": vue.sufaceLines[i ],

          };
          let data = {
             "params":param
          }
          //{{ index == 0 ? '0' : String.fromCharCode(64 + index) }} - {{ String.fromCharCode(index + 65)}} 
         let sfno = "on";
          var fc = function(i, sfno, data) {
              vue.$axios.post(url,data).then(res=>{
                        if (res && res.data.code == 'ok') {
                            let baseImgData = "data:image/png;base64,"+res.data.data.image;
                            //周长
                            let length = res.data.data.arc_length ? res.data.data.arc_length /1000 : 1
                            //面积
                            let area  = res.data.data.contour_area ? res.data.data.contour_area : 1
                            //重量
                            let weight = (((area *  vue.thickness ) / 1000) * vue.density) / 1000
                            vue.priviewImgUrls.push({url:baseImgData, idx: i, sfno: sfno,length:length,area:area,weight:weight})
                        }
                        
                      });
          } ;
          fc(i, sfno, data);
          
      }
    },
    downloadFile(url,  data) {
      var vue = this
       vue.$axios.post(url,data).then((res) => {
        console.log(res)
        if (window.navigator.msSaveBlob) {
          //IE以及IE内核的浏览器
          try {
            window.navigator.msSaveBlob(res.data, data.name); 
          } catch (e) {console.log("down error", e)}
        } else {
        //在headers中截取filename
        var filename = data.name
        var blob = new Blob([res.data], {type: "application/octet-stream"})
          var downloadElement = document.createElement('a')
          var href = window.URL.createObjectURL(blob); //创建下载的链接
          downloadElement.href = href;
          downloadElement.download = filename 
          document.body.appendChild(downloadElement);
          downloadElement.click(); //点击下载
          document.body.removeChild(downloadElement); //下载完成移除元素
          window.URL.revokeObjectURL(href); //释放掉blob对象 
        }
      });
    }, 
    downLoadDxf() {
      var url = '';
      url = this.$url + '/v2/surface/dxf'
      var vue = this
      var param = {
        "line1": vue.sufaceLines[0],
        "line2": vue.sufaceLines[1],

      };
      let data = {
        "name": "c.dxf",
        "params":param
      }
      this.downloadFile(url, data);
    },
    downLoadCnc() {
      var url = '';
      url = this.$url + '/v2/surface/cnc'
      var vue = this
      var param = {
        "line1": vue.sufaceLines[0],
        "line2": vue.sufaceLines[1],

      };
      let data = {
        "name": "c.cnc",
        "params":param
      }
      this.downloadFile(url, data);
    },

    /**
     * 初始化threejs
     */
    initThree () {
      console.log("init three scene")
      this.scene = new THREE.Scene()
      // 点光源
      const ambientLight = new THREE.AmbientLight(0x444444)
      this.scene.add(ambientLight)

      const DirLight = new THREE.DirectionalLight(0xffffff, 0.8)
      this.scene.add(DirLight)
      this.width = window.innerWidth // 窗口宽度
      this.height = 320
      
      let spotLight = new THREE.SpotLight(0xffffff,1.6)
      spotLight.angle = Math.PI / 2
      spotLight.penumbra = 0
      spotLight.position.set(600,0,0)
      this.scene.add(spotLight)

      let spotLight2 = new THREE.SpotLight(0xffffff,1.6)
      spotLight2.angle = Math.PI / 2
      spotLight2.penumbra = 0
      spotLight2.position.set (-600,0,0)
      this.scene.add(spotLight2)
      // 创建相机对象
      //this.camera = new THREE.PerspectiveCamera(this.cameraWatchAngle, width / height, 0.1, 10000)
      this.camera = new THREE.OrthographicCamera( this.width / - 2, this.width / 2, this.height / 2, this.height / - 2, 1, 10000 )
      //camera.add(pointLight)
      //this.scene.fog = new THREE.Fog(0xd3e6f0, 1,200)
      this.scene.add(this.camera)
      

  
      /**
              * 创建渲染器对象
              */
      this.renderer = new THREE.WebGLRenderer({ antialias: true })
      this.renderer.setSize(this.width, this.height)// 设置渲染区域尺寸
      this.renderer.setClearAlpha(0.0)
			this.renderer.outputEncoding = THREE.sRGBEncoding;
      document.getElementById('canvas3d').appendChild(this.renderer.domElement) // body元素中插入canvas对象
      const pmremGenerator = new THREE.PMREMGenerator( this.renderer );
      this.scene.background = new THREE.Color( this.backgroundColor );
			this.scene.environment = pmremGenerator.fromScene( new RoomEnvironment(), 0.04 ).texture;
      this.controls = new OrbitControls(this.camera, this.renderer.domElement)
      this.controls.target.set(0, 0.5, 0)
      this.controls.update()
      this.controls.enablePan = true
      this.controls.dampingFactor = 0.15
      this.controls.enableDamping = true
      //this.controls.maxPolarAngle = Math.PI / 2


      /* eslint new-cap: ["error", { "newIsCap": false }] */


      this.renderer.render(this.scene, this.camera)
      console.log("finish three scene")


    },
    animate () {
      requestAnimationFrame(this.animate)
      // graphMesh && (graphMesh.rotation.z += 0.01);

      this.controls.update()
     // console.log(this.controls.target)

      this.renderer.render(this.scene, this.camera)
    },
      str_pad( hex ){
        var zero = '000000';
        var tmp = 6-hex.length;
        var c =  "#" + zero.substr(0,tmp) + hex;
        return c;
      }
  },
  mounted() {
      this.canvas = document.getElementById("canvas")
      this.initThree()
      this.showStep1()
      this.animate()
      
  },
  computed: {
      /**
       * 压缩比率
       */
     compressRatio: function() {
        let minsize = Math.min(this.canvas.width, this.canvas.height) - 10;
        return minsize / (this.maxDiameter + this.maxRBoxSize);
     },
     /**
      * 中心点坐标
      */
     centerPos : function() {
         return {x : this.canvas.width / 2, y: this.canvas.height / 2}
     },
     /**
       * 轨迹长度
       */
      trackLength: function() {
        let length = this.radio  * Math.abs(this.theta) * Math.PI/ 180
        return (Math.sqrt(length ** 2 + this.drop**2)).toFixed(2);
      },
      needCopyAndDown: function() {
        return BrowserInfo.isAndroid && BrowserInfo.isWeChat
      },
      /**
       * 选中逆时针曲面
       */
      selectedSufafesA: function() {
        return this.sufaceMetas.filter(v => v.selected);
      },
      /**
       * 选中顺时针曲面
       */
      selectedSufafesB: function() {
        return this.sufaceMetasB.filter(v => v.selected);
      },
      /**
       * 当前日期
       */
      currentDate: function() {
        var date = new Date();
        return date.getFullYear() + "-" + (date.getMonth()  + 1 )+ "-" + date.getDate()
      },
      /**
       * 是否展示重量,预览图全部加载完成再展示
       */
      canShowWeight() {
          return this.priviewImgUrls.length == this.graphMeshs.length
      },
      /**
       * 切割长度
       */
      cutLength() {
          let total = 0
          for (let i in this.priviewImgUrls) {
            total += this.priviewImgUrls[i].length
            if (this.priviewImgUrls[i].sfno == this.showPreviewNo) {
              return this.priviewImgUrls[i].length
            }
          }
          return total
      },
      /**
       * 切割总量
       */
      cutWeight() {
          let total = 0
          for (let i in this.priviewImgUrls) {
            total += this.priviewImgUrls[i].weight
            if (this.priviewImgUrls[i].sfno == this.showPreviewNo) {
              return this.priviewImgUrls[i].weight
            }
          }
          return total
      },
      /**
       * 最大半径
       */
      maxRadio() {
        return Math.max(400,this.radio)
      }
  }
};
</script>

<style scoped>
#prviewArea {
  height: 300px;
  width: 100%;
}
.custom-button {
  width: 26px;
  color: #fff;
  font-size: 10px;
  line-height: 18px;
  text-align: center;
  background-color: #3ea5b9;
  border-radius: 100px;
}
#footer {
  padding-top: 20px;
  min-height: 320px;
}
.van-row {
  padding-top: 20px;
}
.van-image {
  display: flex;
  align-items: center;
}
.van-slider {
  margin-top: 13px;
}
</style>