/* eslint-disable no-multiple-empty-lines */
/* eslint-disable space-unary-ops */
/* eslint-disable space-in-parens */
/* eslint-disable semi */
/* eslint-disable no-tabs */
/* eslint-disable indent */
/* eslint-disable no-unused-vars */
/* eslint-disable space-infix-ops */
/* eslint-disable vue/valid-template-root */
<template id="naviBar">
    <div>
    <van-tabbar v-model="adx">
        <van-tabbar-item v-for="(item) in items" :key="item.name" replace :to="item.to" :name="item.name" :icon="item.icon" >{{item.title}}
            <!-- {{index}}-->
        </van-tabbar-item>
    </van-tabbar>
    </div>
</template>
<script>
import Vue from 'vue';
import { Tabbar, TabbarItem } from 'vant';
Vue.use(Tabbar);
Vue.use(TabbarItem);
  export default {
    props: {
        // 定义你的props
        active: {
            type: String,
            default: 'home'
        }
    },
    data() {
        return {
            adx: this.active,
            items:[
                {
                'name': 'home',
                'title' : '首页',
                'to': '/',
                'icon' : 'home-o'
                },
                {
                'name': 'list',
                'title' : '列表',
                'to': '/list',
                'icon' : 'notes-o'
                },
                {
                'name': 'setting',
                'title' : '设置',
                'to': '/person',
                'icon' : "setting-o"
                }
             ]
        }
        
    }
}
</script>